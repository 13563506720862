import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";
import Select from 'react-select'


function OrderCancellationSetting(props) {

    const cancellationOptions = [
        { value: 'accepted', label: 'Accepted' },
        { value: 'startPreparing', label: 'Start Peparing' },
        { value: 'orderReady', label: 'Order Ready' },
        { value: 'pickUp', label: 'Pick Up' },
        { value: 'arrived', label: 'Arrived' }
    ]

    const chargeOptions = [
        { value: 'absolute', label: 'Absolute' },
        { value: 'percentage', label: 'Percentage' },
    ]


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Order Cancellation Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setOrderCancellation(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Do you want to set cancellation charge?
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                If ON than apply cancellation charge
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom flex-row justify-content-between mb-3 pb-3">
                        <div>
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Cancellation charge apply between
                            </p>
                        </div>
                        <div className="m-auto">
                            <div className="input-group mt-1 form-group">
                                <div className="mt-2">
                                    <Select
                                        defaultValue={''}
                                        className={styles.selectBoxLg}
                                        options={cancellationOptions}
                                        placeholder='' />

                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Cancellation Charge Type
                                </p>
                            </div>
                        </div>
                        <div className="col-4 m-auto">
                            <Select
                                defaultValue={''}
                                className={styles.selectBoxSm}
                                options={chargeOptions}
                                placeholder='' />
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Cancellation Charge
                                </p>
                                <p
                                    className="text-muted mb-0 text-truncate"
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                >
                                    If above on then apply
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end m-auto">
                            <div className="input-group mt-1 ">
                                <input type="number" className="form-control rounded-0 shadow-none"></input>
                                <div className={styles.textFront}>
                                    ৳
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Price
                                </p>
                                <p
                                    className="text-muted mb-0 text-truncate"
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                >
                                    Cancellation charge apply above this price
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end m-auto">
                            <div className="input-group mt-1 ">
                                <div className={styles.textFront}>
                                    ৳
                                </div>
                                <input type="number" className="form-control rounded-0 shadow-none"></input>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setOrderCancellation(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default OrderCancellationSetting;
