import React, { useState } from 'react'
import classNames from 'classnames'
import styles from '../../../../assets/css/Dispatch.module.css'

function DispatchOrder() {
  const [activeTab, setActiveTab] = useState('new')
  return (
    <div className={styles.dispatch_container}>
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection} style={{ width: '100%' }}>
          <div className={styles.titleWithBC}>
            <h3>Dispatch</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>Running Order</li>
              <li className={styles.breadcrumb_item}>Dispatch</li>
            </ol>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className={styles.mapContainer}>
          <div className="card" style={{ height: '100%' }}>
            <div className={classNames("card-body", styles.mapBody)}>
              <h1>Map</h1>
            </div>
          </div>
        </div>
        <div className={styles.assigneeContainer}>
          <div className="card" style={{ height: '100%' }}>
            <div className="card-body" style={{ height: '100%' }}>
              <div className={styles.assigneHead}>
                <div className={styles.assigneTabCont}>
                  <div className={classNames(styles.assigneeTab, activeTab === 'new' ? styles.active : '')} onClick={() => setActiveTab('new')}>New</div>
                  <div className={classNames(styles.assigneeTab, activeTab === 'assigned' ? styles.active : '')} onClick={() => setActiveTab('assigned')}>Assigned</div>
                  <div className={classNames(styles.assigneeTab, activeTab === 'delivery' ? styles.active : '')} onClick={() => setActiveTab('delivery')}>On Delivery</div>
                </div>
                <div className={styles.assigneSearch}>
                  <input className={styles.assigneSearchInput} type={'text'} placeholder={'Search'} />
                </div>
              </div>
              <div className={styles.assigneBody}>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
                <div className={styles.assigneItem}>
                  <div className={styles.itemDetailsSec}>
                    <p> Dalim Chowdhury </p>
                    <p> +8800182788529 </p>
                    <p> Rd No-6, Dhaka 1212, Bangladesh</p>
                  </div>
                  <div className={styles.itemBtnSec}>
                    <p> #91   09:38 AM </p>
                    <button className={styles.assignBtn}>Assign</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default DispatchOrder