import React from 'react'
import Modal from 'react-modal';

const FormDetails = ({ isOpen, onClose, formData }) => {
    console.log(105, formData);
    if (!formData) return null;
    return (
        <Modal
            isOpen={isOpen}
            onRequestClose={onClose}
            className="Modal"
            overlayClassName="Overlay"
        >
            <div>
                <h2>Form Details</h2>
                <table>
                    <tbody>
                        {Object.entries(formData).map(([key, value]) => (
                            <tr key={key}>
                                <td>{key}</td>
                                <td>{typeof value === 'object' ? JSON.stringify(value) : value}</td>
                            </tr>
                        ))}
                    </tbody>
                </table>
                <button onClick={onClose}>Close</button>
            </div>
        </Modal>
    );
};

export default FormDetails;

