import React from "react";
import styles from "../../../../../assets/css/ModifierCategory.module.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AddNewModifier from "../ModifierCategory/AddNewModifier"


function ModifierCategory() {
    return (
        <div className={styles.modifierCategory_page}>
            <ToastContainer />
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Modifier Category</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Modifier</li>
                            <li className={styles.breadcrumb_item}>Modifier Category</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection} style={{ justifyContent: 'space-between' }}>
                    <p style={{ marginBottom: 0 }}></p>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.page_divider}>
                    <AddNewModifier />
                </div>
                <div className={styles.page_divider}>
                    <div className={styles.listContainer} style={{ display: 'flex', alignItems: 'start', justifyContent: 'center' }}>
                        <div className="col-md-12">
                            <div className={styles.modifierItem}>
                                <div className={styles.modifierItemHolder}>
                                    <p className={styles.modifierTitle}>T-shirt</p>
                                    <button className={styles.EditBtn}>
                                        <span className={styles.editAction}><i className="fa-thin fa-pen-to-square"></i></span>
                                        <span className={styles.deleteAction}><i className="fa-thin fa-trash-can"></i></span>
                                    </button>
                                </div>
                            </div>
                            <div className={styles.modifierItem}>
                                <div className={styles.modifierItemHolder}>
                                    <p className={styles.modifierTitle}>Shirt</p>
                                    <button className={styles.EditBtn}>
                                        <span className={styles.editAction}><i className="fa-thin fa-pen-to-square"></i></span>
                                        <span className={styles.deleteAction}><i className="fa-thin fa-trash-can"></i></span>
                                    </button>
                                </div>
                            </div>
                            <div className={styles.modifierItem}>
                                <div className={styles.modifierItemHolder}>
                                    <p className={styles.modifierTitle}>Shoe size </p>
                                    <button className={styles.EditBtn}>
                                        <span className={styles.editAction}><i className="fa-thin fa-pen-to-square"></i></span>
                                        <span className={styles.deleteAction}><i className="fa-thin fa-trash-can"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );
}

export default ModifierCategory;

