
import React, { useState } from 'react';
import ReactCrop from "react-image-crop"
import 'react-image-crop/dist/ReactCrop.css'
import styles from '../../assets/css/ImageCropper.module.css'

function ImageCropper(props) {
    const [src, selectFile] = useState(null)
    const handleFileChange = event => {
        selectFile(URL.createObjectURL(event.target.files[0]))
    }

    const [image, setImage] = useState(null)
    const [crop, setCrop] = useState({ aspect: 10 / 8 });
    const [croppedImage, setCroppedImage] = useState(null);

    function getCroppedImg() {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        const base64Image = canvas.toDataURL('image/jpeg');
        setCroppedImage(base64Image);
    }

    return (
        <div className={styles.container}>
            <div className={styles.popupBody}>
                <div className='row text-center'>
                    <div className='col-md-12 d-non'>
                        <input type='file' accept='image/*' onChange={handleFileChange}></input>
                    </div>
                    {src &&
                        <div className='col-md-12 d-flex justify-content-center align-items-center'>
                            <div className={styles.cropperContainer}>
                                <ReactCrop className={styles.ImageCropper} src={src} onImageLoaded={setImage} crop={crop} onChange={setCrop} /><br></br>
                                <button className={styles.cropbtn} onClick={getCroppedImg}>Confirm</button>
                            </div>
                        </div>

                    }
                    {/* {croppedImage &&
                        <div className='col-6'>
                            <img src={croppedImage} alt='Cropped Image' className='image-fluid'></img>
                        </div>
                    } */}
                </div>
            </div>
        </div>
    );
};

export default ImageCropper;


// import React, { useState, useEffect, useRef } from 'react'
// import styles from '../../assets/css/CropImage.module.css'
// import ReactCrop from "react-image-crop"
// import 'react-image-crop/dist/ReactCrop.css'

// function CropImage(props) {
//     const [src] = useState(props.currentImage)

//     const [image, setImage] = useState(null)
//     const [crop, setCrop] = useState({ aspect: 10 / 8 });
//     const [croppedImage, setCroppedImage] = useState(null);

//     function getCroppedImg() {
//         const canvas = document.createElement('canvas');
//         const scaleX = image.naturalWidth / image.width;
//         const scaleY = image.naturalHeight / image.height;
//         canvas.width = crop.width;
//         canvas.height = crop.height;

//         const ctx = canvas.getContext('2d');
//         ctx.drawImage(
//             image,
//             crop.x * scaleX,
//             crop.y * scaleY,
//             crop.width * scaleX,
//             crop.height * scaleY,
//             0,
//             0,
//             crop.width,
//             crop.height
//         );
//         const base64Image = canvas.toDataURL('image/jpeg');
//         setCroppedImage(base64Image);
//     }
//     return (
//         <div className={styles.wrapper}>
//             <div className={styles.cropContainer}>
//                 <div className={styles.cropBody}>
//                     {!!imgSrc && (
//                         <ReactCrop src={src} onImageLoaded={setImage} crop={crop} onChange={setCrop}>
//                         </ReactCrop>
//                     )}

//                 </div>
//                 <div className={styles.cropFooter}>
//                     <button className={styles.cropConfirm} onClick={() => props.cropConfirm(croppedImage)}> Confirm </button>
//                 </div>
//                 {!!completedCrop && (
//                     <>
//                         <div>
//                             <canvas
//                                 ref={previewCanvasRef}
//                                 style={{
//                                     border: '1px solid black',
//                                     objectFit: 'contain',
//                                     width: completedCrop.width,
//                                     height: completedCrop.height,
//                                 }}
//                             />
//                         </div>
//                         <div>
//                             {/* <button onClick={onDownloadCropClick}>Download Crop</button> */}
//                             <a
//                                 ref={hiddenAnchorRef}
//                                 download
//                                 style={{
//                                     position: 'absolute',
//                                     top: '-200vh',
//                                     visibility: 'hidden',
//                                 }}
//                             >
//                                 Hidden download
//                             </a>
//                         </div>
//                     </>
//                 )}
//             </div>
//         </div>
//     )
// }

// export default CropImage


// {
//     !!completedCrop && (
//         <>
//             <div>
//                 <canvas
//                     ref={previewCanvasRef}
//                     style={{
//                         border: '1px solid black',
//                         objectFit: 'contain',
//                         width: completedCrop.width,
//                         height: completedCrop.height,
//                     }}
//                 />
//             </div>
//             <div>
//                 {/* <button onClick={onDownloadCropClick}>Download Crop</button> */}
//                 <a
//                     ref={hiddenAnchorRef}
//                     download
//                     style={{
//                         position: 'absolute',
//                         top: '-200vh',
//                         visibility: 'hidden',
//                     }}
//                 >
//                     Hidden download
//                 </a>
//             </div>
//         </>
//     )
// }