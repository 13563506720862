import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";


function LanguageSetting(props) {


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Language Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setLanguage(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="row g-0 border-bottom mb-3 pb-3">
                        <div className="col-12">
                            <div className='form-check form-check-inline'>
                                <input type="checkbox" className="form-check-input shadow-none" checked></input>
                                <label className="form-check-label">English</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setLanguage(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default LanguageSetting;
