import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/css/User.module.css";

import { IMAGE_URL } from "../../../Config/Config";
import { get_forms,duplicate_form,delete_form } from '../../../Utils/method'
import classNames from "classnames";
import brandPng from '../../../assets/brand.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function DynamicForms() {
    const [loader, setLoader] = useState(true);
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [forms, setForms] = useState([]);

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let apires = await get_forms();
                console.log('response', apires, _isMounted)
                if (_isMounted) {
                    let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => a.created_at.toString().localeCompare(b.created_at.toString()));
                    console.log('get_forms:', sort_v);
                    setForms(sort_v)
                    setLoader(false)
                }
            } catch (error) {
                setLoader(false)
                console.log();
            }
        }
        initial()
        return () => { _isMounted = false }
    }, [])
    const duplicate = async (id)=>{
        try{
            await duplicate_form({form_id:id});
            window.location.reload();
        }catch(error){
            console.log(error)
        }

    }
    const deleteForm = async (id)=>{
        try{
            await delete_form({form_id:id});
            window.location.reload();
        }catch(error){
            console.log(error)
        }

    }

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    const deleteItemFunc = (v) => {
        console.log(v)
        setSelectedItem(v)
    }




    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const results = !searchTerm
        ? forms
        : forms.filter((item) =>
            item.form_title.toLowerCase().includes(searchTerm.toLowerCase())
        );

    return (
        <div className={styles.brand_page}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Form</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Form</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection} style={{ justifyContent: 'space-between' }}>
                    <p style={{ marginBottom: 0 }}>Forms ({forms.length})</p>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} value={searchTerm} onChange={handleChange} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* <div className={styles.bottomSeparator}></div> */}
                    </div>

                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.main_body}>
                    <div className={styles.orderContainer}>
                        {results.length > 0 &&
                            results.map(v =>
                                <div className={classNames("card rounded-0", styles.subStore_card)} key={v._id}>
                                    <div className={classNames("p-3", styles.card_body)}>
                                        {v.is_active && <span className={styles.approve_text}>Active</span>}
                                        <div className="row">
                                            <div className="col-8">
                                                <h5 className="mb-2 font-weight-semibold">{v.form_title}</h5>
                                            </div>
                                            <div className="col-4 text-end">
                                                <Link to={'/dynamic_forms/edit?form_id='+v.form_id}><button className={styles.ItemEditBtn} >Edit</button> </Link>
                                                <button className={styles.ItemEditBtn} onClick={()=>duplicate(v.form_id)}>Duplicate</button> 
                                                <button className={styles.ItemEditBtn} onClick={()=>deleteForm(v.form_id)}>Delete</button> 
                                                
                                            </div>
                                        </div>
                                        <div className="mb-2 d-flex flex-column flex-grow-1">
                                            {/* <div className="row g-0">
                                                <div className="col-12">
                                                    <p className="mb-2 text-truncate small text-muted">
                                                        {v.email} {v.phone !== '' && ', +' + v.phone}
                                                    </p>
                                                </div>
                                            </div> */}
                                            {v.form_id.length > 0 &&
                                                <div className="row g-0">
                                                    <div className="col-12">
                                                        <p className="text-truncate small text-muted">
                                                            Form Id:
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6" ><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{v.form_id}</p></div>
                                                </div>
                                            }
                                            {/* {v.created_at.length > 0 &&
                                                <div className="row g-0">
                                                    <div className="col-12">
                                                        <p className="text-truncate small text-muted">
                                                            Created At:
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6" ><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{v.created_at}</p></div>
                                                </div>
                                            } */}
                                            {v.updated_at.length > 0 &&
                                                <div className="row g-0">
                                                    <div className="col-12">
                                                        <p className="text-truncate small text-muted">
                                                            Last Updated At:
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6" ><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{v.updated_at}</p></div>
                                                </div>
                                            }
                                            {v.type &&
                                                <div className="row g-0">
                                                    <div className="col-12">
                                                        <p className="text-truncate small text-muted">
                                                            Type:
                                                        </p>
                                                    </div>
                                                    <div className="col-md-6" ><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{v.type}</p></div>
                                                </div>
                                            }
                                            
                                        </div>
                                    </div>
                                </div>
                            )}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DynamicForms;