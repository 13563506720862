import React, { useState, useEffect } from "react";
import styles from "../../../../assets/css/PastOrder.module.css";
import { getStoreData, list_orders_history } from "../../../../Utils/method";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ORDER_STATE_Case } from "../../../../Utils/common";
import Select from "react-select";
import moment from 'moment';
import classNames from "classnames";


function History() {
  const store_data = getStoreData({ type: 'get' });
  const [history, setHistory] = useState([])
  const [loader, setLoader] = useState(true)
  const searchOptions = [
    { value: 'user', label: 'Search By : User' },
    { value: 'order', label: 'Search By : Order' },
  ]

  const deliveryOptions = [
    { value: 'delivery', label: 'Delivery', name: 'delivery', checked: true },
    { value: 'pickup', label: 'Pick Up', name: 'pickup', checked: true },
    { value: 'schedule', label: 'Schedule', name: 'schedule', checked: true },
    { value: 'now', label: 'Now', name: 'now', checked: true },
  ]

  const [paymentOptions, setPaymentOptions] = useState([
    { value: 'cash', label: 'Cash', id: 'cash', name: 'cash', checked: true }
  ])
  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: '14px',
      boxShadow: 'none',
      '&:focus': {
        border: '0 !important',
      },
      border: 'none',
      background: 'transparent',
      minHeight: '18px'
    }),
    multiValue: (base) => ({
      ...base,
      background: '#ced4da',
      borderRadius: '0px',
      marginTop: '0px',
      fontWeight: '400',
      lineHeight: '18px',
      position: 'relative',
      top: '-2px',
      left: '-10px'
    }),
    ValueContainer: (base) => ({

    })
  }

  useEffect(() => {
    let _isMounted = true;

    async function initial() {
      setLoader(true)
      try {
        let d = {
          id: store_data.store._id,
          store_id: store_data.store._id,
          server_token: store_data.store.server_token,
          start_date: null,
          end_date: null,
          page: 1,
          perPage: 10,
          query: {},
          timezone: store_data.timezone
        }
        console.log(30, d);

        let apires = await list_orders_history(d);
        console.log(33, apires);
        if (_isMounted) {
          if (apires.success) {
            setHistory(apires.data.results)
          } else {
            console.log(33, apires.status_phrase);
            toast.error('No data found!', {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error);
      }
    }
    initial()

    return () => {
      _isMounted = false
    }
  }, [])

  return (
    <div className={styles.order_container}>
      <ToastContainer />
      {loader && <div className="loaderContainer"></div>}
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}>
          <div className={styles.titleWithBC}>
            <h3>History</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>History</li>
              <li className={styles.breadcrumb_item}>History</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection}>
          <span className={styles.per_page}>Per Page</span>
          <select className={styles.btn_page}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <Select className={styles.searchBy}
                defaultValue={''}
                isSearchable={false}
                placeholder='Search By : User'
                // onChange={set_search_by}
                options={searchOptions}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Search" className={styles.searchbox} />
                <button className={styles.searchIcon} type="submit">
                  <span><i className="fa-light fa-magnifying-glass"></i></span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <Select className={styles.btn_delivery}
                defaultValue={''}
                isMulti
                isSearchable={false}
                placeholder='Delivery Type'
                styles={selectStyles}
                options={deliveryOptions}
              // onChange={set_delivery_type}
              >
              </Select>
            </div>
            <div className={styles.navItem}>
              <Select className={styles.btn_payment}
                defaultValue={''}
                isMulti
                isSearchable={false}
                placeholder='Select Payment By '
                styles={selectStyles}
                options={paymentOptions}
              // onChange={set_payment_by}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply}>Apply</button>
            </div>
          </div>
          <div className={styles.nav_item_right}>
            <div className={styles.navItem}>
              <button className={styles.exportButton}>Export</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="card rounded-0">
              <div className="card-body table-responsive">
                <table className="table orderlist_table">
                  <thead className={styles.thead_light}>
                    <tr className={styles.table_heads}>
                      <th>Order</th>
                      <th>User</th>
                      <th>Delivery Boy</th>
                      <th>Price</th>
                      <th>Status</th>
                      <th>Type</th>
                      <th>Completed</th>
                      <th>Created</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tbody}>
                    {!loader &&
                      history.map((v, i) =>
                        <tr className="text-left" key={'past_' + i}>
                          <td >{v.unique_id}</td>
                          <td >{v.user_name}</td>
                          <td >{v.provider_name ? v.provider_name : 'N/A'}</td>
                          <td >{v.currency_sign} {v.price} ({v.payment_method})</td>
                          <td >
                            {/* <span className={styles.statusLabel}>{ORDER_STATE_Case(v.order_status).replace(/_/g, ' ')}</span> */}
                            {v.order_status === 25 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#3e884f', color: '#fff' }}> Delivered </span>
                              : v.order_status === 17 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#3e884f', color: '#fff' }}> Picked </span>
                                : v.order_status === 112 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#bd2130', color: '#fff' }}> Cancelled </span>
                                  : v.order_status === 104 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#bd2130', color: '#fff' }}> Cancelled </span>
                                    : v.order_status === 101 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#bd2130', color: '#fff' }}> Cancelled By User </span>
                                      : v.order_status === 103 ? <span className={classNames(styles.statusLabel)} style={{ backgroundColor: '#d39e00', color: '#fff' }}> Rejected </span>
                                        : <span className={styles.statusLabel} style={{ backgroundColor: '#d39e00', color: '#fff' }}> .... </span>
                            }
                          </td>
                          <td >
                            {!v.is_user_pick_up_order && !v.is_schedule_order ?
                              <span className={styles.typeLabel} style={{ border: '1px solid #3e884f', color: '#3e884f' }}>Delivery</span>
                              : v.is_user_pick_up_order ?
                                <span className={styles.typeLabel} style={{ border: '1px solid #145388', color: '#145388' }}> Pickup</span>
                                : v.is_schedule_order &&
                                <span className={styles.typeLabel} style={{ border: '1px solid #3e884f', color: '#3e884f' }}>Schedule Delivery</span>
                            }
                          </td>
                          <td >{moment(v.completed_at).format('DD MMM YYYY hh:mm A')}</td>
                          <td >{moment(v.created_at).format('DD MMM YYYY hh:mm A')}</td>
                        </tr>
                      )}
                    {(!loader && history.length === 0) &&
                      <tr className="text-center">
                        <td colSpan={12}>History Not Found</td>
                      </tr>
                    }
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div >
      {/* <div className="row mt-2">
        <div className="col-12">
          <pagination className="justify-content-center">
            <ul className="pagination justify-content-center">
              <li className="pagination-prev page-item disabled">
                <a className={styles.arrow_links}>
                  <i className="fa-duotone fa-circle-arrow-left" style={{ fontSize: 28, paddingTop: 10 }}></i>
                </a>
              </li>
              <li className="pagination-page page-item active">
                <a href="#" className={styles.page_link}>1</a>
              </li>
              <li className="pagination-next page-item disabled">
                <a href="#" className={styles.arrow_links}>
                  <i className="fa-duotone fa-circle-arrow-right" style={{ fontSize: 28, paddingTop: 10 }} ></i>
                </a>
              </li>
            </ul>
          </pagination>
        </div>
      </div> */}
    </div >
  );
}

export default History;
