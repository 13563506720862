import React, { useState } from 'react';
import styles from '../../../../assets/css/Order_details.module.css'
import classNames from 'classnames';
import ukbd from "../../../../assets/UKBD.png"
import PerfectScrollbar from 'react-perfect-scrollbar'

const Order_details = (props) => {
    const [activeTab, setActiveTab] = useState('DETAILS')

    return (
        <div className={styles.backwrap}>
            <div className={styles.Order_details_popup}>
                <div className="row mt-3">
                    <div className="col-md-12 text-center">
                        <h4 className={styles.itemDes}>
                            <span className={styles.pop_logo}><img src={ukbd} alt="" /> </span> <span className={styles.pop_title}>UK & EU Brands {'>'} Order {'>'} Running Order {'>'} Past Order</span>
                            <span className={styles.itemDesIcon} onClick={() => props.setOrderDetails(false)}><i className="fa-thin fa-xmark"></i></span>
                        </h4>
                    </div>
                    <div className="col-md-12">
                        <div className={styles.tab_container}>
                            <div className={styles.tab_header}>
                                <div onClick={() => setActiveTab('DETAILS')} className={classNames(styles.tab_head_item, activeTab === 'DETAILS' ? styles.active : '')}>DETAILS</div>
                                <div onClick={() => setActiveTab('INVOICE')} className={classNames(styles.tab_head_item, activeTab === 'INVOICE' ? styles.active : '')}>INVOICE</div>
                                <div onClick={() => setActiveTab('STATUS')} className={classNames(styles.tab_head_item, activeTab === 'STATUS' ? styles.active : '')}>STATUS</div>
                                <div onClick={() => setActiveTab('CHAT')} className={classNames(styles.tab_head_item, activeTab === 'CHAT' ? styles.active : '')}>CHAT</div>
                            </div>
                            <div className={styles.tab_body}>
                                {activeTab === 'DETAILS' &&
                                    <div className='tab-pane fade show active' style={{ height: 'calc(100% - 54px)' }}>

                                        <div className={styles.tab_data_container}>
                                            <div className='row'>
                                                <div className='col-md-6'>
                                                    <div className='col-md-12'>
                                                        <h4>Invoice #138 - Jun 7, 2023</h4>
                                                    </div>
                                                    <div className='col-md-12'>
                                                        <p className={styles.confirmation_text}>Confirmation Code - 853258</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={styles.delivery_at}>
                                                        <p className={styles.delivery_at_text}>
                                                            Delivery at<br></br>07 Jun 2023 10:14 AM
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                            <hr></hr>
                                            <div className='row mt-5'>
                                                <div className='col-md-12'>
                                                    <h4 >
                                                        Order By
                                                    </h4>
                                                </div>
                                            </div>
                                            <div className='row mt-3'>
                                                <div className='col-md-8'>
                                                    <h4 style={{ fontWeight: 300 }}>Sultanul Arefin</h4>
                                                    <h4 style={{ fontWeight: 300 }}>+8801717171717</h4>
                                                </div>
                                                <div className='col-md-4'>
                                                    <div className={styles.callbtn}>
                                                        <i class="fa-thin fa-phone"></i>
                                                    </div>
                                                </div>

                                            </div>
                                            <hr></hr>
                                            <div className='row mt-5'>
                                                <div className='col-md-6'>
                                                    <div className={styles.logoAndText}>
                                                        <i class="fa-duotone fa-map-location-dot"></i>
                                                        <h4 className={styles.address_text}>
                                                            Dhaka, Bangladesh
                                                        </h4>
                                                    </div>
                                                    <div className={styles.small_text}>
                                                        <p>0 km, 0 min</p>
                                                    </div>
                                                </div>
                                                <div className='col-md-6'>
                                                    <div className={styles.delivery_at}>
                                                        <p className={styles.order_for}>
                                                            Order For<span>(01614303606)</span>
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeTab === 'INVOICE' &&
                                    <div className='tab-pane fade show active' style={{ height: 'calc(100% - 54px)' }}>
                                        <div className={styles.tab_data_container}>
                                            <div className='card mb-5 border-0  shadow-sm'>
                                                <div className='card-body'>
                                                    <div className='row '>
                                                        <div className='col-md-6'>
                                                            <div className='col-md-12'>
                                                                <h1>Total {':'} &#2547; 5.54</h1>
                                                            </div>
                                                            <div className='col-md-12'>
                                                                <div className={styles.InvoiceDelivery_text}>
                                                                    <p>Free Delivery</p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className='col-md-6'>
                                                            <div className={styles.invoice_text}>
                                                                <h5>
                                                                    #invoice 138
                                                                </h5>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <hr></hr>
                                                    <table className='table table-borderless mt-5 mb-5'>
                                                        <thead>
                                                            <tr>
                                                                <th className='text-muted mb-2'>ORDER DETAILS</th>
                                                                <th className='text-muted mb-2'>COUNT</th>
                                                                <th className='text-muted mb-2 ' style={{ textAlign: 'right' }}>PRICE</th>
                                                            </tr>
                                                        </thead>
                                                    </table>
                                                    <hr></hr>
                                                    <table className='table table-borderless d-flex justify-content-end mb-3'>
                                                        <tbody>
                                                            <tr>
                                                                <td className='text-muted'>Cart Total : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 5.54</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-muted'>VAT/TAX : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 0</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-muted'>Delivery Price : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 0.00</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-muted'>Tip Amount : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 0</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='' style={{ color: '#012965' }}>Total Item Price : </td>
                                                                <td style={{ textAlign: 'right', color: '#012965' }}>&#2547; 5.54</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-muted'>Discount : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 0</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='text-muted'>Store Profit : </td>
                                                                <td style={{ textAlign: 'right' }}>&#2547; 0</td>
                                                            </tr>
                                                            <tr>
                                                                <td className='' style={{ color: '#012965', fontSize: '20px' }}>Total : </td>
                                                                <td style={{ textAlign: 'right', color: '#012965', fontSize: '20px' }}>&#2547; 5.54</td>
                                                            </tr>
                                                        </tbody>

                                                    </table><hr></hr>
                                                    <h2 style={{ textAlign: 'center' }}>Payment Done By</h2>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeTab === 'STATUS' &&
                                    <div className='tab-pane fade show active' style={{ height: 'calc(100% - 54px)' }}>
                                        <div className={styles.tab_data_container}>
                                            <div>
                                                <ol className={styles.orderStep}>
                                                    <li className='pb-3'>Waiting For Accept Store<br></br>
                                                        <span style={{ fontWeight: 'bold' }}>07 Jun 23, 10:14 AM</span>
                                                    </li>
                                                    <li className='pb-3'>Waiting For Accept Store<br></br>
                                                        <span style={{ fontWeight: 'bold' }}>07 Jun 23, 10:14 AM</span>
                                                    </li>
                                                    <li className='pb-3'>Waiting For Accept Store<br></br>
                                                        <span style={{ fontWeight: 'bold' }}>07 Jun 23, 10:14 AM</span>
                                                    </li>
                                                    <li className='pb-3'>Waiting For Accept Store<br></br>
                                                        <span style={{ fontWeight: 'bold' }}>07 Jun 23, 10:14 AM</span>
                                                    </li>
                                                    <li className='pb-3'>Waiting For Accept Store<br></br>
                                                        <span style={{ fontWeight: 'bold' }}>07 Jun 23, 10:14 AM</span>
                                                    </li>
                                                </ol>
                                            </div>
                                        </div>
                                    </div>
                                }
                                {activeTab === 'CHAT' &&
                                    <div className='tab-pane fade show active' style={{ height: 'calc(100% - 54px)' }}>
                                        <div className={styles.tab_data_container}>
                                            <PerfectScrollbar className={styles.scroll}>
                                                <div className={styles.positionStatic}>
                                                    <div className='ps-content'>
                                                        <div className='float-end card rounded-0 d-inline-block mb-3 mt-2'>
                                                            <div className='psoition-absolute pt-1 pe-2 r-0 ps-2 l-0'>
                                                                <span className={styles.message_span}>Jun 21, 2023</span>
                                                                <div className='card-body'>
                                                                    <div className={styles.chat_text_left}>
                                                                        <p className={styles.chatText}>Hello</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </PerfectScrollbar>
                                            <div className={styles.sendMessageBox}>
                                                <input type='text' className={classNames('form-control d-inline-block', styles.chatInput)} placeholder='Say Something....'></input>
                                                <button className={styles.msgSendBtn}>
                                                    <i class="fa-regular fa-arrow-right"></i>
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default Order_details;