/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import styles from '../../../assets/css/Checkout.module.css';
import classNames from "classnames";
import AddressForm from "./AddressForm";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from 'react-redux';
import {
    // get_favoutire_addresses,
    // delete_favourite_address,
    // get_card_list,
    // delete_card,
    // select_card
} from '../../../Utils/method'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Loader from "../Loader";
import ukbd from '../../../assets/UKBD.png'
import visa from '../../../assets/visa.png'
import mastercard from '../../../assets/masterCard.png'

function Checkout(props) {
    let navigate = useNavigate();
    const dispatch = useDispatch()
    const [currentAddress, setCurrentAddress] = useState(1);
    const [currentMethod, setCurrentMethod] = useState(2);
    const [newAddress, setNewAddress] = useState(false);
    const [addressLoader, setAddressLoader] = useState(false);
    const [deliveryAddress, setDeliveryAddress] = useState([]);
    const [card_list, setCard_list] = useState([])

    const addressOnselect = (data) => {
        setCurrentAddress(data._id);
        dispatch({ type: 'set_selectedDLAddress', payload: data });
    }

    const get_addresses = async () => {
        // try {
        //     setAddressLoader(true)
        //     let apires = await get_favoutire_addresses();
        //     if (apires.success) {
        //         setDeliveryAddress([...apires.favourite_addresses]);
        //         if (apires.favourite_addresses.length > 0) {
        //             addressOnselect(apires.favourite_addresses[0])
        //         }
        //     }
        //     setAddressLoader(false)
        // } catch (error) {
        //     console.log(error);
        //     setAddressLoader(false)
        // }
    }

    const delete_address = async (e, data) => {
        // e.stopPropagation();
        // try {
        //     let apires = await delete_favourite_address({ address_id: data._id })
        //     console.log(87, apires)
        //     if (apires.success) {
        //         setDeliveryAddress([...apires.favourite_addresses]);
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    const deleteCard = async (id) => {
        // try {
        //     let apires = await delete_card(id);
        //     if (apires.success) {
        //         toast.success('Card Deleted successfully', {
        //             position: 'top-right',
        //             autoClose: 4000,
        //             hideProgressBar: false,
        //             closeOnClick: true,
        //             pauseOnHover: true,
        //             draggable: true
        //         });
        //         setCard_list(prev => prev.filter(f => f._id !== id))
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    const [s_card, setS_card] = useState('')
    const [default_card, setDefault_card] = useState([])
    const selectCard = async (id) => {
        // try {
        //     setS_card(id)
        //     let apires = await select_card(id)
        //     console.log(86, apires);
        //     setDefault_card([apires.card])
        // } catch (error) {
        //     console.log(error);
        // }
    }

    useEffect(() => {
        console.log(93, default_card);
    }, [default_card])

    const get_cards = async () => {
        // try {
        //     let apires = await get_card_list()
        //     console.log('get_card_list:', apires);
        //     if (apires.success) {
        //         setS_card(apires.cards.filter(f => f.is_default)[0]._id)
        //         setDefault_card(apires.cards.filter(f => f.is_default))
        //         setCard_list(apires.cards)
        //     } else {
        //         setDefault_card([])
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    useEffect(() => {
        get_cards()
        get_addresses();
    }, [])

    return (
        <div className={styles.checkout_Popup}>
            <ToastContainer newestOnTop />
            <h4 className={styles.carting_head}>
                <div className={styles.pageTitleSection}>
                    <Link className={styles.pageLogoHolder} to={'https://ukbd.app'}>
                        <img className={styles.pageLogo} src={ukbd} alt="" />
                    </Link>
                    <div className={styles.pageTitle} onClick={() => navigate('/')}>
                    <span>UK & EU Brands {'>'} Partner {'>'} Checkout</span>
                    </div>
                </div>
                <span className={styles.closeCart} onClick={() => props.setCart_stage(prev => Number(prev - 1))}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </h4>
            <div className={styles.cartItemContainer}>
                <div className={styles.checkout_section}>
                    <div className={styles.sectionTitle}>SELECT DELIVERY ADDRESS</div>
                    <div className={styles.sectionBody}>
                            <div className={styles.deliveryAddressConatiner}>
                                <AddressForm type={'checkout'} setNewAddress={setNewAddress} setDeliveryAddress={setDeliveryAddress} addressOnselect={addressOnselect} />
                            </div>
                        {/* {newAddress ?
                            :
                            <div className={styles.deliveryAddressConatiner}>
                                {addressLoader ? <Loader />
                                    :
                                    deliveryAddress.length > 0 ?
                                        deliveryAddress.map((v) =>
                                            <div className={classNames(styles.delivery_address)} onClick={() => addressOnselect(v)} key={v._id}>
                                                <div className={styles.stdmethod_mark}>
                                                    <span className={classNames(styles.markerDot, currentAddress === v._id ? styles.active : '')}></span>
                                                </div>
                                                <div className={styles.addresses}>
                                                    <h2>{v.address_name}</h2>
                                                    <address>
                                                        {v.phone !== '' && <p> Phone: {v.phone} </p>}
                                                        <p>{v.address}</p>
                                                    </address>
                                                </div>
                                                <div className={styles.stdmethodRate} onClick={(e) => delete_address(e, v)}>
                                                    <span style={{ color: '#ff0000' }}><i className="fa-thin fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                        )
                                        : ''
                                }
                            </div>
                        } */}
                        {/* {!newAddress &&
                            <div className={styles.addNewAddress} onClick={() => setNewAddress(true)}>
                                <span><i className="fa-light fa-plus"></i></span>
                                <span style={{ marginLeft: '10px' }}>Add a new address</span>
                            </div>
                        } */}
                    </div>
                </div>
                <div className={styles.checkout_section}>
                    <div className={styles.sectionTitle}>SELECT DELIVERY OPTION</div>
                    <div className={styles.sectionBody}>
                        <div className={styles.deliveryMethodConatiner}>
                            <div className={classNames(styles.stdmethod_item)} onClick={() => setCurrentMethod(2)}>
                                <div className={styles.stdmethod_mark}>
                                    <span className={classNames(styles.markerDot, currentMethod === 2 ? styles.active : '')}></span>
                                </div>
                                <div className={styles.stdmethod_Desc}>
                                    <div className={styles.stdmethodTitle}> Standard Delivery</div>
                                    <p className={styles.stdmethodSubTitle}>Your order will be delivered within 10 to 15 days</p>
                                </div>
                                <div className={styles.stdmethodRate}>
                                    <span>Free</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.checkout_section}>
                    <div className={styles.sectionTitle}>SELECT PAYMENT METHOD</div>
                    <div className={styles.sectionBody}>
                        <div className={styles.paymentMethodConatiner}>
                            <div className={classNames(styles.payMethod_item)} style={{ backgroundColor: 'rgb(1 41 101 / 8%)' }}>
                                {/* <div className={styles.stdmethod_mark}>
                                    <span className={classNames(styles.markerDot, styles.active)}></span>
                                </div> */}
                                <div className={styles.stdmethod_Desc} style={{ paddingLeft: 10 }}>
                                    <div className={styles.stdmethodTitle}>Credit/Debit Card</div>
                                </div>
                                <div className={styles.paymentMIcon}>
                                    <span className={styles.visaCa}></span>
                                    <span className={styles.masterCa}></span>
                                    <span className={styles.aExpressCa}></span>
                                </div>
                            </div>
                            {card_list.length > 0 &&
                                <div className={styles.savedCards}>
                                    <div className={classNames(styles.card_item, styles.add_new_card)} onClick={() => navigate('/payment', { state: [] })}>
                                        <span style={{ marginRight: 10 }}><i className="fa-light fa-plus"></i></span> Add New Card
                                    </div>
                                    <div className={styles.saved_cards}>
                                        {card_list.map(v =>
                                            <div className={styles.card_item} key={v._id}>
                                                <div className={styles.card_left} onClick={() => selectCard(v._id)}>
                                                    <div className={styles.stdmethod_mark}>
                                                        <span className={classNames(styles.markerDot, s_card === v._id && styles.active)}></span>
                                                    </div>
                                                    <div className={styles.card_logo}>
                                                        <img src={v.card_type === 'visa' ? visa : v.card_type === 'mastercard' ? mastercard : ''} alt='card' />
                                                    </div>
                                                    <div className={styles.card_details}>
                                                        <p>**** {v.last_four}, Exp. {v.card_expiry_date}</p>
                                                        {/* <p>{v.card_holder_name}</p> */}
                                                    </div>
                                                </div>
                                                <div className={styles.card_right}>
                                                    <span style={{ color: '#ff0000' }} onClick={() => deleteCard(v._id)}><i className="fa-thin fa-trash-can"></i></span>
                                                </div>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            }
                            <div className={classNames(styles.payMethod_item)} style={{ pointerEvents: 'none' }}>
                                {/* <div className={styles.stdmethod_mark}>
                                    <span className={classNames(styles.markerDot)}></span>
                                </div> */}
                                <div className={styles.stdmethod_Desc} style={{ width: 'calc(100% - 50px)',paddingLeft: 10 }}>
                                    <div className={styles.stdmethodTitle}>Cash on Delivery <span style={{ fontSize: '14px', opacity: '0.8', marginLeft: '5px' }}>(Not Available)</span></div>
                                </div>
                                {/* <div></div> */}
                                {/* <div className={styles.paymentMIcon}></div> */}
                            </div>
                            <div className={classNames(styles.payMethod_item)} style={{ pointerEvents: 'none' }}>
                                {/* <div className={styles.stdmethod_mark}>
                                    <span className={classNames(styles.markerDot)}></span>
                                </div> */}
                                <div className={styles.stdmethod_Desc} style={{ width: 'calc(100% - 105px)',paddingLeft: 10 }}>
                                    <div className={styles.stdmethodTitle}>bKash <span style={{ fontSize: '14px', opacity: '0.8', marginLeft: '5px' }}>(Not Available)</span></div>
                                </div>
                                <div className={styles.paymentMIcon} style={{ width: 55 }}>
                                    <span className={styles.bKashCa}></span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {/* {timelineStage === 1 ?
                    <div className={styles.itemPart}>
                        {newAddress ?
                            <div className={styles.deliveryAddressConatiner}>
                                <AddressForm type={'checkout'} setNewAddress={setNewAddress} setDeliveryAddress={setDeliveryAddress} addressOnselect={addressOnselect} />
                            </div>
                            :
                            <div className={styles.deliveryAddressConatiner}>
                                {
                                    addressLoader ? <Loader />
                                        :
                                        deliveryAddress.length > 0 ?
                                            deliveryAddress.map((v) =>
                                                <div className={classNames(styles.delivery_address)} onClick={() => addressOnselect(v)} key={v._id}>
                                                    <div className={styles.stdmethod_mark}>
                                                        <span className={classNames(styles.markerDot, currentAddress === v._id ? styles.active : '')}></span>
                                                    </div>
                                                    <div className={styles.addresses}>
                                                        <h2>{v.address_name}</h2>
                                                        <address>
                                                            {v.phone !== '' && <p> Phone: {v.phone} </p>}
                                                            <p>{v.address}</p>
                                                        </address>
                                                    </div>
                                                    <div className={styles.stdmethodRate} onClick={(e) => delete_address(e, v)}>
                                                        <span><i className="fa-thin fa-trash-can"></i></span>
                                                    </div>
                                                </div>
                                            )
                                            : ''}
                                <div className={styles.addNewAddress} onClick={() => setNewAddress(true)}>
                                    <span><i className="fa-light fa-plus"></i></span>
                                    <span style={{ marginLeft: '10px' }}>Add a new address</span>
                                </div>
                            </div>
                        }
                        <div className={styles.deliveryMethodConatiner}>
                            <p className={styles.method_head}>
                                <h2>Delivery Methods</h2>
                            </p>
                            <div className={classNames(styles.stdmethod_item)} onClick={() => setCurrentMethod(2)}>
                                <div className={styles.stdmethod_mark}>
                                    <span className={classNames(styles.markerDot, currentMethod === 2 ? styles.active : '')}></span>
                                </div>
                                <div className={styles.stdmethod_Desc}>
                                    <div className={styles.stdmethodTitle}> Standard Delivery</div>
                                    <div className={styles.stdmethodSubTitle}>
                                        <span>Your order will be delivered within 10 to 15 days</span>
                                    </div>
                                </div>
                                <div className={styles.stdmethodRate}>
                                    <span>Free</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    : timelineStage === 2 ?
                        <Payment invoice={invoice} deliveryAddress={deliveryAddress} />
                        :
                        <Confirmation />
                } */}

                {/* <Summary type="checkout" deliveryAddress={deliveryAddress} timelineStage={timelineStage} setShow={props.setShow} /> */}
            </div>
            <div className={styles.carting_footer}>
                <button className={styles.proceed_to_chec}>SECURELY PAY</button>
            </div>
        </div>
    )
}

export default Checkout;