import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";


function GeneralSetting(props) {


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit General Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setGeneralStting(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Business
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                Business
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Busy
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                Busy
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Visible
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                Visible
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setGeneralStting(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default GeneralSetting;
