import classNames from 'classnames';
import React from 'react'
import styles from '../../assets/css/DeletePopup.module.css';

function DeletePopup(props) {
  return (
    <div className={styles.wrapper}>
        <div className={styles.deleteContainer}>
            <div className={styles.iconSec}>
                <span className={styles.icon}><i className="fa-thin fa-circle-xmark"></i></span>
            </div>
            <div className={styles.titleSec}>
                <h2>Are you sure?</h2>
            </div>
            <div className={styles.subTitleSec}>
                <p>Do you really want to delete this record? This process cannot be undone.</p>
            </div>
            <div className={styles.buttonSec}>
                <button className={styles.button} onClick={()=> props.deleteCancel()}>Cancel</button>
                <button className={classNames(styles.button, styles.delete)} onClick={()=> props.deleteItem()}>Delete</button>
            </div>
        </div>
    </div>
  )
}

export default DeletePopup