/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "../../../../assets/css/Brand.module.css";
import { IMAGE_URL } from "../../../../Config/Config";
import { delete_brand, get_brand, getStoreData, get_domains } from '../../../../Utils/method'
import brandPng from '../../../../assets/brand.png'
import AddNewBrand from "./AddNewBrand";
import DeletePopup from "../../../Popups/DeletePopup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Brand() {
    const [loader, setLoader] = useState(true);
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [brands, setBrands] = useState([]);
    const [domain_list, setDomain_list] = useState(localStorage.getItem('domain_list') ? JSON.parse(localStorage.getItem('domain_list')) : []);
    const sub_store = getStoreData('get').sub_store;

    const get_domain_list = async () => {
        try {
            let apires = await get_domains();
            let data = []
            let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
                if (sub_store) {
                    if (sub_store?.access_domains.indexOf(p.name) > -1) {
                        data.push({ value: p._id, label: p.name })
                    }
                } else {
                    data.push({ value: p._id, label: p.name })
                }
            }

            localStorage.setItem('domain_list', JSON.stringify(data))
            setDomain_list(data);
        } catch (error) {
            console.log();
        }
    }


    const updateArray = (nv) => {
        setBrands([...brands, nv])
    }

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let apires = await get_brand(`?perPage=${2000}`);
                if (_isMounted) {
                    console.log(16, apires);
                    if (apires.data.brands.length > 0) {
                        let sort_v = apires.data.brands.sort((a, b) => a.name.localeCompare(b.name));
                        if (sub_store) {
                            // console.log(59, sort_v, access_domains)
                            let access = []
                            for (const p of domain_list) {
                                if (sub_store?.access_domains.indexOf(p.label) > -1) {
                                    access.push(p.value)
                                }
                            }
                            setBrands(sort_v.filter(f => access.indexOf(f.domain_ids[0]) > -1));
                        } else {
                            setBrands(sort_v);
                        }
                    }
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.log(error)
            }
        }
        get_domain_list()
        initial()
        return () => { _isMounted = false }
    }, [])

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    const deleteItemFunc = (v) => {
        console.log(v)
        setSelectedItem(v)
        setDeleteStatus(true);
    }

    const deleteCancel = () => {
        setDeleteStatus(false);
    }

    const deleteItem = async () => {
        try {
            let apires = await delete_brand({ _id: selectedItem._id, is_delete_items: false })
            console.log(apires)
            toast.success('Brand Deleted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            setBrands(brands.filter(i => i._id !== selectedItem._id))
            setDeleteStatus(false);
        } catch (error) {
            console.log(error)
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const results = !searchTerm
        ? brands
        : brands.filter((item) =>
            item.name.toLowerCase().includes(searchTerm.toLowerCase())
        );

    return (
        <div className={styles.brand_page}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Brand</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Menu</li>
                            <li className={styles.breadcrumb_item}>Brand</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection} style={{ justifyContent: 'space-between' }}>
                    <p style={{ marginBottom: 0 }}>Brand ({brands.length})</p>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} value={searchTerm} onChange={handleChange} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* <div className={styles.bottomSeparator}></div> */}
                    </div>

                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.page_divider}>
                    <AddNewBrand editStatus={editStatus} setEditStatus={setEditStatus} editItem={editItem} updateArray={updateArray} editCounter={editCounter} brands={brands} setBrands={setBrands} domain_list={domain_list} />
                </div>
                <div className={styles.page_divider}>
                    <div className={styles.listContainer} style={results.length === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>
                        {results.length > 0 ?
                            results.map((v) =>
                                <div className="col-md-12" key={v._id}>
                                    <div className={styles.brandItem}>
                                        <div className={styles.brandItemHolder}>
                                            {v.image !== '' ?
                                                <img className={styles.brandItemImg} src={IMAGE_URL + v.image}
                                                    onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = brandPng }} alt="Brand_img" />
                                                :
                                                <img className={styles.brandItemImg} src={brandPng} onError={({ e }) => { e.onerror = null; e.src = brandPng; }} alt='category_img' />
                                            }
                                        </div>
                                        <p className={styles.brandItemTitle}>{v.name}</p>
                                        <button className={styles.EditBtn}>
                                            <span className={styles.editAction} onClick={() => editItemFunc(v)}><i className="fa-thin fa-pen-to-square"></i></span>
                                            <span className={styles.delteAction} onClick={() => deleteItemFunc(v)}><i className="fa-thin fa-trash-can"></i></span>
                                        </button>
                                    </div>
                                </div>
                            )
                            :
                            <h2 className="not_found_msg">No data found!</h2>
                        }
                    </div>
                </div>
            </div>
            {deleteStatus && <DeletePopup deleteCancel={deleteCancel} deleteItem={deleteItem} />}
        </div>
    )
}

export default Brand;