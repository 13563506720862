import React, { useEffect, useState, useRef } from 'react'
import styles from '.././../../../../assets/css/AddSubCategory.module.css'

function AddNewModifier() {
    return (
        <div className={styles.addSubCategoryPopup}>
            <div className={styles.addSub_header}>
                <p>Add New Modifier</p>
            </div>
            <form className={styles.addSub_body}>
                <div className='col-md-12'>
                    <div className='row mt-1 mb-2'>
                        <div className='row'>
                            <label className='small mb-1'>Modifier Category Name</label>
                            <div className='input-group mt-1'>
                                <input type="text" name="name" className='form-control rounded-0' placeholder='Modifier Name'></input>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
            <div className={styles.addSub_footer}>
                <button className={styles.cancelBtn} >Reset</button>
                <button className={styles.submitBtn} >Submit</button>
            </div>
        </div>
    );
}
export default AddNewModifier;