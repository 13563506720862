import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../../assets/css/AddSubCategory.module.css'
import { IMAGE_URL } from '../../../../Config/Config'
import { getStoreData, add_product, update_product, get_domains } from '../../../../Utils/method'
import categoryPng from '../../../../assets/category.png'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select'

function AddSubCategory(props) {
  const store_data = getStoreData({ type: 'get' });
  const [domain_list, setDomain_list] = useState([]);
  const [selected_domain, setSelected_domain] = useState([]);

  const [formData, setFormData] = useState({
    name: '',
    image: '',
    domain_ids: [],
    is_visible_in_store: false,
    store_id: store_data.store._id,
    server_token: store_data.store.server_token
  })

  const formRef = useRef(null);
  const [curentImage, setCurentImage] = useState('');

  useEffect(() => {
    if (props.editStatus) {
      setFormData({
        ...formData,
        name: props.editItem.name,
        image: props.editItem.image_url,
        domain_ids: props.editItem.domain_ids,
        is_visible_in_store: props.editItem.is_visible_in_store,
      })

      let _domain = []
      for (const key in domain_list) {
        const domain = domain_list[key];
        for (const i in props.editItem.domain_ids) {
          const id = props.editItem.domain_ids[i];
          if (domain.value === id) {
            _domain.push(domain)
          }
        }
      }
      setSelected_domain(_domain)
    }
  }, [props.editStatus, props.editCounter])

  const getFormData = async (e, ext) => {
    if (ext) {
      setSelected_domain(e)
      setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
    } else {
      let { name, value } = e.target;
      if (name === 'is_visible_in_store') {
        setFormData({ ...formData, [name]: e.target.checked });
      } else if (name === 'image') {
        setFormData({ ...formData, [name]: e.target.files[0] })
        setCurentImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setFormData({ ...formData, [name]: value })
      }
    }
  }

  const resetForm = () => {
    setFormData({ name: '', image: '', domain_ids: [], is_visible_in_store: false })
    setSelected_domain([])
    setCurentImage('');
    props.setEditStatus(false);
    formRef.current.reset()
  }

  const checkInputFields = () => {
    if (formData.name === '') {
      return false;
    } else {
      return true;
    }
  }

  const saveSubCategory = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('name[0]', formData.name);
    data.append('image_url[0]', formData.image);
    data.append('is_visible_in_store', formData.is_visible_in_store);
    data.append('store_id', formData.store_id);
    data.append('server_token', formData.server_token);

    if (checkInputFields()) {
      try {
        let apires = await add_product(data);
        console.log(apires);
        toast.success('Sub-category created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        resetForm();
        props.updateArray(apires.product);
      } catch (error) {
        console.log(error)
      }
    }
  }

  // useEffect(() => {
  //   let _isMounted = true;
  //   async function initial() {
  //     try {
  //       let apires = await get_main_category();
  //       console.log(apires.data)
  //       if (_isMounted) {
  //         setCategories(apires.data.data)
  //       }
  //     } catch (error) {
  //       console.log(error)
  //     }
  //   }
  //   initial()
  //   return () => _isMounted = false;
  // }, [])

  const updateSubCategory = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('_id', props.editItem._id);
    data.append('product_id', props.editItem._id);
    data.append('name[0]', formData.name);
    data.append('is_visible_in_store', formData.is_visible_in_store);
    data.append('store_id', formData.store_id);
    data.append('server_token', formData.server_token);
    data.append('timezone', store_data.timezone);

    if (typeof formData.image !== 'string') {
      data.append('image_url[0]', formData.image);
    }

    try {
      let apires = await update_product(data);
      console.log(135, apires);
      toast.success('Sub-category updated successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      resetForm();
    } catch (error) {
      console.log(error)
    }
  }

  const get_domain_list = async () => {
    try {
      let apires = await get_domains();
      let data = [];
      let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
      for (const p of sort_v) {
        data.push({ value: p._id, label: p.name })
      }
      console.log('get_domains:', data);
      setDomain_list(data)
    } catch (error) {
      console.log();
    }
  }

  useEffect(() => {
    get_domain_list()
  }, [])

  return (
    <div className={styles.addSubCategoryPopup}>
      <ToastContainer />
      <div className={styles.addSub_header}>
        {props.editStatus ? <p>Edit Sub-category</p> : <p>Add New Sub-Category</p>}
      </div>
      <form ref={formRef} className={styles.addSub_body}>
        <div className="col-md-12">
          <div className="row mt-1 mb-2">
            <div className="row">
              <label className="small">Sub Category Name</label>
              <div className="input-group mt-1 ">
                <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Sub Category Name' />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Domain</label>
              <div className="mt-1 ">
                <Select
                  value={selected_domain}
                  isMulti
                  name="domain_ids"
                  className="form-control rounded-0 basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => getFormData(e, 'domain_ids')}
                  options={domain_list}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">File Upload</label>
              <div className="input-group mt-1">
                <input type="file" onChange={(e) => getFormData(e)} name="image" className="form-control rounded-0" placeholder='Sub Category Name' />
              </div>
            </div>
            <div className="row mt-3">
              <div className="input-group mt-1 ">
                <div className="form-check form-switch">
                  <input type="checkbox" className="form-check-input" checked={formData.is_visible_in_store} name="is_visible_in_store" onChange={(e) => getFormData(e)} id="is_visible" />
                  <label className="form-check-label small" htmlFor="is_visible">Is Item Visible to user ?</label>
                </div>
                {/* <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Sub Category Name' /> */}
              </div>
            </div>
            <div className="row">
              <div className="input-group mt-1 ">
                <div className={styles.img_view_container}>
                  {props.editStatus && curentImage === '' ?
                    <img style={{ maxWidth: 150 }} src={IMAGE_URL + props.editItem.image_url} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                    : props.editStatus && curentImage !== '' ?
                      <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                      : !props.editStatus && curentImage !== '' ?
                        <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                        : <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {props.editStatus ?
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
          <button className={styles.submitBtn} onClick={updateSubCategory}>Update</button>
        </div>
        :
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
          <button className={styles.submitBtn} onClick={saveSubCategory}>Submit</button>
        </div>
      }
    </div>
  )
}

export default AddSubCategory