import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";


function ExpertiseSetting(props) {


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Expertise Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setExpertise(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Tags not found
                            </p>
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setExpertise(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default ExpertiseSetting;
