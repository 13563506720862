import React, { useEffect, useState } from "react";
import styles from "../../../../assets/css/SubCategory.module.css";
import { IMAGE_URL } from "../../../../Config/Config";
import { delete_supplier, get_supplier } from '../../../../Utils/method'
import supplierPng from '../../../../assets/supplier.png'
import AddNewSupplier from "./AddNewSupplier";
import DeletePopup from "../../../Popups/DeletePopup";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Supplier() {
    const [loader, setLoader] = useState(false);
    const [supplier, setSupplier] = useState([]);
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [deleteStatus, setDeleteStatus] = useState(false);
    const [selectedItem, setSelectedItem] = useState({});

    const updateArray = (nv) => {
        setSupplier([...supplier, nv])
    }

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let apires = await get_supplier(`?perPage=${2000}`);
                if (_isMounted) {
                    console.log(16, apires);
                    if (apires.data.suppliers.length > 0) {
                        let sort_v = apires.data.suppliers.sort((a, b) => a.name.localeCompare(b.name));
                        setSupplier(sort_v);
                    }
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.log(error)
            }
        }
        initial()
        return () => { _isMounted = false }
    }, [])

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    const deleteItemFunc = (v) => {
        console.log(v)
        setSelectedItem(v)
        setDeleteStatus(true);
    }

    const deleteCancel = () => {
        setDeleteStatus(false);
    }

    const deleteItem = async () => {
        try {
            let apires = await delete_supplier({ _id: selectedItem._id })
            console.log(apires)
            toast.success('Supplier Deleted successfully', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            setSupplier(supplier.filter(i => i._id !== selectedItem._id))
            setDeleteStatus(false);
        } catch (error) {
            console.log(error)
        }
    }

    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const results = !searchTerm
        ? supplier
        : supplier.filter((item) =>
            item.name.toString().toLowerCase().includes(searchTerm.toLowerCase())
        );

    return (
        <div className={styles.subcategory_page}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Supplier</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Menu</li>
                            <li className={styles.breadcrumb_item}>Supplier</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} onChange={handleChange} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                        {/* <div className={styles.bottomSeparator}></div> */}
                    </div>

                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.page_divider}>
                    <AddNewSupplier editStatus={editStatus} setEditStatus={setEditStatus} editItem={editItem} setSupplier={setSupplier} supplier={supplier} updateArray={updateArray} editCounter={editCounter} />
                </div>
                <div className={styles.page_divider}>
                    <div className={styles.listContainer} style={results.length === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>
                        {results.length > 0 ?
                            results.map((v) =>
                                <div className="col-md-12" key={v._id}>
                                    <div className={styles.categoryItem}>
                                        <div className={styles.categoryItemHolder}>
                                            {v.image !== '' ?
                                                <img style={{ padding: 15 }} className={styles.categoryItemImg} src={IMAGE_URL + v.image}
                                                    onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = supplierPng }} alt="Supplier" />
                                                :
                                                <img style={{ padding: 15 }} className={styles.categoryItemImg} src={supplierPng} onError={({ e }) => { e.onerror = null; e.src = supplierPng; }} alt='category_img' />
                                            }
                                        </div>
                                        <p className={styles.categoryItemTitle}>{v.name}</p>
                                        <button className={styles.EditBtn}>
                                            <span className={styles.editAction} onClick={() => editItemFunc(v)}><i className="fa-thin fa-pen-to-square"></i></span>
                                            <span className={styles.delteAction} onClick={() => deleteItemFunc(v)}><i className="fa-thin fa-trash-can"></i></span>
                                        </button>
                                    </div>
                                </div>
                            )
                            :
                            <h2 className="not_found_msg">No data found!</h2>
                        }
                    </div>
                </div>
            </div>
            {deleteStatus && <DeletePopup deleteCancel={deleteCancel} deleteItem={deleteItem} />}
        </div>
    )
}

export default Supplier;