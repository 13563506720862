import React from "react";
import { Link } from "react-router-dom";
import styles from "../../assets/css/TopBar.module.css";
import {RouteAccessCheck} from "../../Utils/common"

function TopBar() {
  return (
    <div className={styles.top_bar}>
      {/* <div className={styles.header_head_right}>
        <div className={styles.menu_user}>
          <span className={styles.userName} style={{ marginRight: "2px" }}>test</span>
          <div className={styles.user_img}>
            <i className="fa-thin fa-user"></i>
          </div>
        </div>
      </div> */}
      {RouteAccessCheck('/menu/add-new-item') && <Link to={'/menu/add-new-item'} className={styles.addNewItem}> Add New Item</Link>}
      {RouteAccessCheck('/menu/add-new-brand-item') && <Link to={'/menu/add-new-brand-item'} className={styles.addNewItem}> Add New Brand Item</Link>}
      {RouteAccessCheck('/menu/add-new-job') && <Link to={'/menu/add-new-job'} className={styles.addNewItem}> Add New Job</Link>}
      {RouteAccessCheck('/menu/import-new-item') && <Link to={'/menu/import-new-item'} className={styles.addNewItem}> Import New Item</Link>}
      {RouteAccessCheck('/menu/brand') && <Link to={'/menu/brand'} className={styles.addNewItem}> Add New Brand</Link>}
      {RouteAccessCheck('/menu/supplier') && <Link to={'/menu/supplier'} className={styles.addNewItem}> Add New Supplier</Link>}
      {RouteAccessCheck('/menu/category') && <Link to={'/menu/category'} className={styles.addNewItem}> Add New Category</Link>}
      {RouteAccessCheck('/menu/sub-category') && <Link to={'/menu/sub-category'} className={styles.addNewItem}> Add New Sub-category</Link>}
      
      
      
      
      
      
    </div>
  );
}

export default TopBar;
