import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSelector } from 'react-redux'
import { UserLogin, SubUserLogin,getDeviceToken, getStoreData } from "../../../Utils/method"
import isEmail from 'validator/lib/isEmail';
import styles from '../../../assets/css/SignIn.module.css';
import classNames from "classnames";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function SignIn() {
    let navigate = useNavigate();
    const location = useLocation();
    console.log(location.pathname.indexOf('admin'))
    const login_modal = useSelector((state) => state.rootReducer.login_modal)
    const [passVisibility, setPassVisibility] = useState(false);
    const [spining, setSpining] = useState(false);
    const [buttonState, setButtonState] = useState(false);
    const [is_store,] = useState(location.pathname.indexOf('admin') > -1 ? true:false);
    const [loginData, setLoginData] = useState({
        email: '',
        password: '',
        login_by: 'manual',
        social_id: '',
        device_token: getDeviceToken(),
        device_type: 'web',
        store_type: 0,
        // is_store_login_by_phone: this.is_store_login_by_phone,
        // is_store_login_by_email: this.is_store_login_by_email,
        captcha_token: '',
        // is_editor: (this.loginForm.value.storeType === 2)
    })

    useEffect(() => {

        let state = false;
        if (loginData.email === '' || !isEmail(loginData.email)) {
            state = false;
        } else if (loginData.password === '') {
            state = false;
        } else {
            state = true
        }

        setButtonState(state);
    }, [loginData]);

    const LoginUser = async () => {
        if (buttonState) {
            try {
                setSpining(prev => !prev);
                let apires = ''
                if(is_store){
                apires = await UserLogin(loginData);
                }else{
                apires = await SubUserLogin(loginData);
                }
               
                console.log(40, apires)
                if (apires.success) {
                    getStoreData({ data: apires, type: 'set' });
                    navigate("/");
                } else {
                    toast.error(apires.status_phrase, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
                setSpining(prev => !prev);
            } catch (error) {
                setSpining(prev => !prev);
                console.log(error);
            }
        }
    }

    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer />
            <div className={styles.signin_partition}>
                <div className={styles.form_content}>
                    <div className={styles.signin_form}>
                        <h1 className={styles.signin_title}>Sign In</h1>
                        <form >
                            <div className={styles.container_login}>
                                <div className={styles.inputContSignin}>
                                    <input className={styles.inputs} type="text" placeholder="Email or Phone Number" name="uname" onChange={(event) => setLoginData({ ...loginData, email: event.target.value })} required value={loginData.email} />
                                </div>
                                <div className={styles.inputContSignin}>
                                    <input className={styles.inputs} type={!passVisibility ? "password" : "text"} minLength={6} placeholder="Password" name="psw" onChange={(event) => setLoginData({ ...loginData, password: event.target.value })} required value={loginData.password} />
                                    <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-thin", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span> {/*<i className="fa-thin fa-eye-slash"></i> */}
                                </div>
                                {/* <div className={styles.inputContSignin}>
                                    <select className={styles.inputs} onChange={(e)=>setIsStore(e.value)} defaultValue={is_store}>
                                        <option value={true}>Store</option>
                                        <option value={false}>SubStore</option>
                                    </select>
                                </div> */}
                                <span className={styles.psw}><Link to="/forgot-password">Forgotten your password?</Link></span>
                                <label className={styles.rememberContainer}>Remember me
                                    <input type="checkbox" />
                                    <span className={styles.checkmark}></span>
                                </label>
                                <div className={classNames(styles.inputContSignin, spining ? styles.onProcoess : '')}>
                                    <button className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} type="button" onClick={LoginUser}>LOGIN <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                                </div>
                            </div>
                        </form>
                    </div>
                    <div className={styles.signin_form}>
                        <h1 className={styles.signin_title}>New to UKBD App . . .</h1>
                        <p className={styles.signintextMsg}>Creating an account is quick and simple and allows  you to track, change or return your order.</p>
                        <Link to={"/sign-up"}> <button className={styles.signin_button} type="button" >CREATE AN ACCOUNT</button></Link>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SignIn;