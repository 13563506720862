import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../../assets/css/PastOrder.module.css";
import ProgressBar from "react-bootstrap/ProgressBar";
import Select from "react-select";

function Reviews() {
  const searchOptions = [
    { value: 'user', label: 'Search By : User' },
    { value: 'order', label: 'Search By : Order' },
  ]

  const deliveryOptions = [
    { value: 'delivery', label: 'Delivery', name: 'delivery', checked: true },
    { value: 'pickup', label: 'Pick Up', name: 'pickup', checked: true },
    { value: 'schedule', label: 'Schedule', name: 'schedule', checked: true },
    { value: 'now', label: 'Now', name: 'now', checked: true },
  ]

  const [paymentOptions, setPaymentOptions] = useState([
    { value: 'cash', label: 'Cash', id: 'cash', name: 'cash', checked: true }
  ])
  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: '14px',
      boxShadow: 'none',
      '&:focus': {
        border: '0 !important',
      },
      border: 'none',
      background: 'transparent',
      minHeight: '18px'
    }),
    multiValue: (base) => ({
      ...base,
      background: '#ced4da',
      borderRadius: '0px',
      marginTop: '0px',
      fontWeight: '400',
      lineHeight: '18px',
      position: 'relative',
      top: '-2px',
      left: '-10px'
    }),
    ValueContainer: (base) => ({

    })
  }
  return (
    <div className={styles.order_container}>
      {/* <div className={styles.main_head}>
        <div className={styles.top_head}>
          <div className={styles.titleWithBC}>
            <h3>Reviews</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>History</li>
              <li className={styles.breadcrumb_item}>Reviews</li>
            </ol>
          </div>
          <button className={styles.export_btn}>Export</button>
        </div>

        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <select className={styles.searchBy}>
                <option>Search By : All</option>
                <option>Search By : Delivery Boy</option>
                <option>Search By : Order</option>
                <option>Search By : User</option>
                <span>
                  <i className="fa-duotone fa-caret-down"></i>
                </span>
              </select>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input
                  type="text"
                  placeholder="Search"
                  className={styles.searchbox}
                ></input>
                <button className={styles.searchIcon} type="submit">
                  <span>
                    <i className="fa-light fa-magnifying-glass"></i>
                  </span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_delivery}>
                Deliver Type
                <span>
                  <i className="fa-duotone fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_payment}>
                Review Filter
                <span>
                  <i className="fa-duotone fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div className={styles.navItem}>
              <select className={styles.btn_payment}>
                <option>29 Jan 2023 - 04 Feb 2023</option>
                <option>22 Jan 2023 - 28 Jan 2023</option>
                <option>08 Jan 2023 - 14 Jan 2023</option>
                <option>01 Jan 2023 - 07 Jan 2023</option>
                <option>18 Dec 2023 - 24 Dec 2023</option>
                <span>
                  <i className="fa-duotone fa-caret-down"></i>
                </span>
              </select>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply}>Apply</button>
            </div>
          </div>
          <div className={styles.nav_item_right}>
            <div className={styles.navItem}>
              <span className={styles.per_page}>Per Page</span>
              <button className={styles.btn_page}>
                10
                <span>
                  <i className="fa-duotone fa-caret-down"></i>
                </span>
              </button>
            </div>
          </div>
        </div>
      </div> */}
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}>
          <div className={styles.titleWithBC}>
            <h3>Reviews</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>History</li>
              <li className={styles.breadcrumb_item}>Reviews</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection}>
          <span className={styles.per_page}>Per Page</span>
          <select className={styles.btn_page}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <Select className={styles.searchBy}
                defaultValue={''}
                isSearchable={false}
                placeholder='Search By : User'
                // onChange={set_search_by}
                options={searchOptions}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Search" className={styles.searchbox} />
                <button className={styles.searchIcon} type="submit">
                  <span><i className="fa-light fa-magnifying-glass"></i></span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <Select className={styles.btn_delivery}
                defaultValue={''}
                isMulti
                isSearchable={false}
                placeholder='Delivery Type'
                styles={selectStyles}
                options={deliveryOptions}
              // onChange={set_delivery_type}
              >
              </Select>
            </div>
            <div className={styles.navItem}>
              <Select className={styles.btn_payment}
                defaultValue={''}
                isMulti
                isSearchable={false}
                placeholder='Select Payment By '
                styles={selectStyles}
                options={paymentOptions}
              // onChange={set_payment_by}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply}>Apply</button>
            </div>
          </div>
          <div className={styles.nav_item_right}>
            <div className={styles.navItem}>
              <button className={styles.exportButton}>Export</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className="row">
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-6 mb-4 col-item">
            <div className="card rounded-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <h5 className="mb-4" style={{ fontWeight: 300 }}>
                      Order Review
                    </h5>
                  </div>
                  <div className="col-4 text-end">
                    <h2 style={{ fontWeight: 300 }}>0 / 0</h2>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star  text-primary"></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0} className=""></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-6 mb-4 col-item">
            <div className="card rounded-0">
              <div className="card-body">
                <div className="row">
                  <div className="col-8">
                    <h5 className="mb-4" style={{ fontWeight: 300 }}>
                      Order Review
                    </h5>
                  </div>
                  <div className="col-4 text-end">
                    <h2 style={{ fontWeight: 300 }}>0 / 0</h2>
                  </div>
                </div>
                <div className="d-flex flex-column flex-grow-1">
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                  <div className="row g-0 mb-2">
                    <div className="col-3 m-auto">
                      <div className="ratings mb-1">
                        <i className="fa-solid fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star me-1 text-primary"></i>
                        <i className="fa-light fa-star text-primary "></i>
                      </div>
                    </div>
                    <div className="col-7 m-auto">
                      <ProgressBar now={0}></ProgressBar>
                    </div>
                    <div className="col-2 text-end m-auto">
                      <p className="text-muted mb-0 truncate text-small">
                        0 (0%)
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="card rounded-0">
              <div className="card-body table-responsive">
                <table className="table orderlist_table">
                  <thead className={styles.thead_light}>
                    <tr className={styles.table_heads}>
                      <th>Order</th>
                      <th>User</th>
                      <th>Delivery Boy</th>
                      <th>Price</th>
                      <th>From User</th>
                      <th>From Provider</th>
                      <th>Type</th>
                      <th>Completed</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tbody}>
                    <tr className="text-center">
                      <td colSpan={8}>Orders Not Found</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <div className="row mt-2">
        <div className="col-12">
          <pagination className="justify-content-center">
            <ul className="pagination justify-content-center">
              <li className="pagination-prev page-item disabled">
                <a className={styles.arrow_links}>
                  <i
                    className="fa-duotone fa-circle-arrow-left"
                    style={{ fontSize: 28, paddingTop: 10 }}
                  ></i>
                </a>
              </li>
              <li className="pagination-page page-item active">
                <a href="#" className={styles.page_link}>
                  1
                </a>
              </li>
              <li className="pagination-next page-item disabled">
                <a href="#" className={styles.arrow_links}>
                  <i
                    className="fa-duotone fa-circle-arrow-right"
                    style={{ fontSize: 28, paddingTop: 10 }}
                  ></i>
                </a>
              </li>
            </ul>
          </pagination>
        </div>
      </div> */}
    </div>
  );
}

export default Reviews;
