/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../assets/css/AddSubCategory.module.css'
import { add_new_domain, edit_domain, upload_file } from '../../../Utils/method'
import { access_list } from '../../../Utils/common'
import { toast } from 'react-toastify';
import classNames from 'classnames';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import { IMAGE_URL } from '../../../Config/Config';
import { get_forms } from '../../../Utils/method'
import { ClientList } from '../DynamicForm/ClientList';


function AddNewDomain(props) {
  const [activeTab, setActiveTab] = useState('system');
  const [seleted_form,setSeleted_form] = useState('');
  const [additional_fields,setAdditional_fields] = useState([])
  const [html_pages_expand, sethtml_pages_expand] = useState(false);
  const [domain_settings_expand, setdomain_settings_expand] = useState(false);
  const [home_settings_expand, setHome_settings_expand] = useState(false);
  const [text_slider_expand, set_text_slider_expand] = useState(false);
  const [landing_page_settings_expand, setLanding_page_settings_expand] = useState(false);
  const [footer_banner_settings_expand, setFooter_banner_settings_expand] = useState(false);
  const [product_preview_settings_expand, setProduct_preview_settings_expand] = useState(false);
  const [give_access_expand, setgive_access_expand] = useState(false)
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    access_list: [],
    smtp_email: '',
    smtp_password: '',
    logo: '',
    upload_imageurl: ''
  })

  const [home_settings_data, setHome_settings_data] = useState([{
    id: 1,
    key: new Date().getTime(),
    text: "",
    is_show: true,
    sequence_number: 1,
    path: "",
    upload_imageurl: "",
    href: ""
  }])
  const OpenTab = (type) => {
    setActiveTab(type)
  }
  const [forms, setForms] = useState([]);
  useEffect(() => {
    let _isMounted = true;
    async function initial() {
      // setLoader(true);
      try {
        let apires = await get_forms(`?type=domain`);
        console.log('response', apires, _isMounted)
        if (_isMounted) {
          let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => a.created_at.toString().localeCompare(b.created_at.toString()));
          console.log('get_forms:', sort_v);
          setForms(sort_v)
        }
      } catch (error) {
        console.log();
      }
    }
    initial()
    return () => { _isMounted = false }
  }, [])

  const [landing_page_data, setLanding_page_data] = useState([{
    id: 1,
    key: new Date().getTime(),
    text: "",
    is_show: true,
    sequence_number: 1,
    path: "",
    upload_imageurl: "",
    href: ""
  }])
  const [text_slider_data, set_text_slider_data] = useState([{
    id: 1 + new Date().getTime(),
    key: new Date().getTime(),
    text: "",
    is_show: true,
    device: 'both',
    sequence_number: 1,
  }])

  const [footer_banner_data, setFooter_banner_data] = useState([{
    id: 1,
    key: new Date().getTime(),
    text: "",
    is_show: true,
    sequence_number: 1,
    path: "",
    upload_imageurl: "",
    href: ""
  }])

  const [product_preview_data, setProduct_preview_data] = useState([{
    id: 1,
    key: new Date().getTime(),
    text: "",
    is_show: true,
    sequence_number: 1,
    path: "",
    upload_imageurl: "",
    href: ""
  }])

  const add_new_item = (type) => {
    if (type === 'text_slider') {
      set_text_slider_data([
        ...text_slider_data,
        {
          id: Number(text_slider_data.length + 1) + new Date().getTime(),
          key: new Date().getTime(),
          text: "",
          is_show: true,
          device: 'both',
          sequence_number: text_slider_data.length > 0 ? (text_slider_data[text_slider_data.length - 1].sequence_number + 1) : 1
        }
      ])
    } else
      if (type === 'product_preview') {
        setProduct_preview_data([
          ...product_preview_data,
          {
            id: Number(product_preview_data.length + 1),
            key: new Date().getTime(),
            text: "",
            is_show: true,
            sequence_number: 1,
            path: "",
            upload_imageurl: "",
            href: ""
          }
        ])
      } else if (type === 'home_settings') {
        setHome_settings_data([
          ...home_settings_data,
          {
            id: Number(home_settings_data.length + 1),
            key: new Date().getTime(),
            text: "",
            is_show: true,
            sequence_number: 1,
            path: "",
            upload_imageurl: "",
            href: ""
          }
        ])
      } else if (type === 'landing_page') {
        setLanding_page_data([
          ...landing_page_data,
          {
            id: Number(landing_page_data.length + 1),
            key: new Date().getTime(),
            text: "",
            is_show: true,
            sequence_number: 1,
            path: "",
            upload_imageurl: "",
            href: ""
          }
        ])
      } else if (type === 'footer_banner') {
        setFooter_banner_data([
          ...footer_banner_data,
          {
            id: Number(footer_banner_data.length + 1),
            key: new Date().getTime(),
            text: "",
            is_show: true,
            sequence_number: 1,
            path: "",
            upload_imageurl: "",
            href: ""
          }
        ])
      }
  }
  const handleRemove_item = (id, type) => {
    if (type === 'text_slider') {
      set_text_slider_data(text_slider_data.filter(v => v.id !== id))
    }
    // else
    // if (type === 'product_preview') {
    //   setProduct_preview_data([
    //     ...product_preview_data,
    //     {
    //       id: Number(product_preview_data.length + 1),
    //       key: new Date().getTime(),
    //       text: "",
    //       is_show: true,
    //       sequence_number: 1,
    //       path: "",
    //       upload_imageurl: "",
    //       href: ""
    //     }
    //   ])
    // } else if (type === 'home_settings') {
    //   setHome_settings_data([
    //     ...home_settings_data,
    //     {
    //       id: Number(home_settings_data.length + 1),
    //       key: new Date().getTime(),
    //       text: "",
    //       is_show: true,
    //       sequence_number: 1,
    //       path: "",
    //       upload_imageurl: "",
    //       href: ""
    //     }
    //   ])
    // } else if (type === 'landing_page') {
    //   setLanding_page_data([
    //     ...landing_page_data,
    //     {
    //       id: Number(landing_page_data.length + 1),
    //       key: new Date().getTime(),
    //       text: "",
    //       is_show: true,
    //       sequence_number: 1,
    //       path: "",
    //       upload_imageurl: "",
    //       href: ""
    //     }
    //   ])
    // } else if (type === 'footer_banner') {
    //   setFooter_banner_data([
    //     ...footer_banner_data,
    //     {
    //       id: Number(footer_banner_data.length + 1),
    //       key: new Date().getTime(),
    //       text: "",
    //       is_show: true,
    //       sequence_number: 1,
    //       path: "",
    //       upload_imageurl: "",
    //       href: ""
    //     }
    //   ])
    // }
  }

  const fileOnChange = async (e, type, id) => {
    const { name, files } = e.target;
    console.log(name, files);
    let data = new FormData()
    data.append('files[0]', files[0])
    setLoader(true)
    console.log(type, id);
    try {
      let apires = await upload_file(data)
      console.log(apires);
      if (apires.success) {
        if (type === 'product_preview') {
          setProduct_preview_data([...product_preview_data.map(m => m.id === id ? { ...m, [name]: apires.data, upload_imageurl: URL.createObjectURL(e.target.files[0]) } : m)])
        } else if (type === 'home_settings') {
          setHome_settings_data([...home_settings_data.map(m => m.id === id ? { ...m, [name]: apires.data, upload_imageurl: URL.createObjectURL(e.target.files[0]) } : m)])
        } else if (type === 'landing_page') {
          setLanding_page_data([...landing_page_data.map(m => m.id === id ? { ...m, [name]: apires.data, upload_imageurl: URL.createObjectURL(e.target.files[0]) } : m)])
        } else if (type === 'footer_banner') {
          setFooter_banner_data([...footer_banner_data.map(m => m.id === id ? { ...m, [name]: apires.data, upload_imageurl: URL.createObjectURL(e.target.files[0]) } : m)])
        } else if (type === 'logo') {
          setFormData({ ...formData, logo: apires.data, upload_imageurl: URL.createObjectURL(e.target.files[0]) })
        }
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  useEffect(() => {
    console.log(home_settings_data,
      landing_page_data,
      footer_banner_data,
      product_preview_data);
  }, [product_preview_data, home_settings_data, landing_page_data, footer_banner_data])

  const onchangeField = (e, type, id) => {
    const { name, value } = e.target;

    if (type === 'product_preview') {
      setProduct_preview_data([...product_preview_data.map(m => m.id === id ? { ...m, [name]: value } : m)])
    } else if (type === 'text_slider') {
      set_text_slider_data([...text_slider_data.map(m => m.id === id ? { ...m, [name]: value } : m)])
    } else if (type === 'home_settings') {
      setHome_settings_data([...home_settings_data.map(m => m.id === id ? { ...m, [name]: value } : m)])
    } else if (type === 'landing_page') {
      setLanding_page_data([...landing_page_data.map(m => m.id === id ? { ...m, [name]: value } : m)])
    } else if (type === 'footer_banner') {
      setFooter_banner_data([...footer_banner_data.map(m => m.id === id ? { ...m, [name]: value } : m)])
    }
  }


  const formRef = useRef(null);
  useEffect(() => {
    if (props.editStatus) {
      setFormData({ name: '', access_list: [] })
      setSeleted_form('');
      setAdditional_fields([]);
      setActiveTab('system');
      setFormData({
        ...formData,
        name: props.editItem.name,
        smtp_email: props.editItem.smtp_email,
        smtp_password: props.editItem.smtp_password,
        logo: props.editItem.logo,
        access_list: props.editItem.access_list,
        contact_email: props.editItem.contact_email,
        contact_phone: props.editItem.contact_phone,
        android_app_link: props.editItem.android_app_link,
        ios_app_link: props.editItem.ios_app_link,
        facebook_link: props.editItem.facebook_link,
        instagram_link: props.editItem.instagram_link,
        linked_in_link: props.editItem.linked_in_link,
        twitter_link: props.editItem.twitter_link,
        youtube_link: props.editItem.youtube_link,
        about_us: props.editItem.about_us,
        contact_us: props.editItem.contact_us,
        terms_conditions: props.editItem.terms_conditions,
        privacy_policy: props.editItem.privacy_policy,
        faq: props.editItem.faq,
        form_id:seleted_form,
        additional_fields:JSON.stringify(additional_fields.map(v => { return ({ ...v, instance_image: '' }) }))
      })
      if(props.editItem.form_id){
        setSeleted_form(props.editItem.form_id);
          let oldFields = [];
          let newFields = [];
          let new_fieldids = [];
          let old_id_message = {}
          for(let i of props.editItem.additional_fields){
            console.log(i)
            oldFields.push(i.label);
          }
          if(forms.filter(v=> v.form_id === props.editItem.form_id).length > 0){
            for(let i of forms.filter(v=> v.form_id === props.editItem.form_id)[0].dynamic_fields){
              if(oldFields.indexOf(i.label) === -1){
                new_fieldids.push(i.id)
                newFields.push(i)
              }else{
                old_id_message[i.id] = i.image;
              }
            }
          }
          
          
          setAdditional_fields([...props.editItem.additional_fields.filter(f=> new_fieldids.indexOf(f.id) === -1).map(v=> old_id_message[v.id] !== undefined ? {...v,image:old_id_message[v.id]}:v),...newFields].sort((a,b)=> a.sl - b.sl));
      }

      setProduct_preview_data(props.editItem.homepage_product_preview.length > 0 ? props.editItem.homepage_product_preview : product_preview_data)
      setHome_settings_data(props.editItem.home_banner_images.length > 0 ? props.editItem.home_banner_images : home_settings_data)
      setLanding_page_data(props.editItem.landing_page_images.length > 0 ? props.editItem.landing_page_images : landing_page_data)
      setFooter_banner_data(props.editItem.footer_banner_images.length > 0 ? props.editItem.footer_banner_images : footer_banner_data)
      set_text_slider_data(props.editItem.text_slider.length > 0 ? props.editItem.text_slider : text_slider_data)
    }
  }, [props.editStatus, props.editCounter])

  const getFormData = async (e, ext = '') => {

    if (ext !== '') {
      setFormData({ ...formData, [ext]: e })
    } else {
      let { name, value } = e.target;
      setFormData({ ...formData, [name]: value })
    }

  }

  const resetForm = () => {
    setFormData({ name: '', access_list: [] })
    setSeleted_form('');
    setAdditional_fields([]);
    setActiveTab('system');
    props.setEditStatus(false);
    formRef.current.reset()
  }

  const checkInputFields = () => {
    if (formData.name.trim() === '') {
      return false;
    } else {
      return true;
    }
  }

  const saveDomain = async () => {

    if (checkInputFields()) {
      setLoader(true)
      try {
        let apires = await add_new_domain(formData);
        console.log(apires);
        toast.success('Domain Added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        resetForm();
        props.updateArray(apires.domain)
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error)
        toast.error(error.error_description, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    }
  }

  const updateDomain = async () => {
    const data = formData
    delete data['name'];
    data['_id'] = props.editItem._id;
    data['landing_page_images'] = JSON.stringify(landing_page_data);
    data['homepage_product_preview'] = JSON.stringify(product_preview_data);
    data['home_banner_images'] = JSON.stringify(home_settings_data);
    data['footer_banner_images'] = JSON.stringify(footer_banner_data);
    data['text_slider'] = JSON.stringify(text_slider_data);
    data['form_id'] = seleted_form;
    data['additional_fields'] = JSON.stringify(additional_fields);
    
      setLoader(true)
      try {
        let apires = await edit_domain(data);
        console.log('edit_domain:', apires);
        toast.success('Domain Updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        props.setDomains([...props.domains.map(b => b._id === apires.domain._id ? { ...apires.domain } : b)])
        resetForm();
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error)
      }
  }

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setFormData({ ...formData, access_list: [...formData.access_list, value] })
    } else {
      setFormData({ ...formData, access_list: [...formData.access_list.filter(f => f !== value)] })
    }
  }

  return (
    <div className={styles.addSubCategoryPopup}>
      <div className={styles.addSub_header}>
        {props.editStatus ? <p>Edit Domain {"> " + formData.name}</p> : <p>Add New Domain</p>}
      </div>
      <form ref={formRef} className={styles.addSub_body}>
        {props.editStatus &&
          <ul className="nav justify-content-center">
            <li className="nav-item">
              <span className={activeTab === 'system' ? "nav-link active" : "nav-link"} aria-current="page" onClick={() => OpenTab('system')} >System</span>
            </li>

            <li className="nav-item">
              <span className={activeTab === 'additional' ? "nav-link active" : "nav-link"} onClick={() => OpenTab('additional')} >Additional</span>
            </li>
          </ul>
        }

        {(activeTab === 'system' || !props.editStatus) &&
          <div className="col-md-12" id="sytemTab">
            <div className="row mt-1 mb-2">
              {!props.editStatus &&
                <div className="row" style={{ marginBottom: '15px' }}>
                  <label className="small mb-1">Doamin Name</label>
                  <div className="input-group mt-1 ">
                    <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Brand Name' disabled={props.editStatus ? true : false} />
                  </div>
                </div>
              }
              <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setgive_access_expand(!give_access_expand) }}>
                <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}>Give Domain Access <span >{give_access_expand ? "-" : "+"}</span> </p> </label>
              </div>
              {give_access_expand && access_list.map((v, i) =>
                <div className={classNames("form-group", styles.item_group)} key={'access_' + i} style={{ display: 'flex', alignItems: 'center', height: '32px', width: '50%', float: 'leftF' }}>
                  <div className="form-check form-switch">
                    <input type="checkbox" className="form-check-input" value={v.url} name={"access_list_" + i} onChange={handleCheckbox} id={"access_" + i} checked={formData.access_list.indexOf(v.url) > -1 ? true : false} />
                    <label className="form-check-label small" htmlFor={"access_" + i} >{v.title}</label>
                  </div>
                </div>
              )}
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setdomain_settings_expand(!domain_settings_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Domain Settings <span>{domain_settings_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && domain_settings_expand &&
                <>
                  <div className="row">
                    <div className="col-md-4">
                      <label htmlFor={'domain_logo'} dd={'domain_logo'} style={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ced4da', backgroundColor: '#fff', cursor: 'pointer' }}>Logo upload</label>
                      <input type="file" id={'domain_logo'} onChange={(e) => fileOnChange(e, 'logo', 'domain_logo')} name="logo" className="form-control rounded-0" hidden />
                    </div>

                  </div>
                  {formData.logo !== "" &&
                    <div className="row">
                      <div className="input-group mt-1">
                        <div className={styles.img_view_container}>
                          {formData.logo !== "" ?
                            <img src={IMAGE_URL + formData.logo} onError={(e) => { e.onerror = null; e.src = IMAGE_URL + formData.upload_imageurl }} alt='' />
                            :
                            <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                          }
                        </div>
                      </div>
                    </div>
                  }
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Image BaseURL</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.base_url} name="base_url" className="form-control rounded-0" placeholder='Image baseURL' />
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">SMTP Email</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.smtp_email} name="smtp_email" className="form-control rounded-0" placeholder='SMTP Email' />
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">SMTP Password</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.smtp_password} name="smtp_password" className="form-control rounded-0" placeholder='SMTP Password' />
                    </div>
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Contact Email</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.contact_email} name="contact_email" className="form-control rounded-0" placeholder='Contact Email' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Contact Phone</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.contact_phone} name="contact_phone" className="form-control rounded-0" placeholder='Contact Phone' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Android App link</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.android_app_link} name="android_app_link" className="form-control rounded-0" placeholder='Android App Link' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">IOS App link</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.ios_app_link} name="ios_app_link" className="form-control rounded-0" placeholder='IOS App Link' />
                    </div>
                  </div>



                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Facebook</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.facebook_link} name="facebook_link" className="form-control rounded-0" placeholder='Facebook' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Instagram</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.instagram_link} name="instagram_link" className="form-control rounded-0" placeholder='Instagram' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">LinkedIn</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.linked_in_link} name="linked_in_link" className="form-control rounded-0" placeholder='LinkedIn' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Twitter</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.twitter_link} name="twitter_link" className="form-control rounded-0" placeholder='Twitter' />
                    </div>
                  </div>

                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">YouTube</label>
                    <div className="input-group mt-1 ">
                      <input type="text" onChange={(e) => getFormData(e)} value={formData.youtube_link} name="youtube_link" className="form-control rounded-0" placeholder='YouTube' />
                    </div>
                  </div>


                </>
              }

              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setHome_settings_expand(!home_settings_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Home Settings <span>{domain_settings_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && home_settings_expand &&
                <div className="row">
                  {home_settings_data.map((v, i) =>
                    <div className="col-md-12 mt-2" key={'home_settings_' + i}>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor={'home_settings_' + v.id} dd={v.id} style={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ced4da', backgroundColor: '#fff', cursor: 'pointer' }}>Image upload</label>
                          <input type="file" id={'home_settings_' + v.id} onChange={(e) => fileOnChange(e, 'home_settings', v.id)} name="path" className="form-control rounded-0" hidden />
                        </div>
                        <div className="col-md-4"><input type="number" onChange={(e) => onchangeField(e, 'home_settings', v.id)} value={v.sequence_number} name="sequence_number" className="form-control rounded-0" placeholder="Sequence" /></div>
                        <div className="col-md-4"><input type="text" onChange={(e) => onchangeField(e, 'home_settings', v.id)} value={v.href} name="href" className="form-control rounded-0" placeholder="Url" /></div>
                      </div>
                      {v.path !== "" &&
                        <div className="row">
                          <div className="input-group mt-1">
                            <div className={styles.img_view_container}>
                              {v.path !== "" ?
                                <img src={IMAGE_URL + v.path} onError={(e) => { e.onerror = null; e.src = IMAGE_URL + v.upload_imageurl }} alt='' />
                                :
                                <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}
                  <div className="row">
                    <p style={{ textAlign: 'right', marginTop: '8px', marginBottom: '8px' }}> <span onClick={() => add_new_item('home_settings')} style={{ fontSize: '12px', cursor: 'pointer', borderBottom: '1px solid #ced4da' }}>ADD NEW ITEM</span> </p>
                  </div>
                </div>
              }
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { set_text_slider_expand(!text_slider_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Text Slider <span>{text_slider_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && text_slider_expand &&
                <div className="row">
                  {text_slider_data.map((v, i) =>
                    <div className="col-md-12 mt-2" key={'text_slider_' + i}>
                      <div className="row">
                        <div className="col-md-2"><input type="number" onChange={(e) => onchangeField(e, 'text_slider', v.id)} value={v.sequence_number} name="sequence_number" className="form-control rounded-0" placeholder="Sequence" /></div>
                        <div className="col-md-6"><input type="text" onChange={(e) => onchangeField(e, 'text_slider', v.id)} value={v.text} name="text" className="form-control rounded-0" placeholder="Text" /></div>
                        <div className="col-md-3">

                          <select className='form-control' onChange={(e) => onchangeField(e, 'text_slider', v.id)} name="device" value={v.device}>
                            <option value="both">Both</option>
                            <option value="mobile">Mobile</option>
                            <option value="web">Web</option>
                          </select>

                        </div>
                        {text_slider_data.length !== 1 &&
                          <span onClick={() => handleRemove_item(v.id, 'text_slider')} className={styles.add_fields_custom} style={{ backgroundColor: '#ff0000', width: '20px' }}> D </span>
                        }



                      </div>
                    </div>
                  )}
                  <div className="row">
                    <p style={{ textAlign: 'right', marginTop: '8px', marginBottom: '8px' }}> <span onClick={() => add_new_item('text_slider')} style={{ fontSize: '12px', cursor: 'pointer', borderBottom: '1px solid #ced4da' }}>ADD NEW ITEM</span> </p>
                  </div>
                </div>
              }
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setLanding_page_settings_expand(!landing_page_settings_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Landing Page Settings <span>{landing_page_settings_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && landing_page_settings_expand &&
                <div className="row">
                  {landing_page_data.map((v, i) =>
                    <div className="col-md-12 mt-2" key={'landing_page_' + i}>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor={'landing_page_' + v.id} style={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ced4da', backgroundColor: '#fff', cursor: 'pointer' }}>Image upload</label>
                          <input type="file" id={'landing_page_' + v.id} onChange={(e) => fileOnChange(e, 'landing_page', v.id)} name="path" className="form-control rounded-0" hidden />
                        </div>
                        <div className="col-md-4"><input type="number" onChange={(e) => onchangeField(e, 'landing_page', v.id)} value={v.sequence_number} name="sequence_number" className="form-control rounded-0" placeholder="Sequence" /></div>
                        <div className="col-md-4"><input type="text" onChange={(e) => onchangeField(e, 'landing_page', v.id)} value={v.href} name="href" className="form-control rounded-0" placeholder="Url" /></div>
                      </div>
                      {v.path !== "" &&
                        <div className="row">
                          <div className="input-group mt-1">
                            <div className={styles.img_view_container}>
                              {v.path !== "" ?
                                <img src={IMAGE_URL + v.path} onError={(e) => { e.onerror = null; e.src = IMAGE_URL + v.upload_imageurl }} alt='' />
                                :
                                <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}
                  <div className="row">
                    <p style={{ textAlign: 'right', marginTop: '8px', marginBottom: '8px' }}> <span onClick={() => add_new_item('landing_page')} style={{ fontSize: '12px', cursor: 'pointer', borderBottom: '1px solid #ced4da' }}>ADD NEW ITEM</span> </p>
                  </div>
                </div>
              }
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setFooter_banner_settings_expand(!footer_banner_settings_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Footer Banner Settings <span>{footer_banner_settings_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && footer_banner_settings_expand &&
                <div className="row">
                  {footer_banner_data.map((v, i) =>
                    <div className="col-md-12 mt-2" key={'footer_banner_' + i}>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor={'footer_banner_' + v.id} style={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ced4da', backgroundColor: '#fff', cursor: 'pointer' }}>Image upload</label>
                          <input type="file" id={'footer_banner_' + v.id} onChange={(e) => fileOnChange(e, 'footer_banner', v.id)} name="path" className="form-control rounded-0" hidden />
                        </div>
                        <div className="col-md-4"><input type="number" onChange={(e) => onchangeField(e, 'footer_banner', v.id)} value={v.sequence_number} name="sequence_number" className="form-control rounded-0" placeholder="Sequence" /></div>
                        <div className="col-md-4"><input type="text" onChange={(e) => onchangeField(e, 'footer_banner', v.id)} value={v.href} name="href" className="form-control rounded-0" placeholder="Url" /></div>
                      </div>
                      {v.path !== "" &&
                        <div className="row">
                          <div className="input-group mt-1">
                            <div className={styles.img_view_container}>
                              {v.path !== "" ?
                                <img src={IMAGE_URL + v.path} onError={(e) => { e.onerror = null; e.src = IMAGE_URL + v.upload_imageurl }} alt='' />
                                :
                                <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}
                  <div className="row">
                    <p style={{ textAlign: 'right', marginTop: '8px', marginBottom: '8px' }}> <span onClick={() => add_new_item('footer_banner')} style={{ fontSize: '12px', cursor: 'pointer', borderBottom: '1px solid #ced4da' }}>ADD NEW ITEM</span> </p>
                  </div>
                </div>
              }
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { setProduct_preview_settings_expand(!product_preview_settings_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> Home Page Product Preview Settings <span>{product_preview_settings_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && product_preview_settings_expand &&
                <div className="row">
                  {product_preview_data.map((v, i) =>
                    <div className="col-md-12 mt-2" key={'product_preview_' + i}>
                      <div className="row">
                        <div className="col-md-4">
                          <label htmlFor={'product_preview_' + v.id} style={{ height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center', border: '1px solid #ced4da', backgroundColor: '#fff', cursor: 'pointer' }}>Image upload</label>
                          <input type="file" id={'product_preview_' + v.id} onChange={(e) => fileOnChange(e, 'product_preview', v.id)} name="path" className="form-control rounded-0" hidden />
                        </div>
                        <div className="col-md-4"><input type="number" onChange={(e) => onchangeField(e, 'product_preview', v.id)} value={v.sequence_number} name="sequence_number" className="form-control rounded-0" placeholder="Sequence" /></div>
                        <div className="col-md-4"><input type="text" onChange={(e) => onchangeField(e, 'product_preview', v.id)} value={v.href} name="href" className="form-control rounded-0" placeholder="Url" /></div>
                      </div>
                      {v.path !== "" &&
                        <div className="row">
                          <div className="input-group mt-1">
                            <div className={styles.img_view_container}>
                              {v.path !== "" ?
                                <img src={IMAGE_URL + v.path} onError={(e) => { e.onerror = null; e.src = IMAGE_URL + v.upload_imageurl }} alt='' />
                                :
                                <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                              }
                            </div>
                          </div>
                        </div>
                      }
                    </div>
                  )}
                  <div className="row">
                    <p style={{ textAlign: 'right', marginTop: '8px', marginBottom: '8px' }}> <span onClick={() => add_new_item('product_preview')} style={{ fontSize: '12px', cursor: 'pointer', borderBottom: '1px solid #ced4da' }}>ADD NEW ITEM</span> </p>
                  </div>
                </div>
              }
              {props.editStatus &&
                <div className="row" style={{ marginBottom: '10px' }} onClick={() => { sethtml_pages_expand(!html_pages_expand) }}>
                  <label className="small mb-1"><p style={{ padding: '8px', backgroundColor: '#e9ecef', cursor: 'pointer' }}> HTML Page Settings <span>{html_pages_expand ? "-" : "+"}</span></p></label>
                </div>
              }
              {props.editStatus && html_pages_expand &&
                <>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">About Us</label>
                    <ReactQuill value={formData.about_us} placeholder="About Us" onChange={(e) => getFormData(e, 'about_us')} />
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Contact Us</label>
                    <ReactQuill value={formData.contact_us} placeholder="Contact Us" onChange={(e) => getFormData(e, 'contact_us')} />
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Terms & Conditions</label>
                    <ReactQuill value={formData.terms_conditions} placeholder="Terms & Conditions" onChange={(e) => getFormData(e, 'terms_conditions')} />
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">Privacy Policy</label>
                    <ReactQuill value={formData.privacy_policy} placeholder="Privacy Policy" onChange={(e) => getFormData(e, 'privacy_policy')} />
                  </div>
                  <div className="row" style={{ marginBottom: '15px' }}>
                    <label className="small mb-1">FAQs</label>
                    <ReactQuill value={formData.faq} placeholder="FAQs" onChange={(e) => getFormData(e, 'faq')} />
                  </div>
                </>
              }

            </div>
          </div>
        }
        {activeTab === 'additional' &&
          <div className="col-md-12" id="additionalTab">
            
            <select className="form-select" value={seleted_form} onChange={(e)=>{
              setSeleted_form(e.target.value); 
              if(props.editItem.form_id === e.target.value){
                let oldFields = [];
                let newFields = [];
                for(let i of props.editItem.additional_fields){
                  oldFields.push(i.label);
                }
                if(forms.filter(v=> v.form_id === e.target.value).length > 0){
                  for(let i of forms.filter(v=> v.form_id === e.target.value)[0].dynamic_fields){
                    if(oldFields.indexOf(i.label) === -1){
                      newFields.push(i)
                    }
                  }
                }
                
                

                setAdditional_fields([...props.editItem.additional_fields,...newFields])
              }else{
                setAdditional_fields(forms.filter(v=> v.form_id === e.target.value)[0].dynamic_fields)
              }
              
              }
              }>
              <option selected>Select Form</option>
              {forms.map(v=> 
                <option key={v._id} value={v.form_id}>{v.form_title}</option>
              )}
              


              
            </select>
            {seleted_form !== '' && 
                <ClientList DynamicFields={additional_fields} setAdditional_fields={setAdditional_fields}></ClientList>
              }
          </div>
        }

      </form>
      {props.editStatus ?
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
          <button className={styles.submitBtn} style={formData.name.trim() !== '' ? {} : { pointerEvents: 'none', opacity: '0.6' }} onClick={updateDomain}>{loader ? 'Loading...' : 'Update'}</button>
        </div>
        :
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
          <button className={styles.submitBtn} style={formData.name.trim() !== '' ? {} : { pointerEvents: 'none', opacity: '0.6' }} onClick={saveDomain}>{loader ? 'Loading...' : 'Submit'}</button>
        </div>}
    </div>
  )
}

export default AddNewDomain;