/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/AddNewStore.module.css";
import { add_sub_store, getStoreData, update_sub_store } from "../../../Utils/method";
import { isEmail } from 'validator'
import classNames from 'classnames';
import { access_list } from '../../../Utils/common'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';

const colourStyles = {
  control: (base, provided) => ({
    ...base,
    fontSize: '14px',
    boxShadow: 'none',
    '&:focus': {
      border: '0 !important',
    },
    border: '1px solid #ced4da',
    borderRadius: '1px',
    background: 'transparent',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: 'rgba(0,48,25,.1)',
  })
  ,
  options: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    padding: '1px 15px',
    width: '100%',
    borderBottom: '1px solid rgba(0,48,25,1)'
  })
  ,
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    height: 'auto',
    width: '100%',
  })
  ,
  multiValue: (base) => ({
    ...base,
    background: '#ced4da',
    borderRadius: '0px',
    marginTop: '0px',
    fontWeight: '400',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: 'rgba(0,48,25,.9)',
  })
}

function AddNewSubStore(props) {
  const store = getStoreData({ type: 'get' })
  const [loader, setLoader] = useState(false);
  const domains = JSON.parse(localStorage.getItem('all_domains'));
  const [seleted_domains, setSelectedDomains] = useState([])
  const [seleted_brands, setSelected_brands] = useState([])
  const [seleted_domain_details, setseleted_domain_details] = useState(domains[0])

  const [brands] = useState(props.brands);

  const [error_field, setError_field] = useState('')
  const [form_data, setForm_data] = useState({
    name: '',
    email: '',
    phone: '',
    password: '',
    access_list: [],
    access_brand: [],
    is_email_verified: false,
    is_approved: false,
    access_domains: [domains[0].name]
  });

  useEffect(() => {
    if (props.edit_item.length > 0) {
      console.log(36, props.edit_item);
      setForm_data({
        name: props.edit_item[0].name.toString(),
        email: props.edit_item[0].email,
        phone: props.edit_item[0].phone,
        password: '',
        access_list: props.edit_item[0].access_list,
        access_brand: props.edit_item[0].access_brand,
        is_email_verified: props.edit_item[0].is_email_verified,
        is_approved: props.edit_item[0].is_approved,
        access_domains: props.edit_item[0].access_domains.length > 0 ? props.edit_item[0].access_domains : [domains[0].name]
      })

      let data = []
      for (const p of props.brands) {
        if (props.edit_item[0].access_brand.indexOf(p.value) > -1) {
          data.push(p)
        }
      }
      setSelected_brands(data)
    }
  }, [])

  useEffect(() => {
    console.log(106,seleted_brands);
  }, [seleted_brands])
  

  const handleApprove = (e) => {
    const { name, checked } = e.target;
    setForm_data({ ...form_data, [name]: checked })
  }

  const handleCheckbox = (e) => {
    const { value, checked } = e.target;
    if (checked) {
      setForm_data({ ...form_data, access_list: [...form_data.access_list, value] })
    } else {
      setForm_data({ ...form_data, access_list: [...form_data.access_list.filter(f => f !== value)] })
    }
  }

  const getFormData = (e) => {
    let { name, value } = e.target;
    setForm_data({ ...form_data, [name]: value })
  };

  const [isValidate, setIsValidate] = useState(false)

  useEffect(() => {
    let validate = false
    if (form_data.name.trim() === '') {
      validate = false
    } else if (!isEmail(form_data.email)) {
      validate = false
    } else if (form_data.password === '' || form_data.password.length < 6) {
      if (props.edit_item.length > 0) {
        validate = true
      } else {
        validate = false
      }
    } else if (form_data.phone === '' || form_data.phone.length < 9) {
      validate = false
    } else if (form_data.access_list.length === 0) {
      validate = false
    } else {
      if (seleted_domains.length > 0) {
        validate = true
      } else {
        validate = false
      }
    }
    console.log('form_data:', form_data);
    setIsValidate(validate)
  }, [form_data]);

  useEffect(() => {
    setSelectedDomains([domains[0].name]);
  }, [])

  useEffect(() => {
    console.log(126, seleted_domains);
  }, [seleted_domains])

  const handleBrands = (arr) => {
    // console.log(arr);
    setSelected_brands(arr)
    let ids = []
    for (const i of arr) {
      console.log(i);
      ids.push(i.value)
      setForm_data({ ...form_data, access_brand: ids })
    }
  }

  const update_url = (e) => {
    if (e.target.value) {
      setSelectedDomains([e.target.value]);
      setseleted_domain_details(domains.filter(f => f.name === e.target.value)[0]);
      setForm_data({ ...form_data, access_list: [], access_domains: [e.target.value] })
    }
  }

  const add_new_subStore = async () => {
    try {
      setLoader(true)
      var json = {
        ...form_data,
        country_phone_code: store.store.country_phone_code,
        urls: [],
        store_id: store.store._id,
        server_token: store.store.server_token,
        timezone: store.timezone,
        id: store.store._id,
      }
      let apires = await add_sub_store(json);
      console.log('add_sub_store:', apires);
      if (apires.success) {
        props.setSub_stores([...props.sub_stores, apires.sub_store])
        props.setNewStore(false)
        toast.success('Sub-store created successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      } else {
        if (apires.status_phrase === 'Email Already Registered') {
          setError_field('email')
        } else if (apires.status_phrase === 'Mobile Number Already Registered') {
          setError_field('phone')
        }
        toast.error(apires.status_phrase, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  const update_subStore = async () => {
    try {
      setLoader(true)
      var json = {
        ...form_data,
        store_id: store.store._id,
        server_token: store.store.server_token,
        timezone: store.timezone,
        _id: props.edit_item[0]._id,
      }

      let apires = await update_sub_store(json);
      console.log('update_sub_store:', apires);
      if (apires.success) {
        props.setSub_stores([...props.sub_stores.map(m => m._id === apires.sub_store._id ? apires.sub_store : m)])
        props.setEdit_item([])
        props.setNewStore(false)
      } else {
        if (apires.status_phrase === 'Email Already Registered') {
          setError_field('email')
        } else if (apires.status_phrase === 'Mobile Number Already Registered') {
          setError_field('phone')
        }
        toast.error(apires.status_phrase, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <div className={styles.addStorePopup}>
        <h5 className={styles.addSub_header}>
          Add New Sub Store
          <span className={styles.itemDesIcon} onClick={() => { props.setNewStore(false); props.setEdit_item([]) }}>
            <i style={{ color: 'rgb(255 0 0 / 40%)', fontSize: 22 }} className="fa-thin fa-xmark"></i>
          </span>
        </h5>
        <div className={styles.addSub_body}>
          <div className="col-md-12">
            <div className="mb-3">
              <label className="form-label">Name <span style={{ color: '#ff0000' }}>*</span></label>
              <input type="text" name="name" value={form_data.name} onChange={getFormData} className="form-control rounded-0" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Email <span style={{ color: '#ff0000' }}>*</span></label>
              <input type="email" name="email" style={error_field === 'email' ? { borderColor: '#ff0000' } : {}} value={form_data.email} onChange={getFormData} className="form-control rounded-0" required />
            </div>
            <div className="mb-3">
              <label className="form-label">Phone <span style={{ color: '#ff0000' }}>*</span></label>
              <input type="number" name="phone" style={error_field === 'phone' ? { borderColor: '#ff0000' } : {}} value={form_data.phone} onChange={getFormData} className="form-control rounded-0" maxLength={16} minLength={8} required />
            </div>
            <div className="mb-3">
              <label className="form-label">Password {!props.edit_item.length && <span style={{ color: '#ff0000' }}>*</span>}</label>
              <input type="password" name="password" value={form_data.password} onChange={getFormData} className="form-control rounded-0" required autoComplete="new-password" />
            </div>
            <div className="row">
              <div className="mb-3">
                <label className="form-label">Brands <span style={{ color: '#ff0000' }}>*</span></label>
                {/* <select onChange={(e) => setForm_data({ ...form_data, access_brand: [e.target.value] })} value={form_data.access_brand.length > 0 ? form_data.access_brand[0] : ''} className="form-control rounded-0" required>
                  <option value={''} disabled>Select</option>
                  {brands.map(v =>
                    <option key={v.value} value={v.value}>{v.label}</option>
                  )}
                </select> */}
                <Select
                  value={seleted_brands}
                  isMulti
                  // name="colors"
                  options={brands}
                  className="basic-multi-select"
                  classNamePrefix="select"
                  styles={colourStyles}
                  onChange={(e) => handleBrands(e)}
                />
              </div>
            </div>
            <div className="mb-3">
              <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center', height: '32px', width: '50%', float: 'left' }}>
                <div className="form-check form-switch">
                  <input type="checkbox" className="form-check-input" value={form_data.is_email_verified} name="is_email_verified" onChange={handleApprove} id="is_email_verified" checked={form_data.is_email_verified} />
                  <label className="form-check-label small" htmlFor="is_email_verified" >Email Approve</label>
                </div>
              </div>
              <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center', height: '32px', width: '50%', float: 'left' }}>
                <div className="form-check form-switch">
                  <input type="checkbox" className="form-check-input" value={form_data.is_approved} name="is_approved" onChange={handleApprove} id="is_approved" checked={form_data.is_approved} />
                  <label className="form-check-label small" htmlFor="is_approved" >Login Approve</label>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="mt-3 mb-3">
                {domains.length &&
                  <select onChange={(e) => update_url(e)} value={form_data.access_domains[0]} className="form-control rounded-0" required>
                    {domains.map(v =>
                      <option key={v._id} value={v.name}>{v.name}</option>
                    )}
                  </select>
                }
              </div>
            </div>
            <hr></hr>
            <div className="row" style={{ marginBottom: '10px' }}>
              <label className="small mb-1">Give Access</label>
            </div>
            {access_list.filter(f => seleted_domain_details.access_list.indexOf(f.url) > -1).map((v, i) =>
              <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center', height: '32px', width: '50%', float: 'left' }} key={'check_' + i}>
                <div className="form-check form-switch">
                  <input type="checkbox" className="form-check-input" value={v.url} name={"access_list_" + i} onChange={handleCheckbox} id={"access_" + i} checked={form_data.access_list.indexOf(v.url) > -1 ? true : false} />
                  <label className="form-check-label small" htmlFor={"access_" + i} >{v.title}</label>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.button_section}>
          {props.edit_item.length > 0 ?
            <button className={styles.add_sub_store_btn} style={loader || !isValidate ? { pointerEvents: 'none', opacity: '0.6' } : {}} onClick={update_subStore}>{loader ? 'Processing...' : 'Update'}</button>
            :
            <button className={styles.add_sub_store_btn} style={loader || !isValidate ? { pointerEvents: 'none', opacity: '0.6' } : {}} onClick={add_new_subStore}>{loader ? 'Processing...' : 'Add'}</button>
          }
        </div>
      </div>
    </div>
  );
}

export default AddNewSubStore;
