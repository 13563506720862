const RouteList = {
    "dashboard": "/",
    "product_items": "/product-items/:_id",
    "create_order": "/order/create-order",
    "past_order": "/order/past-order",
    "today_order": "/order/today-order",
    "tomorrow_order": "/order/tomorrow-order",
    "schedule_order": "/order/schedule-order",
    "dispatch": "/order/dispatch-order",
    "table_booking": "/order/table-booking",
    "current_week_history": "/order/current-week",
    "current_month_history": "/order/current-month",
    "history": "/order/history",
    "reviews": "/order/reviews",
    "current_month_earning": "/earning/current-month",
    "current_week_earning": "/earning/current-week",
    "earning_earning": "/earning/earnings",
    "user": "/users",
    "dynamic_forms": "/dynamic_forms",
    "dynamic_forms_add": "/dynamic_forms/add",
    "dynamic_forms_edit": "/dynamic_forms/edit",
    "dynamic_submitted_forms": "/dynamic_forms/submitted_forms",
    "dynamic_form_preview": "/dynamic_forms/form_preview",
    "dynamic_send_link": "/dynamic_forms/send_link",
    "offer": "/offer",
    "domains": "/domains",
    "addNew_offer": "/add-new-offer",
    "modifier_Category": "/modifier/modifier-category",
    "import_export": "/modifier/import-data",
    "store_setting": "/settings/store-settings",
    "delivery_setting": "/settings/delivery-settings",
    "time_setting": "/settings/time-settings",
    "email_setting": "/settings/email-settings",
    "addNew_store": "/add-new-store",
    "image_cropper": "/image-cropper",

    "sign_in": "/sign-in",
    "sign_in_admin": "/sign-in-admin",
    "sign_up": "/sign-up",
    "menu": "/menu",
    "addNew_item": "/menu/add-new-item",
    "addNew_job": "/menu/add-new-job",
    "import_item": "/menu/import-new-item",
    "item_list": "/menu/item-list",
    "category": "/menu/category",
    "sub_category": "/menu/sub-category",
    "brand": "/menu/brand",
    "supplier": "/menu/supplier",
    "store": "/menu/store",
    "activity": "/activity",
    "jobs": "/menu/jobs",
    "addNew_brand_item": "/menu/add-new-brand-item",
    // Applications
    "job_application": "/job-application",
    "sponsorship_application": "/sponsorship-application",
    "admission_application": "/admission-application",
    "accomodation_application": "/accomodation-application",

}

export default RouteList;




