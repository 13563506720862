import React from 'react'
import styles from "../../../assets/css/Setting.module.css";
import { Editor } from '@tinymce/tinymce-react';
import { useEffect, useState, useRef } from "react";
import { email_list, update_email } from '../../../Utils/method';
import Select from 'react-select';
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';

export default function EmailSetting() {
    const [formData, setFormData] = useState(null);
    const [emailOptions, setEmailOptions] = useState([]);
    const [selectedEmailTitle, setSelectedEmailTitle] = useState('');
    const [selectedEmailContent, setSelectedEmailContent] = useState('');
    const [emailAdminInfo, setEmailAdminInfo] = useState('');
    const [emailId, setEmailId] = useState('');
    const [editorContent, setEditorContent] = useState('');
    const [allowedParams, setAllowedParams] = useState([]);
    const [isSend, setIsSend] = useState(false);
    const editorRef = useRef(null);
    console.log(10, formData);


    useEffect(() => {
        emailList();
    }, [])

    useEffect(() => {
        if (formData) {
            const options = formData.map(email => ({
                value: email.email_unique_title,
                label: email.email_title
            }));
            setEmailOptions(options);
        }
    }, [formData]);

    const selectStyles = {
        control: (base, provided) => ({
            ...base,
            fontSize: '14px',
            boxShadow: 'none',
            '&:focus': {
                border: '0 !important',
            },
            border: 'none',
            background: 'transparent',
            minHeight: '40px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            height: '22px',
            color: "rgb(255 0 0 / 80%)",
            '&:hover': {
                color: '#012965'
            }
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            height: '21px'
        }),

        // placeholder: (baseStyles) => ({
        //     ...baseStyles,
        //     color: 'rgba(0,48,25,.1)',
        // })

        options: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 0,
            padding: '1px 15px',
            width: '100%',
            borderBottom: '1px solid rgba(0,48,25,1)'
        })
        ,

        menu: (baseStyles) => ({
            ...baseStyles,
            borderRadius: 0,
            height: 'auto',
            width: '100%',
            right: '0px',
            top: '34px',
            zIndex: 100,

        })
        ,
        multiValue: (base) => ({
            ...base,
            background: '#ced4da',
            borderRadius: '0px',
            marginTop: '0px',
            fontWeight: '400',
            lineHeight: '18px',
            position: 'relative',
            top: '-2px',
            left: '-10px'
        }),
        singleValue: (baseStyles) => ({
            ...baseStyles,
            color: 'rgba(0,48,25,.9)',
        })
    }




    const emailList = async () => {
        try {
            let apires = await email_list();
            console.log(13, apires)
            if (apires.success) {
                setFormData(apires.email_details);
            }
            else {
                console.log(22, 'fetching data failed')
            }
        }
        catch (error) {
            console.log(error)
        }
    }

    const handleSelectChange = selectedOption => {
        const selectedEmail = formData.find(email => email.email_unique_title === selectedOption.value);
        const emailSetting = EMAIL_TAG_SETTING.find(setting => setting.EMAIL_UNIQUE_ID.includes(selectedEmail.unique_id));
        setSelectedEmailTitle(selectedEmail.email_title);
        setSelectedEmailContent(selectedEmail.email_content);
        setEditorContent(selectedEmail.email_content);
        setEmailAdminInfo(selectedEmail.email_admin_info);
        setEmailId(selectedEmail._id);
        setIsSend(selectedEmail.is_send);
        const params = emailSetting ? emailSetting.ALLOWED_PARAMS : [];
        setAllowedParams(params);
    }

    const handleEditorChange = (content, editor) => {
        setEditorContent(content);
    };
    const handleTitleChange = e => {
        setSelectedEmailTitle(e.target.value);
    };
    const handleIsSendChange = e => {
        setIsSend(e.target.checked);
    };

    const EMAIL_TAG_CONSTANT = {
        USER_FIRST_NAME: 'USER_FIRST_NAME',
        USER_LAST_NAME: 'USER_LAST_NAME',
        PROVIDER_FIRST_NAME: 'PROVIDER_FIRST_NAME',
        PROVIDER_LAST_NAME: 'PROVIDER_LAST_NAME',
        STORE_NAME: 'STORE_NAME',
        OTP: 'OTP',
        ORDER_NO: 'ORDER_NO',
        PASSWORD: 'PASSWORD'
    };

    const EMAIL_TAG_SETTING = [
        {
            EMAIL_UNIQUE_ID: [1, 2, 3, 6],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.USER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.USER_LAST_NAME
            ]
        },
        {
            EMAIL_UNIQUE_ID: [4],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.USER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.USER_LAST_NAME,
                EMAIL_TAG_CONSTANT.OTP
            ]
        },
        {
            EMAIL_UNIQUE_ID: [11, 12, 13, 16],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PROVIDER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.PROVIDER_LAST_NAME
            ]
        },
        {
            EMAIL_UNIQUE_ID: [14],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PROVIDER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.PROVIDER_LAST_NAME,
                EMAIL_TAG_CONSTANT.OTP
            ]
        },
        {
            EMAIL_UNIQUE_ID: [21, 22, 23, 26],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.STORE_NAME
            ]
        },
        {
            EMAIL_UNIQUE_ID: [24],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.STORE_NAME,
                EMAIL_TAG_CONSTANT.OTP
            ]
        },
        {
            EMAIL_UNIQUE_ID: [40, 41, 42, 43, 44, 51, 52, 53, 55, 56, 57, 58, 59, 74, 60, 61, 62, 64, 65, 48, 49],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.ORDER_NO,
                EMAIL_TAG_CONSTANT.STORE_NAME,
                EMAIL_TAG_CONSTANT.USER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.USER_LAST_NAME,
            ]
        },
        {
            EMAIL_UNIQUE_ID: [45, 47, 76, 77, 50],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.ORDER_NO,
                EMAIL_TAG_CONSTANT.STORE_NAME,
                EMAIL_TAG_CONSTANT.USER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.USER_LAST_NAME,
            ]
        },
        {
            EMAIL_UNIQUE_ID: [101],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PASSWORD
            ]
        },
        {
            EMAIL_UNIQUE_ID: [102],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PASSWORD
            ]
        },
        {
            EMAIL_UNIQUE_ID: [103],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PROVIDER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.PROVIDER_LAST_NAME,
                EMAIL_TAG_CONSTANT.PASSWORD
            ]
        },
        {
            EMAIL_UNIQUE_ID: [104],
            ALLOWED_PARAMS: [
                EMAIL_TAG_CONSTANT.PROVIDER_FIRST_NAME,
                EMAIL_TAG_CONSTANT.PROVIDER_LAST_NAME,
                EMAIL_TAG_CONSTANT.PASSWORD
            ]
        },
    ];
    const updateEmail = async () => {
        try {
            const data = {
                email: selectedEmailTitle,
                email_content: editorContent,
                is_send: isSend,
                email_admin_info: emailAdminInfo,
                email_id: emailId,

            };
            const response = await update_email(data);
            console.log(response);

            toast.success('Email updated successfully', {
                position: 'bottom-right',
                hideProgressBar: false,
                autoClose: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });

        } catch (error) {
            console.error('Failed to update email:', error);
            toast.error('Failed to update email', {
                position: 'top-right',
                autoClose: false,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
        }
    };

    return (<>
        <ToastContainer

        />
        <div className='container-fluid'>
            <div className='card mt-1 rounded-0'>
                <div className='card-body'>
                    <div className='row'>
                        <div className='col-8'>
                            <p className='mb-4' style={{ fontSize: 18 }}>Edit Email</p>
                        </div>
                        <div className='col-4 text-end'>
                            <button className={styles.settingBtn} onClick={updateEmail}>Save</button>
                        </div>
                    </div>
                    <div className='ps-3 pe-3 pt-3 pb-0 d-flex flex-column flex-grow-1'>
                        <div className='row'>
                            <div className='col-12 mb-3'>
                                <p className='mb-1'>Contents</p>
                                <span className={styles.contentButton}>[DOMAIN_LOGO]</span>
                                <span className={styles.contentButton}>[DOMAIN_NAME]</span>
                                {allowedParams.map(param => (
                                    <span className={styles.contentButton} key={param}>[{param}{selectedEmailContent[param]}]</span>
                                ))}
                            </div>
                        </div>
                        <div className='row no-gutters'>
                            <div className='col-12 col-lg-8'>
                                <p>Select email for</p>
                                <Select
                                    className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                    options={emailOptions}
                                    placeholder="Select an email title"
                                    styles={selectStyles}
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <div className='col-12 col-lg-4 d-flex justify-content-end .flex-column'>
                                <label className='custom-control-label pe-2' style={{ fontSize: "14px", lineHeight: '25px' }}>
                                    Do you want send selected mail ?
                                </label>
                                <div className='form-check form-switch'>
                                    <input type="checkbox"
                                        className='form-check-input'
                                        onChange={handleIsSendChange}
                                        checked={isSend}>
                                    </input>
                                </div>
                            </div>
                        </div>
                        <div className='row no-gutters mt-3'>
                            <div className='col-12 col-lg-8'>
                                <p>Email title</p>
                                <input
                                    type='text'
                                    className='form-control rounded-0'
                                    value={selectedEmailTitle}
                                    onChange={handleTitleChange}>
                                </input>
                            </div>
                        </div>
                        <div className='row no-gutters mt-3'>
                            <div className='col-12'>
                                <Editor
                                    apiKey="1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw"
                                    initialValue={selectedEmailContent}
                                    value={editorContent}
                                    init={{
                                        height: "65vh",
                                        width: '100%',
                                        menubar: true,
                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                    }}
                                    onEditorChange={handleEditorChange}
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    </>)
}


