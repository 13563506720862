import React, { useState } from 'react';
import styles from '../../../assets/css/SearchSuggestion.module.css'
import { useNavigate } from 'react-router-dom';
import ukbd from '../../../assets/UKBD.png'
import InfiniteScroll from 'react-infinite-scroll-component';
import { getStoreData, searchItems } from '../../../Utils/method';
import classNames from 'classnames';
import { IMAGE_URL } from '../../../Config/Config';
import { useCart } from 'react-use-cart';
import { IoBagSharp } from 'react-icons/io5';

const SearchSuggestion = (props) => {
    const navigate = useNavigate()
    const store = getStoreData({ type: 'get' });
    const [searchValue, setSearchValue] = useState('');
    const [searchedItemList, setSearchedItemList] = useState([]);
    const [searchLoader, setSearchLoader] = useState(false);
    const [hasNextPage, setHasNextPage] = useState()
    const [nextPage, setNextPage] = useState()
    const { inCart, items, updateItemQuantity, addItem, cartTotal, totalUniqueItems } = useCart()

    // console.log(17, store);

    const fetchMoreData = async () => {
        try {
            // setSearchLoader(true);
            console.log(144, hasNextPage, {
                store_id: store.store._id,
                search_text: searchValue,
                page: nextPage,
                limit: 20,
            })
            if (hasNextPage) {
                let apires = await searchItems({
                    store_id: store.store._id,
                    search_text: searchValue,
                    page: nextPage,
                    limit: 20,
                });
                if (apires.success) {
                    setSearchedItemList([...searchedItemList, ...apires.paginate_data.docs]);
                    setHasNextPage(apires.paginate_data.hasNextPage);
                    setNextPage(apires.paginate_data.nextPage);
                }
                console.log('Fetch data: ', apires);
            }
        } catch (error) {
            console.log(error)
        }
    }

    let timer;
    const searchOnKeyup = (e) => {
        // if (e.keyCode === 27) {
        //     e.target.blur()
        //     setSearchPopup(false)
        // }
        // let str = 'Masteringjs.ioF';
        // str.slice(0, -1);

        clearTimeout(timer);
        timer = setTimeout(async function () {
            setSearchLoader(true);
            var vLength = e.target.value.length
            if (vLength > 0) {
                let apires = await searchItems({
                    store_id: store.store._id,
                    search_text: e.target.value,
                    page: 1,
                    limit: 50,
                });
                if (apires.success) {
                    setSearchedItemList(apires.paginate_data.docs);
                    setHasNextPage(apires.paginate_data.hasNextPage);
                    setNextPage(apires.paginate_data.nextPage);
                }
                setSearchLoader(false);
                console.log(apires)
            }
        }, 500);
    }

    const addto_cart = (item) => {
        console.log(item)

        if (inCart(item._id)) {
            items.filter(k => k.id === item._id).map(v =>
                updateItemQuantity(item._id, v.quantity + 1)
            )
            // q = items.filter(k => k.id === item._id)[0].quantity + 1;
        } else {
            addItem({ ...item, id: item._id })
        }
    }

    const fractionCheck = (v) => {
        console.log(97, v);
        // let data = v.split(' ');
        let data = v.toString();
        if (data.indexOf('.') === -1) {
            return v;
        } else {
            let frc = data.split('.')
            return <><span>{frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    return (
        <div className={styles.searchSuggestionPopup}>
            <div className={styles.carting_head}>
                <div className={styles.pageTitleSection}>
                    <div className={styles.pageLogoHolder} onClick={() => navigate('/')}>
                        <img className={styles.pageLogo} src={ukbd} alt="" />
                    </div>
                    <div className={styles.pageTitle} onClick={() => navigate('/')}>
                        <h2>UK & EU Brands {'>'} Partner {'>'} Create Order</h2>
                    </div>
                </div>
                <div className={styles.cart_total_section}>
                    <IoBagSharp color="#ff0000" size={16} style={{ position: 'relative', top: '-1px' }} />
                    {totalUniqueItems > 0 && <span className={styles.pro_qty}>[{totalUniqueItems}]</span>}
                    <span className={styles.cartTotal}>£ {fractionCheck(cartTotal.toFixed(2))}</span>
                </div>
                <span className={styles.closeCart} onClick={() => props.setSearchSuggest(false)}>
                    <i className="fa-thin fa-xmark"></i>
                </span>
            </div>
            <div className={styles.searchInputSection}>
                <span className={styles.searchButton}>
                    <i className="fa-thin fa-magnifying-glass"></i>
                </span>
                <input type="text" id="headerSearch1" value={searchValue} onChange={(e) => setSearchValue(e.target.value)} onKeyUp={searchOnKeyup} placeholder="Search ..." autoFocus />
            </div>
            <div className={styles.container}>
                <div className={styles.suggest_container}>
                    <InfiniteScroll
                        dataLength={searchedItemList.length}
                        next={fetchMoreData}
                        hasMore={hasNextPage}
                        className={styles.searchItemContainer}
                        // loader={
                        //     <div style={{ width: '100%', height: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        //         <img style={{ width: '100px', }} src={loader} alt={'loader'} />
                        //     </div>
                        // }
                        scrollableTarget="searchItemContainer"
                    >
                        {
                            searchedItemList.map((v, i) =>
                                <div className={classNames(styles.srItemHolder, inCart(v._id) ? styles.itemInCart : '')} key={'sri_' + i}>
                                    <div className={styles.srItem} onClick={() => setSearchValue('')}>
                                        <div className={styles.sri_image}>
                                            <img src={IMAGE_URL + v.image_url[0]} alt={'sri_img'} />
                                        </div>
                                        <div className={styles.sriDetails}>
                                            <div className={styles.sri_name}><p>{v.name[0].toLowerCase()}</p></div>
                                            <div className={styles.sri_price}>
                                                {/* <CurrencyFormat value={v.price} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign('GBP') + ' '} renderText={value => value} /> */}
                                            </div>
                                        </div>
                                    </div>
                                    {/* {inCart(v._id) ? <div className={styles.srItemAdd}>
                                        <button className={styles._btn_dec}>-</button>
                                        <input type='text' value={1} />
                                        <button className={styles._btn_inc}>+</button>
                                    </div>
                                        :
                                    } */}
                                    <div className={styles.srItemAdd}>
                                        <button className={classNames(styles._btn_add, inCart(v._id) && styles.alreadyIncart)} onClick={() => addto_cart(v)}>+</button>
                                    </div>
                                </div>
                            )
                        }
                    </InfiniteScroll>
                </div>
            </div>
        </div>
    );
}

export default SearchSuggestion;