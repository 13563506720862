import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/Setting.module.css";
import EditStoreSetting from "./StoreSettings/EditStoreSetting";
import EditOrderSetting from "./StoreSettings/EditOrderSetting";
import ExpertiseSetting from "./StoreSettings/ExpertiseSetting";
import GeneralSetting from "./StoreSettings/GenaralSetting";
import FreeDeliverySetting from "./StoreSettings/FreeDeliverySetting";
import LanguageSetting from "./StoreSettings/LanguageSetting";
import OrderCancelalationSetting from "./StoreSettings/OrderCancelalationSetting";
import { getStoreData, get_store_data } from "../../../Utils/method";


function StoreSetting() {
  const store = getStoreData({ type: 'get' })
  const [editStore, setEditStore] = useState(false)
  const [editOrder, setEditOrder] = useState(false)
  const [freeDelivery, setFreeDelivery] = useState(false)
  const [orderCancellation, setOrderCancellation] = useState(false)
  const [language, setLanguage] = useState(false)
  const [generalStting, setGeneralStting] = useState(false)
  const [expertise, setExpertise] = useState(false)
  const [loader, setLoader] = useState(true)


  const [store_details, setStore_details] = useState(null)
  useEffect(() => {
    let _isMounted = true;
    async function initial() {
      setLoader(true)
      try {
        let apires = await get_store_data({
          id: store.store._id,
          server_token: store.store.server_token,
          servertoken: store.store.server_token,
          store_id: store.store._id,
          timezone: store.timezone,
        });
        if (_isMounted) {
          if (apires.success) {
            let store_detail = apires.store_detail
            setStore_details(store_detail)
            // setStoreCart({
            //     ...storeCart,
            //     address: store_detail.address,
            //     city: store_detail.city,
            //     location: store_detail.location,
            //     user_details: {
            //         name: store_detail.name[0],
            //         email: store_detail.email,
            //         phone: store_detail.phone,
            //         country_phone_code: store_detail.country_phone_code,
            //         image_url: store_detail.image_url
            //     },
            // })
          }
          // console.log(58, apires);
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error);
      }
    }
    initial()

    return () => {
      _isMounted = false
    }
  }, [])

  return (
    <>
      {loader && <div className="loaderContainer"></div>}
      {!loader &&
        <div className="row">
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>Store Setting</p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setEditStore(true)}>Edit</button>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                      Do You provide PICKUP Delivery ?
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_provide_pickup_delivery} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Do You provide Delivery ?</p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_provide_delivery} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Min. & Max. Delivery Time</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Approximate delivery time to show user
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >
                      {store_details.delivery_time}-{store_details.delivery_time_max} min
                    </p>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Price Rating</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Showing max price of item (Ex. if price $99 then 2)
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >{store_details.price_rating}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>
                        Order Setting
                      </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setEditOrder(true)}>Edit</button>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Do you want to apply individual tax on Item?</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      If ON than apply individual Item Tax / Vat on order
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_use_item_tax} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Tax On Item</p>
                    {store_details.store_taxes_details.length > 0 &&
                      <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                        {store_details.store_taxes_details.map((v, i) =>
                          <span key={i + '_tx'}>{v.tax_name[0]}-{v.tax} %</span>
                        )}
                      </p>
                    }
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >
                      {!store_details.is_tax_included ?
                        'Tax Is Exclusive'
                        :
                        'Tax Is Inclusive'
                      }
                    </p>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}> Is your tax included on item price or exclusive </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      If ON then select tax included on item and modifiers price
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_tax_included} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Minimum Order Price</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      User can order above this price
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >£ {store_details.min_order_price}</p>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}> Assign to Deliveryman when order is accepted </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Assign to Deliveryman when order is accepted
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_ask_estimated_time_for_ready_order} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}> Free Delivery Setting </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setFreeDelivery(true)}>Edit</button>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}> Delivery fee on store </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      If ON than user will not pay delivery fees
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_store_pay_delivery_fees} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Price</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      User get free delivery if order price is more than this
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >
                      £ {store_details.free_delivery_for_above_order_price}
                    </p>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}> Radius </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Free delivery available for user this radius
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >{store_details.free_delivery_within_radius} {!store_details.is_distance_unit_mile ? 'KM' : 'Mile'}</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>
                        Order cancellation setting
                      </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setOrderCancellation(true)}>Edit</button>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                      Do you want to set cancellation charge?
                    </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      If ON than apply cancellation charge
                    </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      User can cancel order till Order Ready
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_order_cancellation_charge_apply} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                      Cancellation Charge ({store_details.order_cancellation_charge_type === 1 ? 'Percentage' : 'Absolute'})
                    </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      If above on then apply
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >
                      {store_details.order_cancellation_charge_type === 2 && '£ '}{store_details.order_cancellation_charge_value}{store_details.order_cancellation_charge_type === 1 && ' %'}
                    </p>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Price </p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Cancellation charge apply above this price
                    </p>
                  </div>
                  <div>
                    <p className="mb-0 text-truncate" style={{ fontSize: 14, fontWeight: 300 }} >
                      £ {store_details.order_cancellation_charge_for_above_order_price}
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>
                        Language
                      </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setLanguage(true)}>Edit</button>
                  </div>
                </div>
                {store_details.languages_supported.map((v, i) =>
                  v.is_visible &&
                  <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3" key={i + '_ls'}>
                    <div className="flex-grow-1 text-truncate">
                      <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                        {v.name}
                      </p>
                    </div>
                  </div>
                )
                }
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>
                        General Settings
                      </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setGeneralStting(true)}>Edit</button>
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Business</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Business
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_business} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Busy</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >Busy</p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_store_busy} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
                <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                  <div className="flex-grow-1 text-truncate">
                    <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>Visible</p>
                    <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                      Visible
                    </p>
                  </div>
                  <div className="form-check form-switch">
                    <input type="checkbox" defaultChecked={store_details.is_visible} className="form-check-input" id="is_visible" style={{ pointerEvents: 'none' }} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-md-12 col-sm-6 col-lg-6 col-xl-4 mb-4 col-item">
            <div className="card h-100 rounded-0 mb-4">
              <div className="card-body rounded-0">
                <div className="d-flex flex-row justify-content-between pb-2">
                  <div className="float-left float-none-xs">
                    <div className="d-inline-block">
                      <p className="d-inline" style={{ fontSize: 18 }}>
                        Select tags where your expertise lie
                      </p>
                    </div>
                  </div>
                  <div className="btn-group float-right float-none-xs mb-4">
                    <button className={styles.settingBtn} onClick={() => setExpertise(true)}>Edit</button>
                  </div>
                </div>
                {store_details.famous_products_tags.map((v, i) =>
                  v.checked &&
                  <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3" key={i + '_fs'}>
                    <div className="flex-grow-1 text-truncate">
                      <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }} >{v}</p>
                    </div>
                  </div>
                )
                }
              </div>
            </div>
          </div>
        </div >
      }
      
      {editStore && <EditStoreSetting store_details={store_details} setEditStore={setEditStore} />}
      {editOrder && <EditOrderSetting setEditOrder={setEditOrder} />}
      {freeDelivery && <FreeDeliverySetting setFreeDelivery={setFreeDelivery} />}
      {orderCancellation && <OrderCancelalationSetting setOrderCancellation={setOrderCancellation} />}
      {language && <LanguageSetting setLanguage={setLanguage} />}
      {generalStting && <GeneralSetting setGeneralStting={setGeneralStting} />}
      {expertise && <ExpertiseSetting setExpertise={setExpertise} />}
    </>
  );
}

export default StoreSetting;
