const baseURL = process.env.REACT_APP_baseURL;
const IMAGE_URL = process.env.REACT_APP_IMAGE_URL;
const PRODUCT_URL = process.env.REACT_APP_PRODUCT_URL;
const appStatic = {
    country: "Bangladesh",
    address:"Dhaka, Bangladesh",
    city:"Dhaka District",
    latitude: 23.810332,
    longitude: 90.4125181,
    delivery_id: '6294b04587ea271b9bd4f3bc',
    store_id: '6342a0361d2151beeb670d32',
    user_name: 'UK & EU Brands',
    user_id: '63bd4f21bd775b43870a7cde',
    IS_USE_AWS : false,
    GOOGLE_KEY: 'AIzaSyA0j_iI4ofSPVmpFotExikp-7YzCzqjOYE',
    STRIPE_KEY: 'pk_test_51Hmtd7HZHvm37V55Y9LigG689ls9LdasE3j9Iayi3CRVH8c1kjLEIrtMP8lopfR3MMRCxKXYRsfvYo8se8OzVHjg00xXVwZMJx',
    FIREBASE_CONFIG : {
        apiKey: "AIzaSyA0j_iI4ofSPVmpFotExikp-7YzCzqjOYE",
        authDomain: "lithe-record-248412.firebaseapp.com",
        databaseURL: "https://lithe-record-248412-default-rtdb.firebaseio.com",
        projectId: "lithe-record-248412",
        storageBucket: "lithe-record-248412.appspot.com",
        messagingSenderId: "992368544641",
        appId: "1:992368544641:web:8eda65b603031673aa7680"
    }
}
export {
    baseURL,
    appStatic,
    IMAGE_URL,
    PRODUCT_URL
}; 