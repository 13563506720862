import axios from "axios";
import { baseURL } from "../Config/Config";
import { getStoreData } from "./method";

const API = {
    add_new_form: baseURL + 'api/dynamic_form/add_new_form',
    duplicate_form: baseURL + 'api/dynamic_form/duplicate_form',
    delete_form: baseURL + 'api/dynamic_form/delete_form',
    send_link: baseURL + 'api/dynamic_form/send_link',
    upload_file_form: baseURL + 'api/dynamic_form/upload_file',
    get_forms: baseURL + 'api/dynamic_form/all_form',
    get_single_forms: baseURL + 'api/dynamic_form/get_single_form',
    edit_form: baseURL + 'api/dynamic_form/edit_form',
    submit_public_form: baseURL + 'api/dynamic_form/submit_public_form',
    form_type_model: baseURL + 'api/dynamic_form/form_type_model',
    get_applied_data: baseURL + 'api/dynamic_form/get_applied_data',
    get_single_public_form: baseURL + 'api/dynamic_form/get_single_public_form',
    get_submited_applications: baseURL + 'api/dynamic_form/get_submited_applications',
    login: baseURL + 'api/store/login',
    register_sub_store: baseURL + 'api/store/register_sub_store',
    register_sub_store_verify: baseURL + 'api/store/register_sub_store_verify',
    sub_login: baseURL + 'api/store/sub_store_login',
    get_store_detail: baseURL + 'api/store/get_detail',
    get_store_data: baseURL + 'api/store/get_store_data',
    delivery_list: baseURL + 'admin/delivery_list',
    country_list: baseURL + 'admin/country_list',
    city_list: baseURL + 'admin/city_list',
    register: baseURL + 'api/store/register',
    update: baseURL + 'api/store/update',
    // Items
    add_item: baseURL + 'api/store/add_item',
    get_item_list_paginate: baseURL + 'api/store/get_item_list_paginate',
    get_store_product_item_list: baseURL + 'api/store/get_store_product_item_list',
    get_product_list: baseURL + 'api/store/get_subcategory_list',
    update_item: baseURL + 'api/store/update_item',
    update_item_image: baseURL + 'api/store/update_item_image',
    delete_item_image: baseURL + 'api/store/delete_item_image',
    search_items: baseURL + 'api/user/search_items',
    // Add New Job
    add_new_job: baseURL + 'api/store/add_new_job',
    // Real Brands
    add_new_rolax: baseURL + 'api/store/add_new_rolax',
    // Import Items
    get_import_initial_data: baseURL + 'api/store/get_import_initial_data',
    get_import_items: baseURL + 'api/store/get_import_items',
    import_selected_item_from_product: baseURL + 'api/store/import_selected_item_from_product',

    // Brand APIs
    get_brand: baseURL + 'api/store/get_brand',
    add_new_brand: baseURL + 'api/store/add_new_brand',
    edit_brand: baseURL + 'api/store/edit_brand',
    delete_brand: baseURL + 'api/store/delete_brand',
    // Supplier APIs
    get_supplier: baseURL + 'api/store/get_supplier',
    add_new_supplier: baseURL + 'api/store/add_new_supplier',
    edit_supplier: baseURL + 'api/store/edit_supplier',
    delete_supplier: baseURL + 'api/store/delete_supplier',

    list_orders: baseURL + 'api/store/list_orders',
    set_order_status: baseURL + 'api/store/set_order_status',
    complete_order: baseURL + 'api/store/complete_order',
    list_earning: baseURL + 'api/store/list_earning',
    list_orders_history: baseURL + 'api/store/list_orders_history',
    sub_store_list: baseURL + 'api/store/sub_store_list',

    add_product: baseURL + 'api/store/add_product',
    get_product_data: baseURL + 'api/store/get_product_data',
    update_product: baseURL + 'api/store/update_product',
    delete_product: baseURL + 'api/store/delete_product',
    store_conversion_rate_update: baseURL + 'api/store/store_conversion_rate_update',

    // Product Group
    add_product_group_data: baseURL + 'api/store/add_product_group_data',
    update_product_group: baseURL + 'api/store/update_product_group',
    get_product_group_list: baseURL + 'api/store/get_product_group_list',

    // Offer
    add_promo: baseURL + 'api/store/add_promo',
    search_sort_promo_code_list: baseURL + 'api/store/search_sort_promo_code_list',

    // Payment Gateway
    payment_gateway_list: baseURL + 'admin/payment_gateway_list',

    // Create Order
    create_order: "api/user/create_order",
    create_instant_order: baseURL + 'api/store/create_order',
    pay_order_payment: baseURL + 'api/user/pay_order_payment',
    add_item_in_cart: baseURL + 'api/user/add_item_in_cart',
    get_active_vehicle_list: baseURL + 'api/store/get_vehicles_list',
    store_change_delivery_address: baseURL + 'api/store/store_change_delivery_address',

    // Sub Store
    add_sub_store: baseURL + 'api/store/add_sub_store',
    update_sub_store: baseURL + 'api/store/update_sub_store',

    items: baseURL + 'api/items',
    // delete_item: baseURL + 'api/delete_item',
    delete_item: baseURL + 'api/store/delete_item',
    add_new_sub_category: baseURL + 'category/add_new_sub_category',
    get_sub_category: baseURL + 'category/get_sub_category',
    get_main_category: baseURL + 'category/get_main_category',
    edit_store: baseURL + 'store/edit_store',
    get_stores: baseURL + 'store/get_stores',
    get_today_activity: baseURL + 'activity/get_today_activity',
    get_report: baseURL + 'activity/get_report',
    logout: baseURL + 'api/user/logout',
    get_domains: baseURL + 'api/domain/get_domains',
    add_new_domain: baseURL + 'api/domain/add_new_domain',
    edit_domain: baseURL + 'api/domain/edit_domain',
    delete_domain: baseURL + 'api/domain/delete_domain',
    upload_file: baseURL + 'api/domain/upload_file',
    email_list: baseURL + 'admin/email_list',
    update_email: baseURL + 'admin/update_email',
    upload_file_application: baseURL + 'api/dynamic_form/upload_file',
}
const postMETHOD = (key, data) => {
    const selected_domain = localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain')) : null;
    let headers = {
        'Authorization': JSON.parse(localStorage.getItem('user_data')) !== null ? JSON.parse(localStorage.getItem('user_data')).token : '',
        'selected_domain': selected_domain ? selected_domain._id : null
    }
    return new Promise((resolve, reject) => {
        axios.post(API[key], data, { headers })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    })

}
const getMETHOD = (key, params = "") => {
    const selected_domain = localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain')) : null;
    // console.log(61, key,params)
    // console.log(61, API[key]+params)
    let headers = {
        'Authorization': JSON.parse(localStorage.getItem('user_data')) !== null ? JSON.parse(localStorage.getItem('user_data')).token : '',
        'selected_domain': selected_domain ? selected_domain._id : null
    }
    return new Promise((resolve, reject) => {
        axios.get(API[key] + params, { headers })
            .then(function (response) {
                resolve(response);
            })
            .catch(function (error) {
                reject(error);
            })
    })

}
export {
    postMETHOD,
    getMETHOD
} 