import React from 'react';
import styles from '../../../../assets/css/ImagePreview.module.css'
import { IMAGE_URL } from '../../../../Config/Config';

const ImagePreview = (props) => {
    return (
        <div className={styles.wrapper}>
            <div className={styles.preview_popup}>
                    <span className={styles.closePopup} onClick={()=> props.setPreviewPopup(false)}><i className="fa-thin fa-xmark"></i></span>
                {/* <div className={styles.preview_header}>
                    <h2></h2>
                </div> */}
                <div className={styles.preview_body}>
                    <img src={IMAGE_URL + props.previewItem} alt='Preview' />
                </div>
            </div>
        </div>
    );
}

export default ImagePreview;