/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, memo,useRef } from "react";
import classNames from "classnames";
import styles from "../../../assets/css/ProductItems.module.css";
import 'moment-timezone';
import { IMAGE_URL } from "../../../Config/Config";
import { getStoreData, get_item_list, get_product_list, update_item, update_item_image, delete_item_image, get_supplier, get_brand, get_store_detail, store_conversion_rate_update, delete_item, get_domains } from '../../../Utils/method'
import CropImage from "../../Popups/CropImage";
import Select from 'react-select';
import DeletePopup from "../../Popups/DeletePopup";
import { getCurrencySign, priceConvertToAlternate } from "../../../Utils/common";
import Paginate from "../Paginate";
// import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import ImagePreview from "./ImagePreview";
import { Link } from "react-router-dom";
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import defaultImg from '../../../assets/default-image.png'
import ukbd from '../../../assets/UKBD.png'
import ImagePreview from "../Menu/Items/ImagePreview";
import { Editor } from '@tinymce/tinymce-react';

const offerOptions = [
  { value: '0', label: 'None' },
  { value: '10', label: '10% OFF' },
  { value: '20', label: '20% OFF' },
  { value: '30', label: '30% OFF' },
  { value: '50', label: 'BUY 1 GET 1 FREE' },
  { value: 'custom', label: 'Custom' },
];

const deliveryTimeOptions = [
  { value: '2 to 3 days', label: '2 To 3 Days' },
  { value: '2 to 10 days', label: '2 To 10 Days' },
  { value: '7 to 10 days', label: '7 To 10 Days' },
  { value: '15 days. Subject to BD Customs', label: '15 days. Subject to BD Customs' },
  { value: 'same day', label: 'Same Day' },
];

const selectStyles = {
  control: (base) => ({
    ...base,
    fontSize: '14px',
    boxShadow: 'none',
    // '&:focus': {
    //     border: '0 !important',
    // },
    background: '#fff',
    minHeight: '18px',
    border: '1px solid #ced4da',
    borderRadius: '2px',
  }),
  container: (baseStyles) => ({
    ...baseStyles,
    width: '100%',
  }),
  placeholder: (baseStyles, state) => ({
    ...baseStyles,
    color: 'rgba(0,48,25,.1)',
  }),
  options: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    padding: '1px 15px',
    width: '100%',
    borderBottom: '1px solid rgba(0,48,25,1)'
  }),
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    height: 'auto',
    width: '100%',
    right: '0px',
    top: '13px',
  })
  ,
  multiValue: (base) => ({
    ...base,
    background: '#ced4da',
    borderRadius: '0px',
    marginTop: '0px',
    fontWeight: '400',
    lineHeight: '18px',
    position: 'relative',
    top: '-2px',
    left: '-10px'
  })
}

function BrandItems() {
  
  const [store_data, setStore_data] = useState(getStoreData({ type: 'get' }));
  const [subCategories_all, setSubCategories_all] = useState([]);
  const [suppliers_all, setSuppliers_all] = useState([]);
  const [brands_all, setBrands_all] = useState([]);
  const [subCategories, setSubCategories] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [brands, setBrands] = useState([]);

  const [items, setItems] = useState([]);
  const [all_product_list, setAll_Product_list] = useState([]);
  const [product_list, setProduct_list] = useState([]);
  const [loader, setLoader] = useState(true);
  const [cropPopup, setCropPopup] = useState(false);
  const [expandedItem, setExpandedItem] = useState("");
  const [crop, setCrop] = useState({ aspect: 1 / 1 });
  const [croppedImageUrl, setCroppedImageUrl] = useState(null);
  const [deleteStatus, setDeleteStatus] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  // const [selectedImage, setSelectedImage] = useState([]);
  const [curentImage, setCurentImage] = useState('');
  const [previewPopup, setPreviewPopup] = useState(false);
  const [previewItem, setPreviewItem] = useState('')
  const [filterSubCate, setFilterSubCate] = useState([]);
  const [searchTerm, setSearchTerm] = useState('');
  const [is_search, setIs_search] = useState(false);
  const [is_filtered, setIs_filtered] = useState(false);
  const [conversion_rate, setConversion_rate] = useState(store_data.store.conversion_rate);
  const [domain_list, setDomain_list] = useState([]);
  const sub_store = getStoreData('get').sub_store;

  const getProduct_list = async () => {
    try {
      let apires = await get_product_list({
        store_id: store_data.store._id,
        server_token: store_data.store.server_token,
      });
      console.log(16, apires);
      if (apires.success) {
        let data = []
        let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
        for (const p of sort_v) {
          data.push({ value: p._id, label: p.name[0] })
        }
        setProduct_list(data)
        setAll_Product_list(sort_v);
      }
    } catch (error) {
      console.log(error)
    }
  }

  // const filterOnClick = async () => {
  //   setIs_filtered(true);
  //   setFilterSubCate('');
  //   // let is_show_to_slide = is_show_to_slide(true);
  //   getPaginate_data(1, 'filter', 'is_show_to_slide', true);
  // }

  const filter_by_subcate = async (data) => {
    setIs_filtered(true)
    setFilterSubCate(data)
    getPaginate_data(1, 'filter', 'product_id', data.value)
  }

  const getPaginate_data = async (page, type, field, value) => {
    setLoader(true)
    try {
      console.log(85, page, type);
      let data = {
        store_id: store_data.store._id,
        server_token: sub_store ? sub_store.server_token : store_data.store.server_token,
        sub_store_email: sub_store ? sub_store.email : '',
        page: page,
        perPage: Number(itemPerPage),
        is_store: sub_store ? false : true,
      }

      if (type === 'filter' || type === 'search') {
        let obj = {};
        obj[field] = value;
        data['search_options'] = obj
      }

      // if (type === 'filter') {
      //   let obj = {};
      //   let name = searchTerm.replace(/&/g, "%26");
      //   if (is_search) {
      //     obj['name'] = name.trim();
      //   } else if (is_filtered) {
      //     obj['product_id'] = value ? value : filterSubCate.value;
      //   } else if (is_filtered && is_search) {
      //     obj['name'] = name.trim();
      //     obj['product_id'] = value ? value : filterSubCate.value;
      //   }
      //   data['search_options'] = obj
      // }

      console.log(93, data);
      let apires = await get_item_list(data);
      console.log('get_item_list:', apires);
      setItems(apires.data.items);
      setCurrentPage(apires.data.currentPage)
      setTotalPage(apires.data.totalPages)
      if (type === 'reset') {
        setIs_search(false);
        setIs_filtered(false);
        setSearchTerm('')
      }
      setLoader(false);
    } catch (error) {
      setLoader(false);
      console.log(error);
    }
  }

  const searchOnKeyup = async (e) => {
    if (e.key === 'Enter') {
      if (searchTerm.trim().length > 0) {
        setIs_search(true)
        let name = searchTerm.replace(/&/g, "%26");
        getPaginate_data(1, 'search', 'name', name.trim())
      } else {
        if (is_search) {
          resetFilter();
        }
      }
    }
  }

  // const filterOnClick = async () => {
  //   if (searchTerm) {
  //     setIs_search(true);
  //     let is_show_to_slide = is_show_to_slide(true);
  //     getPaginate_data(1, 'search', 'is_show_to_slide');
  //   }
  //   else {
  //     if (is_search) {
  //       resetFilter();
  //     }
  //   }
  // }

  /*is_show_to_slide*/
  const searchOnClick = async () => {
    if (searchTerm.trim().length > 0) {
      setIs_search(true);
      let name = searchTerm.replace(/&/g, "%26");
      getPaginate_data(1, 'search', 'name', name.trim());
    }
    else {
      if (is_search) {
        resetFilter();
      }
    }
  }

  const handleChange = (event) => {
    setSearchTerm(event.target.value);
  }

  const resetFilter = () => {
    getPaginate_data(1, 'reset')
  }

  const handleChangePage = (page) => {
    setCurrentPage(page);
    if (is_search) {
      getPaginate_data(page, 'search', 'name', searchTerm.trim())
    } else if (is_filtered) {
      getPaginate_data(page, 'filter', 'product_id', filterSubCate.value)
    } else {
      getPaginate_data(page, 'pagi')
    }
  };

  const getSuppliers = async () => {
    try {
      let apires = await get_supplier(`?perPage=${2000}`);
      console.log(16, apires);
      if (apires.data.suppliers.length > 0) {
        let data = []
        let sort_v = apires.data.suppliers.sort((a, b) => a.name.localeCompare(b.name));
        for (const o of sort_v) {
          data.push({ value: o._id, label: o.name })
        }
        setSuppliers([...data])
        setSuppliers_all(sort_v)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const getBrands = async () => {
    try {
      let apires = await get_brand(`?perPage=${2000}`);
      console.log(16, apires);
      if (apires.data.brands.length > 0) {
        let data = []
        let sort_v = apires.data.brands.sort((a, b) => a.name.localeCompare(b.name));
        for (const o of sort_v) {
          data.push({ value: o._id, label: o.name })
        }
        setBrands([...data])
        setBrands_all(sort_v);
      }
    } catch (error) {
      console.log(error)
    }
  }

  const get_store = async () => {
    try {
      let apires = await get_store_detail({ store_id: store_data.store._id })
      console.log('get_store_detail :', apires.data.store);
      setStore_data(apires.data)
      setConversion_rate(apires.data.store.conversion_rate)
    } catch (error) {
      console.log(error);
    }
  }

  const conversion_rate_update = async () => {
    try {
      let apires = await store_conversion_rate_update({
        conversion_rate: Number(conversion_rate),
        store_id: store_data.store._id
      })
      console.log('conversion_rate:', apires);
      getPaginate_data(1, 'initial')
      toast.success('Conversion rate Update successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    } catch (error) {
      console.log(error);
    }
  }

  const get_domain_list = async () => {
    try {
      let apires = await get_domains();
      let data = []
      let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
      for (const p of sort_v) {
        data.push({ value: p._id, label: p.name })
      }
      console.log('get_domains:', data);
      setDomain_list(data)
    } catch (error) {
      console.log();
    }
  }

  useEffect(() => {
    get_store();
    getProduct_list();
    getSuppliers();
    getBrands();
    get_domain_list()
  }, [])

  useEffect(() => {
    setCurrentPage(1)
    if (is_filtered) {
      getPaginate_data(1, 'filter', 'product_id', filterSubCate.value)
    } else {
      getPaginate_data(1, 'initial')
    }
  }, [itemPerPage])

  // Image Crop section
  const onCropChange = (crop) => {
    setCrop(crop);
  };

  const onCropComplete = (crop, pixelCrop) => {
    const image = document.getElementById('image');
    const canvas = document.createElement('canvas');
    canvas.width = pixelCrop.width;
    canvas.height = pixelCrop.height;
    const ctx = canvas.getContext('2d');
    ctx.drawImage(
      image,
      pixelCrop.x,
      pixelCrop.y,
      pixelCrop.width,
      pixelCrop.height,
      0,
      0,
      pixelCrop.width,
      pixelCrop.height
    );
    const dataUrl = canvas.toDataURL('image/jpeg');
    setCroppedImageUrl(dataUrl);
  };

  const deleteItemFunc = (e, v) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    setSelectedItem(v)
    setDeleteStatus(true);
  }

  const deleteCancel = () => {
    setDeleteStatus(false);
  }

  const deleteItem = async () => {
    try {
      setDeleteStatus(false);
      let apires = await delete_item({ item_id: selectedItem._id })
      console.log('delete_item:', apires)
      if (apires.success) {
        toast.success('Item Deleted successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setItems(items.filter(i => i._id !== selectedItem._id))
      }
    } catch (error) {
      console.log(error)
    }
  }

  const [active_item, setActive_item] = useState(null)
  const [original_file, setOriginal_file] = useState(null)
  const update_image = async (_file) => {
    try {
      const data = new FormData();
      data.append('image_url[0]', _file);
      data.append('item_id', active_item._id);
      data.append('store_id', store_data.store._id);
      data.append('servertoken', store_data.store.server_token);
      data.append('timezone', store_data.timezone);

      let apires = await update_item_image(data);
      console.log(527, apires);
      if (apires.data.success) {
        toast.success('Item Update successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  }

  const cropConfirm = async (croped) => {
    console.log('Closed Crop')
    let file_name = original_file.name
    let file_type = original_file.type
    let blob = await fetch(croped).then(r => r.blob());
    const file = new File([blob], file_name, {
      type: file_type,
      lastModified: Date.now()
    });
    let dt = new DataTransfer();
    dt.items.add(file);
    let _file = dt.files[0];
    console.log(_file);
    // setSelectedImage([...selectedImage, URL.createObjectURL(_file), croped]);
    console.log(URL.createObjectURL(_file), croped);
    update_image(_file)
    setCropPopup(false);
  }

  return (
    <>
      <div className={styles.itemList_page}>
        <ToastContainer />
        {loader && <div className="loaderContainer"></div>}
        <div className={styles.itemsPageHeader}>
          <div className={styles.pageHeaderSection}>
            <div className={styles.titleWithBC}>
              <h3>Items</h3>
              <ol className={styles.breadcrumb}>
                <li className={styles.breadcrumb_item}>Home</li>
                <li className={styles.breadcrumb_item}>Menu</li>
                <li className={styles.breadcrumb_item}>Items</li>
              </ol>
            </div>
          </div>
          <div className={styles.pageHeaderSection} style={{ padding: 0 }}>
            <div className={styles.nav} style={{ width: 'auto' }}>
              <div className={styles.paginationSection}>
                <Paginate
                  pageCount={totalPage}
                  onPageChange={handleChangePage}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  currentPage={currentPage}
                />

                <div className={styles.navItem}>
                  <span className={styles.per_page}>Per Page</span>
                  <select className={styles.btn_page} value={itemPerPage} onChange={(e) => setItemPerPage(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.filterSection}>
          <div className={styles.nav} style={{ width: 'auto' }}>
            <div className={styles.nav_item_left}>
              {/* <div className={styles.navItem}>
                <button className={styles.searchBy} style={{ width: 150 }} onClick={() => { resetFilter(); setFilterSubCate([]) }}> Show All
                  <span><i className="fa-duotone fa-caret-down"></i></span>
                </button>
              </div> */}

              <div className={classNames(styles.navItem, 'select_filter')}>
                <Select
                  className={styles.filterSelect_item}
                  value={filterSubCate}
                  onChange={(e) => filter_by_subcate(e)}
                  options={product_list}
                />
              </div>

              <div className={styles.navItem}>
                <div className={styles.searchbar}>
                  <input type="text" placeholder="Search" value={searchTerm} onChange={handleChange} onKeyUp={searchOnKeyup} className={styles.searchbox} />
                  {is_search ?
                    <button className={styles.searchIcon} style={{ color: '#ff0000' }} type="submit" onClick={() => resetFilter()}>
                      <i className="fa-thin fa-circle-xmark"></i>
                    </button>
                    :
                    <button className={styles.searchIcon} type="submit" onClick={searchOnClick}>
                      <span><i className="fa-light fa-magnifying-glass"></i></span>
                    </button>}
                </div>
              </div>
              {/* <div className={styles.navItem}>
                <div className={styles.isInSlide}>
                  <button className={styles.searchBy} onClick={() => { filterOnClick() }} >Show Slide Items</button>
                </div>
              </div> */}
              <div className={styles.navItem}>
                <div className={styles.isInSlide}>
                  <button className={styles.resetFilters} onClick={() => { resetFilter() }} >Reset Filters</button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.nav} style={{ width: 'auto' }}>
            <div className={styles.paginationSection}>
              <div className={styles.navItem}>
                {/* <div className="form-group">
                  <div className="input-group">
                    <span className={styles.field_update_label}></span>
                    <input type="text" placeholder="Conversion rate" style={{ color: '#012965', padding: '0px 4px' }} value={conversion_rate} onChange={(e) => setConversion_rate(e.target.value)} className={styles.conversion_rate} />
                    <span className={styles.field_update_btn} onClick={conversion_rate_update}><i className="fa-thin fa-arrow-right-long"></i></span>
                  </div>
                </div>
                <div className="form-group">
                  <div className="input-group">
                    <span className={styles.field_update_label}></span>
                    <input type="text" placeholder="Conversion rate" style={{ color: '#012965', padding: '0px 4px' }} value={conversion_rate} onChange={(e) => setConversion_rate(e.target.value)} className={styles.conversion_rate} />
                    <span className={styles.field_update_btn} onClick={conversion_rate_update}><i className="fa-thin fa-arrow-right-long"></i></span>
                  </div>
                </div> */}
                <div className="form-group">
                  <div className="input-group">
                    <span className={styles.field_update_label}>£1 to BDT </span>
                    <input type="text" placeholder="Conversion rate" style={{ color: '#012965', padding: '0px 4px' }} value={conversion_rate} onChange={(e) => setConversion_rate(e.target.value)} className={styles.conversion_rate} />
                    <span className={styles.field_update_btn} onClick={conversion_rate_update}><i className="fa-thin fa-arrow-right-long"></i></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.itemsContainer} style={!loader && items.length === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>
          {!loader && items.length === 0 ? <h2 className="not_found_msg">No data found!</h2> : ''}
          {!loader &&
            items.map((v, i) => (
              <DrawList sl={i + 1} v={v} key={v._id}
                subCategories={subCategories}
                subCategories_all={subCategories_all}
                suppliers={suppliers}
                suppliers_all={suppliers_all}
                brands={brands}
                brands_all={brands_all}
                offerOptions={offerOptions}
                deliveryTimeOptions={deliveryTimeOptions}
                deleteItemFunc={deleteItemFunc}
                expandedItem={expandedItem}
                setExpandedItem={setExpandedItem}
                onCropChange={onCropChange}
                onCropComplete={onCropComplete}
                setCropPopup={setCropPopup}
                setCurentImage={setCurentImage}
                // selectedImage={selectedImage}
                // setSelectedImage={setSelectedImage}
                setPreviewPopup={setPreviewPopup}
                setPreviewItem={setPreviewItem}
                product_list={product_list}
                all_product_list={all_product_list}
                store_data={store_data}
                conversion_rate={conversion_rate}
                setItems={setItems}
                items={items}
                getPaginate_data={getPaginate_data}
                page={currentPage}
                is_search={is_search}
                searchTerm={searchTerm}
                is_filtered={is_filtered}
                filterSubCate={filterSubCate}
                setActive_item={setActive_item}
                setOriginal_file={setOriginal_file}
                ukbd={ukbd}
                domain_list={domain_list}
              />
            ))}
        </div>

        {deleteStatus && <DeletePopup deleteCancel={deleteCancel} deleteItem={deleteItem} />}
        {previewPopup && <ImagePreview previewItem={previewItem} setPreviewPopup={setPreviewPopup} />}
      </div>
      {cropPopup && <CropImage setCropPopup={setCropPopup} cropConfirm={cropConfirm} curentImage={curentImage} />}
    </>
  )
}

const DrawList = (props) => {
  const editorRef = useRef(null);
  const detailsChangeRef = useRef(null);
  const [detailsEdit,setDetailsEdit] = useState(false)
  const [currentItem, setCurrentItem] = useState({
    ...props.v,
    input_price: Number(props.v.input_price).toFixed(2),
    shipping_duties: Number(props.v.shipping_duties).toFixed(2),
    handling_charge_in_the_uk: Number(props.v.handling_charge_in_the_uk).toFixed(2),
    price: Number(props.v.price).toFixed(2),
    view_price: Number(props.v.view_price).toFixed(2),
    item_weight: Number(props.v.item_weight).toFixed(3),
    short_details: props.v.short_details ? props.v.short_details : []
  })
  const [selectedOffer, setSelectedOffer] = useState(
    (props.v.offer_percentage === 'custom1' || props.v.offer_percentage === 'custom2') ? offerOptions[offerOptions.length - 1] :
      props.v.offer_percentage === '' || props.v.offer_percentage === '0' ? offerOptions[0] :
        offerOptions.filter(i => i.value === props.v.offer_percentage)[0]
  );
  const [selectedDeliverTime, setSelectedDeliverTime] = useState(deliveryTimeOptions.filter(i => i.value === props.v.delivery_time_static)[0]);

  const [selectedProduct, setSelectedProduct] = useState(props.product_list.filter(i => i.value === props.v.product_id)[0]);
  const [selectedSupplier, setSelectedSupplier] = useState(props.suppliers.filter(i => i.value === props.v.supplier_id)[0]);
  const [selectedBrand, setSelectedBrand] = useState(props.brands.filter(i => i.value === props.v.brand_id)[0]);

  // const [selectedStore, setSelectedStore] = useState(props.stores.filter(i => i.value === props.v.store_id[0])[0]);
  const [activeTab, setActiveTab] = useState(1)
  const [itemUpdateButton, setItemUpdateButton] = useState(false)
  const [removedImage, setRemovedImage] = useState([]);
  const [uploadableImage, setUploadableImage] = useState([]);
  const [loader, setLoader] = useState(false);

  const selectTab = (e) => {
    setActiveTab(e);
  }

  function handleImageChange(e) {
    props.setActive_item(currentItem)
    setUploadableImage([...uploadableImage, e.target.files[0]]);
    // props.setSelectedImage([...props.selectedImage, URL.createObjectURL(e.target.files[0])]);
    props.setOriginal_file(e.target.files[0])
    props.setCurentImage(URL.createObjectURL(e.target.files[0]));
    // props.setCropPopup(true);
  }

  function handleDrop(e) {
    e.preventDefault();
    const file = e.dataTransfer.files[0];
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      // setImage(reader.result);
      console.log(121, reader.result)
      // props.setSelectedImage([...props.selectedImage, reader.result]);
      setUploadableImage([...uploadableImage, file]);
      // props.setCurentImage(reader.result);
    };
    // props.setCropPopup(true);
  }

  function handleDragOver(e) {
    e.preventDefault();
  }

  function handleDragLeave(e) {
    e.preventDefault();
  }

  const handlePaste = (e) => {
    const items = e.clipboardData.items;
    for (let i = 0; i < items.length; i++) {
      const item = items[i];
      console.log(533, item);
      if (item.kind === 'file' && item.type.includes('image')) {
        const blob = item.getAsFile();
        const reader = new FileReader();
        reader.onloadend = () => {
          // props.setSelectedImage([...props.selectedImage, reader.result]);
          setUploadableImage([...uploadableImage, blob]);
          console.log(533, blob);
        };
        reader.readAsDataURL(blob);

        break;
      }
    }
  };

  const updateOnChange = async (e, ext_name) => {
    const { name, value } = e.target;
    console.log(name, value)
    if (name === 'is_item_in_stock' || name === 'is_visible_in_store' || name === 'is_most_popular' || name === 'is_show_to_slide') {
      setCurrentItem({ ...currentItem, [name]: e.target.checked });
    } else if ((name === 'offer_percentage') && ext_name !== undefined) {
      setCurrentItem({ ...currentItem, [name]: ext_name });
      // } else if ((name === 'input_price')) {
      //   setCurrentItem({ ...currentItem, [name]: (value).toFixed(2) });
    } else {
      setCurrentItem({ ...currentItem, [name]: value })
    }
  }

  const setNoteContent = (e) => {
    setCurrentItem({ ...currentItem, note_for_item: e });
  }

  const setShortDetailsContent = (e) => {
    // console.log('setShortDetailsContent:', e);
    setCurrentItem({ ...currentItem, short_details: e });
  }

  const setDetailsContent = (e) => {
    // console.log('setDetailsContent:', e);
    setCurrentItem({ ...currentItem, details: e });
  }

  const SelectOnChange = (e, type) => {
    console.log(e, type)
    if (type === 'product_id') {
      let data = props.all_product_list.filter(i => i._id === e.value)
      console.log(411, data, currentItem);
      setCurrentItem({
        ...currentItem,
        [type]: e.value,
        product_sequence_number: data[0].sequence_number,
        sequence_number: Number(data[0].sequence_number.toString() + currentItem.actual_sequence_number.toString())
      });
      setSelectedProduct(e)
    } else if (type === 'supplier_id') {
      let data = props.suppliers_all.filter(i => i._id === e.value)
      console.log(data[0]);
      console.log(data[0].image);
      setCurrentItem({
        ...currentItem,
        'supplier_id': e.value,
        supplier_name: data[0].name,
        supplier_icon: data[0].image
      });
      setSelectedSupplier(e)
    } else if (type === 'brand_id') {
      let data = props.brands_all.filter(i => i._id === e.value)
      setCurrentItem({
        ...currentItem,
        'brand_id': e.value,
        brand_name: data[0].name,
        brand_icon: data[0].image
      });
      setSelectedBrand(e)
    } else if (type === 'delivery_time_static') {
      setCurrentItem({ ...currentItem, [type]: e.value });
      setSelectedDeliverTime(e)
    } else if (type === 'offer_percentage') {
      setCurrentItem({ ...currentItem, [type]: e.value });
      setSelectedOffer(e)
    }
  }

  const updateCurrentItem = (e) => {
    if (e) {
      e.stopPropagation();
      e.nativeEvent.stopImmediatePropagation();
    }
    if (props.is_search) {
      props.getPaginate_data(props.page, 'search', 'name', props.searchTerm.trim())
    } else if (props.is_filtered) {
      props.getPaginate_data(props.page, 'filter', 'product_id', props.filterSubCate.value)
    } else {
      props.getPaginate_data(props.page, 'pagi')
    }
  }

  const updateOnSubmit = async (e) => {
    e.stopPropagation();
    e.nativeEvent.stopImmediatePropagation();
    console.log(456, props.store_data);
    const data = new FormData();
    for (let i = 0; i < currentItem.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', currentItem.domain_ids[i]);
    }
    data.append('_id', currentItem._id);
    data.append('item_id', currentItem._id);
    data.append('store_id', props.store_data.store._id);
    data.append('server_token', props.store_data.store.server_token);
    data.append('timezone', props.store_data.timezone);
    currentItem.name !== props.v.name && data.append('name[0]', currentItem.name);
    // currentItem.item_weight !== Number(props.v.item_weight).toFixed(3) && data.append('item_weight', currentItem.item_weight);
    currentItem.short_details !== props.v.short_details && data.append('short_details[0]', currentItem.short_details);
    // currentItem.details !== props.v.details && data.append('details[0]', currentItem.details);
    detailsEdit && data.append('details[0]', editorRef.current.getContent());
    // currentItem.details !== props.v.details && data.append('details[0]', editorRef.current.getContent());
    // currentItem.offer_percentage !== props.v.offer_percentage && data.append('offer_percentage', currentItem.offer_percentage);
    // currentItem.custom_tag1 !== props.v.custom_tag1 && data.append('custom_tag1', currentItem.custom_tag1);
    // currentItem.custom_tag2 !== props.v.custom_tag2 && data.append('custom_tag2', currentItem.custom_tag2);
    data.append('input_price', currentItem.input_price || currentItem.price);
    data.append('input_price_multi', currentItem.input_price_multi || 1);
    data.append('input_price_plus', currentItem.input_price_plus || 0);
    // currentItem.is_show_to_slide !== props.v.is_show_to_slide && data.append('is_show_to_slide', currentItem.is_show_to_slide);
    // currentItem.slide_sequence_number !== props.v.slide_sequence_number && data.append('slide_sequence_number', currentItem.slide_sequence_number);

    currentItem.actual_sequence_number !== props.v.actual_sequence_number && data.append('actual_sequence_number', currentItem.actual_sequence_number);
    currentItem.product_sequence_number !== props.v.product_sequence_number && data.append('product_sequence_number', currentItem.product_sequence_number);
    currentItem.note_for_item !== props.v.note_for_item && data.append('note_for_item', currentItem.note_for_item);
    currentItem.is_item_in_stock !== props.v.is_item_in_stock && data.append('is_item_in_stock', currentItem.is_item_in_stock);
    currentItem.is_most_popular !== props.v.is_most_popular && data.append('is_most_popular', currentItem.is_most_popular);
    currentItem.is_visible_in_store !== props.v.is_visible_in_store && data.append('is_visible_in_store', currentItem.is_visible_in_store);
    currentItem.delivery_time_static !== props.v.delivery_time_static && data.append('delivery_time_static', currentItem.delivery_time_static);
    currentItem.source_link !== props.v.source_link && data.append('source_link', currentItem.source_link);
    currentItem.supplier_name !== props.v.supplier_name && data.append('supplier_name', currentItem.supplier_name);
    currentItem.supplier_icon !== props.v.supplier_icon && data.append('supplier_icon', currentItem.supplier_icon);
    !props.v.supplier_id ? data.append('supplier_id', currentItem.supplier_id ? currentItem.supplier_id : '') : currentItem.supplier_id !== props.v.supplier_id && data.append('supplier_id', currentItem.supplier_id);
    currentItem.brand_name !== props.v.brand_name && data.append('brand_name', currentItem.brand_name);
    currentItem.brand_icon !== props.v.brand_icon && data.append('brand_icon', currentItem.brand_icon);
    !props.v.brand_id ? data.append('brand_id', currentItem.brand_id ? currentItem.brand_id : '') : currentItem.brand_id !== props.v.brand_id && data.append('brand_id', currentItem.brand_id);
    currentItem.product_id !== props.v.product_id && data.append('product_id', currentItem.product_id);
    JSON.stringify(currentItem.additional_fields) !== JSON.stringify(props.v.additional_fields) && data.append('additional_fields', JSON.stringify(currentItem.additional_fields));

    console.log(529, props.v.supplier_id, props.v.brand_id);
    try {
      let apires = await update_item(data);
      // console.log(332, apires)
      if (apires.data.success) {
        updateCurrentItem()
        toast.success('Item Update successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });

        props.setExpandedItem('')
        setItemUpdateButton(false)
      }
    } catch (error) {
      console.log(error)
    }
  }

  const update_image = async () => {
    setLoader(true)
    try {
      const data = new FormData();
      data.append('image_url[0]', uploadableImage[0]);
      data.append('item_id', currentItem._id);
      data.append('store_id', props.store_data.store._id);
      data.append('servertoken', props.store_data.store.server_token);
      data.append('timezone', props.store_data.timezone);

      let apires = await update_item_image(data);
      console.log(527, apires.data.item.image_url);
      if (apires.data.success) {
        toast.success('Item Update successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        setCurrentItem({ ...currentItem, image_url: apires.data.item.image_url })
      }
      setUploadableImage([])
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  useEffect(() => {
    if (uploadableImage.length > 0) {
      update_image()
    }
  }, [uploadableImage])

  const delete_image = async (d) => {
    console.log(d);
    const data = new FormData();
    data.append('image_url[0]', d);
    data.append('_id', currentItem._id);
    data.append('store_id', props.store_data.store._id);
    data.append('server_token', props.store_data.store.server_token);
    data.append('timezone', props.store_data.timezone);

    try {
      let apires = await delete_item_image(data)
      console.log(560, apires);
      if (apires.data.success) {
        setCurrentItem({ ...currentItem, image_url: currentItem.image_url.filter(f => f !== d) })
        toast.success('Image removed successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } catch (error) {
      console.log(error);
    }
  }


  useEffect(() => {
    let buttonStatus = false;
    if (currentItem.name !== props.v.name) {
      buttonStatus = true
    } else if (currentItem.item_weight !== Number(props.v.item_weight).toFixed(3)) {
      buttonStatus = true;
    } else if (currentItem.short_details !== props.v.short_details) {
      buttonStatus = true;
    } else if (currentItem.is_show_to_slide !== props.v.is_show_to_slide) {
      buttonStatus = true;
    } else if (currentItem.slide_sequence_number !== props.v.slide_sequence_number) {
      buttonStatus = true;
    }else if (detailsEdit) {
      buttonStatus = true;
    } else if (currentItem.offer_percentage !== props.v.offer_percentage) {
      buttonStatus = true;
    } else if (currentItem.custom_tag1 !== props.v.custom_tag1) {
      buttonStatus = true;
    } else if (currentItem.custom_tag2 !== props.v.custom_tag2) {
      buttonStatus = true;
    } else if (currentItem.input_price !== Number(props.v.input_price).toFixed(2)) {
      buttonStatus = true;
    } else if (currentItem.input_price_multi !== props.v.input_price_multi) {
      buttonStatus = true;
    } else if (currentItem.input_price_plus !== props.v.input_price_plus) {
      buttonStatus = true;
    } else if (currentItem.actual_sequence_number !== props.v.actual_sequence_number) {
      buttonStatus = true;
    } else if (currentItem.product_sequence_number !== props.v.product_sequence_number) {
      buttonStatus = true;
    } else if (currentItem.note_for_item !== props.v.note_for_item) {
      buttonStatus = true;
    } else if (currentItem.is_item_in_stock !== props.v.is_item_in_stock) {
      buttonStatus = true;
    } else if (currentItem.is_most_popular !== props.v.is_most_popular) {
      buttonStatus = true;
    } else if (currentItem.is_visible_in_store !== props.v.is_visible_in_store) {
      buttonStatus = true;
    } else if (currentItem.delivery_time_static !== props.v.delivery_time_static) {
      buttonStatus = true;
    } else if (currentItem.source_link !== props.v.source_link) {
      buttonStatus = true;
    } else if (currentItem.supplier_id !== props.v.supplier_id) {
      buttonStatus = true;
    } else if (currentItem.brand_id !== props.v.brand_id) {
      buttonStatus = true;
    } else if (currentItem.domain_ids.toString() !== props.v.domain_ids.toString()) {
      buttonStatus = true;
    } else if (JSON.stringify(currentItem.additional_fields) !== JSON.stringify(props.v.additional_fields)) {
      buttonStatus = true;
    } else {
      buttonStatus = false;
    }
    console.log(809, currentItem);
    setItemUpdateButton(buttonStatus)
  }, [currentItem,detailsEdit])

  let serial = props.sl.toString();
  if (serial.length < 4) {
    serial = serial.padStart(4, '0');
  }

  // const onBlurWeight = (e) => {
  //   const { name, value } = e.target;
  //   setCurrentItem({ ...currentItem, [name]: Number(value).toFixed(3) });
  // }

  const onBlurPrice = (e) => {
    const { name, value } = e.target;
    setCurrentItem({ ...currentItem, [name]: Number(value).toFixed(2) });
  }

  const [selected_domain, setSelected_domain] = useState([]);

  useEffect(() => {
    let _domain = []
    for (const key in props.domain_list) {
      const domain = props.domain_list[key];
      for (const i in props.v.domain_ids) {
        const id = props.v.domain_ids[i];
        if (domain.value === id) {
          _domain.push(domain)
        }
      }
    }
    setSelected_domain(_domain)
  }, [])

  const handleDomain = (d) => {
    setSelected_domain(d)
    let data = []
    for (const iterator of d) {
      console.log(iterator.value);
      data.push(iterator.value)
    }
    setCurrentItem({ ...currentItem, domain_ids: data })
  }

  const handleAdditionalFields = (e) => {
    const { name, value } = e.target;
    console.log(name, value);
    console.log(1040, currentItem);
    setCurrentItem({ ...currentItem, additional_fields: [...currentItem.additional_fields.map(m => m.name === name ? { ...m, value: value } : m)] });
    // set_form_data({ ...form_data, additional_fields: [...form_data.additional_fields.map(m => m.name === name ? { ...m, value: value } : m)] });
  }

  return (
    <div className={classNames(styles.mainItems)}>
      <div className={styles.itemSection} onClick={() => props.setExpandedItem((prev) => (prev === currentItem._id ? "" : currentItem._id))}>
        <div className={classNames(styles.headerOne)} style={{ pointerEvents: 'none' }}>
          <div className={styles.numberSection}>
            <div>{serial}</div>
            <div className={styles.combineField}>
              <div>{currentItem.product_sequence_number}</div>
              <input type={'text'} value={currentItem.actual_sequence_number} onChange={(e) => updateOnChange(e)} name="actual_sequence_number" className="text-center" />
            </div>
          </div>
          <Select
            className={styles.customSelect_item}
            defaultValue={selectedProduct}
            onChange={(e) => SelectOnChange(e, 'product_id')}
            options={props.product_list}
          />
        </div>
        <div className={classNames(styles.headerTwo)}>
          <div className={styles.itemImage}>
            <img
              src={IMAGE_URL + currentItem.image_url[0]}
              onError={({ currentTarget }) => {
                currentTarget.onerror = null; // prevents looping
                currentTarget.src = defaultImg;
              }}
              alt={"item"} />
          </div>
          <div className={styles.itemTitle}>
            <p>{currentItem.name}</p>
          </div>
        </div>
        <div className={classNames(styles.headerThree)} style={{ pointerEvents: 'none', alignItems: 'flex-start' }}>
          <div className="form-check form-switch">
            <input type="checkbox" className="form-check-input" checked={currentItem.is_visible_in_store} name="is_visible_in_store" onChange={(e) => updateOnChange(e)} id="is_visible" />
            <label className="form-check-label small" htmlFor="is_visible">Visible</label>
          </div>
          <Select
            className={styles.customSelect_item}
            defaultValue={selectedDeliverTime}
            onChange={(e) => SelectOnChange(e, 'delivery_time_static')}
            options={props.deliveryTimeOptions}
          />
        </div>
        <div className={classNames(styles.itemPrice, styles.headerFour)}>
          <p> {getCurrencySign('GBP')} {currentItem.price} </p>
          <p> {getCurrencySign('BDT')}  {priceConvertToAlternate(currentItem.price, 'GBP', 136.55)} </p>
        </div>
        <div className={classNames(styles.headerFive)}>
          {/* {itemUpdateButton ?
            <>
              <span onClick={updateOnSubmit} className={styles.updateButton}> Update</span>
              <span onClick={updateCurrentItem} className={styles.updateButton} style={{ marginTop: 5 }}> Reset</span>
            </>
            :
          } */}
          <span onClick={(e) => props.deleteItemFunc(e, currentItem)} style={{ color: '#ff0000' }}> <i className="fa-thin fa-trash-can"></i></span>
          {/* <span className={classNames(props.expandedItem === currentItem._id ? styles.active : '')} style={{ padding: '5px' }} onClick={() => props.setExpandedItem((prev) => (prev === currentItem._id ? "" : currentItem._id))}> <i className="fa-thin fa-chevron-down"></i> </span> */}
        </div>
      </div>
      {props.expandedItem === currentItem._id && (
        <div className={classNames(styles.expandSection, styles.item_editable)}>
          <div className={styles.edit_item_header}>
            <span className={styles.pop_logo}>
              <img src={props.ukbd} alt="" />
            </span>
            <span className={styles.pop_title}>Buyrealbrands {'>'} Partner {'>'} Edit Brand Item</span>
            <div className={styles.tab_footer_section}>
                <button className={styles.leftArrow} style={activeTab === 1 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 1 ? prev - 1 : prev)}><i className="fa-thin fa-chevron-left"></i></button>
                {itemUpdateButton && <div className={styles.footerMiddleSection}>
                  <button className={styles.save_btn} onClick={updateCurrentItem} style={{ marginRight: 15 }}>Reset</button>
                  <button className={styles.save_btn} onClick={updateOnSubmit} style={loader ? { backgroundColor: '#012965', color: '#fff', pointerEvents: 'none' } : {}}>{loader ? 'Updating...' : 'Update'}</button>
                </div>}
                <button className={styles.RightArrow} style={activeTab === 4 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 4 ? prev + 1 : prev)}><i className="fa-thin fa-chevron-right"></i></button>
              </div>
            <span className={styles.popup_close} onClick={() => props.setExpandedItem((prev) => (prev === currentItem._id ? "" : currentItem._id))}><i className="fa-thin fa-xmark"></i></span>
          </div>
          <div className={styles.tabContainer}>
            <div className={styles.tabHead}>
              <div className={classNames(styles.tabs, activeTab === 1 ? styles.active : '')} onClick={() => selectTab(1)}>Details</div>
              <div className={classNames(styles.tabs, activeTab === 2 ? styles.active : '')} onClick={() => selectTab(2)}>Description</div>
              <div className={classNames(styles.tabs, activeTab === 3 ? styles.active : '')} onClick={() => selectTab(3)}>Images</div>
              <div className={classNames(styles.tabs, activeTab === 4 ? styles.active : '')} onClick={() => selectTab(4)}>Others</div>
            </div>
            <div className={styles.tabBody}>
              {loader && <div className="loaderContainer"></div>}
              {activeTab === 1 &&
                <div className="tab-pane fade show active" id="home">
                  <div className={classNames(styles.form_container)}>
                    <div className={classNames("col-md-3", styles.form_column)}>
                      <div className={styles.columnItemContainer}>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Brand</label>
                          <div className="input-group">
                            <Select
                              className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                              defaultValue={selectedBrand}
                              onChange={(e) => SelectOnChange(e, 'brand_id')}
                              options={props.brands}
                            />
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Item Name</label>
                          <div className="input-group">
                            <input type="text" value={currentItem.name} name="name" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Sub-Category</label>
                          <div className="input-group">
                            <Select
                              className={styles.customSelect_item}
                              defaultValue={selectedProduct}
                              onChange={(e) => SelectOnChange(e, 'product_id')}
                              options={props.product_list}
                            />
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Supplier</label>
                          <div className="input-group">
                            <Select
                              className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                              defaultValue={selectedSupplier}
                              onChange={(e) => SelectOnChange(e, 'supplier_id')}
                              options={props.suppliers}
                            />
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)} style={{ minHeight: '68px', height: 'unset' }}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Domain <span style={{ color: '#ff0000' }}>*</span></label>
                          <div className="input-group mt-0">
                            <Select
                              isMulti
                              name="colors"
                              value={selected_domain}
                              options={props.domain_list}
                              className="basic-multi-select"
                              classNamePrefix="select"
                              styles={selectStyles}
                              onChange={handleDomain}
                            />
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Price</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.input_price} min={0} name="input_price" onChange={(e) => updateOnChange(e)} onBlur={onBlurPrice} className="form-control rounded-0 bs_input_f" />
                          </div>
                        </div>

                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Shipping & Duties per KG</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.shipping_duties} onChange={(e) => updateOnChange(e)} onBlur={onBlurPrice} min={0} name='shipping_duties' className="form-control rounded-0 bs_input_f" />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>UK Handling Charge (%)</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.handling_charge_in_the_uk} onChange={(e) => updateOnChange(e)} onBlur={onBlurPrice} min={0} name='handling_charge_in_the_uk' className="form-control rounded-0 bs_input_f" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className={classNames("col-md-3", styles.form_column)}>
                      <div className={styles.columnItemContainer}>
                        {currentItem.additional_fields && currentItem.additional_fields.map((v, i) =>
                          <div className={classNames("form-group", styles.item_group)} key={'fi_' + i}>
                            <label className={classNames("form-label mb-0", styles.form_label)}>{v.label}</label>
                            <div className="input-group mt-0">
                              {v.type === 'select' ?
                                <select className="form-control rounded-0 bs_input_f" name={v.name} value={v.value} onChange={handleAdditionalFields}>
                                  <option value={''} disabled>Select</option>
                                  {v.options.map((o, oi) =>
                                    <option value={o.value} key={'op_' + oi}>{o.label}</option>
                                  )}
                                </select>
                                :
                                <input type={v.type} value={v.value} name={v.name} onChange={handleAdditionalFields} className="form-control rounded-0 bs_input_f" placeholder={v.label} />
                              }
                            </div>
                          </div>
                        )}

                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Multiplier</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.input_price_multi ? currentItem.input_price_multi : 1} min={0} name="input_price_multi" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Plus / Minus</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.input_price_plus ? currentItem.input_price_plus : 0} min={0} name="input_price_plus" onChange={(e) => updateOnChange(e)} className="form-control rounded-0 bs_input_f" />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Total Price / Web Price</label>
                          <div className="input-group">
                            <input type="number" defaultValue={currentItem.price} className="form-control rounded-0 bs_input_f" readOnly />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Cart Price</label>
                          <div className="input-group">
                            <input type="number" defaultValue={currentItem.view_price} className="form-control rounded-0 bs_input_f" readOnly />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>BDT Price</label>
                          <div className="input-group">
                            <input type="number" defaultValue={Math.round(currentItem.view_price * props.conversion_rate)} className="form-control rounded-0 bs_input_f" readOnly />
                          </div>
                        </div> */}
                      </div>
                    </div>
                    <div className={classNames("col-md-3", styles.form_column)}>
                      <div className={styles.columnItemContainer}>
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Offer Percentage</label>
                          <div className="input-group">
                            <Select
                              className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                              defaultValue={selectedOffer}
                              onChange={(e) => SelectOnChange(e, 'offer_percentage')}
                              options={props.offerOptions}
                            />
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <div className="col-md-12 flex-column">
                            <div className="form-check form-switch">
                              <input type="checkbox" className="form-check-input" id="is_custom1" name="offer_percentage" onChange={(e) => updateOnChange(e, 'custom1')} />
                              <label className="form-check-label small" htmlFor="is_custom1"> Custom Tag 1</label>
                              <input type="text" className="form-control rounded-0 bs_input_f" value={currentItem.custom_tag1} name="custom_tag1" onChange={(e) => updateOnChange(e)} placeholder="Custom Tag 1" />
                            </div>
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <div className="col-md-12 flex-column">
                            <div className="form-check form-switch">
                              <input type="checkbox" className="form-check-input" id="is_custom2" name="offer_percentage" onChange={(e) => updateOnChange(e, 'custom2')} />
                              <label className="form-check-label small" htmlFor="is_custom2">Custom Tag 2</label>
                              <input type="text" className="form-control rounded-0 bs_input_f" value={currentItem.custom_tag2} name="custom_tag2" onChange={(e) => updateOnChange(e)} placeholder="Custom Tag 2" />
                            </div>
                          </div>
                        </div> */}
                        <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch">
                            <input type="checkbox" className="form-check-input" checked={currentItem.is_visible_in_store} name="is_visible_in_store" onChange={(e) => updateOnChange(e)} id="is_visible" />
                            <label className="form-check-label small" htmlFor="is_visible">Visible</label>
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch">
                            <input type="checkbox" className="form-check-input" checked={currentItem.is_item_in_stock} name="is_item_in_stock" onChange={(e) => updateOnChange(e)} id="in_stock" />
                            <label className="form-check-label small" htmlFor="in_stock"> In-Stock</label>
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch">
                            <input type="checkbox" className="form-check-input" checked={currentItem.is_most_popular} name="is_most_popular" onChange={(e) => updateOnChange(e)} id="is_popular" />
                            <label className="form-check-label small" htmlFor="is_popular" >Popular</label>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className={classNames("col-md-3", styles.form_column)}>
                      <div className={styles.columnItemContainer}>

                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Item Weight</label>
                          <div className="input-group">
                            <input type="number" value={currentItem.item_weight} name="item_weight" onChange={(e) => updateOnChange(e)} onBlur={onBlurWeight} className="form-control rounded-0 bs_input_f" placeholder="Net weight" />
                          </div>
                        </div> */}
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Source Link</label>
                          <div className="input-group">
                            <input type="text" className="form-control rounded-0 bs_input_f" value={currentItem.source_link} name="source_link" onChange={(e) => updateOnChange(e)} />
                            <Link to={currentItem.source_link} target="_blank" className="rounded-0 text-center d-block width-40 input-group-text" style={{ minWidth: '36px', cursor: 'pointer' }}>Go</Link>
                          </div>
                        </div>
                        <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Delivery Time</label>
                          <div className="input-group">
                            <Select
                              className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                              defaultValue={selectedDeliverTime}
                              onChange={(e) => SelectOnChange(e, 'delivery_time_static')}
                              options={props.deliveryTimeOptions}
                            />
                          </div>

                        </div>
                        {/* <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                          <div className="form-check form-switch">
                            <input type="checkbox" className="form-check-input" checked={currentItem.is_show_to_slide} name="is_show_to_slide" onChange={(e) => updateOnChange(e)} id="is_show_to_slide" />
                            <label className="form-check-label small" htmlFor="is_show_to_slide"> Show In Slide</label>
                          </div>
                        </div> */}
                        {/* <div className={classNames("form-group", styles.item_group)}>
                          <label className={classNames("form-label mb-0", styles.form_label)}>Slide Sequence Number</label>
                          <div className="input-group mt-0">
                            <input type="Number" className="form-control rounded-0 bs_input_f" value={currentItem.slide_sequence_number} name="slide_sequence_number" onChange={(e) => updateOnChange(e)} placeholder="Slide Sequence Number" />
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              }
              {activeTab === 2 &&
                <div className="tab-pane fade show active" id="profile">
                  <div className={classNames(styles.form_container)}>
                    <div className={classNames(styles.form_column)}>
                      <label className={classNames("form-label mb-0", styles.form_label)}>Short Description</label>
                      {/* <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)' }}>
                        <textarea cols="30" rows="auto" className="form-control rounded-0" style={{ minHeight: '100%', border: 'none', resize: 'none' }} placeholder="" value={currentItem.short_details} name="short_details" onChange={(e) => updateOnChange(e)} ></textarea>
                      </div> */}
                      <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                        <ReactQuill className={'item_editor'} value={currentItem.short_details.toString()} placeholder="Short Description" onChange={(e) => setShortDetailsContent(e)} />
                      </div>
                    </div>
                    <div className={classNames(styles.form_column)} style={{ width: '50%' }}>
                      <label className={classNames("form-label mb-0", styles.form_label)}>Full Description</label>
                      {/* <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)' }}>
                        <textarea cols="30" rows="auto" className="form-control rounded-0" style={{ minHeight: '100%', border: 'none', resize: 'none' }} placeholder="" value={currentItem.details} name="details" onChange={(e) => updateOnChange(e)} ></textarea>
                      </div> */}
                      <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                        <Editor
                          apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                         
                          onInit={(evt, editor) => {
                            editorRef.current = editor
                            detailsChangeRef.current = 0

                          }

                          }
                          init={{
                            
                            height: 383,
                            width: '100%',
                            menubar: true,
                            plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                            toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                          }}
                          onEditorChange={(event, editor) => {
                            if (detailsChangeRef) {
                              if (detailsChangeRef.current !== 0) {
                                // console.log(currentItem.details[0].toString() === editor.getContent())
                                detailsChangeRef.current = detailsChangeRef.current + 1; 
                                setDetailsEdit(true)
                                // if (currentItem.details[0].toString() !== editor.getContent().toString()) {
                                //   console.log(1375,detailsChangeRef.current)
                                //   setCurrentItem({ ...currentItem, details: [editor.getContent()] })
                                // }
                              }else{
                                detailsChangeRef.current = 1
                              }
                            }



                          }}
                          initialValue={currentItem.details[0] ? currentItem.details[0].toString():''}
                        />
                        {/* <ReactQuill className={'item_editor'} value={currentItem.details.toString()} placeholder="Item Details" onChange={(e) => setDetailsContent(e)} /> */}
                      </div>
                    </div>
                    <div className={classNames(styles.form_column)}>
                      <label className={classNames("form-label mb-0", styles.form_label)}>Note</label>
                      {/* <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)' }}>
                        <textarea cols="30" rows="auto" className="form-control rounded-0" style={{ minHeight: '100%', border: 'none', resize: 'none' }} value={currentItem.note_for_item} name="note_for_item" onChange={(e) => updateOnChange(e)} placeholder=""></textarea>
                      </div> */}
                      <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', backgroundColor: '#fff', border: 'none' }}>
                        <ReactQuill className={'item_editor'} value={currentItem.note_for_item} placeholder="Write a note ..." onChange={(e) => setNoteContent(e)} />
                      </div>
                    </div>
                  </div>
                </div>
              }
              {activeTab === 3 &&
                <div className="tab-pane fade show active" id="contact">
                  <div className={classNames(styles.form_container)}>
                    <div className={styles.imageDropDownContainer}
                      onPaste={handlePaste}
                      onDrop={handleDrop}
                      onDragOver={handleDragOver}
                      onDragLeave={handleDragLeave} >
                      {currentItem.image_url.length > 0 ?
                        currentItem.image_url.filter(i => removedImage.indexOf(i) === -1).map((v, i) =>
                          <div className={styles.uploadedImage} key={i + '_prev_img'}>
                            <div className={styles.uploadedInner}>
                              <img src={IMAGE_URL + v} alt="Preview" />
                              <span className={styles.deleteImgIcon} onClick={() => delete_image(v)}><i className="fa-light fa-trash-can"></i></span>
                            </div>
                          </div>
                        )
                        : ''
                      }

                      {/* {props.selectedImage.length > 0 ?
                        props.selectedImage.map((v, i) =>
                          <div className={styles.uploadedImage} key={i + '_img'}>
                            <div className={styles.uploadedInner}>
                              <img src={v} alt="Preview" />
                            </div>
                          </div>
                        )
                        : ''
                      } */}
                      <label className={styles.uploaderDiv} htmlFor={'imageUpload'}>
                        <input type={'file'} name={'imageUpload'} id={'imageUpload'} onChange={handleImageChange} accept="image/*" hidden />
                        <span className={styles.addIcon}>
                          <i className="fa-thin fa-circle-plus"></i>
                        </span>
                        <span className={styles.addLabel}>Add Image</span>
                      </label>
                    </div>
                  </div>
                </div>
              }
              {activeTab === 4 &&
                <div className="tab-pane fade show active" id="profile">
                  <div className={classNames(styles.form_container)}>
                    {/* <div className="col-md-12">
                      
                    </div> */}
                  </div>
                </div>
              }

              
            </div>
          </div>

          {/* <div className="card-body table-responsive">
            <table className="table orderlist_table">
              <thead className={styles.thead_light}>
                <tr className={styles.table_heads}>
                  <th>Created at</th>
                  <th>Created by</th>
                  <th>Updated at</th>
                  <th>Updated by</th>
                  <th>Approved by</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                <tr className="">
                  <td> {currentItem.created_at !== null && moment(currentItem.created_at).format('lll')}</td>
                  <td>{currentItem.created_by}</td>
                  <td> {currentItem.updated_at !== null && moment(currentItem.updated_at).format('lll')}</td>
                  <td>{currentItem.updated_by}</td>
                  <td>{currentItem.approved_by}</td>
                </tr>
              </tbody>
            </table>
          </div> */}

        </div>
      )}
    </div>
  )
}

export default memo(BrandItems)