import React, { useState } from "react";
import styles from "../../../assets/css/Setting.module.css";
import StoreSetting from "./StoreSetting";
import DeliverySetting from "./DeliverySetting";
import StoreTimeSetting from "./StoreTimeSetting";
function Setting() {
  const [activetab, setActivetab] = useState("storeSetting");
  return (
    <div className={styles.settings_page}>

      <div className={styles.main_body}>
        <div className={styles.settings_cotainer} style={{ padding: "15px 0px" }} >
          {activetab === "storeSetting" ? (
            <StoreSetting />
          ) : activetab === "deliverySetting" ? (
            <DeliverySetting />
          ) : (
            <StoreTimeSetting />
          )}
        </div>
      </div>
    </div>
  );
}
export default Setting;
