/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from "react";
import styles from "../../../assets/css/AddNewStore.module.css";
import { add_sub_store, getStoreData, update_sub_store } from "../../../Utils/method";
import { isEmail } from 'validator'
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import ReactQuill from 'react-quill';
import { Editor } from '@tinymce/tinymce-react';


const colourStyles = {
  control: (base, provided) => ({
    ...base,
    fontSize: '14px',
    boxShadow: 'none',
    '&:focus': {
      border: '0 !important',
    },
    border: '1px solid #ced4da',
    borderRadius: '1px',
    background: 'transparent',
  }),
  placeholder: (baseStyles) => ({
    ...baseStyles,
    color: 'rgba(0,48,25,.1)',
  })
  ,
  options: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    padding: '1px 15px',
    width: '100%',
    borderBottom: '1px solid rgba(0,48,25,1)'
  })
  ,
  menu: (baseStyles) => ({
    ...baseStyles,
    borderRadius: 0,
    height: 'auto',
    width: '100%',
  })
  ,
  multiValue: (base) => ({
    ...base,
    background: '#ced4da',
    borderRadius: '0px',
    marginTop: '0px',
    fontWeight: '400',
  }),
  singleValue: (baseStyles) => ({
    ...baseStyles,
    color: 'rgba(0,48,25,.9)',
  })
}

function EmailSettings(props) {
  const [tab, setTab] = useState(1);
  const editorRef = useRef(null);
  const [btnStatus, setBtnStatus] = useState(false);
  const setDetailsContent = (e) => {

  }
  const setSubject = (e,key) => {
    props.setFormData({ ...props.formData, [key]: e.target.value });
  }
  useEffect(() => {
    if(tab === 1){
      if (props.formData.email_subject.length > 0 && props.formData.email_body.length > 0) {
        setBtnStatus(true);
      } else {
        setBtnStatus(false)
      }
    }else{
      if (props.formData.link_email_subject && props.formData.link_email_subject.length > 0 && props.formData.link_email_body && props.formData.link_email_body.length > 0) {
        setBtnStatus(true);
      } else {
        setBtnStatus(false)
      }
    }
    
    console.log(71, props.formData)

  }, [props.formData,tab])
  const log = () => {
    
    if (editorRef.current) {
      if(tab === 1){
        props.setFormData({ ...props.formData, email_body: editorRef.current.getContent() });
      }else{
        props.setFormData({ ...props.formData, link_email_body: editorRef.current.getContent() });
      }
      
      props.setEmailSettingPop(false);
    }
  };

  return (
    <div className={styles.wrapper}>
      <ToastContainer />
      <div className={styles.addStorePopup} style={{ width: '750px' }}>
        <h5 className={styles.addSub_header}>
          <p>
            <span onClick={() => setTab(1)} style={{opacity:tab !== 1 ? 0.5:1,cursor:"pointer" }}>Form email</span>
            <span onClick={() => setTab(2)} style={{opacity:tab !== 2 ? 0.5:1,cursor:"pointer",marginLeft:'4px' }}>Form link email</span>
          </p>

          <span className={styles.itemDesIcon} onClick={() => { props.setEmailSettingPop(false) }}>
            <i style={{ color: 'rgb(255 0 0 / 40%)', fontSize: 22 }} className="fa-thin fa-xmark"></i>
          </span>
        </h5>
        {tab === 1 &&
        <div className={styles.addSub_body}>
          <div className="col-md-12">
            <label className="small mb-1"> Subject</label>
            <div className="input-group mt-1 ">
              <input type="text" className="form-control rounded-0" value={props.formData.email_subject} onChange={(e) => { setSubject(e,'email_subject') }} placeholder='Subject' />

            </div>
          </div>
          <div className="col-md-12 mt-1">
            <label className="small mb-1" style={{ width: '100%' }}>Contents</label>
            <code style={{ width: '100%' }} >[FORM_USER_NAME]
              [FORM_TITLE]
              [APPLICATION_ID]
              [DYNAMIC_FIELDS]</code>
            <label className="small mb-1" style={{ width: '100%' }}>Body</label>
            <div className="input-group " style={{ width: '100%' }}>

              <Editor
                apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                  height: 500,
                  width: '100%',
                  menubar: true,
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                }}
                initialValue={props.formData.email_body}
              />

            </div>
          </div>
        </div>
        }
        {tab === 2 &&
        <div className={styles.addSub_body}>
          <div className="col-md-12">
            <label className="small mb-1"> Subject</label>
            <div className="input-group mt-1 ">
              <input type="text" className="form-control rounded-0" value={props.formData.link_email_subject || ''} onChange={(e) => { setSubject(e,'link_email_subject') }} placeholder='Subject' />

            </div>
          </div>
          <div className="col-md-12 mt-1">
            <label className="small mb-1" style={{ width: '100%' }}>Contents</label>
            <code style={{ width: '100%' }} >[FORM_USER_NAME]
              [FORM_TITLE]
              [APPLICATION_ID]
              [DYNAMIC_FIELDS]</code>
            <label className="small mb-1" style={{ width: '100%' }}>Body</label>
            <div className="input-group " style={{ width: '100%' }}>

              <Editor
                apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                onInit={(evt, editor) => editorRef.current = editor}
                init={{
                  height: 500,
                  width: '100%',
                  menubar: true,
                  plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                  toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                }}
                initialValue={props.formData.link_email_body || ''}
              />

            </div>
          </div>
        </div>
        }
        <div className={styles.button_section}>
          {
            <button className={styles.add_sub_store_btn} onClick={log} >Submit</button>
          }

        </div>
      </div>
    </div>
  );
}

export default EmailSettings;
