/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useState, useEffect } from 'react'
import { get_report, get_today_activity } from '../../../Utils/method';
import styles from '../../../assets/css/Activity.module.css'
import { IMAGE_URL } from '../../../Config/Config';
import Moment from 'react-moment';
import 'moment-timezone';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

function Activity() {
    const [loader, setLoader] = useState(true);
    const [activities, setActivities] = useState([])
    const [filter, setFilter] = useState({
        start: '',
        end: '',
    });

    useEffect(()=> {
        console.log(20, filter)
    },[filter])

    useEffect(() => {
        let _isMounted = true;

        async function initial() {
            setLoader(true);
            try {
                let apires = await get_today_activity();
                console.log(apires.data.activities)
                if (_isMounted) {
                    setActivities(apires.data.activities)
                }
                setLoader(false);
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
        initial()
        return () => {
            _isMounted = false;
        }
    }, [])

    const get_reports = async () => {
        setLoader(true);
        try {
            let apires = await get_report(filter);
            console.log(apires);
            setLoader(false);
        } catch (error) {
            setLoader(false);
            console.log(error);
        }
    }

    return (
        <div className={styles.brand_page}>
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Activity</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Activity</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.filterSection}>
                <div className={styles.nav}>
                    <div className={styles.nav_item_left}>
                        <div className={styles.navItem} style={{ paddingLeft: 0 }}>
                            <span className={styles.dateLabel}>Start Date</span>
                            <div className={styles.searchbar}>
                                <input type="date" placeholder="Search" value={filter.start} onChange={(e) => setFilter({ ...filter, start: e.target.value })} className={styles.searchbox} />
                            </div>
                        </div>
                        <div className={styles.navItem}>
                            <span className={styles.dateLabel}>End Date</span>
                            <div className={styles.searchbar}>
                                <input type="date" placeholder="Search" value={filter.end} onChange={(e) => setFilter({ ...filter, end: e.target.value })} className={styles.searchbox} />
                            </div>
                        </div>
                        <div className={styles.navItem}>
                            <div className={classNames(styles.searchbar, styles.filterBtn)} style={filter.start === '' || filter.end === '' ? { pointerEvents: 'none', opacity: 0.6 } : {}}>
                                <button className={styles.searchIcon} type="submit" onClick={get_reports}>
                                    <span><i className="fa-light fa-magnifying-glass"></i></span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.nav} style={{ width: 'auto' }}>
                    <div className={styles.paginationSection}>
                        <div className="justify-content-center" style={{ marginRight: 10 }}>
                            <ul className="pagination justify-content-center">
                                <li className="pagination-prev page-item disabled">
                                    <Link className={styles.page_link}>
                                        <i className="fa-thin fa-angles-left" style={{ fontSize: 16 }}></i>
                                    </Link>
                                </li>
                                <li className="pagination-page page-item active">
                                    <Link href="#" className={styles.page_link}> 1 </Link>
                                </li>
                                <li className="pagination-page page-item active">
                                    <Link href="#" className={styles.page_link}> 2 </Link>
                                </li>
                                <li className="pagination-page page-item active">
                                    <Link href="#" className={styles.page_link}> 3 </Link>
                                </li>
                                <li className="pagination-page page-item active">
                                    <Link href="#" className={styles.page_link}> 4 </Link>
                                </li>
                                <li className="pagination-page page-item active">
                                    <Link href="#" className={styles.page_link}> 5 </Link>
                                </li>
                                <li className="pagination-next page-item disabled">
                                    <Link href="#" className={styles.page_link}>
                                        <i className="fa-thin fa-angles-right" style={{ fontSize: 16 }}></i>
                                    </Link>
                                </li>
                            </ul>
                        </div>
                        <div className={styles.navItem}>
                            <span className={styles.per_page}>Per Page</span>
                            <button className={styles.btn_page}> 10
                                <span> <i className="fa-duotone fa-caret-down"></i></span>
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main_body}>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12">
                        <div className="card rounded-0">
                            <div className="card-body table-responsive">
                                <table className="table orderlist_table">
                                    <thead className={styles.thead_light}>
                                        <tr className={styles.table_heads}>
                                            <th>Name</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                            <th>Created by</th>
                                            <th>Created at</th>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.tbody}>
                                        {activities.map(v =>
                                            <tr className="" key={v._id}>
                                                <td>{v.history.name}</td>
                                                <td>{v.target_type}</td>
                                                <td>{v.type}</td>
                                                <td>{v.user_name}</td>
                                                <td><Moment format="YYYY-MM-DD hh:mm A">{v.history.created_at}</Moment></td>
                                            </tr>
                                        )}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* {deleteStatus && <DeletePopup deleteCancel={deleteCancel} deleteItem={deleteItem} />} */}
        </div>
    )
}

export default Activity