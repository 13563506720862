import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/AddNewOffer.module.css";
import promoImage from "../../../assets/promo_code.png";
import { add_promo, getStoreData } from "../../../Utils/method";

function AddNewOffer(props) {
  const store = getStoreData({ type: 'get' });
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday"
  ];

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December"
  ];

  const weeks = [
    "First",
    "Second",
    "Third",
    "Fourth",
    "Fifth"
  ];
  const [loader, setLoader] = useState(false)
  // const [form_data, setForm_data] = useState({
  //   promo_code_name:'',
  //   promo_details:'',
  //   promo_code_type:0,
  //   promo_code_value:0,
  //   promo_for:'',
  //   promo_apply_on:'',
  //   is_active:false,
  //   is_promo_apply_on_completed_order:false,
  //   promo_apply_after_completed_order:0,
  //   is_promo_required_uses:false,
  //   promo_code_uses:'',
  //   is_promo_have_minimum_amount_limit:false,
  //   promo_code_apply_on_minimum_amount:0,
  //   is_promo_have_item_count_limit:false,
  //   promo_code_item_count_limit:'', //1
  //   is_promo_have_max_discount_limit:false,
  //   promo_code_max_discount_amount:0,
  //   is_promo_have_date:false,
  //   promo_start_date:'', //2
  //   promo_expire_date:'', //3
  //   promo_recursion_type:'',
  //   store_id:'', //4
  //   server_token:'', //5
  //   days:'',
  //   months:'',
  //   weeks:'',
  //   promo_end_time:'',
  //   promo_start_time:'',
  //   timezone:'',
  // })
  const [form_data, setForm_data] = useState({
    promo_details: '',
    promo_code_name: null,
    promo_code_value: 0,
    promo_code_type: '0',
    promo_code_uses: 0,
    is_active: false,
    is_promo_have_date: false,
    promo_start_date: '',
    promo_expire_date: '',
    is_promo_required_uses: false,
    is_promo_have_minimum_amount_limit: false,
    is_promo_have_max_discount_limit: false,
    is_promo_apply_on_completed_order: false,
    is_promo_have_item_count_limit: false,
    promo_recursion_type: "0",
    promo_for: "2",
    promo_apply_after_completed_order: 0,
    promo_code_apply_on_minimum_amount: 0,
    promo_code_max_discount_amount: 0,
    promo_code_apply_on_minimum_item_count: 0,
    promo_end_time: null,
    promo_start_time: null,
    days: [],
    months: [],
    weeks: [],
    promo_apply_on: [],
    admin_loyalty_type: 1,
    store_id: store.store._id,
    server_token: store.store.server_token,
    timezone: store.timezone,
  })

  const getForm_data = (e) => {
    const { name, value, type } = e.target;

    if (type === 'checkbox') {
      setForm_data({ ...form_data, [name]: e.target.checked })
    } else {
      setForm_data({ ...form_data, [name]: value })
    }
  }

  useEffect(() => {
    console.log(77, form_data);
  }, [form_data])

  const addNewPromo = async () => {
    setLoader(true)
    try {
      let apires = await add_promo(form_data)
      console.log(apires);
      if (apires.data.success) {
        props.addNewOffer_to_list()
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  return (
    <div className={styles.wrapper}>
      <div className={styles.addSubCategoryPopup}>
        <h5 className={styles.addSub_header}>Add New Offer
          <span className={styles.itemDesIcon} onClick={() => props.setNewOffer(false)}>
            <i style={{ color: 'rgb(255 0 0 / 40%)', fontSize: 22 }} className="fa-thin fa-xmark"></i>
          </span>
        </h5>
        <div className={styles.formContainer}>
          <div className="card mb-3">
            <div className="position-absolute card-top-buttons float-right">
              {/* <button type="button" className={styles.imgButton}> */}
              <label htmlFor="inputGroupFile02" className={styles.imgButton}><i className="fa-thin fa-pencil"></i></label>
              <input type="file" id="inputGroupFile02" className={styles.customFileInput} hidden />
              {/* </button> */}
            </div>
            <img src={promoImage} alt="" />
          </div>
          <div className="mt-2">
            <label>Promo Code</label>
            <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_name" className="form-control rounded-0" />
          </div>
          <div className="row mt-2">
            <div className="col-7">
              <label>Promo type</label>
              <select className="form-select rounded-0" onChange={(e) => getForm_data(e)} value={form_data.promo_code_type} name="promo_code_type">
                <option value={'0'}>Percentage</option>
                <option value={'1'}>Absolute</option>
              </select>
            </div>
            <div className="col-5">
              <label>Discount</label>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_value" min={0} className="form-control rounded-0 text-end" />
            </div>
          </div>
          <div className="mt-2">
            <label>Description</label>
            <textarea onChange={(e) => getForm_data(e)} name="promo_details" className="form-control rounded-0" rows={3}></textarea>
          </div>
          <div className="mt-2">
            <label>Promo For</label>
            <select className="form-select rounded-0" onChange={(e) => getForm_data(e)} name="promo_for">
              <option value={'2'}>Store</option>
              <option value={'21'}>Sub Category</option>
              <option value={'22'}>Item</option>
            </select>
          </div>
          <div className="mt-2">
            <div className="form-check form-switch">
              <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_active" className="form-check-input" id="is_visible" />
              <label>Is your offer running ?</label>
            </div>
          </div>
          <div className="mt-2">
            <p className="text-truncate mb-0" style={{ fontSize: 16, fontWeight: 400 }} > Offer Advance settings </p>
            <hr className="mt-0"></hr>
          </div>
          <div className="mt-2">
            <div className="form-check form-switch">
              <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_promo_have_date" className="form-check-input" id="is_visible" />
              <label>Do you want to set offer validation date ?</label>
            </div>
          </div>
          {form_data.is_promo_have_date &&
            <div className="mt-2">
              <div className="row">
                <div class="col-6 col-lg-6">
                  <label>Offer Start Date</label>
                  <input type="date" onChange={(e) => getForm_data(e)} name="promo_start_date" className="form-control rounded-0" />
                </div>
                <div class="col-6 col-lg-6">
                  <label>Offer Expire Date</label>
                  <input type="date" onChange={(e) => getForm_data(e)} name="promo_expire_date" className="form-control rounded-0" />
                </div>
              </div>
            </div>
          }
          <div className="mt-2">
            <label>Recursion type</label>
            <select className="form-select rounded-0 small" onChange={(e) => getForm_data(e)} name="promo_recursion_type">
              <option value={'0'}>No Recursion</option>
              <option value={'1'}>Daily Recursion</option>
              <option value={'2'}>Weekly Recursion</option>
              <option value={'3'}>Monthly Recursion</option>
              <option value={'4'}>Annually Recursion</option>
            </select>
          </div>
          {form_data.promo_recursion_type === '1' &&
            <div className="mt-2">
              <div className="row">
                <div class="col-12 col-lg-6">
                  <label>Open Time</label>
                  <input type="time" onChange={(e) => getForm_data(e)} name="promo_start_time" className="form-control rounded-0" />
                </div>
                <div class="col-12 col-lg-6">
                  <label>Close Time</label>
                  <input type="time" onChange={(e) => getForm_data(e)} name="promo_end_time" className="form-control rounded-0" />
                </div>
              </div>
            </div>
          }
          {form_data.promo_recursion_type === '2' &&
            <div className="mt-2">
              <label>Day</label>
              {/* <ng-select [multiple]="true" [closeOnSelect]="false" formControlName="days">
                    <ng-option *ngFor="let day of days" [value]="day">{{day}}</ng-option>
                </ng-select> */}
              <select onChange={(e) => getForm_data(e)} name="days" className="form-select rounded-0 small">
                {days.map(v => <option key={'_' + v} value={v}>{v}</option>)}
              </select>
            </div>
          }
          {form_data.promo_recursion_type === '3' &&
            <div className="mt-2">
              <label>Week</label>
              {/* <ng-select [multiple]="true" [closeOnSelect]="false" formControlName="weeks">
                    <ng-option *ngFor="let week of weeks" [value]="week">{{week}}</ng-option>
                </ng-select> */}
              <select onChange={(e) => getForm_data(e)} name="weeks" className="form-select rounded-0 small">
                {weeks.map(v => <option key={'_' + v} value={v}>{v}</option>)}
              </select>
            </div>
          }
          {form_data.promo_recursion_type === '4' &&
            <div className="mt-2">
              <label>Month</label>
              {/* // <ng-select [multiple]="true" [closeOnSelect]="false" formControlName="months">
                //     <ng-option *ngFor="let month of months" [value]="month">{{month}}</ng-option>
                // </ng-select> */}
              <select onChange={(e) => getForm_data(e)} name="months" className="form-select rounded-0 small">
                {months.map(v => <option key={'_' + v} value={v}>{v}</option>)}
              </select>
            </div>
          }
          <div className="mt-2">
            <label>Apply offer after complete no of order</label>
            <div className="input-group mb-3">
              <div className="input-group-text rounded-0" style={{ display: 'flex', height: 38 }}>
                <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_promo_apply_on_completed_order" className="form-check-input rounded-0 mt-0" aria-label="Checkbox for following text input" />
              </div>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_apply_after_completed_order" disabled={!form_data.is_promo_apply_on_completed_order} className="form-control rounded-0" aria-label="Text input with checkbox" />
            </div>
          </div>
          <div className="mt-2">
            <label>Required Uses</label>
            <div className="input-group mb-3">
              <div className="input-group-text rounded-0" style={{ display: 'flex', height: 38 }}>
                <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_promo_required_uses" className="form-check-input rounded-0 mt-0" value="" aria-label="Checkbox for following text input" />
              </div>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_uses" disabled={!form_data.is_promo_required_uses} className="form-control rounded-0" aria-label="Text input with checkbox" />
            </div>
          </div>
          <div className="mt-2">
            <label>Do you want to set min order amount for apply ?</label>
            <div className="input-group mb-3">
              <div className="input-group-text rounded-0" style={{ display: 'flex', height: 38 }}>
                <input className="form-check-input rounded-0 mt-0" onChange={(e) => getForm_data(e)} name="is_promo_have_minimum_amount_limit" type="checkbox" aria-label="Checkbox for following text input" />
              </div>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_apply_on_minimum_amount" disabled={!form_data.is_promo_have_minimum_amount_limit} className="form-control rounded-0" aria-label="Text input with checkbox" />
            </div>
          </div>
          <div className="mt-2">
            <label>Do you want to set max discount amount ?</label>
            <div className="input-group mb-3">
              <div className="input-group-text rounded-0" style={{ display: 'flex', height: 38 }}>
                <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_promo_have_max_discount_limit" className="form-check-input rounded-0 mt-0" aria-label="Checkbox for following text input" />
              </div>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_max_discount_amount" disabled={!form_data.is_promo_have_max_discount_limit} className="form-control rounded-0" aria-label="Text input with checkbox" />
            </div>
          </div>
          <div className="mt-2">
            <label>Do you want to set min item on cart for apply ?</label>
            <div className="input-group mb-3">
              <div className="input-group-text rounded-0" style={{ display: 'flex', height: 38 }}>
                <input type="checkbox" onChange={(e) => getForm_data(e)} name="is_promo_have_item_count_limit" className="form-check-input rounded-0 mt-0" aria-label="Checkbox for following text input" />
              </div>
              <input type="text" onChange={(e) => getForm_data(e)} name="promo_code_apply_on_minimum_item_count" disabled={!form_data.is_promo_have_item_count_limit} className="form-control rounded-0" aria-label="Text input with checkbox" />
            </div>
          </div>

          <hr className="mb-0"></hr>
          <div className="row">
            <div className="col-6 mt-3 text-center">
              <input type="submit" className="btn rounded-1 form-control outline-none" value="Cancel"
                style={{
                  color: "#00365a",
                  fontSize: 16,
                  borderColor: "#00365a",
                }}
              />
            </div>
            <div className="col-6 mt-3 text-center">
              <input type="submit" className="btn btn-primary rounded-1 form-control outline-none" onClick={addNewPromo} value="Add"
                style={{
                  fontSize: 16,
                  backgroundColor: "#00365a",
                  borderColor: "#00365a",
                }}
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddNewOffer;
