import React, { useState, useEffect } from 'react';
import styles from "../../assets/css/ProductItems.module.css";
import classNames from 'classnames';

const Paginate = ({ pageCount, onPageChange, marginPagesDisplayed, pageRangeDisplayed, currentPage }) => {
  const [current, setCurrent] = useState(currentPage || 1);

  useEffect(() => {
    // console.log(':::::::::currentPage::::::::::::', currentPage);
    setCurrent(currentPage)
  }, [currentPage, pageCount])

  const handlePageClick = (page) => {
    setCurrent(page);
    onPageChange(page);
  };

  const getPageNumbers = () => {
    const pageNumbers = [];
    const totalPageBlocks = marginPagesDisplayed * 2 + pageRangeDisplayed;
    const totalPages = pageCount < totalPageBlocks ? pageCount : totalPageBlocks;
    const leftMargin = marginPagesDisplayed;
    const rightMargin = marginPagesDisplayed;

    if (current - leftMargin > 1) {
      pageNumbers.push('leftEllipsis');
    }

    for (let i = current - leftMargin; i <= current + rightMargin; i++) {
      if (i > 0 && i <= pageCount) {
        pageNumbers.push(i);
      }
    }

    if (current + rightMargin < pageCount) {
      pageNumbers.push('rightEllipsis');
    }

    return pageNumbers;
  };

  const pageNumbers = getPageNumbers();

  return (
    // <div className="pagination">
    <div className="justify-content-center" style={{ marginRight: 10 }}>
      <ul className="pagination justify-content-center align-items-center">
        <div className="pagination-text" style={{ fontSize: 14, marginRight: 6, fontFamily: 'var(--robotoC)' }}>{`Page ${current} of ${pageCount}`}</div>
        <button disabled={current === 1} onClick={() => handlePageClick(1)} className="pagination-prev page-item" >
          <span className={styles.page_link}>
            <i className="fa-thin fa-angles-left" style={{ fontSize: 16 }}></i>
          </span>
        </button>
        <button disabled={current === 1} onClick={() => handlePageClick(current - 1)} className="pagination-prev page-item" >
          <span className={styles.page_link}>
            <i className="fa-thin fa-chevron-left" style={{ fontSize: 16 }}></i>
          </span>
        </button>
        {pageNumbers.map((pageNumber) => {
          if (pageNumber === 'leftEllipsis' || pageNumber === 'rightEllipsis') {
            return (
              <span key={pageNumber} className="pagination-ellipsis">...</span>
            );
          } else {
            return (
              <button
                key={pageNumber}
                className={`pagination-page page-item ${pageNumber === current ? 'active' : ''}`}
                onClick={() => handlePageClick(pageNumber)}
              >
                <span className={classNames(styles.page_link, pageNumber === current ? 'active' : '')}>{pageNumber}</span>
              </button>
            );
          }
        })}
        <button disabled={(current === pageCount) || current > pageCount} onClick={() => handlePageClick(current + 1)} className="pagination-next page-item" >
          <span className={styles.page_link}>
            <i className="fa-thin fa-chevron-right" style={{ fontSize: 16 }}></i>
          </span>
        </button>
        <button disabled={(current === pageCount) || current > pageCount} onClick={() => handlePageClick(pageCount)} className="pagination-next page-item" >
          <span className={styles.page_link}>
            <i className="fa-thin fa-angles-right" style={{ fontSize: 16 }}></i>
          </span>
        </button>
      </ul>
    </div>
  );
};

export default Paginate;
