import React, { useState } from "react";
// import UKBD from "../../assets/UKBD.png";
// import UKEUbrands from "./SideBarItems/UKEUbrands";
import styles from "../../assets/css/LeftBar.module.css";
import "../../assets/css/RightBar.module.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
// import { useSelector } from "react-redux";
// import { IMAGE_URL } from "../../Config/Config";
import classNames from "classnames";
import { getStoreData, logout } from "../../Utils/method";
import { RouteAccessCheck } from "../../Utils/common"


function LeftBar() {

  const allDomains = JSON.parse(localStorage.getItem('all_domains'));
  const swtichDomain = (e) => {
    let value = e.target.value;
    localStorage.setItem('domain', JSON.stringify([{ _id: 'all_domain_access', name: 'All' }, ...allDomains].filter(v => v._id == value)[0]));
    window.location.href = "/";
    // console.log(JSON.stringify(allDomains.filter(v=> v._id == value)[0]))

  }
  const selected_domain = localStorage.getItem('domain') ? JSON.parse(localStorage.getItem('domain')) : null;
  const location = useLocation();
  const navigate = useNavigate();
  const user_data = getStoreData({ type: 'get' }).store;
  const sub_store = getStoreData('get').sub_store;

  const [open, setOpen] = useState(false);
  const [collapseItem, setCollapseItem] = useState(
    location.pathname.indexOf('/order/') > -1 ? 'Order' :
      location.pathname.indexOf('/earning/') > -1 ? 'Earning' :
        location.pathname.indexOf('/menu/') > -1 ? 'Menu' :
          location.pathname.indexOf('/user/') > -1 ? 'User' :
            location.pathname.indexOf('/offers') > -1 ? 'Offers' :
              location.pathname.indexOf('/*-application') > -1 ? 'application' :
                location.pathname.indexOf('/settings/') > -1 ? 'Settings' : ''
  );
  const [collapseSubItem, setCollapseSubItem] = useState(
    (location.pathname.indexOf('/menu/item-list') > -1 || location.pathname.indexOf('/menu/category') > -1) ? 'Menu' : ''
  );
  // const categories = useSelector((state) => state.rootReducer.product_groups);

  const categoryCollapse = (id) => {
    setCollapseItem(prev => prev !== id ? id : '');
  };
  const subCategoryCollapse = (id) => {
    setCollapseSubItem(prev => prev !== id ? id : '');
  };

  const LogOut = () => {
    if (logout()) {
      localStorage.removeItem('domain')
      navigate('/sign-in', { replace: true })
    } else {
      navigate('/', { replace: true })
    }
  }

  return (
    <aside className={styles.left_bar}>
      <div className={styles.aside_head}>
        <div className={styles.logo}>
          <i className="fa-thin fa-user"></i>
        </div>
        <div className={styles.userName}>
          {/* {selected_domain && <span>{selected_domain.name == 'All' ? `ALL Domains [${sub_store ? "USER":"ADMIN"}]`:`${selected_domain.name} [${sub_store ? "USER":"ADMIN"}]`}</span>} */}
          {user_data !== undefined && <span>{user_data.name[0]}</span>}
          <div className={styles.logOutBtn} onClick={LogOut}><i className="fa-thin fa-lock" style={{ marginRight: 5 }}></i> Logout</div>
        </div>

        <div>

        </div>
      </div>
      {!sub_store &&
        <select className="switch_domain" defaultValue={selected_domain._id} onChange={(e) => swtichDomain(e)}>
          <option value="all_domain_access">All</option>
          {!sub_store && allDomains.map(v => <option value={v._id}>{v.name}</option>)}

        </select>
      }
      <div className={styles.aside_container}>
        {/* <UKEUbrands /> */}
        <div className={styles.asideFixedContainer}></div>
        <div className={styles.category_list}>
          <div className={styles.group_items}>
            {(RouteAccessCheck(["/order/past-order", "/order/today-order", "/order/tomorrow-order"], 'OR')) && <div className={styles.main_category_container} onClick={() => categoryCollapse('Order')}>
              <p className={styles.title}>Order</p>
              <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
            </div>}

            {collapseItem === 'Order' &&
              <div className={styles.collapseBody}>

                <ul className={styles.sub_category_container} style={{ padding: '0px 10px 0' }}>
                  {RouteAccessCheck("/order/create-order") && <Link to={"/order/create-order"} className={styles.subCate_item}>Create Order</Link>}
                </ul>
                <ul className={styles.sub_category_container}>
                  <div className={classNames(styles.subCate_item, styles.secondCollapse)}>
                    <div className={classNames(styles.main_category_container, styles.secondContainer)} onClick={() => subCategoryCollapse('Running')}>
                      <p className={styles.title}>Running Order</p>
                      <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
                    </div>
                    {(collapseItem === 'Order' && collapseSubItem === 'Running') &&
                      <div className={styles.collapseBody}>
                        <ul className={styles.sub_category_container}>
                          {RouteAccessCheck("/order/past-order") && <Link to={"/order/past-order"} className={styles.subCate_item}>Past Order</Link>}
                          {RouteAccessCheck("/order/today-order") && <Link to={"/order/today-order"} className={styles.subCate_item}>Today Order</Link>}
                          {RouteAccessCheck("/order/tomorrow-order") && <Link to={"/order/tomorrow-order"} className={styles.subCate_item}>Tomorrow Order</Link>}



                          {/* <Link to={"/order/schedule-order"} className={styles.subCate_item}>Schedule Order</Link> */}
                          {/* <Link to={"/order/dispatch-order"} className={styles.subCate_item}>Dispatch</Link> */}
                          {/* <Link to={"/order/table-booking"} className={styles.subCate_item}>Table Booking</Link> */}
                        </ul>
                      </div>
                    }
                  </div>
                </ul>
                <ul className={styles.sub_category_container}>
                  <div className={classNames(styles.subCate_item, styles.secondCollapse)}>
                    <div className={classNames(styles.main_category_container, styles.secondContainer)} onClick={() => subCategoryCollapse('History')}>
                      <p className={styles.title}>History</p>
                      <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
                    </div>
                    {(collapseItem === 'Order' && collapseSubItem === 'History') &&
                      <div className={styles.collapseBody}>
                        <ul className={styles.sub_category_container}>
                          <Link to={"/order/current-week"} className={styles.subCate_item}>Current Week</Link>
                          <Link to={"/order/current-month"} className={styles.subCate_item}>Current Month</Link>
                          <Link to={"/order/history"} className={styles.subCate_item}>History</Link>
                          <Link to={"/order/reviews"} className={styles.subCate_item}>Reviews</Link>
                        </ul>
                      </div>
                    }
                  </div>
                </ul>
              </div>
            }
          </div>
          {(RouteAccessCheck(["/earning/current-week", "/earning/current-month", "/earning/earnings"], 'OR')) &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('Earning')}>
                <p className={styles.title}>Earning</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {
                collapseItem === 'Earning' &&
                <div className={styles.collapseBody}>
                  <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                    {RouteAccessCheck("/earning/current-week") && <Link to={"/earning/current-week"} className={styles.subCate_item}>Current Week</Link>}

                    {RouteAccessCheck("/earning/current-month") && <Link to={"/earning/current-month"} className={styles.subCate_item}>Current Month</Link>}
                    {RouteAccessCheck("/earning/earnings") && <Link to={"/earning/earnings"} className={styles.subCate_item}>Earning</Link>}

                  </ul>
                </div>
              }
            </div>
          }

          {RouteAccessCheck(["/modifier/modifier-category", "/modifier/import-data", "/menu/jobs", "/menu/item-list", "/menu/brand", "/menu/supplier", "/menu/category", "/menu/sub-category"], "OR") &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('Menu')}>
                <p className={styles.title}>Product</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {collapseItem === 'Menu' &&
                <div className={styles.collapseBody}>
                  {RouteAccessCheck(["/menu/item-list", "/menu/brand", "/menu/supplier", "/menu/category", "/menu/sub-category"], "OR") &&
                    <ul className={styles.sub_category_container}>
                      <div className={classNames(styles.subCate_item, styles.secondCollapse)}>
                        <div className={classNames(styles.main_category_container, styles.secondContainer)} onClick={() => subCategoryCollapse('Menu')}>
                          <p className={styles.title}>Menu</p>
                          <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
                        </div>
                        {(collapseItem === 'Menu' && collapseSubItem === 'Menu') &&
                          <div className={styles.collapseBody}>
                            <ul className={styles.sub_category_container}>
                              {RouteAccessCheck("/menu/item-list") && <Link to={"/menu/item-list"} className={styles.subCate_item}>Items</Link>}
                              {RouteAccessCheck("/menu/brand") && <Link to={"/menu/brand"} className={styles.subCate_item}>Brand</Link>}

                              {/* <Link to={"/menu/store"} className={styles.subCate_item}>Store</Link> */}

                              {RouteAccessCheck("/menu/supplier") && <Link to={"/menu/supplier"} className={styles.subCate_item}>Supplier</Link>}
                              {RouteAccessCheck("/menu/category") && <Link to={"/menu/category"} className={styles.subCate_item}>Category</Link>}
                              {RouteAccessCheck("/menu/sub-category") && <Link to={"/menu/sub-category"} className={styles.subCate_item}>Sub-category</Link>}
                            </ul>
                          </div>
                        }
                      </div>
                    </ul>
                  }

                  {RouteAccessCheck("/menu/jobs") &&
                    <ul className={styles.sub_category_container}>
                      <div className={classNames(styles.subCate_item, styles.secondCollapse)}>
                        <Link to={"/menu/jobs"} className={styles.main_category_container}>
                          <p className={styles.title}>Jobs</p>
                        </Link>
                      </div>
                    </ul>
                  }

                  {RouteAccessCheck(["/modifier/modifier-category", "/modifier/import-data"], "OR") &&
                    <ul className={styles.sub_category_container}>
                      <div className={classNames(styles.subCate_item, styles.secondCollapse)}>
                        <div className={classNames(styles.main_category_container, styles.secondContainer)} onClick={() => subCategoryCollapse('Modifier')}>
                          <p className={styles.title}>Modifier</p>
                          <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
                        </div>
                        {(collapseItem === 'Menu' && collapseSubItem === 'Modifier') &&
                          <div className={styles.collapseBody}>
                            <ul className={styles.sub_category_container}>
                              <Link to={"/modifier/modifier-category"} className={styles.subCate_item}>Modifier Category</Link>
                              <Link to={"/modifier/import-data"} className={styles.subCate_item}>Import Export</Link>
                            </ul>
                          </div>
                        }
                      </div>
                    </ul>
                  }

                </div>
              }
            </div>
          }
          {RouteAccessCheck(["/job-application", "/sponsorship-application", "/admission-application", "/accomodation-application"], "OR") &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('application')}>
                <p className={styles.title}>Applications</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {collapseItem === 'application' &&
                <div className={styles.collapseBody}>
                  <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                    {RouteAccessCheck("/job-application") && <Link to={"/job-application"} className={styles.subCate_item}>Job</Link>}
                    {RouteAccessCheck("/sponsorship-application") && <Link to={"/sponsorship-application"} className={styles.subCate_item}>Sponsorship</Link>}
                    {RouteAccessCheck("/admission-application") && <Link to={"/admission-application"} className={styles.subCate_item}>Admission</Link>}
                    {RouteAccessCheck("/accomodation-application") && <Link to={"/accomodation-application"} className={styles.subCate_item}>Accomodation</Link>}
                  </ul>
                </div>
              }
            </div>
          }
          {RouteAccessCheck(["/users"], "OR") &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('User')}>
                <p className={styles.title}>User</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {collapseItem === 'User' &&
                <div className={styles.collapseBody}>
                  <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                    <Link to={"/users"} className={styles.subCate_item}>Sub Stores</Link>
                  </ul>
                </div>
              }
            </div>
          }
          {RouteAccessCheck(["/dynamic_forms"], "OR") &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('DynamicForm')}>
                <p className={styles.title}>Dynamic Form</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {collapseItem === 'DynamicForm' &&
                <div className={styles.collapseBody}>
                  <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                    <Link to={"/dynamic_forms/add"} className={styles.subCate_item}>Add New Form</Link>
                    <Link to={"/dynamic_forms"} className={styles.subCate_item}>Manage Forms</Link>
                    <Link to={"/dynamic_forms/submitted_forms"} className={styles.subCate_item}>Submitted Forms</Link>
                    <Link to={"/dynamic_forms/send_link"} className={styles.subCate_item}>Send Link</Link>
                    <Link to={"/dynamic_forms/form_preview"} className={styles.subCate_item}>Form Preview</Link>
                  </ul>
                </div>
              }
            </div>
          }
          {RouteAccessCheck(["/offer"], "OR") &&
            <div className={styles.group_items}>
              <Link to={"/offer"} className={styles.main_category_container}>
                <p className={styles.title}>Offers</p>
              </Link>
            </div>
          }
          {RouteAccessCheck(["/domains"], "OR") &&
            <div className={styles.group_items}>
              <Link to={"/domains"} className={styles.main_category_container}>
                <p className={styles.title}>Domains</p>
              </Link>
            </div>
          }

          {RouteAccessCheck(["/settings/store-settings", "/settings/delivery-settings", "/settings/time-settings"], "OR") &&
            <div className={styles.group_items}>
              <div className={styles.main_category_container} onClick={() => categoryCollapse('Settings')}>
                <p className={styles.title}>Settings</p>
                <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
              </div>
              {collapseItem === 'Settings' &&
                <div className={styles.collapseBody}>
                  <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                    {RouteAccessCheck("/settings/store-settings") && <Link to={"/settings/store-settings"} className={styles.subCate_item}>Store Settings</Link>}
                    {RouteAccessCheck("/settings/delivery-settings") && <Link to={"/settings/delivery-settings"} className={styles.subCate_item}>Delivery Settings</Link>}
                    {RouteAccessCheck("/settings/time-settings") && <Link to={"/settings/time-settings"} className={styles.subCate_item}>Store Time Settings</Link>}
                    {RouteAccessCheck("/settings/time-settings") && <Link to={"/settings/email-settings"} className={styles.subCate_item}>Email Setting</Link>}

                  </ul>
                </div>
              }
            </div>
          }
          {/* <div className={styles.group_items}>
            <div className={styles.main_category_container} onClick={() => categoryCollapse('Activity')}>
              <p className={styles.title}>Activity</p>
              <p className={styles.icon_p}><i className="fa-light fa-chevron-down"></i></p>
            </div>
            {collapseItem === 'Activity' &&
              <div className={styles.collapseBody}>
                <ul className={styles.sub_category_container} style={{ padding: '0px 10px 10px' }}>
                  <Link to={"/activity"} className={styles.subCate_item}>Activity</Link>
                </ul>
              </div>
            }
          </div> */}
        </div>
      </div>
      <div className={styles.aside_foot}>

        <p>
          {new Date().getFullYear()} &#169; {"UKBD App Ltd."}
        </p>
      </div>
    </aside>
  );
}

export default LeftBar;
