import React, { useState, useEffect, useRef } from 'react'
import { Link, useNavigate } from "react-router-dom";
// import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css'
import { get_brand, register_sub_store, register_sub_store_verify } from "../../../Utils/method"
import isEmail from 'validator/lib/isEmail';
import styles from '../../../assets/css/SignIn.module.css'
import classNames from 'classnames';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Select from 'react-select'
import CreatableSelect from 'react-select/creatable';

function SignUp() {
    const navigate = useNavigate();
    const [passVisibility, setPassVisibility] = useState(false);
    const [acceptCondition, setAcceptCondition] = useState(false);
    const [buttonState, setButtonState] = useState(false);
    const [spining, setSpining] = useState(false);
    const [page_state, setPage_state] = useState(1);
    const [otpValues, setOtpValues] = useState(['', '', '', '', '', '']);
    const inputRefs = useRef([]);
    const [brands, setBrands] = useState([]);
    const [selected_brands, setSelected_brands] = useState([]);

    const [errMsg, setErrMsg] = useState({
        email: '',
        password: ''
    })

    const [inputData, setInputData] = useState({
        name: '',
        email: '',
        password: '',
        request_brand_names: [],
    })

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            try {
                let apires = await get_brand(`?perPage=${2000}`);
                if (_isMounted) {
                    console.log(16, 'get_brand:', apires);
                    if (apires.data.brands.length > 0) {
                        let data = [];
                        let sort_v = apires.data.brands.sort((a, b) => a.name.localeCompare(b.name));
                        for (const p of sort_v) {
                            data.push({ value: p._id, label: p.name })
                        }
                        setBrands(data);
                    }
                    // setLoader(false);
                }
            } catch (error) {
                // setLoader(false);
                console.log(error)
            }
        }
        initial()
        return () => { _isMounted = false }
    }, [])

    const onChangeFormData = (e, ext) => {
        if (ext) {
            setSelected_brands(e)
            setInputData({ ...inputData, [ext]: [...e.map(item => item.label)] })
        } else {

            let { name, value } = e.target;
            console.log(name, value)

            if (name === 'name') {
                if (Number(value.charAt(0))) {
                    return;
                }
            } else if (name === 'email') {
                if (value !== '' && !isEmail(value)) {
                    console.log(41, 'It\'s not Email.')
                    setErrMsg({ ...errMsg, email: 'Enter valid email address' });
                } else {
                    setErrMsg({ ...errMsg, email: '' });
                }
            }
            setInputData({ ...inputData, [name]: value })
        }
    }

    useEffect(() => {
        let state = false;
        if (inputData.name === '') {
            state = false;
        } else if (inputData.email === '' || !isEmail(inputData.email)) {
            state = false;
        } else if (inputData.password === '' || inputData.password.length < 6) {
            state = false;
        } else if (inputData.request_brand_names.length === 0) {
            state = false;
        } else if (!acceptCondition) {
            state = false;
        } else {
            state = true
        }

        setButtonState(state);
    }, [inputData, acceptCondition]);

    // const [phone, setPhone] = useState('');
    // const getPhoneData = (value, data, event, formattedValue) => {
    //     // let {
    //     //     // countryCode,
    //     //     dialCode,
    //     //     // name
    //     // } = data;
    //     setInputData({
    //         ...inputData,
    //         phone: '0' + value.slice(data.dialCode.length),
    //         country_phone_code: '+' + data.dialCode,
    //         // country_code: countryCode
    //     })
    // }

    const reset_form = () => {
        setInputData({
            name: '',
            email: '',
            password: '',
            request_brand_names: [],
        })
    }

    const sub_storeSignUp = async () => {
        try {
            if (buttonState) {
                setSpining(prev => !prev);
                let apires = await register_sub_store(inputData);
                console.log(apires);
                if (apires.success) {
                    setPage_state(2)
                } else {
                    toast.error(apires.message, {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                }
            }
            setSpining(prev => !prev);

        } catch (error) {
            setSpining(prev => !prev);
            toast.error(error.status_phrase, {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            console.log(131, error.status_phrase)
        }
    }

    const verify = async () => {
        const otpString = otpValues.join('');
        try {
            if (otpString.length === 6) {
                // setSendTime(Date.now() + 120000);
                let apires = await register_sub_store_verify({ email: inputData.email, otp: otpString });
                console.log(apires);
                if (apires.success) {
                    toast.success('Partner Sign Up Successful!', {
                        position: 'top-right',
                        autoClose: 5000,
                        hideProgressBar: false,
                        closeOnClick: true,
                        pauseOnHover: true,
                        draggable: true
                    });
                    // setSuccess(true)
                    setPage_state(1)
                    setSelected_brands([])
                    setOtpValues(['', '', '', '', '', ''])
                    reset_form()
                } else {
                    setOtpValues(['', '', '', '', '', ''])
                    toast.error(apires.message, { autoClose: 1000 });
                }
            }

        } catch (error) {
            // setSendTime(Date.now() + 120000);
            console.log(error);
        }
    }

    const handleOtpChange = (index, value) => {
        const newOtpValues = [...otpValues];
        newOtpValues[index] = value;
        setOtpValues(newOtpValues);

        // Automatically focus on the next input field if not the last one
        if (index < inputRefs.current.length - 1 && value !== '') {
            inputRefs.current[index + 1].focus();
        }
    };

    const handleBackspace = (index, event) => {
        if (event.key === 'Backspace' && index > 0 && otpValues[index] === '') {
            const newOtpValues = [...otpValues];
            newOtpValues[index - 1] = '';
            setOtpValues(newOtpValues);

            // Delay focusing to ensure the updated state is reflected
            setTimeout(() => {
                inputRefs.current[index - 1].focus();
            }, 0);
        }
    };

    useEffect(() => {
        const handlePaste = (event) => {
            const clipboardData = event.clipboardData || window.clipboardData;
            const pastedText = clipboardData.getData('text');

            // Check if the pasted text is a 6-digit number
            if (/^\d{6}$/.test(pastedText)) {
                event.preventDefault(); // Prevent default paste behavior

                const digitsArray = pastedText.split('').map(Number);
                console.log('pastedText:', digitsArray);
                setOtpValues(digitsArray);
            }
        };

        // Add event listener for Ctrl+V (paste) event
        document.addEventListener('paste', handlePaste);

        // Clean up the event listener when the component unmounts
        return () => {
            document.removeEventListener('paste', handlePaste);
        };
    }, []);

    // const resend_verification = async () => {
    //     // try {
    //     //     setSendTime(Date.now() + 120000);
    //     //     let apires;
    //     //     if (type === 'job') {
    //     //         apires = await resend_job_otp({ application_id: application_id });
    //     //     } else if (type === 'sponsor') {
    //     //         apires = await resend_sponsorship_otp({ application_id: application_id });
    //     //     } else if (type === 'study') {
    //     //         apires = await resend_study_otp({ application_id: application_id });
    //     //     } else if (type === 'admission') {
    //     //         apires = await resend_study_admission_otp({ application_id: application_id });
    //     //     }
    //     //     console.log('resend:', apires);
    //     //     // if (apires.success) {
    //     //     //     setVerification(false)
    //     //     //     setSuccess(true)
    //     //     //     reset_form()
    //     //     // }

    //     // } catch (error) {
    //     //     setSendTime(Date.now() + 120000);
    //     //     console.log(error);
    //     // }
    // }


    return (
        <div className={styles.sign_in_page_container}>
            <ToastContainer />
            <div className={styles.signin_partition}>
                <div className={styles.popup_nav}>
                    <span className={styles.closeCart} onClick={() => navigate(-1)}>
                        <i className="fa-thin fa-xmark"></i>
                    </span>
                </div>
                <div className={styles.form_content}>
                    {page_state === 1 &&
                        <div className={styles.signUp_form}>
                            <h1 className={styles.signup_title}>Sign Up </h1>
                            <div className={styles.inputCont}>
                                <input className={styles.inputs} value={inputData.name} name="name" onChange={onChangeFormData} type="text" placeholder="Name" autoComplete="new-password" />
                            </div>
                            <div className={styles.inputCont}>
                                <input className={styles.inputs} value={inputData.email} name="email" onChange={onChangeFormData} type="email" placeholder="E-mail" required />
                            </div>
                            <div className={styles.inputCont}>
                                <input className={styles.inputs} value={inputData.password} name="password" minLength={6} onChange={onChangeFormData} type={!passVisibility ? "password" : "text"} placeholder="Password" required autoComplete="new-password" />
                                <span className={styles.viewPassword} onClick={() => setPassVisibility(!passVisibility)}><i className={classNames("fa-duotone", passVisibility ? "fa-eye-slash" : "fa-eye")}></i></span>
                            </div>
                            <div className={styles.inputCont}>
                                <CreatableSelect
                                    value={selected_brands}
                                    isMulti
                                    name="request_brand_names"
                                    className={classNames(styles.dropdown, "form-control rounded-0 basic-multi-select")}
                                    classNamePrefix="select"
                                    onChange={(e) => onChangeFormData(e, 'request_brand_names')}
                                    placeholder="Select Brands"
                                    options={brands}
                                />
                            </div>
                            <div className={styles.inputCont}>
                                <label className={styles.rememberContainer}> By Continuing you may agree to out <Link to={'/terms-conditions'} target={'_blank'}> Terms Conditions </Link> and <Link to={'/privacy-policy'} target={'_blank'}> Privacy Policy </Link>
                                    <input type="checkbox" onChange={(e) => setAcceptCondition(e.target.checked)} name="conditions" />
                                    <span className={styles.checkmark}></span>
                                </label>
                            </div>
                            <div className={classNames(styles.inputCont, spining ? styles.onProcoess : '')}>
                                <button type="submit" className={classNames(styles.signin_button, !buttonState ? styles.buttoninactive : '')} onClick={sub_storeSignUp}><span> Sign Up </span> <span className={styles.spiner}> <i className="fa-duotone fa-spinner fa-spin"></i> </span></button>
                            </div>
                            {/* <div className={styles.inputCont}>
                            <select className={styles.inputs} value={inputData.country_id} onChange={countryOnChange} name="country_id" required>
                                <option value={''} disabled>Select country</option>
                                {countries.map(c => <option key={c._id} value={c._id}>{c.country_name}</option>)}
                            </select>
                        </div> */}
                            {/* <div className={styles.inputCont}>
                            <select className={styles.inputs} value={inputData.city_id} onChange={onChangeFormData} name="city_id" required>
                                {visibleCities.length > 0 ?
                                    <>
                                        <option value={''} disabled>Select city</option>
                                        {visibleCities.map(c => <option key={c._id} value={c._id}>{c.city_name}</option>)}
                                    </>
                                    :
                                    <option value={''} disabled>Select city</option>
                                }
                            </select>
                        </div> */}
                            {/* <div className={styles.inputCont}>
                            <select className={styles.inputs} value={inputData.store_delivery_id} name="store_delivery_id" onChange={onChangeFormData} required>
                                <option value={''} disabled>Select delivery</option>
                                {deliveries.map(d => <option key={d._id} value={d._id}>{d.delivery_name[0]}</option>)}
                            </select>
                        </div> */}
                            {/* <div className={classNames(styles.inputCont, styles.phoneInput)}>
                            <PhoneInput
                                containerClass={styles.phone_module}
                                inputClass={styles.inputs}
                                buttonClass={styles.codeDropDown}
                                dropdownClass={styles.countryList}
                                inputProps={{
                                    name: 'phone',
                                    required: true,
                                }}
                                country={'bd'}
                                value={''}
                                onChange={(value, data, event, formattedValue) => getPhoneData(value, data, event, formattedValue)}
                            />
                        </div> */}



                            {/* <div className={styles.inputCont}>
                            <input className={styles.inputs} value={inputData.address} onChange={onChangeFormData} type="text" placeholder="Address" name="address" />
                        </div> */}

                        </div>
                    }
                    {page_state === 2 &&
                        <div className={styles.opt_container}>
                            <h3 className={styles.main_title}>Verify Your Email</h3>
                            <p className={styles.sub_title}>Please check your inbox for the verification <br /> code send to {inputData.email}</p>
                            <p className={styles.opt_label}>Enter Code</p>
                            <div className={styles.opt_input_container}>
                                {otpValues.map((v, i) => (
                                    <input
                                        key={i}
                                        type="number"
                                        maxLength="1"
                                        value={v}
                                        onChange={(e) => handleOtpChange(i, e.target.value)}
                                        onKeyDown={(e) => handleBackspace(i, e)}
                                        className={styles.opt_input}
                                        ref={(input) => (inputRefs.current[i] = input)}
                                        autoFocus={i === 0 ? true : false}
                                    />
                                ))}

                                {/* <p className={styles.opt_resend}>
                                        <Countdown date={sendtime} key={sendtime} renderer={props => props.completed ?
                                            <span className={styles.resendBTN} onClick={resend_verification}><i className="fa-thin fa-arrow-rotate-right"></i> Resend</span>
                                            :
                                            <span className={styles.resendText} style={{ textDecoration: 'none' }} >Resend code in {props.minutes}:{props.seconds} sec</span>} />

                                    </p> */}
                            </div>
                            <div className={styles.opt_btn_container}>
                                <button className={styles.opt_verify_btn} style={otpValues.join('').length === 6 ? {} : { pointerEvents: 'none', opacity: '0.6' }} onClick={verify}>VERIFY</button>
                            </div>
                            <p className={styles.opt_spam_label}>Can't find it? Please check your spam folder.</p>
                        </div>
                    }
                </div>
            </div>
        </div>
    )
}

export default SignUp;