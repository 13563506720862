import { Navigate, useLocation } from 'react-router-dom';
import { getStoreData } from '../Utils/method'

export { PublicRoute };

function PublicRoute({ children }) {
    const location = useLocation()
    if (getStoreData({ type: 'get' })) {
        return <Navigate to="/" state={{ from: location }} />
    }
    return children;
}