import React, { useEffect, useState } from 'react'
import styles from '../../../../assets/css/Category.module.css'
import { IMAGE_URL } from '../../../../Config/Config';
import { getStoreData, get_product_group_list } from '../../../../Utils/method'
import categoryPng from '../../../../assets/category.png'
import AddNewCategory from './AddNewCategory';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Category() {
    const store_data = getStoreData({ type: 'get' });
    const [loader, setLoader] = useState(false);
    const [categories, setCategories] = useState([]);
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});

    const updateArray = (nv) => {
        console.log(nv);
        if (nv) {
            setCategories([...categories, nv])
        }
    }

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let data = {
                    store_id: store_data.store._id,
                    server_token: store_data.store.server_token,
                    timezone: store_data.timezone
                }
                let apires = await get_product_group_list(data);
                if (_isMounted) {
                    console.log(16, apires);
                    if (apires.product_groups.length > 0) {
                        setCategories(apires.product_groups);
                    }
                    setLoader(false);
                }
            } catch (error) {
                setLoader(false);
                console.log(error);
            }
        }
        initial()
        return () => { _isMounted = false }
    }, [])

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    // const deleteItemFunc = (v) => {
    //     console.log(v)
    // }


    return (
        <div className={styles.category_container}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Category</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Menu</li>
                            <li className={styles.breadcrumb_item}>Category</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Search" className={styles.searchbox} />
                                    <button className={styles.searchIcon} type="submit">
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.page_divider}>
                    <AddNewCategory editStatus={editStatus} setEditStatus={setEditStatus} editCounter={editCounter} editItem={editItem} updateArray={updateArray} categories={categories} setCategories={setCategories}/>
                </div>
                <div className={styles.page_divider}>
                    <div className={styles.listContainer}>
                        {categories.map((v) =>
                            <div className="col-md-12" key={v._id}>
                                <div className={styles.categoryItem}>
                                    <div className={styles.categoryItemHolder}>
                                        {v.image_url !== '' ?
                                            <img className={styles.categoryItemImg} src={IMAGE_URL + v.image_url}
                                                onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt='' />
                                            :
                                            <img className={styles.categoryItemImg} src={categoryPng} onError={({ e }) => { e.onerror = null; e.src = categoryPng; }} alt='category_img' />
                                        }
                                    </div>
                                    <p className={styles.categoryItemTitle}>{v.name}</p>
                                    <button className={styles.EditBtn}>
                                        <span className={styles.editAction} onClick={() => editItemFunc(v)}><i className="fa-thin fa-pen-to-square"></i></span>
                                        {/* <span className={styles.delteAction} onClick={() => deleteItemFunc(v)}><i className="fa-thin fa-trash-can"></i></span> */}
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                </div>
            </div>
            {/* {addCategoryPopup && <AddNewCategory />} */}
        </div>
    )
}

export default Category