import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/User.module.css";
import { get_forms, get_applied_data, get_domains } from '../../../Utils/method'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Paginate from "../Paginate";
import Select from "react-select";
import FormDetails from "./FormDetails";

function SubmittedForms() {
    const [loader, setLoader] = useState(true);
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [forms, setForms] = useState([]);
    const [applications, setApplications] = useState([]);
    const [domains, setDomains] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage, setItemsPerPage] = useState(10);
    const [selectedTitle, setSelectedTitle] = useState('')
    const [selectedFormId, setSelectedFormId] = useState(null);
    const [showModal, setShowModal] = useState(false);
    const [selectedFormData, setSelectedFormData] = useState(null);
    const [totalPage, setTotalPage] = useState(1);
    const [currentItems, setCurrentItems] = useState([]);

    const getdomainname = (domain_id) => {
        let n = ''
        console.log(domain_id, domains)
        let d = domains.filter(v => v._id === domain_id);
        console.log(23, d)
        if (d.length > 0) {
            n = d[0].name
        }
        return n
    }
    // useEffect(() => {
    //     let _isMounted = true;
    //     async function initial() {
    //         setLoader(true);
    //         try {
    //             let domainsApis = await get_domains();
    //             if (_isMounted) {
    //                 let sort_v = domainsApis.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
    //                 console.log('get_domains:', sort_v);
    //                 setDomains(sort_v)
    //             }
    //             let apires2 = await get_applied_data({
    //                 form_id: domainsApis.data.form_id,
    //             });
    //             setApplications(apires2.data);
    //             let apires = await get_forms();
    //             console.log('response', apires, _isMounted)
    //             if (_isMounted) {
    //                 let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => a.created_at.toString().localeCompare(b.created_at.toString()));
    //                 console.log('get_forms:', sort_v);
    //                 setForms(sort_v)
    //                 setLoader(false)
    //             }
    //         } catch (error) {
    //             setLoader(false)
    //             console.log();
    //         }
    //     }
    //     initial()
    //     return () => { _isMounted = false }
    // }, [])
    // useEffect(() => {
    //     let _isMounted = true;
    //     async function initial() {
    //         setLoader(true);
    //         try {
    //             let domainsApis = await get_domains();
    //             if (_isMounted) {
    //                 let sort_v = domainsApis.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
    //                 console.log('get_domains:', sort_v);
    //                 setDomains(sort_v);
    //             }
    //             let apires2 = await get_applied_data({
    //                 domain_id: domainsApis.data[0].domain_id,
    //                 ref_id: '',
    //                 type: '',
    //                 form_id: domainsApis.data[0].form_id,
    //                 page: 1,
    //                 limit: 10
    //             });

    //             console.log(81, domainsApis.form_id);
    //             setApplications(apires2.data);
    //             let apires = await get_forms();
    //             console.log('response', apires, _isMounted);
    //             if (_isMounted) {
    //                 let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => a.created_at.toString().localeCompare(b.created_at.toString()));
    //                 console.log('get_forms:', sort_v);
    //                 setForms(sort_v);
    //                 setLoader(false);
    //             }
    //         } catch (error) {
    //             setLoader(false);
    //             console.log('Error:', error);
    //         }
    //     }
    //     initial();
    //     return () => { _isMounted = false; };
    // }, []);
    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let domainsApis = await get_domains();
                if (_isMounted) {
                    let sort_v = domainsApis.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                    console.log('get_domains:', sort_v);
                    setDomains(sort_v);
                }
                let apires2 = await get_applied_data({
                    domain_id: domainsApis.data[0].domain_id,
                    ref_id: '',
                    type: '',
                    form_id: domainsApis.data[0].form_id,
                    page: 1,
                    limit: itemsPerPage
                });

                console.log(81, domainsApis.form_id);
                setApplications(apires2.data);
                let apires = await get_forms();
                console.log('response', apires, _isMounted);
                if (_isMounted) {
                    let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => b.created_at.toString().localeCompare(a.created_at.toString()));
                    console.log('get_forms:', sort_v);
                    setForms(sort_v);
                    setLoader(false);
                    setCurrentItems(sort_v);
                }
            } catch (error) {
                setLoader(false);
                console.log('Error:', error);
            }
        }
        initial();
        return () => { _isMounted = false; };
    }, [itemsPerPage]);

    const getPaginate_data = async (page, domain_id, limit) => {
        setLoader(true);
        try {
            let data = {
                domain_id: domain_id,
                ref_id: '',
                type: '',
                form_id: domains[0].form_id,
                page: page,
                limit: limit
            }
            let apires = await get_applied_data(data);
            console.log(118, 'get applied data', apires)
            setCurrentItems(apires.data.items);
            setCurrentPage(apires.data.currentPage)
            setTotalPage(apires.data.totalPages)
            setLoader(false);
        }
        catch (error) {
            setLoader(false);
            console.log('Error:', error);
        }
    };

    const handleShowDetails = (formData) => {
        setSelectedFormData(formData);
        setShowModal(true);
    }

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    const deleteItemFunc = (v) => {
        console.log(v)
        setSelectedItem(v)
    }

    const [searchTerm, setSearchTerm] = useState('');
    const handleSearch = (event) => {
        setSearchTerm(event.target.value);
        setCurrentPage(1);
    };

    const results = !searchTerm
        ? forms
        : forms.filter((item) =>
            item.form_title.toLowerCase().includes(searchTerm.toLowerCase())
        );
    const filteredForms = forms.filter(form => {
        if (selectedFormId && form.form_id !== selectedFormId.value) return false;
        if (!searchTerm) return true; // 
        return form.form_title.toLowerCase().includes(searchTerm.toLowerCase());
    });

    const onPageChange = (page) => {
        setCurrentPage(page);
        getPaginate_data(page, selectedFormId ? selectedFormId.value : null, itemsPerPage);
    };

    const handleFormChange = (selectedOption) => {
        setSelectedFormId(selectedOption);
        setCurrentPage(1);
    };
    const allTitles = forms.map(form => ({ value: form.form_id, label: form.form_title }));

    const resetFilter = () => {
        setSelectedFormId(null);
        setCurrentPage(1); // Reset pagination to the first page
    };

    return (
        <div className={styles.brand_page}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Form</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Submitted Data</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection} style={{ justifyContent: 'space-between' }}>
                    <p style={{ marginBottom: 0 }}>Data ({forms.length})</p>
                    <div className={styles.nav}>
                        <div className={styles.paginationSection}>
                            <Paginate
                                pageCount={totalPage}
                                onPageChange={onPageChange}
                                marginPagesDisplayed={3}
                                pageRangeDisplayed={3}
                                currentPage={currentPage}
                            />
                            <div className={styles.navItem}>
                                <span className={styles.per_page}>Per Page</span>
                                <select className={styles.btn_page} value={itemsPerPage} onChange={(e) => setItemsPerPage(e.target.value)}>
                                    <option value={10}>10</option>
                                    <option value={20}>20</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                </select>
                            </div>
                        </div>
                        {/* <div className={styles.bottomSeparator}></div> */}
                    </div>
                </div>
            </div>
            <div className={styles.filterSection}>
                <div className={styles.nav}>
                    <div className={styles.nav_item_left}>
                        <Select
                            className={styles.filterSelect_item}
                            value={selectedTitle}
                            onChange={handleFormChange}
                            options={allTitles}
                            placeholder="Filter by form"
                        />
                        <div className={styles.navItem}>
                            <div className={styles.searchbar}>
                                <input type="text" placeholder="Search" className={styles.searchbox} value={searchTerm} onChange={handleSearch} />
                                <button className={styles.searchIcon} type="submit">
                                    <span><i className="fa-light fa-magnifying-glass"></i></span>
                                </button>
                            </div>
                        </div>
                        <div className={styles.navItem}>
                            <div className={styles.isInSlide}>
                                <button className={styles.resetFilters} onClick={() => { resetFilter() }} >Reset Filters</button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={styles.main_body}>
                <FormDetails
                    isOpen={showModal}
                    onClose={() => setShowModal(false)}
                    formData={selectedFormData}
                />
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12">
                        <div className="card rounded-0">
                            <div className="card-body table-responsive">
                                <table className="table orderlist_table">
                                    <thead className={styles.thead_light}>
                                        <tr className={styles.table_heads}>
                                            <th>Title</th>
                                            <th>Created at</th>
                                            <th>Domain</th>
                                            <th>Type</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody className={styles.tbody}>
                                        {currentItems.map(v =>
                                            <tr className="text-left" key={'past_' + v._id}>
                                                <td>{v.form_title}</td>
                                                <td>{v.created_at}</td>
                                                <td>{getdomainname(v.domain_id)}</td>
                                                <td>{v.type}</td>
                                                <td><button className={styles.ItemEditBtn} onClick={() => handleShowDetails(v)} >Show</button></td>
                                            </tr>
                                        )}
                                    </tbody>
                                    {/* <tbody className={styles.tbody}>
                                        {applications.length > 0 &&
                                            applications.map(v =>
                                                <tr className="text-left" key={'past_' + v._id}>
                                                    <td >{v.form_title}</td>
                                                    <td >{v.created_at}</td>
                                                    <td >{getdomainname(v.domain_id)}</td>
                                                    <td >{v.type}</td>
                                                    <td ><button className={styles.ItemEditBtn} onClick={() => showsubmittedData(v)}>Show</button></td>

                                                </tr>
                                            )}
                                    </tbody> */}
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubmittedForms;