import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";


function FreeDeliverySetting(props) {


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Free Delivery Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setFreeDelivery(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Delivery fee on store
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                If ON than user will not pay delivery fees
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Price
                                </p>
                                <p
                                    className="text-muted mb-0 text-truncate"
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                >
                                    User get free delivery if order price is more than this
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end m-auto">
                            <div className="input-group mt-1 ">
                                <div className={styles.textFront}>
                                    ৳
                                </div>
                                <input type="number" className="form-control rounded-0 shadow-none"></input>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Free delivery available for user this radius
                                </p>
                                <p
                                    className="text-muted mb-0 text-truncate"
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                >
                                    Radius
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end m-auto">
                            <div className="input-group mt-1 ">
                                <input type="number" className="form-control rounded-0 shadow-none"></input>
                                <div className={styles.textFront}>KM</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setFreeDelivery(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default FreeDeliverySetting;
