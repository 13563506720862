import React, { useState } from "react";
import { Link } from "react-router-dom";
import styles from "../../../assets/css/DeliverySetting.module.css";
import DeliverySlotSetting from "./DeliverySettings/DeliverySlotSetting";


function DeliverySetting() {

  const [deliverySlot, setDeliverySlot] = useState(false)

  return (
    <>
      <div className={styles.settings_page}>
        <div className={styles.itemsPageHeader}>
          <div className={styles.pageHeaderSection}>
            <div className={styles.titleWithBC}>
              <h3>Delivery Settings</h3>
              <ol className={styles.breadcrumb}>
                <li className={styles.breadcrumb_item}>Home</li>
                <li className={styles.breadcrumb_item}>Settings</li>
                <li className={styles.breadcrumb_item}>Delivery Settings</li>
              </ol>
            </div>
          </div>
        </div>
        <div className={styles.main_body}>
          <div className={styles.settings_cotainer} style={{ padding: "15px 0px" }} >
            <div className="row">
              <div className="col-md-12 col-sm-6 col-lg-6 col-xl-5 mb-2 col-item">
                <div className="card h-100 rounded-0 mb-1">
                  <div className="card-body rounded-0">
                    <div className="d-flex flex-row justify-content-between pb-2">
                      <div className="float-left float-none-xs">
                        <div className="d-inline-block">
                          <p className="d-inline" style={{ fontSize: 18 }}>
                            Delivery Slot Settings
                          </p>
                        </div>
                      </div>
                      <div className="btn-group float-right float-none-xs mb-4">
                        <button className={styles.settingBtn} onClick={() => setDeliverySlot(true)}>Edit</button>
                      </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                          Can user schedule order?
                        </p>
                        <p
                          className="text-muted mb-0 text-truncate"
                          style={{ fontSize: 12, fontWeight: 300 }}
                        >
                          Can user schedule order?
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                          Min. time after which user can schedule
                        </p>
                        <p
                          className="text-muted mb-0 text-truncate"
                          style={{ fontSize: 12, fontWeight: 300 }}
                        >
                          Min. time after which user can schedule
                        </p>
                      </div>
                      <div>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 14, fontWeight: 300 }}
                        >
                          0 min
                        </p>
                      </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                          Delivery Anywhere
                        </p>
                        <p
                          className="text-muted mb-0 text-truncate"
                          style={{ fontSize: 12, fontWeight: 300 }}
                        >
                          Delivery Anywhere
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Sunday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Monday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Tuesday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Wednesday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Thursday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Friday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                    <div className="d-flex flex-row justify-content-between mb-1 pb-3">
                      <div className="flex-grow-1 text-truncate">
                        <p className="mb-0" style={{ fontSize: 14 }}>
                          Saturday
                        </p>
                        <p
                          className="mb-0 text-truncate"
                          style={{ fontSize: 11, fontWeight: 300 }}
                        >
                          Open Full Day
                        </p>
                      </div>
                      <div className="form-check form-switch">
                        <input
                          type="checkbox"
                          className="form-check-input"
                          id="is_visible"
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {deliverySlot && <DeliverySlotSetting setDeliverySlot={setDeliverySlot} />}
    </>
  );
}

export default DeliverySetting;
