import {combineReducers,createStore } from "redux";
import rootReducer from './reducers/rootReducer'
const reducer = combineReducers(
    {
        rootReducer:rootReducer
    }
)


    const store = createStore(
        reducer,
        window.__REDUX_DEVTOOLS_EXTENSION__ &&
          window.__REDUX_DEVTOOLS_EXTENSION__({
            serialize: {
              options: {
                undefined: true,
                function: function (fn) {
                  return fn.toString();
                },
              },
            },
          })
      );

export default store;