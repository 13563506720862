import React, { useState } from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";
import TimePicker from "react-time-picker";
import 'react-time-picker/dist/TimePicker.css';
import 'react-clock/dist/Clock.css';


function StoreOpenClose(props) {
    const [isChecked, setIsChecked] = useState(false);
    const [newSlot, setNewSlot] = useState(null);
    const [amValue, onChange] = useState('10:00');
    const [pmValue, onChange2] = useState('18:00');


    const handleCheckboxChange = () => {
        setIsChecked(!isChecked);
    };

    const handleSlotClick = (slotId) => {
        setNewSlot(slotId);
    }


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Open Close Time Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setOpenCloseTime(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Sunday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(1)}>Save new slot</button>
                            {newSlot === 1 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Monday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(2)}>Save new slot</button>
                            {newSlot === 2 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Tuesday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(3)}>Save new slot</button>
                            {newSlot === 3 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Wednesday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(4)}>Save new slot</button>
                            {newSlot === 4 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Thursday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(5)}>Save new slot</button>
                            {newSlot === 5 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Friday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(6)}>Save new slot</button>
                            {newSlot === 6 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                    <div className="row g-0 border-bottom mb-3">
                        <div className="mb-2 mt-1 col-4">
                            Saturday
                        </div>
                        <div className="col-4 mb-2 mt-1 pe-11 d-inline border-end">
                            <div className="form-check form-switch ">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div className="">Open Full Day</div>
                            </div>
                        </div>
                        <div className="col-4 mb-2 mt-1 d-inline ps-4 border-end">
                            <div className="form-check form-switch">
                                <input
                                    type="checkbox"
                                    className="form-check-input shadow-none"
                                    id="is_visible"
                                />
                                <div>Slot Time</div>
                            </div>
                        </div>
                        <div className="col-12 mb-4">
                            <button className={styles.cancelBtn} onClick={() => handleSlotClick(7)}>Save new slot</button>
                            {newSlot === 7 &&
                                <div className="mt-4">
                                    <div className="col-12 mb-4">
                                        <div className="row">
                                            <div className="col-6">
                                                <label>Open Time</label><br></br>
                                                <TimePicker
                                                    onChange={onChange}
                                                    value={amValue}
                                                    disableClock />
                                            </div>
                                            <div className="col-6">
                                                <label>Close Time</label><br></br>
                                                <TimePicker
                                                    amPmAriaLabel="Select AM/PM"
                                                    onChange={onChange2}
                                                    value={pmValue}
                                                    disableClock
                                                    clearAriaLabel />
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setOpenCloseTime(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default StoreOpenClose;
