import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/Offer.module.css";
import AddNewOffer from "./AddNewOffer";
import Paginate from "../Paginate";
import { getStoreData, search_sort_promo_code_list } from "../../../Utils/method";
import promoImage from "../../../assets/promo_code.png";

function Offer() {
  const store = getStoreData({ type: 'get' })
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPage, setTotalPage] = useState(1);
  const [itemPerPage, setItemPerPage] = useState(20);
  const [offers, setOffers] = useState([])
  const [loader, setLoader] = useState(true)
  const [newOffer, setNewOffer] = useState(false)


  const handleChangePage = (page) => {
    setCurrentPage(page);
    // if (is_search) {
    //   getPaginate_data(page, 'search', 'name', searchTerm.trim())
    // } else if (is_filtered) {
    //   getPaginate_data(page, 'filter', 'product_id', filterSubCate.value)
    // } else {
    //   getPaginate_data(page, 'pagi')
    // }
  };

  const addNewOffer_to_list = async () => {
    setLoader(true)
    try {
      let data = {
        search_value: '',
        search_field: 'promo_code_name',
        sort_field: 'unique_id',
        sort_promo_code: -1,
        page: 1,
        store_id: store.store._id,
        server_token: store.store.server_token,
      }
      let apires = await search_sort_promo_code_list(data)
      console.log(34, apires);
      if (apires.data.success) {
        setOffers(apires.data.promo_codes)
      } else {
        setOffers([])
      }
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(36, error);
    }
  }


  useEffect(() => {
    let _isMounted = true;
    async function init() {
      setLoader(true)
      try {
        let data = {
          search_value: '',
          search_field: 'promo_code_name',
          sort_field: 'unique_id',
          sort_promo_code: -1,
          page: 1,
          store_id: store.store._id,
          server_token: store.store.server_token,
        }
        let apires = await search_sort_promo_code_list(data)
        console.log(34, apires);
        if (_isMounted) {
          if (apires.data.success) {
            setOffers(apires.data.promo_codes)
          } else {
            setOffers([])
          }
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(36, error);
      }
    }
    init()

    return () => {
      _isMounted = false
    }
  }, [])

  return (
    <>
      <div className={styles.itemList_page}>
        {loader && <div className="loaderContainer"></div>}
        <div className={styles.itemsPageHeader}>
          <div className={styles.pageHeaderSection}>
            <div className={styles.titleWithBC}>
              <h3>Offers</h3>
              <ol className={styles.breadcrumb}>
                <li className={styles.breadcrumb_item}>Home</li>
                <li className={styles.breadcrumb_item}>Offers</li>
              </ol>
            </div>
          </div>
          <div className={styles.pageHeaderSection}>
            <div className={styles.nav} style={{ width: 'auto' }}>
              <div className={styles.paginationSection}>
                <Paginate
                  pageCount={totalPage}
                  onPageChange={handleChangePage}
                  marginPagesDisplayed={3}
                  pageRangeDisplayed={3}
                  currentPage={currentPage}
                />

                <div className={styles.navItem}>
                  <span className={styles.per_page}>Per Page</span>
                  <select className={styles.btn_page} value={itemPerPage} onChange={(e) => setItemPerPage(e.target.value)}>
                    <option value={10}>10</option>
                    <option value={20}>20</option>
                    <option value={50}>50</option>
                    <option value={100}>100</option>
                  </select>
                </div>

                <div className={styles.addOfferButton} onClick={() => setNewOffer(true)}>Add New</div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.itemsContainer} style={!loader && offers.length === 0 ? { display: 'flex', alignItems: 'center', justifyContent: 'center' } : {}}>
          {!loader && offers.length === 0 ? <h2 className="not_found_msg">No data found!</h2> : ''}
          {!loader &&
            offers.map(v =>
              <div className={styles.offerItem} key={v._id}>
                <div className={styles.offerInnerContainer}>
                  <div className={styles.ImageHolder}>
                    <img src={promoImage} alt="" />
                  </div>
                  <div className={styles.promoDescription}>
                    <p>{v?.promo_code_name}</p>
                  </div>
                </div>
              </div>
            )
          }
        </div>
      </div>
      {newOffer && <AddNewOffer setNewOffer={setNewOffer} addNewOffer_to_list={addNewOffer_to_list} />}
    </>
  );
}

export default Offer;
