import React, { useEffect, useState, useRef } from "react";
import { Link } from "react-router-dom";
import styles from '../../../assets/css/AddSubCategory.module.css'

import { IMAGE_URL } from "../../../Config/Config";
import { get_forms, send_link_method,get_submited_applications, get_domains } from '../../../Utils/method'
import classNames from "classnames";
import brandPng from '../../../assets/brand.png'
import { ToastContainer, toast } from 'react-toastify';
import { Editor } from '@tinymce/tinymce-react';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';


function SubmittedForms() {

    const [loader, setLoader] = useState(true);
    const editorRef = useRef(null);
    const [to, setTo] = useState('')
    const [editStatus, setEditStatus] = useState(false);
    const [editItem, setEditItem] = useState({});
    const [selectedItem, setSelectedItem] = useState({});
    const [forms, setForms] = useState([]);
    const [selected_forms, setselected_forms] = useState({});
    const [domains, setDomains] = useState([]);
    const [is_email_valid,setIsEmailValid] = useState(false);
    const [is_subject_valid,setIsSubjectValid] = useState(false);
    const [is_Body_valid,setIsBodyValid] = useState(false);


    const setSubject = (e, key) => {
        setSelectedItem({ ...selectedItem, [key]: e.target.value });
    }
    const getdomainname = (domain_id) => {
        let n = ''
        console.log(domain_id, domains)
        let d = domains.filter(v => v._id === domain_id);
        console.log(23, d)
        if (d.length > 0) {
            n = d[0].name
        }
        return n
    }

    useEffect(() => {
        let _isMounted = true;
        async function initial() {
            setLoader(true);
            try {
                let domainsApis = await get_domains();
                if (_isMounted) {
                    let sort_v = domainsApis.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                    console.log('get_domains:', sort_v);
                    setDomains(sort_v)
                }
                let apires = await get_forms();
                console.log('response', apires, _isMounted)
                if (_isMounted) {
                    let sort_v = apires.data.filter(f => f.created_at.length > 0).sort((a, b) => a.created_at.toString().localeCompare(b.created_at.toString()));
                    console.log('get_forms:', sort_v);

                    let data = [];
                    for (const p of sort_v) {
                        data.push({ value: p.form_id, label: p.form_title, ...p })
                    }
                    setForms(data)
                    setLoader(false)
                }
            } catch (error) {
                setLoader(false)
                console.log();
            }
        }
        initial()
        return () => { _isMounted = false }
    }, [])

    const [editCounter, setEditCounter] = useState(0)
    const editItemFunc = (v) => {
        setEditStatus(true);
        setEditItem(v);
        console.log(v)
        setEditCounter(prev => prev + 1)
    }

    const deleteItemFunc = (v) => {
        console.log(v)
        setSelectedItem(v)
    }




    const [searchTerm, setSearchTerm] = useState('');
    const handleChange = (event) => {
        setSearchTerm(event.target.value);
    };

    const results = !searchTerm
        ? forms
        : forms.filter((item) =>
            item.form_title.toLowerCase().includes(searchTerm.toLowerCase())
        );

    const showsubmittedData = () => {

    }

    const selectForm = (e) => {
        console.log(e);
        setselected_forms(e);
    }
    const SendEmailLink = async ()=>{
        try{
            
            let form_data = {...selected_forms,link_email_body: editorRef.current.getContent()}
            delete form_data['_id'] 
            let data = await send_link_method({
                form_data:form_data,
                to:to
            });
            toast.success('Success');
            setselected_forms({});
            setTo('')

            console.log(data)
        }catch(error){
            console.log(error);
        }
    }

    return (
        <div className={styles.brand_page}>
            <ToastContainer />
            {loader && <div className="loaderContainer"></div>}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Send Link</h3>
                    </div>
                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.addSub_body}>
                    <div className="row mt-3">
                        <div className="col-md-12">
                            <div className="row mb-2">
                                <label className="small mb-1">Select a form first</label>
                                <div className="input-group mt-1 ">
                                    <Select
                                        value={selected_forms}
                                        name="form_id"
                                        className="form-control rounded-0 basic-multi-select"
                                        classNamePrefix="select"
                                        placeholder="Select a form"
                                        onChange={(e) => selectForm(e)}
                                        options={forms}
                                    />
                                    <button onClick={()=>SendEmailLink()} className={styles.submitBtn} style={{ marginLeft: '8px', height: '100%' }}>SEND</button>
                                </div>
                            </div>
                        </div>
                        {Object.keys(selected_forms).length > 0 &&

                            <div className={styles.addSub_body}>
                                <div className="col-md-12">
                                    <label className="small mb-1"> To</label>
                                    <div className="input-group mt-1 ">
                                        <input type="text" className="form-control rounded-0" value={to} onChange={(e) => { setTo(e.target.value) }} placeholder='To' />

                                    </div>
                                </div>
                                <div className="col-md-12">
                                    <label className="small mb-1"> Subject</label>
                                    <div className="input-group mt-1 ">
                                        <input type="text" className="form-control rounded-0" value={selected_forms.link_email_subject || ''} onChange={(e) => { setSubject(e, 'link_email_subject') }} placeholder='Subject' />

                                    </div>
                                </div>
                                <div className="col-md-12 mt-1">
                                    <label className="small mb-1" style={{ width: '100%' }}>Contents</label>
                                    <code style={{ width: '100%' }} >
                                        [LINK]
                                        [FORM_TITLE]
                                        [APPLICATION_ID]</code>
                                    <label className="small mb-1" style={{ width: '100%' }}>Body</label>
                                    <div className="input-group " style={{ width: '100%' }}>

                                        <Editor
                                            apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                                            onInit={(evt, editor) => editorRef.current = editor}
                                            init={{
                                                height: 500,
                                                width: '100%',
                                                menubar: true,
                                                plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                            }}
                                            initialValue={selected_forms.link_email_body || ''}
                                        />

                                    </div>
                                </div>
                            </div>

                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SubmittedForms;