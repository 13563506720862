
import React, { useState } from 'react'
import styles from '../../assets/css/CropImage.module.css'
import ReactCrop from "react-image-crop"
import 'react-image-crop/dist/ReactCrop.css'

function CropImage({ setCropPopup, cropConfirm, curentImage }) {
    let fileUrl = '';
    const [src] = useState(curentImage)
    const [image, setImage] = useState(null)
    // const [crop, setCrop] = useState({ aspect: 10 / 8 });
    const [crop, setCrop] = useState({
        unit: "%",
        x: 0,
        y: 0,
        width: 50,
        height: 50
    });
    const [croppedImage, setCroppedImage] = useState(null);

    const onCropComplete = crop => {
        makeClientCrop(crop);
    };

    const makeClientCrop = async (crop) => {
        if (image && crop.width && crop.height) {
            const croppedImageUrl = await getCroppedImg(
                image,
                crop,
                "newFile.jpeg"
            );
            console.log(33, croppedImageUrl);
            setCroppedImage(croppedImageUrl)
        }
    }

    function getCroppedImg(image, crop, fileName) {
        const canvas = document.createElement('canvas');
        const scaleX = image.naturalWidth / image.width;
        const scaleY = image.naturalHeight / image.height;
        canvas.width = crop.width;
        canvas.height = crop.height;

        const ctx = canvas.getContext('2d');
        ctx.drawImage(
            image,
            crop.x * scaleX,
            crop.y * scaleY,
            crop.width * scaleX,
            crop.height * scaleY,
            0,
            0,
            crop.width,
            crop.height
        );
        // const base64Image = canvas.toDataURL('image/jpeg');
        // console.log(51, base64Image);
        // setCroppedImage(base64Image);

        return new Promise((resolve, reject) => {
            canvas.toBlob(blob => {
                if (!blob) {
                    //reject(new Error('Canvas is empty'));
                    console.error("Canvas is empty");
                    return;
                }
                blob.name = fileName;
                window.URL.revokeObjectURL(fileUrl);
                fileUrl = window.URL.createObjectURL(blob);
                resolve(fileUrl);
            }, "image/jpeg");
        });

    }

    return (
        <div className={styles.wrapper}>
            <div className={styles.cropContainer}>
                <div className={styles.cropBody}>
                    {src && (
                        <ReactCrop
                            src={src}
                            onImageLoaded={setImage}
                            crop={crop}
                            ruleOfThirds
                            onComplete={onCropComplete}
                            onChange={setCrop}>
                        </ReactCrop>
                    )}

                </div>
                <div className={styles.cropFooter}>
                    <button className={styles.cropConfirm} onClick={() => cropConfirm(croppedImage)}> Confirm </button>
                </div>
                {croppedImage && <img src={croppedImage} alt='' />}
            </div>
        </div>
    )
}

export default CropImage