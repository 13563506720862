/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../../assets/css/AddSubCategory.module.css'
import { IMAGE_URL } from '../../../../Config/Config';
import { add_product_group_data, getStoreData, get_domains, get_product_list, update_product_group } from '../../../../Utils/method'
import categoryPng from '../../../../assets/category.png'
import Select from 'react-select';
import { toast } from 'react-toastify';

function AddNewCategory(props) {
  const store_data = getStoreData({ type: 'get' });
  const [all_product_list, setAll_Product_list] = useState([]);
  const [selected_products, setSelected_products] = useState([]);
  const [product_list, setProduct_list] = useState([]);
  const [domain_list, setDomain_list] = useState([]);
  const [selected_domain, setSelected_domain] = useState([]);
  const [loader, setLoader] = useState(false);
  const [formData, setFormData] = useState({
    name: '',
    product_ids: [],
    domain_ids: [],
    sequence_number: '0',
    image_url: '',
    store_id: store_data.store._id,
    server_token: store_data.store.server_token,
    timezone: store_data.timezone
  })
  const formRef = useRef(null);
  const [curentImage, setCurentImage] = useState('');


  useEffect(() => {
    console.log(26, formData);
  }, [formData])

  useEffect(() => {
    if (props.editStatus) {
      setFormData({
        ...formData,
        product_group_id: props.editItem._id,
        name: props.editItem.name,
        image_url: props.editItem.image_url,
        product_ids: props.editItem.product_ids,
        domain_ids: props.editItem.domain_ids,
      })
      let data = []
      for (const key in product_list) {
        const product = product_list[key];
        for (const i in props.editItem.product_ids) {
          const id = props.editItem.product_ids[i];
          if (product.value === id) {
            data.push(product)
          }
        }
      }
      let _domain = []
      for (const key in domain_list) {
        const domain = domain_list[key];
        for (const i in props.editItem.domain_ids) {
          const id = props.editItem.domain_ids[i];
          if (domain.value === id) {
            _domain.push(domain)
          }
        }
      }
      console.log(51, data);
      setSelected_products(data)
      setSelected_domain(_domain)
    }
  }, [props.editStatus, props.editCounter])

  // const onChangeSubCate = (e) => {
  //   setSub_categories(e)
  //   setSub_category_ids([...e.map(item => item.value)])
  // }

  const getFormData = async (e, ext) => {
    if (ext) {
      if (ext === 'product_ids') {
        setSelected_products(e)
        setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
      } else if (ext === 'domain_ids') {
        setSelected_domain(e)
        setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
      }
    } else {
      let { name, value } = e.target;
      if (name === 'name') {
        setFormData({ ...formData, [name]: [value] })
      } else if (name === 'image_url') {
        console.log(41, e.target.files[0]);
        setFormData({ ...formData, [name]: e.target.files[0] })
        setCurentImage(URL.createObjectURL(e.target.files[0]));
      } else {
        setFormData({ ...formData, [name]: value })
      }
    }
  }

  const resetForm = () => {
    setFormData({
      ...formData,
      name: '',
      product_ids: [],
      domain_ids: [],
      image_url: '',
    })
    setCurentImage('');
    formRef.current.reset()
    setSelected_products([])
    setSelected_domain([])
    props.setEditStatus(false)
  }

  const checkInputFields = () => {
    if (formData.name === '') {
      return false;
    } else {
      return true;
    }
  }


  const get_domain_list = async () => {
    try {
      let apires = await get_domains();
      let data = []
      let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
      for (const p of sort_v) {
        data.push({ value: p._id, label: p.name })
      }
      console.log('get_domains:', data);
      setDomain_list(data)
    } catch (error) {
      console.log();
    }
  }


  useEffect(() => {
    let _isMounted = true;
    async function init() {
      console.log(54, 'API called');
      try {
        let apires = await get_product_list({
          store_id: store_data.store._id,
          server_token: store_data.store.server_token,
        })
        if (_isMounted) {
          console.log(59, apires);
          if (apires.success) {
            let data = []
            let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
              data.push({ value: p._id, label: p.name[0] })
            }
            setProduct_list(data)
            setAll_Product_list(sort_v);
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    init()
    get_domain_list()

    return () => {
      _isMounted = false
    }
  }, [])

  const saveCategory = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('name', JSON.stringify(formData.name));
    data.append('product_ids', formData.product_ids.toString());
    data.append('sequence_number', formData.sequence_number);
    data.append('image_url', formData.image_url);
    data.append('store_id', formData.store_id);
    data.append('server_token', formData.server_token);
    data.append('timezone', formData.timezone);

    if (checkInputFields()) {
      try {
        setLoader(true)
        let apires = await add_product_group_data(data);
        console.log(apires);
        if (apires.success) {
          resetForm();
          props.updateArray(apires.product_group);
          toast.success(apires.status_phrase, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        } else {
          toast.error(apires.status_phrase, {
            position: 'top-right',
            autoClose: 5000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true
          });
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error)
      }
    }
  }

  const updateCategory = async () => {
    const data = new FormData();
    data.append('product_group_id', formData.product_group_id);
    data.append('name', JSON.stringify(formData.name));
    data.append('product_ids', formData.product_ids.length > 0 ? formData.product_ids.toString() : '');
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('store_id', formData.store_id);
    data.append('server_token', formData.server_token);
    data.append('timezone', formData.timezone);

    if (typeof formData.image_url !== 'string') {
      data.append('image_url', formData.image_url);
    }

    try {
      setLoader(true)
      let apires = await update_product_group(data);
      console.log(175, apires);
      resetForm()
      toast.success('Category Updated successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      props.setCategories(prev => [...prev.map(c => c._id === apires.product_group._id ? { ...apires.product_group } : c)])
      setLoader(false)
      // props.setCategories([...props.categories.map(c => c._id === apires.data._id ? { ...apires.data } : c)])
    } catch (error) {
      setLoader(false)
      console.log(error)
    }
  }

  return (
    <div className={styles.addSubCategoryPopup}>
      <div className={styles.addSub_header}>
        {props.editStatus ? <p>Edit Category</p> : <p>Add New Category</p>}
      </div>
      <form ref={formRef} className={styles.addSub_body}>
        <div className="col-md-12">
          <div className="row mt-1 mb-2">
            <div className="row">
              <label className="small mb-1">Category Name</label>
              <div className="input-group mt-1 ">
                <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Category Name' />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Sub-categories</label>
              <div className="mt-1 ">
                <Select
                  value={selected_products}
                  isMulti
                  name="colors"
                  className="form-control rounded-0 basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => getFormData(e, 'product_ids')}
                  options={product_list}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Domain</label>
              <div className="mt-1 ">
                <Select
                  value={selected_domain}
                  isMulti
                  name="domain_ids"
                  className="form-control rounded-0 basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => getFormData(e, 'domain_ids')}
                  options={domain_list}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Category Image</label>
              <div className="input-group mt-1 ">
                <input type="file" onChange={(e) => getFormData(e)} name="image_url" className="form-control rounded-0" placeholder='Category Name' />
              </div>
            </div>
            <div className="row">
              <div className="input-group mt-1 ">
                <div className={styles.img_view_container}>
                  {props.editStatus && curentImage === '' ?
                    <img style={{ maxWidth: 150 }} src={IMAGE_URL + props.editItem.image_url} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                    : props.editStatus && curentImage !== '' ?
                      <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                      : !props.editStatus && curentImage !== '' ?
                        <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = categoryPng }} alt={'Supplier Icon'} />
                        : <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {props.editStatus ?
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
          <button className={styles.submitBtn} onClick={updateCategory} style={loader ? { pointerEvents: 'none' } : {}}>{!loader ? 'Update' : 'Loading...'}</button>
        </div>
        :
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
          <button className={styles.submitBtn} onClick={saveCategory} style={loader ? { pointerEvents: 'none' } : {}}>{!loader ? 'Submit' : 'Loading...'}</button>
        </div>}
    </div>
  )
}

export default AddNewCategory;