import React, { useState, useEffect } from 'react'
import classNames from 'classnames';
// import { add_favourite_address } from '../../../Utils/method'
import Geocode from "react-geocode";
import { appStatic } from '../../../Config/Config'
import styles from '../../../assets/css/AddressForm.module.css'
import { useSelector } from 'react-redux';
import Autocomplete, { usePlacesWidget } from "react-google-autocomplete";
// import useGoogle from "react-google-autocomplete/lib/usePlacesAutocompleteService";

function AddressForm(props) {
    Geocode.setApiKey(appStatic.GOOGLE_KEY);
    Geocode.setLanguage("en");
    const user = useSelector((state) => state.rootReducer.user_data);
    console.log(15, user);
    const [loader, setLoader] = useState(false)
    const [address, setAddress] = useState({
        user_id: '',
        server_token: '',
        firstName: '',
        lastName: '',
        phone: '',
        // houseNo: '',
        address: '',
        address_name: '',
        city: '',
        street: '',
        // landmark: '',
        note: '',
        postCode: '',
        country: '',
        latitude: '',
        longitude: ''
    })
    const [buttonStatus, setButtonStatus] = useState(false);

    const onChangeAddress = (e) => {
        let { name, value } = e.target;
        console.log(name, value);
        if (name === 'firstName' || name === 'lastName') {
            setAddress({ ...address, [name]: value, 'address_name': address.firstName + ' ' + address.lastName });
        } else {
            setAddress({ ...address, [name]: value });
        }
    }

    // const getAddress = () => {
    //     Geocode.fromAddress(address.address).then(
    //         (response) => {
    //             const { lat, lng } = response.results[0].geometry.location;
    //             console.log(43, response.results[0]);
    //             const mainAddress = response.results[0].formatted_address;
    //             let city, state, country, postCode, street;
    //             for (let i = 0; i < response.results[0].address_components.length; i++) {
    //                 for (let j = 0; j < response.results[0].address_components[i].types.length; j++) {
    //                     switch (response.results[0].address_components[i].types[j]) {
    //                         case "locality":
    //                             city = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "administrative_area_level_1":
    //                             state = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "country":
    //                             country = response.results[0].address_components[i].long_name;
    //                             break;
    //                         case "route":
    //                             street = response.results[0].address_components[i].long_name;
    //                             break;
    //                         // case "street_number":
    //                         //     street_number = response.results[0].address_components[i].long_name;
    //                         //     break;
    //                         case "postal_code":
    //                             postCode = response.results[0].address_components[i].long_name;
    //                             break;
    //                     }
    //                 }
    //             }
    //             setAddress({
    //                 ...address,
    //                 'address': mainAddress,
    //                 // 'address_name': mainAddress,
    //                 'city': city,
    //                 'street': street,
    //                 'postCode': postCode,
    //                 'country': country,
    //                 'latitude': lat,
    //                 'longitude': lng,
    //             });

    //         },
    //         (error) => {
    //             console.error(error);
    //         }
    //     );
    // }

    useEffect(() => {
        let status = false;
        if (address.firstName === '') {
            status = false;
        } else if (address.lastName === '') {
            status = false;
        } else if (address.phone === '') {
            status = false;
            // } else if (address.houseNo === '') {
            //     status = false;
        } else if (address.address === '') {
            status = false;
        } else if (address.city === '') {
            status = false;
        } else if (address.postCode === '') {
            status = false;
        } else if (address.country === '') {
            status = false;
        } else {
            status = true;
        }
        setButtonStatus(status);
        console.log(address)
    }, [address])

    const saveAddress = async () => {
        // try {
        //     let apires = await add_favourite_address(address);
        //     console.log(apires);
        //     if (apires.success) {
        //         if (props.type === 'billing') {
        //             console.log(125, apires.favourite_addresses[apires.favourite_addresses.length - 1])
        //             props.setAddNewAddress(false);
        //             props.setBillingAddress([apires.favourite_addresses[apires.favourite_addresses.length - 1]]);

        //         } else {
        //             props.setNewAddress(false);
        //             props.setDeliveryAddress([...apires.favourite_addresses]);
        //             if (props.type === 'checkout') {
        //                 props.addressOnselect(apires.favourite_addresses[apires.favourite_addresses.length - 1]);
        //             }
        //         }
        //     }
        // } catch (error) {
        //     console.log(error);
        // }
    }

    const cancelAddressForm = () => {
        if (props.type === 'billing') {
            props.setAddNewAddress(false);
        } else {
            props.setNewAddress(false)
        }
    }

    const { ref } = usePlacesWidget({
        apiKey: appStatic.GOOGLE_KEY,
        onPlaceSelected: (place) => {
            console.log(place);
            const { lat, lng } = place.geometry.location;
            console.log(43, lat(), lng());

            const mainAddress = place.formatted_address;
            let city, state, country, postCode, street;
            for (let i = 0; i < place.address_components.length; i++) {
                for (let j = 0; j < place.address_components[i].types.length; j++) {
                    switch (place.address_components[i].types[j]) {
                        case "locality":
                            city = place.address_components[i].long_name;
                            break;
                        case "administrative_area_level_1":
                            state = place.address_components[i].long_name;
                            break;
                        case "country":
                            country = place.address_components[i].long_name;
                            break;
                        case "route":
                            street = place.address_components[i].long_name;
                            break;
                        // case "street_number":
                        //     street_number = place.address_components[i].long_name;
                        //     break;
                        case "postal_code":
                            postCode = place.address_components[i].long_name;
                            break;
                        default:
                    }
                }
            }
            setAddress({
                ...address,
                'address': mainAddress,
                // 'address_name': mainAddress,
                'city': city,
                'street': street,
                'postCode': postCode,
                'country': country,
                'latitude': lat(),
                'longitude': lng(),
            });
        },
        options: {
            //   types: ["(regions)"],
            types: ["geocode", "establishment"],
            componentRestrictions: { country: ["bd", "in", "uk", "us", "pr", "vi", "gu", "mp"] },
        },
    });

    return (
        <div className={classNames(styles.formContainer, styles[props.type])}>
            <div className={styles.formColumn}>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.firstName} onChange={onChangeAddress} name={'firstName'} placeholder="First Name *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.lastName} onChange={onChangeAddress} name={'lastName'} placeholder="Last Name *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} ref={ref} className={styles.inputs} name={'address'} placeholder="Address Line - 1 *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.street} onChange={onChangeAddress} name={'street'} placeholder="Address Line - 2" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.city} onChange={onChangeAddress} name={'city'} placeholder="City *" autoComplete="new-password" />
                </div>

            </div>
            <div className={styles.formColumn}>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.phone} onChange={onChangeAddress} name={'phone'} placeholder="Mobile number (for delivery updates) *" autoComplete="new-password" />
                </div>
                {/* <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.houseNo} onChange={onChangeAddress} name={'houseNo'} placeholder="House / Flat Number *" autoComplete="new-password" />
                </div> */}
                {/* <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.address_name} onChange={onChangeAddress} name={'address_name'} placeholder="Address Name" autoComplete="new-password" />
                </div> */}
                <div className={styles.inputContainer}>
                    <textarea className={styles.textAreas} onChange={onChangeAddress} name={'note'} placeholder="NOTE: (landmark & any instruction)" ></textarea>
                    {/* <input type={'text'} className={styles.inputs} value={address.landmark} onChange={onChangeAddress} name={'landmark'} placeholder="Landmark" autoComplete="new-password" /> */}
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.postCode} onChange={onChangeAddress} name={'postCode'} placeholder="Postcode *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <input type={'text'} className={styles.inputs} value={address.country} onChange={onChangeAddress} name={'country'} placeholder="Country *" autoComplete="new-password" />
                </div>
                <div className={styles.inputContainer}>
                    <button className={styles.cancelButton} onClick={cancelAddressForm}>Cancel</button>
                    <button className={classNames(styles.saveButton, !buttonStatus ? styles.inactive : '')} onClick={saveAddress}>Save</button>
                </div>
            </div>
        </div>
    )
}

export default AddressForm;