import React, { memo } from "react";
import styles from "../../assets/css/Dashboard.module.css";
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../../assets/css/Dashboard.module.css";
import PastOrder from "../pages/RunningOrder/PastOrder/PastOrder";
import TodayOrder from "../pages/RunningOrder/TodayOrder/TodayOrder";
// import Order from "../pages/Order/Order";
import TomorrowOrder from "../pages/RunningOrder/TomorrowOrder/TomorrowOrder";
import ScheduleOrder from "../pages/RunningOrder/ScheduleOrder/ScheduleOrder";
import TableBooking from "../pages/RunningOrder/TableBooking/TableBooking";
import CurrentMonthHistory from "../pages/OrderHistory/CurrentMonth/CurrentMonth";
import CurrentWeekHistory from "../pages/OrderHistory/CurrentWeek/CurrentWeek";
import CurrentMonthEarning from "../pages/Earning/CurrentMonthEarning/CurrentMonthEarning";
import CurrentWeekEarning from "../pages/Earning/CurrentWeekEarning/CurrentWeekEarning";
import History from "../pages/OrderHistory/History/History";
import Reviews from "../pages/OrderHistory/Reviews/Reviews";
// import FormulaMilk from "../pages/AddItem/BabyAndKids/FormulaMilk/FormulaMilk";
import { useLocation } from "react-router-dom";
import RouteList from "../../Config/RouteList";
// import { getStoreData } from "../../Utils/method";
import ProductItems from "../pages/SubCategory/ProductItems";
import DispatchOrder from "../pages/RunningOrder/Dispatch/DispatchOrder";
import User from "../pages/User/User";
import AddNewSubStore from "../pages/User/AddNewSubStore";
import Setting from "../pages/Setting/Setting";
import Offer from "../pages/Offer/Offer";
import AddNewOffer from "../pages/Offer/AddNewOffer";
import Items from "../pages/Menu/Items/Items";
import CreateOrder from "../pages/Order/CreateOrder";
import Category from "../pages/Menu/Category/Category";
import SubCategory from "../pages/Menu/SubCategory/SubCategory";
import AddNewItem from "../pages/Menu/Items/AddNewItem";
import Brand from "../pages/Menu/Brand/Brand";
import Supplier from "../pages/Menu/Supplier/Supplier";
import Activity from "../pages/Activity/Activity";
import Earnings from "../pages/Earning/Earning/Earnings";
import DeliverySetting from "../pages/Setting/DeliverySetting";
import StoreTimeSetting from "../pages/Setting/StoreTimeSetting";
import ModifierCategory from "../pages/Menu/Modifier/ModifierCategory/ModifierCategory"
import ImportExport from "../pages/Menu/Modifier/ImportExport/ImportExport"
import ImageCropper from "../pages/ImageCropper"
import ImportNewItems from "../pages/Menu/Import/ImportNewItems";
import AddNewJob from "../pages/jobs/AddNewJob";
import Jobs from "../pages/jobs/Jobs";
import AddNewBrandItem from "../pages/RealBrands/AddNewBrandItem";
import Domain from "../pages/Domain/Domain";
import BrandItems from "../pages/RealBrands/BrandItems";
import DynamicForms from "../pages/DynamicForm/DynamicForms";
import AddNewForm from "../pages/DynamicForm/AddNewForm";
import EmailSetting from "../pages/Setting/EmailSetting";
import SubmittedForms from "../pages/DynamicForm/SubmittedForms";
import SendLink from "../pages/DynamicForm/SendLink";
import FormPreview from "../pages/DynamicForm/FormPreview";


const GetComponent = () => {
  const location = useLocation();

  if (location.pathname === RouteList.create_order) {
    return (
      <div className={styles.main_container}>
        <CreateOrder />
      </div>
    );
  } else if (location.pathname === RouteList.image_cropper) {
    return (
      <div className={styles.main_container}>
        <ImageCropper />
      </div>
    );
  } else if (location.pathname === RouteList.past_order) {
    return (
      <div className={styles.main_container}>
        <PastOrder />
      </div>
    );
  } else if (location.pathname === RouteList.today_order) {
    return (
      <div className={styles.main_container}>
        <TodayOrder />
      </div>
    );
  } else if (location.pathname === RouteList.tomorrow_order) {
    return (
      <div className={styles.main_container}>
        <TomorrowOrder />
      </div>
    );
  } else if (location.pathname === RouteList.schedule_order) {
    return (
      <div className={styles.main_container}>
        <ScheduleOrder />
      </div>
    );
  } else if (location.pathname === RouteList.dispatch) {
    return (
      <div className={styles.main_container}>
        <DispatchOrder />
      </div>
    );
  } else if (location.pathname === RouteList.table_booking) {
    return (
      <div className={styles.main_container}>
        <TableBooking />
      </div>
    );
  } else if (location.pathname === RouteList.item_list) {
    const hostname = window.location.hostname;
    return (
      <div className={styles.main_container}>
        {/* <BrandItems /> */}
        {hostname.indexOf('buyrealbrands') > -1 ? <BrandItems /> : <Items />}
      </div>
    );
  } else if (location.pathname === RouteList.jobs) {
    return (
      <div className={styles.main_container}>
        <Jobs />
      </div>
    );
  } else if (location.pathname === RouteList.sub_category) {
    return (
      <div className={styles.main_container}>
        <SubCategory />
      </div>
    );
  } else if (location.pathname === RouteList.brand) {
    return (
      <div className={styles.main_container}>
        <Brand />
      </div>
    );
  } else if (location.pathname === RouteList.supplier) {
    return (
      <div className={styles.main_container}>
        <Supplier />
      </div>
    );
  } else if (location.pathname === RouteList.category) {
    return (
      <div className={styles.main_container}>
        <Category />
      </div>
    );
  } else if (location.pathname === RouteList.activity) {
    return (
      <div className={styles.main_container}>
        <Activity />
      </div>
    );

  } else if (location.pathname === RouteList.user) {
    return (
      <div className={styles.main_container}>
        <User />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_forms) {
    return (
      <div className={styles.main_container}>
        <DynamicForms />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_forms_add) {
    return (
      <div className={styles.main_container}>
        <AddNewForm />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_forms_edit) {
    return (
      <div className={styles.main_container}>
        <AddNewForm />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_submitted_forms) {
    return (
      <div className={styles.main_container}>
        <SubmittedForms />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_form_preview) {
    return (
      <div className={styles.main_container}>
        <FormPreview />
      </div>
    );
  } else if (location.pathname === RouteList.dynamic_send_link) {
    return (
      <div className={styles.main_container}>
        <SendLink />
      </div>
    );
  } else if (location.pathname === RouteList.addNew_store) {
    return (
      <div className={styles.main_container}>
        <AddNewSubStore />
      </div>
    );
  } else if (location.pathname === RouteList.current_week_history) {
    return (
      <div className={styles.main_container}>
        <CurrentWeekHistory />
      </div>
    );
  } else if (location.pathname === RouteList.current_month_history) {
    return (
      <div className={styles.main_container}>
        <CurrentMonthHistory />
      </div>
    );
  } else if (location.pathname === RouteList.history) {
    return (
      <div className={styles.main_container}>
        <History />
      </div>
    );
  } else if (location.pathname === RouteList.reviews) {
    return (
      <div className={styles.main_container}>
        <Reviews />
      </div>
    );
  }
  else if (location.pathname === RouteList.earning_earning) {
    return (
      <div className={styles.main_container}>
        <Earnings />
      </div>
    );
  } else if (location.pathname === RouteList.current_week_earning) {
    return (
      <div className={styles.main_container}>
        <CurrentWeekEarning />
      </div>
    );
  } else if (location.pathname === RouteList.current_month_earning) {
    return (
      <div className={styles.main_container}>
        <CurrentMonthEarning />
      </div>
    );
  }
  else if (location.pathname === RouteList.current_month_earning) {
    return (
      <div className={styles.main_container}>
        <CurrentMonthEarning />
      </div>
    );
  } else if (location.pathname === RouteList.modifier_Category) {
    return (
      <div className={styles.main_container}>
        <ModifierCategory />
      </div>
    );
  } else if (location.pathname === RouteList.import_export) {
    return (
      <div className={styles.main_container}>
        <ImportExport />
      </div>
    );
  } else if (location.pathname === RouteList.store_setting) {
    return (
      <div className={styles.main_container}>
        <Setting />
      </div>
    );
  } else if (location.pathname === RouteList.delivery_setting) {
    return (
      <div className={styles.main_container}>
        <DeliverySetting />
      </div>
    );
  } else if (location.pathname === RouteList.time_setting) {
    return (
      <div className={styles.main_container}>
        <StoreTimeSetting />
      </div>
    );
  } else if (location.pathname === RouteList.email_setting) {
    return (
      <div className={styles.main_container}>
        <EmailSetting />
      </div>
    );
  } else if (location.pathname === RouteList.offer) {
    return (
      <div className={styles.main_container}>
        <Offer />
      </div>
    );
  } else if (location.pathname === RouteList.addNew_offer) {
    return (
      <div className={styles.main_container}>
        <AddNewOffer />
      </div>
    );
  } else if (location.pathname === RouteList.domains) {
    return (
      <div className={styles.main_container}>
        <Domain />
      </div>
    );
  } else if (location.pathname === RouteList.addNew_item) {
    return (
      <div className={styles.main_container}>
        <AddNewItem />
      </div>
    );
  } else if (location.pathname === RouteList.addNew_job) {
    return (
      <div className={styles.main_container}>
        <AddNewJob />
      </div>
    );
  } else if (location.pathname === RouteList.import_item) {
    return (
      <div className={styles.main_container}>
        <ImportNewItems />
      </div>
    );
  } else if (location.pathname.indexOf("/product-items/") > -1) {
    return (
      <div className={styles.main_container}>
        <ProductItems />
      </div>
    );

  } else if (location.pathname === RouteList.addNew_brand_item) {
    return (
      <div className={styles.main_container}>
        <AddNewBrandItem />
      </div>
    );
  } else if (location.pathname === RouteList.job_application) {
    return (
      <div className={styles.main_container}>
        <Jobs />
      </div>
    );
  } else if (location.pathname === RouteList.sponsorship_application) {
    return (
      <div className={styles.main_container}>
        <Jobs />
      </div>
    );
  } else if (location.pathname === RouteList.admission_application) {
    return (
      <div className={styles.main_container}>
        <Jobs />
      </div>
    );
  } else if (location.pathname === RouteList.accomodation_application) {
    return (
      <div className={styles.main_container}>
        <Jobs />
      </div>
    );
  }
};
function MainContainer() {
  return (
    <div className={styles.body_section}>
      <GetComponent />
    </div>
  );
}

export default memo(MainContainer);
