import React, { memo, useEffect, useState } from 'react'
import LeftBar from './layouts/LeftBar'
import MainContainer from './layouts/MainContainer'
import TopBar from './layouts/TopBar'
import styles from '../assets/css/Dashboard.module.css'
import { getStoreData, get_domains, logout } from '../Utils/method'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'

function Dashboard() {
    const selected_domain = localStorage.getItem('domain');
    
    console.log(8, getStoreData('get').sub_store);
    const navigate = useNavigate();
    const [domain_list, setDomain_list] = useState([]);
    const [domain, setDomain] = useState(null);
    const [domain_popup, setDomain_popup] = useState(false);

    const setLocalDomain = () => {
        if (domain) {
            setDomain_popup(false)
            localStorage.setItem('domain', JSON.stringify(domain))
        }
    }
    const get_domain_list = async () => {
        try {
          
            if(!selected_domain){
                let apires = await get_domains();
                console.log(28,apires)
                let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                localStorage.setItem('all_domains',JSON.stringify([...sort_v]));
                console.log('get_domains:', sort_v);
                if(getStoreData('get').sub_store){
                    localStorage.setItem('domain', JSON.stringify(sort_v.filter(v=> getStoreData('get').sub_store.access_domains.indexOf(v.name) > -1)[0]));
                    setDomain_popup(false)
                    setDomain_list(sort_v.filter(v=> getStoreData('get').sub_store.access_domains.indexOf(v.name) > -1))
                }else{
                    localStorage.setItem('domain', JSON.stringify({_id:'all_domain_access',name:'All'}))
                    setDomain_popup(false)
                    setDomain_list([{_id:'all_domain_access',name:'All'},...sort_v]);

                }
            }
            

            
        } catch (error) {
            console.log();
        }
    }

    useEffect(() => {
        console.log(9, getStoreData('get'));
        if (!getStoreData('get').store) {
            if (logout()) {
                navigate('/sign-in', { replace: true })
            } else {
                navigate('/', { replace: true })
            }
        }

        if (!localStorage.getItem('domain')) {
            setDomain_popup(true)
        } else {
            setDomain_popup(false)
        }

        console.log(JSON.parse(localStorage.getItem('domain')));
        console.log(':::::::::::::::::::::');
        get_domain_list()
    }, [])

    return (
        <div className={styles.dashboard}>
            {selected_domain &&  <LeftBar />}
            {selected_domain && 
            <div className={styles.right_section}>
                <TopBar />
                <MainContainer />
                {/* <RightBar /> */}
            </div>}
            
            {/* {domain_popup &&
                <div className={styles.domain_popup}>
                    <div className={styles.domain_popup_inner}>
                        <div className={styles.domain_pop_title}>Select Domain</div>
                        <div className={styles.domains_container}>
                       
                            {domain_list.map(v =>
                                
                                <div key={v._id} onClick={(() => setDomain(v))} className={classNames(styles.domain_item, domain?._id === v._id && styles.active)}>{v.name}</div>
                            )}
                        </div>
                        <div><button className={styles.ok_btn} onClick={setLocalDomain}>Save</button></div>
                    </div>
                </div>
            } */}
        </div>
    )
}

export default memo(Dashboard);