/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import { useLocation } from 'react-router-dom';
import styles from '../../../assets/css/AddSubCategory.module.css'
import styles2 from "../../../assets/css/PastOrder.module.css";
import { IMAGE_URL } from '../../../Config/Config';
import { add_new_form, upload_fileFORM, get_single_forms, edit_form,form_type_model } from '../../../Utils/method'
import { toast, ToastContainer } from 'react-toastify';
import Select from 'react-select'
import classNames from 'classnames';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import EmailSettings from './EmailSettings';
import { Editor } from '@tinymce/tinymce-react';

function AddNewForm(props) {

  const queryLocations = useLocation()
  const editorRef = useRef(null);
  const formRef = useRef(null);
  const [emailSettingPop, setEmailSettingPop] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [DynamicFields, setDynamicFields] = useState([{ id: new Date().getTime(),unique_field:'', is_required: true, is_show: true, placeholder: "", sl: 1, instance_image: '', notes: '', label: '', type: '', name: '', value: '', image: '', is_conditional: false, is_need_previous_address: false,how_many_years:0, condition: '', parent_id: '', options: [{ id: 'op_01', label: '', value: '', extra_input: 'no', extra_input_value: '' }] }]);
  const [all_type_models,setAllTypeModels] = useState({});
  const [targetModel,setTargetModel] = useState([])

  const getform_type_model = async ()=>{
    let data = await form_type_model();
    setAllTypeModels(data)
  }
  const [formData, setFormData] = useState({
    form_title: '',
    form_id: '',
    is_active: true,
    email_subject: '',
    email_body: '',
    link_email_subject: '',
    link_email_body: '',
    type:''
    // dynamic_fields: []
  })
  const getSingleForm = async (form_id) => {
    let data = await get_single_forms({ form_id: form_id });
    console.log(26, data)
    if (data.success) {
      setEditStatus(true);
      setFormData({
        ...formData,
        form_title: data.data.form_title,
        form_id: form_id,
        is_active: data.data.is_active,
        email_subject: data.data.email_subject,
        email_body: data.data.email_body,
        link_email_subject: data.data.link_email_subject,
        link_email_body: data.data.link_email_body,
        type: data.data.type,
      })
      data.data.dynamic_fields = data.data.dynamic_fields.map(v => v.is_required === undefined ? { ...v, is_required: true } : v)
      setDynamicFields(data.data.dynamic_fields.length > 0 ? data.data.dynamic_fields.sort((a, b) => a.sl > b.sl ? 1 : -1) : [{ id: new Date().getTime(),unique_field:'', is_required: true, is_show: true, placeholder: "", sl: 1, instance_image: '', notes: '', label: '', type: '', name: '', value: '', image: '', is_conditional: false, is_need_previous_address: false,how_many_years:0, condition: '', parent_id: '', options: [{ id: 'op_01', label: '', value: '', extra_input: 'no', extra_input_value: '', }] }])
    }

  }
  useEffect(() => {
    if (queryLocations.pathname) {
      let params = new URLSearchParams(queryLocations.search);
      let form_id = params.get('form_id');
      if (form_id) {
        getSingleForm(form_id)
      }

      // setFormData({
      //   ...formData,
      //   name: props.editItem.name,
      //   // dynamic_fields: props.editItem.dynamic_fields 
      // })
      // setDynamicFields(props.editItem.dynamic_fields.length > 0 ? props.editItem.dynamic_fields : [{ id: 'ad_01', label: '', type: '', name: '', value: '', options: [{ id: 'op_01', label: '', value: '' }] }])


    }
    getform_type_model();
  }, [])

  const getFormData = async (e, ext) => {
    if (ext) {
      setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
    } else {
      let { name, value } = e.target;
      if (name === 'image') {
        setFormData({ ...formData, [name]: e.target.files[0] })
      } else if (name === 'form_title') {
        setFormData({ ...formData, form_title: value })
      }else if (name === 'type') {
        if(value === 'job'){
          setTargetModel(all_type_models['job']);
        }
        setFormData({ ...formData, type: value })
      }
    }
  }
 
  const resetForm = () => {
    setFormData({ form_title: '', image: '', domain_ids: [] })
    setDynamicFields([{ id: new Date().getTime(),unique_field:'', is_required: true, is_show: true, placeholder: "", sl: 1, instance_image: '', notes: '', label: '', type: '', name: '', value: '', image: '', is_conditional: false, is_need_previous_address: false,how_many_years:0, condition: '', parent_id: '', options: [{ id: 'op_01', label: '', value: '', extra_input: 'no', extra_input_value: '', }] }]);
    window.location.href = "/dynamic_forms/add"
    // props.setEditStatus(false);
    formRef.current.reset()
  }

  const checkInputFields = () => {
    if (formData.form_title === '' || formData.type === '') {
      return false;
    } else {
      return true;
    }
  }

  const saveForm = async () => {
    const data = new FormData();

    let fields = []
    for (let i = 0; i < DynamicFields.length; i++) {
      let obj = DynamicFields[i]
      if (obj.label !== '' && obj.type !== '') {
        obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        delete obj['instance_image'];
        if (obj.type === 'select' || obj.type === 'multiple_select') {
          let options = []
          for (let j = 0; j < obj.options.length; j++) {
            let option = obj.options[j];
            if (!option.extra_input) {
              option = { ...option, extra_input: '' }
            }
            if (!option.extra_input_value) {
              option = { ...option, extra_input_value: '' }
            }
            if (option.label !== '' && option.value !== '') {
              options.push(option)
            }

          }
          obj['options'] = options;
          fields.push(obj)
        } else {
          fields.push(obj)
        }
      }
    }
    console.log('fields:', fields);

    // data.append('dynamic_fields', JSON.stringify(fields));
    // data.append('form_title', formData.form_title);
    if (checkInputFields()) {
      try {
        console.log({
          dynamic_fields: JSON.stringify(fields.map(v => { return ({ ...v, instance_image: '' }) })),
          form_title: formData.form_title,
          is_active: formData.is_active,
          email_subject: formData.email_subject,
          email_body: formData.email_body,
          link_email_subject: formData.link_email_subject,
          link_email_body: formData.link_email_body,
          type:formData.type

        })
        let apires = await add_new_form({
          dynamic_fields: JSON.stringify(fields.map(v => { return ({ ...v, instance_image: '' }) })),
          form_title: formData.form_title,
          is_active: formData.is_active,
          email_subject: formData.email_subject,
          email_body: formData.email_body,
          link_email_subject: formData.link_email_subject,
          link_email_body: formData.link_email_body,
          type:formData.type

        });
        toast.success('Form Added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        resetForm();
        props.updateArray(apires.data)
      } catch (error) {
        console.log(error)
        toast.error(error.error_description, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    } else {
      toast.error('Field validation error', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
    }
  }

  const updateForm = async () => {
    let fields = []
    for (let i = 0; i < DynamicFields.length; i++) {
      let obj = DynamicFields[i]
      if (obj.label !== '' && obj.type !== '') {
        // if (!obj.name || obj.name === '') {
        // obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        // }
        obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        if (obj.type === 'select' || obj.type === 'multiple_select') {
          let options = []
          for (let j = 0; j < obj.options.length; j++) {
            let option = obj.options[j];
            if (!option.extra_input) {
              option = { ...option, extra_input: '' }
            }
            if (!option.extra_input_value) {
              option = { ...option, extra_input_value: '' }
            }
            if (option.label !== '' && option.value !== '') {
              options.push(option)
            }
          }
          obj['options'] = options;
          fields.push(obj)
        } else {
          fields.push(obj)
        }
      }
    }
    console.log('Update fields:', fields);

    if (checkInputFields()) {
      try {
        let apires = await edit_form({
          ...formData,
          dynamic_fields: JSON.stringify(fields.map(v => { return ({ ...v, instance_image: '' }) })),

        });
        console.log(75, apires.brand);
        toast.success('Form Updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        // props.setBrands([...props.brands.map(b => b._id === apires.brand._id ? { ...apires.brand } : b)])
        resetForm();
      } catch (error) {
        console.log(error)
      }
    }
  }



  const isEmpty = (fields, type) => {
    let last_item = fields[fields.length - 1]
    if (type === 'option') {
      if (last_item.label !== '' && last_item.value !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      if (last_item.label !== '' && last_item.type !== '') {
        // if (last_item.type === 'select') {
        //   let option = last_item.options[last_item.options.length - 1]
        //   if (option.label !== '' && option.value !== '') {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // } else {
        //   return true;
        // }
        return true;
      } else {
        return false;
      }
    }
  }

  const handleAddFields = (i) => {
    console.log(i)
    isEmpty(DynamicFields, 'field') &&
      setDynamicFields([...DynamicFields, { id: new Date().getTime(),unique_field:'', is_required: true, is_show: true, placeholder: "", sl: Number(i + 1), notes: '', image: '', instance_image: '', label: '', type: '', name: '', value: '', is_conditional: false, is_need_previous_address: false,how_many_years:0, condition: '', parent_id: '', options: [{ id: 'op_' + Number(i + 2), label: '', value: '', extra_input: 'no', extra_input_value: '', }] }]);
  };

  const handleFieldsData = async (e, fi, oi, type) => {
    let { name, value } = e.target;
    console.log(246, name, value);
    if (type === 'field' || type === 'notes' || type === 'sl' || type === 'is_show' || type === 'is_required' || type === 'is_conditional' || type === 'condition' || type === 'parent_id' || type === 'is_need_previous_address' || type === 'how_many_years') {
      if (type === 'sl') {
        if (value < (DynamicFields.length + 1)) {
          if (value > 0) {
            setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, [name]: value } : v)].sort((a, b) => a.sl - b.sl))
          } else {
            setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, [name]: value } : v)])
          }

        }

      } else {
        let d = {}
        if (type === 'is_show' || type === 'is_required' || type === 'is_conditional' || type === 'is_need_previous_address' ) {
          console.log(e.target.checked)
          value = e.target.checked;
        } else if (value == 'multiple_select') {
          d = { value: [] }
        } else if (value == 'select') {
          d = { value: '' }
        }
        setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, [name]: value, ...d } : v)])

      }

    } else if (type === 'image') {
      let data = new FormData();
      data.append('image', e.target.files[0])
      let apires = await upload_fileFORM(data);
      if (apires.success) {
        setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, [name]: apires.data, instance_image: URL.createObjectURL(e.target.files[0]) } : v)])
      } else {
        alert('Something wrong try again')
      }
      // setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, [name]: value.trim() } : v)])
    } else {
      setDynamicFields([...DynamicFields.map((v, j) => fi === j ? { ...v, options: [...v.options.map((ov, oj) => oi === oj ? { ...ov, [name]: value.trim() } : ov)] } : v)])
    }
  }

  const handleAddOptions = (i, oi, l) => {
    let item = DynamicFields.filter((f, fi) => fi === i)
    isEmpty(item[0].options, 'option') &&
      setDynamicFields([...DynamicFields.map((v, j) => i === j ? { ...v, options: [...v.options, { id: 'op_' + Number(l + 1), label: '', value: '' }] } : v)])
  }

  const handleRemove = (fid, oid, type) => {
    console.log(fid, oid, type);
    let data = [...DynamicFields]
    if (type === 'field') {
      data.length > 1 && setDynamicFields([...data.filter((f, j) => f.id !== fid)])
    }
    else {
      setDynamicFields([...data.map((v, i) => fid === v.id ? { ...v, options: [...v.options.filter((f, j) => f.id !== oid)] } : v)])
    }
  }

  useEffect(() => {
    console.log(169, DynamicFields);
  }, [DynamicFields])
  const [view_image, setViewImage] = useState('')
  function imageHandler(i, data, type) {
    if (type == 'remove') {
      setDynamicFields([...DynamicFields.map((v, j) => i === j ? { ...v, image: '', instance_image: '' } : v)])
    } else {
      setViewImage(IMAGE_URL + data.image);
    }
  }
  const [FullView, setFullView] = useState(false);
  const [settings_view, setSettings_view] = useState(false);
  const [setting_data, setSettingsData] = useState({});

  const openSettings = (v) => {
    setSettings_view(!settings_view);
    if (settings_view) {
      setSettingsData({})
    } else {
      setSettingsData(v)
    }

  }
  return (
    <div className={classNames(styles.addSubCategoryPopup, FullView && styles.full_dynamic_formView)} >
      <ToastContainer />
      {view_image &&
        <div className={styles.image_viewer}>
          <img src={view_image} alt="view" />
          <span onClick={() => setViewImage('')}>X</span>
        </div>}

      <div className={styles.addSub_header}>
        {editStatus ? <p>Edit Form</p> : <p>Add New Form</p>}
        <div onClick={() => setFullView(!FullView)} style={{ cursor: 'pointer' }}>{!FullView ? <i class="fa-solid fa-expand"></i> : <i class="fa-solid fa-compress"></i>}</div>
      </div>

      <div className={styles2.main_body}>
        <div className="col-md-12">
          <div className="row mb-2">
            <label className="small mb-1">Form Title</label>
            <div className="input-group mt-1 ">
              <input type="text" onChange={(e) => getFormData(e)} value={formData.form_title} name="form_title" className="form-control rounded-0" placeholder='Form Title' />
              <select style={{ marginLeft: '8px' }} onChange={(e) => getFormData(e)} value={formData.type} name="type" className='form-control'>
                <option selected >Type</option>
                <option value="all">ALL</option>
                <option value="domain">Domain</option>
                <option value="blog">Blog</option>
                <option value="job">Job</option>
                <option value="job_submit">Job Submit</option>
                <option value="user_sign_up">User Signup</option>
                <option value="company_sign_up">Company Signup</option>
                <option value="contact_us">Contact Form</option>
              </select >
              <button onClick={() => setEmailSettingPop(true)} className={styles.submitBtn} style={{ marginLeft: '8px', height: '100%' }}>Email settings</button>
            </div>
          </div>
        </div>

        <div className="card rounded-0">
          <div className="card-body table-responsive" style={{ height: "calc(100vh - 260px)", overflow: 'hidden' }}>
            <div className="table orderlist_table table-striped" >
              <div className={styles2.thead_light}>
                <div className={`${styles2.table_heads} ${styles2.table_header}`} style={{ display: 'flex' }}>
                  <div className={styles2.col_sl} >Sl.</div>
                  <div className={styles2.col_type} >ID <i class="fa-solid fa-copy"></i></div>
                  <div className={styles2.col_type} >Field Type</div>
                  <div className={styles2.col_label} >Label</div>
                  <div className={styles2.col_placeholder} >Place Holder</div>
                  <div className={styles2.col_image} >Image</div>
                  {/* <div className={styles2.col_note} >Note</div> */}
                  <div className={styles2.col_on_off} >On/Off</div>
                  <div className={styles2.col_action} >Actions</div>
                </div>
              </div>
              <div className={styles2.tbody} style={{ height: "calc(100vh - 320px)", overflowY: 'auto', overflowX: 'hidden', paddingBottom: '14px' }}>
                {DynamicFields.map((v, i) =>
                  <div className='' style={{ width: '100%' }}>
                    <div className={styles2.table_row} key={'past_' + v.id}>

                      <div className={styles2.col_sl}>
                        <input type="number" max={DynamicFields.length} name='sl' value={v.sl} onChange={(e) => handleFieldsData(e, i, undefined, 'sl')} className="form-control rounded-0" placeholder='#SL' />
                      </div>
                      <div className={styles2.col_type}>
                        <CopyToClipboard text={v.id}
                          onCopy={() => toast.success('Coppied!', {
                            position: 'top-right',
                            autoClose: 2000
                          })}>
                          <button type="button" className="form-control rounded-0">{v.id}</button>

                        </CopyToClipboard>

                      </div>
                      <div className={styles2.col_type}>
                        <select className="form-control rounded-0" name='type' value={v.type} onChange={(e) => handleFieldsData(e, i, undefined, 'field')}>
                          <option value={''} disabled>Field Type</option>
                          <option value={'text'}>Text</option>
                          <option value={'password'}>Password</option>
                          <option value={'textarea'}>Textarea</option>
                          <option value={'number'}>Number</option>
                          <option value={'phone'}>Phone</option>
                          <option value={'email'}>Email</option>
                          <option value={'date'}>Date</option>
                          <option value={'select'}>Select</option>
                          <option value={'multiple_select'}>Multiple Select</option>
                          <option value={'radio'}>Radio</option>
                          <option value={'file'}>File</option>
                          <option value={'gallery'}>Gallery</option>
                          <option value={'current_address'}>Current Address</option>
                          <option value={'image_from_camera'}>Image from camera</option>
                          <option value={'link'}>Link</option>

                        </select>
                      </div>
                      <div className={styles2.col_label}>
                        <input type="text" name='label' value={v.label} onChange={(e) => handleFieldsData(e, i, undefined, 'field')} className="form-control rounded-0" placeholder='Field Label' />
                      </div>
                      <div className={styles2.col_placeholder}>
                        <input type="text" name='placeholder' value={v.placeholder} onChange={(e) => handleFieldsData(e, i, undefined, 'field')} className="form-control rounded-0" placeholder='Placeholder' />
                      </div>
                      <div className={styles2.col_image}>
                        {v.image ?
                          <div className='col-md-12' style={{ 'display': 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            {/* <img onClick={() => imageHandler(i, v, 'view')} src={v.instance_image ? v.instance_image : IMAGE_URL + v.image} alt="image" style={{ 'width': 'auto', 'height': '38px', cursor: 'pointer' }} /> */}
                            <span style={{ color: 'blue', cursor: 'pointer' }} onClick={() => imageHandler(i, v, 'view')}><i class="fa-solid fa-eye"></i></span>
                            <span style={{ color: 'red', paddingLeft: '8px', cursor: 'pointer' }} onClick={() => imageHandler(i, v, 'remove')}><i class="fa-solid fa-trash"></i></span>
                          </div>

                          :
                          <div className='col-md-12' style={{ 'display': 'flex', justifyContent: 'center', alignItems: 'center' }}>
                            <label for={'input_file' + i} style={{ cursor: 'pointer' }}>
                              Upload
                              <input id={'input_file' + i} type="file" name='image' onChange={(e) => handleFieldsData(e, i, undefined, 'image')} className="form-control rounded-0" placeholder='Field Label' accept='image/*' hidden />
                            </label>
                          </div>
                        }
                      </div>
                      {/* <div className={styles2.col_note}>
                        <textarea name="notes" rows="1" cols="1" defaultValue={v.notes} onChange={(e) => handleFieldsData(e, i, undefined, 'notes')} className="form-control rounded-0" placeholder='Notes' style={{ 'height': '38px' }} />
                      </div> */}
                      <div className={styles2.col_on_off}>
                        <div class="form-switch" style={{ display: 'flex', alignItems: 'left' }}>
                          <input class="form-check-input" type="checkbox" name="is_show" role="switch" onChange={(e) => handleFieldsData(e, i, undefined, 'is_show')} checked={v.is_show} />
                        </div>
                      </div>
                      <div className={styles2.col_action}>
                        <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                          <span onClick={() => openSettings(v)} className={styles.add_fields_custom} style={{ marginRight: '4px' }}> More </span>
                          <span onClick={() => handleRemove(v.id, undefined, 'field')} className={styles.add_fields_custom} style={{ backgroundColor: '#ff0000', width: '80px' }}> Delete </span>
                        </div>


                      </div>
                    </div>
                    {settings_view && setting_data.id == v.id &&
                      <div className={styles2.thead_light}>
                        <div style={{ backgroundColor: 'rgb(77 115 161 / 36%)', height: 'auto', width: ' 100%', padding: '12px' }}>
                          <div className='row'>
                            <div className='col-md-3'>
                              <div class="form-group">
                                <label>Is required?</label>
                                <div class="form-switch" >
                                  <input class="form-check-input" type="checkbox" name="is_required" role="switch" onChange={(e) => handleFieldsData(e, i, undefined, 'is_required')} checked={v.is_required} />
                                </div>
                              </div>
                              {targetModel.length > 0 &&
                                   <div class="form-group">
                                    <label>Unique Field</label>
                                    <div class="form-switch" >
                                    <select className="form-control rounded-0" name='unique_field' value={v.unique_field} onChange={(e) => handleFieldsData(e, i, undefined, 'field')}>
                                      <option value={''} disabled>Select</option>
                                      {
                                        targetModel.map(v=>
                                          <option key={v} value={v}>{v}</option>
                                          )
                                      }
                                    </select>
                                    </div>
                                  </div>
                              }
                             
                              <div class="form-group">
                                <label>Is conditional?</label>
                                <div class="form-switch" >
                                  <input class="form-check-input" type="checkbox" name="is_conditional" role="switch" onChange={(e) => handleFieldsData(e, i, undefined, 'is_conditional')} checked={v.is_conditional} />
                                </div>
                              </div>
                              {v.is_conditional &&
                                <div class="form-group">
                                  <label>Condition</label>
                                  <input placeholder="Parent Field Value" class="form-control rounded-0" type="text" name="condition" onChange={(e) => handleFieldsData(e, i, undefined, 'condition')} value={v.condition} />

                                </div>
                              }
                              {/* {v.is_conditional && */}
                                <div class="form-group">
                                  <label>Group ID [Parent Field ID]</label>
                                  <input placeholder="eg. Uniq Id" class="form-control rounded-0" type="text" name="parent_id" onChange={(e) => handleFieldsData(e, i, undefined, 'parent_id')} value={v.parent_id} />

                                </div>
                              {/* } */}
                              {v.type === 'current_address' &&
                                
                                <div class="form-group">
                                <label>Is need previous address?</label>
                                <div class="form-switch" >
                                  <input class="form-check-input" type="checkbox" name="is_need_previous_address" role="switch" onChange={(e) => handleFieldsData(e, i, undefined, 'is_need_previous_address')} checked={v.is_need_previous_address} />
                                </div>
                              </div>
                              }
                              {v.type === 'current_address' && v.is_need_previous_address &&
                                
                                <div class="form-group">
                                  <label>How many years?</label>
                                  <input placeholder="eg. 1" class="form-control rounded-0" type="number" name="how_many_years" onChange={(e) => handleFieldsData(e, i, undefined, 'how_many_years')} value={v.how_many_years} />

                                </div>
                              }
                            </div>
                            <div className='col-md-8'>
                              <div className="input-group " style={{ width: '100%' }}>
                                <label>Description Or Notes</label>
                                <Editor
                                  apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                                  onInit={(evt, editor) => editorRef.current = editor}
                                  onEditorChange={(e) => handleFieldsData({ target: { value: e, name: 'notes' } }, i, undefined, 'notes')}
                                  init={{
                                    height: 200,
                                    width: '100%',
                                    menubar: true,
                                    plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                    toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                  }}
                                  value={v.notes}
                                />

                              </div>
                            </div>
                            <div className='col-md-1' style={{ display: 'flex', justifyContent: 'right', cursor: 'pointer' }}>
                              <span onClick={() => openSettings(v)}><i class="fa-solid fa-xmark"></i></span>
                            </div>
                          </div>




                        </div>
                      </div>
                    }
                    {(v.type === 'multiple_select' || v.type === 'select' || v.type === 'radio') &&
                      <div className='col-md-12 pt-0 pb-0'>
                        {v.options.map((option, oi) =>
                          <div className="row mb-2" key={'option_' + oi}>
                            <div className='col-md-3'>
                              <input type="text" name='label' style={{ backgroundColor: '#ced4df' }} value={option.label} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder={'Option label'} required />
                            </div>
                            <div className='col-md-3'>
                              <input type="text" name='value' style={{ backgroundColor: '#ced4df' }} value={option.value} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder='Option value' required />

                            </div>
                            <div className='col-md-2'>
                              <select name="extra_input" className={classNames(styles.input_field_item, "form-control rounded-0")} onChange={(e) => handleFieldsData(e, i, oi, 'option')} value={option.extra_input} >
                                <option disabled selected>Extra Input</option>
                                <option value={'yes'}>Yes</option>
                                <option value={'no'}>No</option>
                              </select>

                            </div>
                            <div className='col-md-2'>
                              <div style={{ width: '80px', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                <span onClick={() => handleRemove(v.id, option.id, 'option')} className={styles.add_fields} style={{ backgroundColor: '#ff0000', width: '80px' }}> Delete</span>
                              </div>
                              {v.options.length === oi + 1 &&
                                <div style={{ width: '80px', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'left' }}>
                                  <span onClick={() => handleAddOptions(i, oi, v.options.length)} className={styles.add_fields} style={{ backgroundColor: '#ced4df', color: '#012965', width: '80px' }}>  Add</span>
                                </div>
                              }
                            </div>

                          </div>
                        )}
                      </div>
                    }
                  </div>
                )}

              </div>

            </div>

          </div>
          <div style={{ width: 'auto', padding: '8px', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
            <span style={{ width: 'auto', padding: '8px' }} onClick={() => handleAddFields(DynamicFields.length)} className={styles.add_fields}>  Add New Field</span>
          </div>
          {formData.form_id ?
            <div className={styles.addSub_footer}>
              <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
              <button className={styles.submitBtn} onClick={updateForm}>Update</button>
            </div>
            :
            <div className={styles.addSub_footer}>
              <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
              <button className={styles.submitBtn} onClick={saveForm}>Submit</button>

            </div>}
        </div>
      </div>


      {/* <form ref={formRef} className={styles.addSub_body}>
        <div className="col-md-12">
          <div className="row mt-1 mb-2">
            <div className="row">
              <label className="small mb-1">Form Title</label>
              <div className="input-group mt-1 ">
                <input type="text" onChange={(e) => getFormData(e)} value={formData.form_title} name="form_title" className="form-control rounded-0" placeholder='Form Title' />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small" style={{ position: 'relative' }}>Dynamic Fields </label>
            </div>
            {DynamicFields.map((v, i) =>
              <div className="row mt-2" key={'field_' + i}>
                <div className='col-md-12'>
                  <div className="row">
                    <div className='col-md-1'>
                      <input type="number" max={DynamicFields.length} name='sl' value={v.sl} onChange={(e) => handleFieldsData(e, i, undefined, 'sl')} className="form-control rounded-0" placeholder='#SL' />
                    </div>
                    <div className='col-md-1 '>
                      {v.image ?
                        <div className='col-md-12' style={{ 'display': 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <img onClick={() => imageHandler(i, v, 'view')} src={v.instance_image ? v.instance_image : IMAGE_URL + v.image} alt="pic" style={{ 'width': 'auto', 'height': '38px', cursor: 'pointer' }} />
                          <span style={{ color: 'blue', paddingLeft: '8px', cursor: 'pointer' }} onClick={() => imageHandler(i, v, 'view')}><i class="fa-solid fa-eye"></i></span>
                          <span style={{ color: 'red', paddingLeft: '8px', cursor: 'pointer' }} onClick={() => imageHandler(i, v, 'remove')}><i class="fa-solid fa-trash"></i></span>
                        </div>
                        :
                        <div className='col-md-12' style={{ 'display': 'flex', justifyContent: 'center', alignItems: 'center' }}>
                          <label for={'input_file' + i} style={{ cursor: 'pointer' }}>
                            <i class="fa-solid fa-upload"></i>
                            <input id={'input_file' + i} type="file" name='image' onChange={(e) => handleFieldsData(e, i, undefined, 'image')} className="form-control rounded-0" placeholder='Field Label' accept='image/*' hidden />
                          </label>
                        </div>
                      }

                    </div>
                    <div className='col-md-3'>
                      <input type="text" name='label' value={v.label} onChange={(e) => handleFieldsData(e, i, undefined, 'field')} className="form-control rounded-0" placeholder='Field Label' />
                    </div>
                    <div className='col-md-3'>
                      <textarea name="notes" rows="1" cols="1" defaultValue={v.notes} onChange={(e) => handleFieldsData(e, i, undefined, 'notes')} className="form-control rounded-0" placeholder='Notes' style={{ 'height': '38px' }}>

                      </textarea>
                    </div>
                    <div className='col-md-2'>
                      <select className="form-control rounded-0" name='type' value={v.type} onChange={(e) => handleFieldsData(e, i, undefined, 'field')}>
                        <option value={''} disabled>Field Type</option>
                        <option value={'text'}>Text</option>
                        <option value={'textarea'}>Textarea</option>
                        <option value={'number'}>Number</option>
                        <option value={'phone'}>Phone</option>
                        <option value={'email'}>Email</option>
                        <option value={'date'}>Date</option>
                        <option value={'select'}>Select</option>
                        <option value={'radio'}>Radio</option>
                        <option value={'file'}>File</option>
                        <option value={'link'}>Link</option>
                      </select>
                    </div>
                    <div className='col-md-1'>
                      <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span onClick={() => handleRemove(v.id, undefined, 'field')} className={styles.add_fields} style={{ backgroundColor: '#ff0000' }}><i className="fa-light fa-times"></i> </span>
                      </div>
                    </div>
                    {DynamicFields.length === i + 1 &&
                      <div className='col-md-1'>
                        <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span onClick={() => handleAddFields(i)} className={styles.add_fields}><i className="fa-light fa-plus"></i> </span>
                        </div>
                      </div>
                    }
                  </div>
                  {(v.type === 'select' || v.type === 'radio') &&
                    <div className='col-md-12'>
                      {v.options.map((option, oi) =>
                        <div className="row mt-2" key={'option_' + oi}>
                          <div className='col-md-5'>
                            <input type="text" name='label' style={{ backgroundColor: '#ced4df' }} value={option.label} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder={'Option label'} required />
                          </div>
                          <div className='col-md-5'>
                            <input type="text" name='value' style={{ backgroundColor: '#ced4df' }} value={option.value} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder='Option value' required />
                          </div>
                          <div className='col-md-1'>
                            <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <span onClick={() => handleRemove(v.id, option.id, 'option')} className={styles.add_fields} style={{ backgroundColor: '#ff0000' }}><i className="fa-light fa-times"></i> </span>
                            </div>
                          </div>
                          {v.options.length === oi + 1 &&
                            <div className='col-md-1'>
                              <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span onClick={() => handleAddOptions(i, oi)} className={styles.add_fields} style={{ backgroundColor: '#ced4df', color: '#012965' }}> <i className="fa-light fa-plus"></i></span>
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            )}
          </div>
        </div>
      </form> */}

      {emailSettingPop &&
        <EmailSettings
          setEmailSettingPop={setEmailSettingPop}
          setFormData={setFormData}
          formData={formData}
          DynamicFields={DynamicFields}

        >
        </EmailSettings>
      }

    </div>
  );
}

export default AddNewForm;
