import React from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";
import Select from "react-select";


function EditOrderSetting(props) {


    return (
        <div className={styles.wrapper}>
            <div className={styles.storeSettingPopup}>
                <h5 className={styles.addSub_header}>Edit Order Setting
                    <span className={styles.itemDesIcon} onClick={() => props.setEditOrder(false)}>
                        <i className="fa-regular fa-circle-xmark"></i>
                    </span>
                </h5>
                <div className={styles.formContainer}>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Do you want to apply individual tax on Item?
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                If ON than apply individual Item Tax / Vat on order
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom flex-row justify-content-between mb-3 pb-3">
                        <div>
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Tax On Item
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                If above field OFF then apply this tax on order
                            </p>
                        </div>
                        <div className="m-auto">
                            <div className="input-group mt-1 form-group">
                                <div className="mt-2">
                                    <Select
                                        defaultValue={''}
                                        className={styles.selectBox}
                                        placeholder='' />

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Is your tax included on item price or exclusive
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                If ON then select tax included on item and modifiers price
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="col-8">
                            <div className="flex-grow-1 text-truncate">
                                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                    Minimum Order Price
                                </p>
                                <p
                                    className="text-muted mb-0 text-truncate"
                                    style={{ fontSize: 12, fontWeight: 300 }}
                                >
                                    User can order above this price
                                </p>
                            </div>
                        </div>
                        <div className="col-4 text-end m-auto">
                            <div className="input-group mt-1 ">
                                <div className={styles.textFront}>
                                    ৳
                                </div>
                                <input type="number" className="form-control rounded-0 shadow-none"></input>
                            </div>
                        </div>
                    </div>
                    <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
                        <div className="flex-grow-1 text-truncate">
                            <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                                Assign to Deliveryman when order is accepted
                            </p>
                            <p
                                className="text-muted mb-0 text-truncate"
                                style={{ fontSize: 12, fontWeight: 300 }}
                            >
                                Assign to Deliveryman when order is accepted
                            </p>
                        </div>
                        <div className="form-check form-switch">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="is_visible"
                            />
                        </div>
                    </div>
                </div>
                <div className={styles.submitButtons}>
                    <button className={styles.cancelBtn} onClick={() => props.setEditOrder(false)}>Cancel</button>
                    <button className={styles.submitBtn}>Submit</button>
                </div>
            </div >
        </div>
    );
}

export default EditOrderSetting;
