import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../../assets/css/AddSubCategory.module.css'
import { add_new_supplier, edit_supplier, get_domains } from '../../../../Utils/method'
import supplierPng from '../../../../assets/supplier.png'
import { IMAGE_URL } from '../../../../Config/Config'
import { toast } from 'react-toastify';
import Select from 'react-select'

function AddNewSupplier(props) {
  const [formData, setFormData] = useState({
    name: '',
    image: '',
    domain_ids: [],
  })
  const formRef = useRef(null);
  const [curentImage, setCurentImage] = useState('');
  const [domain_list, setDomain_list] = useState([]);
  const [selected_domain, setSelected_domain] = useState([]);

  useEffect(() => {
    if (props.editStatus) {
      setFormData({ ...formData, name: props.editItem.name, image: props.editItem.image, domain_ids: props.editItem.domain_ids })

      let _domain = []
      for (const key in domain_list) {
        const domain = domain_list[key];
        for (const i in props.editItem.domain_ids) {
          const id = props.editItem.domain_ids[i];
          if (domain.value === id) {
            _domain.push(domain)
          }
        }
      }
      setSelected_domain(_domain)
    }
  }, [props.editStatus, props.editCounter])

  const getFormData = async (e, ext) => {
    if (ext) {
      setSelected_domain(e)
      setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
    } else {
      let { name, value } = e.target;
      if (name === 'image') {
        setFormData({ ...formData, [name]: e.target.files[0] })
        setCurentImage(URL.createObjectURL(e.target.files[0]));
      } else if (name === 'name') {
        setFormData({ ...formData, [name]: value })
      }
    }
  }

  const resetForm = () => {
    setFormData({ name: '', image: '', domain_ids: [] })
    setCurentImage('');
    setSelected_domain([])
    props.setEditStatus(false);
    formRef.current.reset()
  }

  const checkInputFields = () => {
    if (formData.name === '' || formData.image === '') {
      return false;
    } else {
      return true;
    }
  }

  const saveSupplier = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('name', formData.name);
    data.append('image_url[0]', formData.image);

    if (checkInputFields()) {
      try {
        let apires = await add_new_supplier(data);
        console.log(apires);
        toast.success('Supplier Added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        resetForm();
        props.updateArray(apires.supplier);
      } catch (error) {
        console.log(error)
      }
    }
  }

  const updateSupplier = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('_id', props.editItem._id);
    data.append('name', formData.name);

    if (typeof formData.image !== 'string') {
      data.append('image_url[0]', formData.image);
    }

    try {
      let apires = await edit_supplier(data);
      console.log(apires);
      toast.success('Supplier Updated successfully', {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true
      });
      props.setSupplier([...props.supplier.map(b => b._id === apires.supplier._id ? { ...apires.supplier } : b)])
      resetForm();
    } catch (error) {
      console.log(error)
    }
  }

  const get_domain_list = async () => {
    try {
      let apires = await get_domains();
      let data = [];
      let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
      for (const p of sort_v) {
        data.push({ value: p._id, label: p.name })
      }
      console.log('get_domains:', data);
      setDomain_list(data)
    } catch (error) {
      console.log();
    }
  }

  useEffect(() => {
    get_domain_list()
  }, [])

  return (
    <div className={styles.addSubCategoryPopup}>
      <div className={styles.addSub_header}>
        {props.editStatus ? <p>Edit Supplier</p> : <p>Add New Supplier</p>}
      </div>
      <form ref={formRef} className={styles.addSub_body}>
        <div className="col-md-12">
          <div className="row mt-1 mb-2">
            <div className="row">
              <label className="small mb-1">Supplier Name</label>
              <div className="input-group mt-1 ">
                <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Supplier Name' />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Domain</label>
              <div className="mt-1 ">
                <Select
                  value={selected_domain}
                  isMulti
                  name="domain_ids"
                  className="form-control rounded-0 basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => getFormData(e, 'domain_ids')}
                  options={domain_list}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Supplier Icon</label>
              <div className="input-group mt-1 ">
                <input type="file" onChange={(e) => getFormData(e)} name="image" className="form-control rounded-0" placeholder='Supplier Icon' />
              </div>
            </div>
            <div className="row">
              <div className="input-group mt-1 ">
                <div className={styles.img_view_container}>
                  {props.editStatus && curentImage === '' ?
                    <img style={{ maxWidth: 150 }} src={IMAGE_URL + props.editItem.image} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = supplierPng }} alt={'Supplier Icon'} />
                    : props.editStatus && curentImage !== '' ?
                      <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = supplierPng }} alt={'Supplier Icon'} />
                      : !props.editStatus && curentImage !== '' ?
                        <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = supplierPng }} alt={'Supplier Icon'} />
                        : <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {props.editStatus ? <div className={styles.addSub_footer}>
        <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
        <button className={styles.submitBtn} style={checkInputFields() ? {}:{opacity:'0.6'}} onClick={updateSupplier}>Update</button>
      </div>
        :
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
          <button className={styles.submitBtn} style={checkInputFields() ? {}:{opacity:'0.6'}} onClick={saveSupplier}>Submit</button>
        </div>}
    </div>
  )
}

export default AddNewSupplier;