import React, { useState } from "react";
import styles from "../../../../assets/css/StoreSetting.module.css";
import { getStoreData, update } from "../../../../Utils/method";


function EditStoreSetting(props) {
  const store = getStoreData({ type: 'get' })
  //   {
  //     "delivery_time": 30,
  //     "delivery_time_max": 45,
  //     "is_provide_pickup_delivery": false,
  //     "is_provide_delivery": true,
  //     "theme_number": 1,
  //     "old_password": "cab1213",
  //     "price_rating": 1,
  //     "store_id": "6342a0361d2151beeb670d32",
  //     "server_token": "9NudqQZWSPKlRjKrrWyoaKHFt9VH6XJJ",
  //     "timezone": "Asia/Dhaka",
  //     "id": "6342a0361d2151beeb670d32"
  // }

  const [settings_data, setSettings_data] = useState({
    delivery_time : props.store_details.delivery_time,
    delivery_time_max : props.store_details.delivery_time_max,
    is_provide_pickup_delivery : props.store_details.is_provide_pickup_delivery,
    is_provide_delivery : props.store_details.is_provide_delivery,
    theme_number : '',
    old_password : '',
    price_rating : props.store_details.price_rating,
    server_token : store.store.server_token,
    timezone : store.timezone,
    store_id : store.store._id,
    id : store.store._id,
  })


  return (
    <div className={styles.wrapper}>
      <div className={styles.storeSettingPopup}>
        <h5 className={styles.addSub_header}>Edit Store Setting
          <span className={styles.itemDesIcon} onClick={() => props.setEditStore(false)}>
            <i className="fa-regular fa-circle-xmark"></i>
          </span>
        </h5>
        <div className={styles.formContainer}>
          <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
            <div className="flex-grow-1 text-truncate">
              <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                Do You provide PICKUP Delivery ?
              </p>
            </div>
            <div className="form-check form-switch">
              <input type="checkbox" className="form-check-input" id="is_visible" />
            </div>
          </div>
          <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
            <div className="flex-grow-1 text-truncate">
              <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                Do You provide Delivery ?
              </p>
            </div>
            <div className="form-check form-switch">
              <input type="checkbox" className="form-check-input" id="is_visible" />
            </div>
          </div>
          <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
            <div className="col-8">
              <div className="flex-grow-1 text-truncate">
                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                  Min. & Max. Delivery Time
                </p>
                <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                  Approximate delivery time to show user
                </p>
              </div>
            </div>

            <div className="col-4 text-end m-auto">
              <div className="input-group">
                <input className="form-control shadow-none rounded-0" defaultValue={30} style={{ fontSize: 14, fontWeight: 300 }}></input>
                <input className="form-control shadow-none rounded-0" defaultValue={45} style={{ fontSize: 14, fontWeight: 300 }}></input>
              </div>
            </div>
          </div>
          <div className="border-bottom d-flex flex-row justify-content-between mb-3 pb-3">
            <div className="col-10">
              <div className="flex-grow-1 text-truncate">
                <p className="mb-0" style={{ fontSize: 14, fontWeight: 300 }}>
                  Price Rating
                </p>
                <p className="text-muted mb-0 text-truncate" style={{ fontSize: 12, fontWeight: 300 }} >
                  Showing max price of item (Ex. if price $99 then 2)
                </p>
              </div>
            </div>
            <div className="col-2 text-end m-auto">
              <div className="form-group">
                <input className="form-control shadow-none rounded-0" defaultValue={1} style={{ fontSize: 14, fontWeight: 300 }}></input>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.submitButtons}>
          <button className={styles.cancelBtn} onClick={() => props.setEditStore(false)}>Cancel</button>
          <button className={styles.submitBtn}>Submit</button>
        </div>
      </div >
    </div>
  );
}

export default EditStoreSetting;
