import React from "react";
import { ToastContainer, toast } from 'react-toastify';
import styles from "../../../../../assets/css/ImportExport.module.css";
function ImportExport() {

    return (
        <div className={styles.modifierCategory_page}>
            <ToastContainer />
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Import Export</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Modifier</li>
                            <li className={styles.breadcrumb_item}>Import Export</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection}>
                    <button className={styles.exportButton}>Help</button>
                </div>
            </div>
            <div className={styles.main_body}>
                <div className={styles.cardContainer}>
                    <div className="row mt-3">
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="card rounded-0">
                                <div className="card-body">
                                    <div className="d-flex flex-row justify-content-between pb-3 pt-3">
                                        <div className="float-left float-none-xs">
                                            <div className="d-inline-block">
                                                <h5 className="d-inline">Import/Export Sub Category - Item</h5>
                                            </div>
                                        </div>
                                        <div className="btn-group float-right float-none-xs mb-4">
                                            <button className={styles.exportButton}>Export</button>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div class="input-group mb-3">
                                                <div class="input-group">
                                                    <input type="file" class="form-control rounded-0 shadow-none" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"></input>
                                                    <button class={styles.UploadButton} type="button" id="inputGroupFileAddon04">Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="card rounded-0">
                                <div className="card-body">
                                    <div className="d-flex flex-row justify-content-between pb-3 pt-3">
                                        <div className="float-left float-none-xs">
                                            <div className="d-inline-block">
                                                <h5 className="d-inline">Import/Export Modifier group</h5>
                                            </div>
                                        </div>
                                        <div className="btn-group float-right float-none-xs mb-4">
                                            <button className={styles.exportButton}>Export</button>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div class="input-group mb-3">
                                                <div class="input-group">
                                                    <input type="file" class="form-control rounded-0 shadow-none" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"></input>
                                                    <button class={styles.UploadButton} type="button" id="inputGroupFileAddon04">Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="card rounded-0">
                                <div className="card-body">
                                    <div className="d-flex flex-row justify-content-between pb-3 pt-3">
                                        <div className="float-left float-none-xs">
                                            <div className="d-inline-block">
                                                <h5 className="d-inline">Import Images</h5>
                                            </div>
                                        </div>
                                        <div className="btn-group float-right float-none-xs mb-4">
                                            <button className={styles.exportButton}>Export</button>
                                        </div>
                                    </div>
                                    <div className="form-group row">
                                        <div className="col-12">
                                            <div class="input-group mb-3">
                                                <div class="input-group">
                                                    <input type="file" class="form-control rounded-0 shadow-none" id="inputGroupFile04" aria-describedby="inputGroupFileAddon04" aria-label="Upload"></input>
                                                    <button class={styles.UploadButton} type="button" id="inputGroupFileAddon04">Upload</button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-sm-6 col-xs-12 mb-4">
                            <div className="card rounded-0">
                                <div className="card-body d-flex">
                                    <button class={styles.ExportallButton} type="button" id="inputGroupFileAddon04">Export All Items</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    );

}
export default ImportExport;