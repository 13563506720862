/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/User.module.css";
import { useSelector } from "react-redux";
import AddNewSubStore from "./AddNewSubStore";
import { getStoreData, get_brand, sub_store_list } from "../../../Utils/method";
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import classNames from "classnames";
import { access_list } from "../../../Utils/common";

function User() {
  const [newStore, setNewStore] = useState(false);
  const [loader, setLoader] = useState(true)
  const store_data = getStoreData({ type: 'get' });
  const [sub_stores, setSub_stores] = useState([])

  const searchOptions = [
    { value: 'user', label: 'Search By : User' },
    { value: 'order', label: 'Search By : Order' },
  ]

  const [brands, setBrands] = useState([]);
  // const [selected_brands, setSelected_brands] = useState([]);

  const get_brands = async () => {
    try {
      let apires = await get_brand(`?perPage=${2000}`);
      console.log(16, 'get_brand:', apires);
      if (apires.data.brands.length > 0) {
        let data = [];
        let sort_v = apires.data.brands.sort((a, b) => a.name.localeCompare(b.name));
        for (const p of sort_v) {
          data.push({ value: p._id, label: p.name })
        }
        setBrands(data);
      }
    } catch (error) {
      // setLoader(false);
      console.log(error)
    }
  }

  useEffect(() => {
    let _isMounted = true;

    async function initial() {
      setLoader(true)
      try {

        let d = {
          store_id: store_data.store._id,
          server_token: store_data.store.server_token,
          timezone: store_data.timezone
        }
        console.log(30, d);

        let apires = await sub_store_list(d);
        console.log(33, apires);
        if (_isMounted) {
          if (apires.success) {
            setSub_stores(apires.sub_stores)
          } else {
            console.log(33, apires.status_phrase);
            toast.error('No data found!', {
              position: 'top-right',
              autoClose: 4000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true
            });
          }
        }
        setLoader(false)
      } catch (error) {
        setLoader(false)
        console.log(error);
      }
    }
    initial()
    get_brands()
    return () => {
      _isMounted = false
    }
  }, [])

  const [edit_item, setEdit_item] = useState([])

  const edit_sub_store = (data) => {
    setEdit_item([data])
    setNewStore(true)
  }

  return (
    <div className={styles.order_container}>
      <ToastContainer />
      {loader && <div className="loaderContainer"></div>}
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}>
          <div className={styles.titleWithBC}>
            <h3>Sub Stores</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Users</li>
              <li className={styles.breadcrumb_item}>Sub Stores</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection}>
          <button className={styles.exportButton} onClick={() => setNewStore(true)}>Add New</button>
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <Select className={styles.searchBy}
                defaultValue={''}
                isSearchable={false}
                placeholder='Search By : User'
                options={searchOptions}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Search" className={styles.searchbox} />
                <button className={styles.searchIcon} type="submit">
                  <span><i className="fa-light fa-magnifying-glass"></i></span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply}>Apply</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className={styles.orderContainer}>
          {!loader &&
            sub_stores.map(v =>
              <div className={classNames("card rounded-0", styles.subStore_card)} key={v._id}>
                <div className={classNames("p-3", styles.card_body)}>
                  {v.is_approved && <span className={styles.approve_text}>APPROVED</span>}
                  <div className="row">
                    <div className="col-8">
                      <h5 className="mb-2 font-weight-semibold">{v.name[0]}</h5>
                    </div>
                    <div className="col-4 text-end">
                      <button className={styles.ItemEditBtn} onClick={() => edit_sub_store(v)}>Edit</button>
                    </div>
                  </div>
                  <div className="mb-2 d-flex flex-column flex-grow-1">
                    <div className="row g-0">
                      <div className="col-12">
                        <p className="mb-2 text-truncate small text-muted">
                          {v.email} {v.phone !== '' && ', +' + v.phone}
                        </p>
                      </div>
                    </div>
                    {v.request_brand_names.length > 0 &&
                      <div className="row g-0">
                        <div className="col-12">
                          <p className="text-truncate small text-muted">
                            Requested Brands:
                          </p>
                        </div>
                        {v.request_brand_names.map((m, i) =>
                          <div className="col-md-6" key={i + '_rq_'}><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{m}</p></div>
                        )}
                      </div>
                    }
                    {v.access_brand.length > 0 &&
                      <div className="row g-0">
                        <div className="col-12">
                          <p className="text-truncate small text-muted">
                            Brand Access:
                          </p>
                        </div>
                        {brands.map((a, ai) =>
                          v.access_brand.map((m, i) =>
                            a.value === m && <div className="col-md-6" key={i + '_br_'}><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{a.label}</p></div>
                          )
                        )}
                      </div>
                    }
                    {v.access_list.length > 0 &&
                      <div className="row g-0">
                        <div className="col-12">
                          <p className="text-truncate small text-muted">
                            URL Access:
                          </p>
                        </div>
                        {access_list.map((a, ai) =>
                          v.access_list.map((m, i) =>
                            a.url === m && <div className="col-md-6" key={i + '_'}><p className={classNames(styles.text_label, "fw-light mb-0 d-inline-block")}>{a.title}</p></div>
                          )
                        )}

                      </div>
                    }
                  </div>
                </div>
              </div>
            )}
        </div>
      </div>
      {newStore &&
        <AddNewSubStore
          setNewStore={setNewStore}
          sub_stores={sub_stores}
          setSub_stores={setSub_stores}
          edit_item={edit_item}
          setEdit_item={setEdit_item}
          brands={brands}
        />
      }
    </div>
  );
}

export default User;
