import React from 'react'
import { useState } from 'react';
import styles from './AddSubCategory.module.css'
import PhoneInput from 'react-phone-input-2'
import 'react-phone-input-2/lib/style.css';
import { IMAGE_URL } from '../../../Config/Config';
import { Link } from 'react-router-dom';
import { FaCloudUploadAlt } from "react-icons/fa";
import { GoCheck } from "react-icons/go";
import {  upload_file_application } from '../../../Utils/method';

export const ClientList = ({DynamicFields,setAdditional_fields}) => {
    const [up_loading, setUp_loading] = useState(false);
    const [changeCount, setChangeCount] = useState(0);
    const [pdfPreview, setPdfPreview] = useState(null);
    const [selectedItems, setSelectedItems] = useState([]);
    const [view_image, setViewImage] = useState('')
    const isEmpty = (value) => {
        if (value !== '') {
            return false;
        } else {
            return true
        }
    }
    function imageHandler(i, data, type) {
        if (type === 'remove') {
            // setnextBtnValid(false)
            setAdditional_fields([...DynamicFields.map((v, j) => i === j ? { ...v, value: '', instance_image: '' } : v)])
            setPdfPreview(null)
        } else {
            setViewImage(IMAGE_URL + data.value);
        }
    }
    function isValidEmail(email) {
        // Regular expression for a basic email validation
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    }
    const handleSelect = (e, fi, oi, v, type) => {
        console.log(40,e, fi, oi, v, type)
        if (!type) {
            console.log("🚀 ~ handleSelect ~ selectedItems:", selectedItems)
            const itemIndex = selectedItems.findIndex(item => item.value === v.value);
            let ext_selectedItem = []
            if (itemIndex !== -1) {
                // Item is checked, so remove it from selectedItems
                const newSelectedItems = [...selectedItems];
                newSelectedItems.splice(itemIndex, 1);
                ext_selectedItem = [...newSelectedItems]
                setSelectedItems(newSelectedItems);
            } else {
                // Item is unchecked, so add it to selectedItems
                const selectedItem = DynamicFields[fi]?.options.find(item => item.value === v.value);
                ext_selectedItem = [...selectedItems, selectedItem];
                setSelectedItems(ext_selectedItem);
            }
            let value = []
            // let status = false
            console.log("🚀 ~ handleSelect ~ ext_selectedItem:", ext_selectedItem)
            if (ext_selectedItem.length > 0) {
                for (const ie of ext_selectedItem) {
                    console.log("🚀 ~ handleSelect ~ ie:", ie)

                    value.push(ie?.value)
                }
                // status = true
            } else {
                // status = false
            }
            // setnextBtnValid(status)
            setAdditional_fields([...DynamicFields.map((m, j) => fi === j ? { ...m, value: value } : m)])
        } else {
            let extra_value = e.target.value;
            console.log("🚀 ~ handleSelect ~ extra_value:", extra_value)
            if (oi?.value.indexOf('Other') > -1) {
                setAdditional_fields([...DynamicFields.map((m, j) => fi === j ? { ...m, options: [...m.options.map(op => op.id === v.id ? { ...op, extra_input_value: extra_value } : op)] } : m)])
            }
        }
    }
    const handleFieldsData = async (e, fi, oi, type) => {
        console.log(80,e, fi, oi, type)
        setChangeCount((v) => (v + 1));
        const value = oi.type === 'phone' ? e : e.target.value;
        if (type === 'value') {
            let status = false;
            if (!isEmpty(value)) {
                if (oi.type == 'email') {
                    if (isValidEmail(value)) {
                        status = true;
                    }
                } else {
                    status = true;
                }
            }
            // setnextBtnValid(status)
            setAdditional_fields([...DynamicFields.map((v, j) => fi === j ? { ...v, value: value } : v)])

        } else if (type === 'file') {
            const file = e.target.files[0];
            setUp_loading(true)
            let data = new FormData();
            data.append('image', file)
            let apires = await upload_file_application(data);
            if (apires.success) {
                // setnextBtnValid(true)
                if (file && file.type === 'application/pdf') {
                    const reader = new FileReader();
                    reader.onloadend = () => {
                        console.log(78, reader.result);
                        setPdfPreview(reader.result)
                        setAdditional_fields([...DynamicFields.map((v, j) => fi === j ? { ...v, value: apires.data, instance_image: reader.result } : v)])
                    };

                    reader.readAsDataURL(file);
                } else {
                    setAdditional_fields([...DynamicFields.map((v, j) => fi === j ? { ...v, value: apires.data, instance_image: URL.createObjectURL(file) } : v)])
                }
            } else {
                alert('Something wrong try again')
            }
            setUp_loading(false)
        }else if(type === 'sl'){
            setAdditional_fields([...DynamicFields.map((v, j) => fi === j ? { ...v, sl: value } : v)])
            // document.getElementById("input_sl_"+oi.id).focus();
        }
    }
    // .sort((a,b)=> a.sl - b.sl)
    return (
        <>
            {DynamicFields.map((v, i) =>
                <div className={styles.info_item} key={'info_' + i}>
                    <div className={styles.info_item_label}> <p className={styles.info_label}> {v.label} </p> </div>
                    <div className={styles.info_item_value}>
                        {/* {(v.type !== 'file' && v.type !== 'link' ?
                            (v.type == 'multiple_select' && v.value.length > 0) ? v.value.toString() : v.value
                            :
                            <Link href={v.type === 'file' ? IMAGE_URL + v.value : v.value}>{v.type === 'file' ? v.value : v.value}</Link>
                        )} */}
                        <div className={styles.input_group} style={{display:'flex'}}>
                                <input id={"input_sl_"+v.id} type="number" className={styles.inputs} placeholder={"sl"} onChange={(e) => handleFieldsData(e, i, v, 'sl')} value={v.sl} style={{width:'50px',textAlign:'center',marginRight:'4px'}}/>
                                {(v.type !== 'radio' && v.type !== 'file' && v.type !== 'select' && v.type !== 'textarea' && v.type !== 'phone' && v.type !== 'multiple_select') &&
                                    <input type={v.type} className={styles.inputs} placeholder={v.placeholder} onChange={(e) => handleFieldsData(e, i, v, 'value')} value={v.value} />
                                }
                                {v.type === 'phone' &&
                                    <PhoneInput
                                        country={'gb'}
                                        inputProps={{
                                            required: true,
                                            autoFocus: true
                                        }}
                                        inputClass={styles.phone_inputs}
                                        buttonClass={styles.phone_btn}
                                        enableSearch
                                        value={v.value}
                                        onChange={phone => handleFieldsData(phone, i, v, 'value')}
                                    />
                                }
                                {v.type === 'textarea' &&
                                    <textarea className={styles.text_area} placeholder={v.placeholder} onChange={(e) => handleFieldsData(e, i, v, 'value')} value={v.value} />
                                }
                                {(v.type === 'select' || v.type === 'radio') &&
                                    <div className={styles.multiSelect_cotainer}>
                                        {v.options.map((vi, ii) =>
                                            <label htmlFor={`${vi.id}_${ii}`} className={styles.multi_option} key={`${vi.id}_${ii}`}>
                                                <div className={styles.custom_checkbox}>
                                                    <input className={styles.input_checkbox} type="radio" name={'radiolabel'} id={`${vi.id}_${ii}`} value={vi.value} onChange={(e) => handleFieldsData(e, i, v, 'value')} checked={v.value === vi.value} />
                                                    <div className={styles.check_box_design}>
                                                        <span className={styles.check_box_check}><GoCheck /></span>
                                                        <span className={styles.check_box_uncheck}></span>
                                                    </div>
                                                    
                                                </div>
                                                <span className={styles.multi_option_label}>
                                                    <span>{vi.label}</span>
                                                    {vi.extra_input === 'yes' && <input className={styles.other_input} onChange={(e) => handleSelect(e, i, v, vi, 'extra_input')} value={vi.extra_input_value} type='text' placeholder={vi.value} autoFocus={v.value.indexOf('Other') > -1} />}
                                                </span>
                                            </label>
                                        )}
                                    </div>
                                }
                                {v.type === 'multiple_select' &&
                                    <div className={styles.multiSelect_cotainer}>
                                        {v.options.map((vi, ii) =>
                                            <label htmlFor={`${vi.id}_${ii}`} className={styles.multi_option} key={`${vi.id}_${ii}`}>
                                                <div className={styles.custom_checkbox}>
                                                    <input className={styles.input_checkbox}
                                                        checked={v.value.indexOf(vi.value) > -1}
                                                        onChange={(e) => handleSelect(e, i, v, vi)}
                                                        id={`${vi.id}_${ii}`} value={vi.value} type='checkbox'
                                                    />
                                                    <div className={styles.check_box_design}>
                                                        <span className={styles.check_box_check}><GoCheck /></span>
                                                        <span className={styles.check_box_uncheck}></span>
                                                    </div>
                                                    
                                                </div>
                                                <span className={styles.multi_option_label}>
                                                    <span>{vi.label}</span>
                                                    {vi.extra_input === 'yes' && <input className={styles.other_input} onChange={(e) => handleSelect(e, i, v, vi, 'extra_input')} value={vi.extra_input_value} type='text' placeholder={vi.value} autoFocus={v.value.indexOf('Other') > -1} />}
                                                </span>
                                            </label>
                                        )}
                                    </div>
                                }
                                {v.type === 'file' ?
                                    up_loading ?
                                        <p>Uploading...</p>
                                        :
                                        <>
                                            {pdfPreview ?
                                                <img style={{ marginBottom: '5px' }} src={''} width="100" height="100" alt="prev" />
                                                :
                                                <img style={{ marginBottom: '5px' }} onClick={() => imageHandler(i, v, 'view')} src={v.instance_image ? v.instance_image : IMAGE_URL + v.value} alt="prev" width="250" height="200" />
                                            }
                                            <input type="file" className="custom-file-input" id="validatedCustomFile" onChange={(e) => handleFieldsData(e, i, v, 'file')} accept="image/*,.pdf" hidden />
                                            {/* <label className={styles.inputs} style={{ display: 'flex', alignItems: 'center', cursor: 'pointer', padding: '0px 20px' }} htmlFor="validatedCustomFile"><FaCloudUploadAlt /> {DynamicFields[activeIndex]?.placeholder || DynamicFields[activeIndex]?.label}</label> */}
                                        </>
                                    : null
                                }
                                

                        </div>
                    </div>
                </div>
            )}
        </>

    )
}