/* eslint-disable react-hooks/exhaustive-deps */
import classNames from "classnames";
import React, { useEffect,useRef } from "react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styles from "../../../assets/css/AddNewItem.module.css";
import { add_item, get_brand, get_domains, get_product_list, get_supplier, getStoreData, update_item_image } from '../../../Utils/method'
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // import the styles
import ukbd from '../../../assets/UKBD.png';
import { Editor } from '@tinymce/tinymce-react';

const selectStyles = {
    control: (base) => ({
        ...base,
        fontSize: '14px',
        boxShadow: 'none',
        // '&:focus': {
        //     border: '0 !important',
        // },
        background: '#fff',
        minHeight: '18px',
        border: '1px solid #ced4da',
        borderRadius: '2px',
    }),
    container: (baseStyles) => ({
        ...baseStyles,
        width: '100%',
    }),
    placeholder: (baseStyles, state) => ({
        ...baseStyles,
        color: 'rgba(0,48,25,.1)',
    }),
    options: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        padding: '1px 15px',
        width: '100%',
        borderBottom: '1px solid rgba(0,48,25,1)'
    }),
    menu: (baseStyles) => ({
        ...baseStyles,
        borderRadius: 0,
        height: 'auto',
        width: '100%',
        right: '0px',
        top: '13px',
    })
    ,
    multiValue: (base) => ({
        ...base,
        background: '#ced4da',
        borderRadius: '0px',
        marginTop: '0px',
        fontWeight: '400',
        lineHeight: '18px',
        position: 'relative',
        top: '-2px',
        left: '-10px'
    })
}

function AddNewBrandItem() {
    let navigate = useNavigate();
    const editorRef = useRef(null);
    const store_data = getStoreData({ type: 'get' });
    const [selectedImage, setSelectedImage] = useState([]);
    const [loader, setLoader] = useState();

    const [brands, setBrands] = useState([]);
    const [brands_all, setBrands_all] = useState([]);
    const [selectedBrand, setSelectedBrand] = useState(null);
    const [suppliers, setSuppliers] = useState([]);
    const [suppliers_all, setSuppliers_all] = useState([]);
    const [all_product_list, setAll_Product_list] = useState([]);
    const [product_list, setProduct_list] = useState([]);

    const [selectedProduct, setSelectedProduct] = useState(null);
    const [selectedSupplier, setSelectedSupplier] = useState(null);
    const [uploadableImage, setUploadableImage] = useState([]);
    const [domain_list, setDomain_list] = useState([]);
    const [selected_domain, setselected_domain] = useState(null)
    const currentYear = new Date().getFullYear();
    const [selectedYear, setSelectedYear] = useState(currentYear);

    const years = Array.from({ length: 10 }, (_, index) => {
        const year = currentYear - index;
        return { label: year, value: year };
    });

    const [activeTab, setActiveTab] = useState(1)
    const selectTab = (e) => {
        setActiveTab(e);
    }

    const [form_data, set_form_data] = useState({
        name: '',
        price: '',
        images: [],
        short_details: '',
        details: '',
        input_price: 0,
        product_sequence_number: '',
        note_for_item: '',
        is_item_in_stock: true,
        is_most_popular: false,
        is_visible_in_store: true,
        delivery_time_static: '',
        source_link: '',
        supplier_name: '',
        supplier_icon: '',
        supplier_id: '',
        brand_name: '',
        brand_icon: '',
        brand_id: '',
        product_id: '',
        domain_ids: [],
        input_price_multi: 1,
        input_price_plus: 0,
        // model_year: '',
        // case_size: '',
        // bracelet_material: '',
        // dial_color: '',
        // movement: '',
        // have_box: '',
        // have_papers: '',
        // item_weight: '',
        // offer_percentage: 'NONE',
        // custom_tag1: '',
        // custom_tag2: '',
        // handling_charge_in_the_uk: 17.5,
        // shipping_duties: 7.25,
    })

    const reset_form = () => {
        set_form_data({
            ...form_data,
            name: '',
            price: '',
            images: [],
            short_details: '',
            details: '',
            input_price: 0,
            product_sequence_number: '',
            note_for_item: '',
            is_item_in_stock: true,
            is_most_popular: false,
            is_visible_in_store: true,
            delivery_time_static: '',
            source_link: '',
            supplier_name: '',
            supplier_icon: '',
            supplier_id: '',
            product_id: '',
            domain_ids: [],
            input_price_multi: 1,
            input_price_plus: 0,
        })
        setSelectedProduct(null)
        setSelectedImage([])
        setUploadableImage([])
    }

    const setNoteContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, note_for_item: e });
    }

    const setShortDetailsContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, short_details: e });
    }

    const setDetailsContent = (e) => {
        console.log(183, e);
        set_form_data({ ...form_data, details: e });
    }

    const handleOnChange = (e, ext_name) => {
        if (ext_name) {
            console.log(e, ext_name);
            if (ext_name === 'product_id') {
                let data = all_product_list.filter(i => i._id === e.value)
                console.log(411, data);
                set_form_data({
                    ...form_data,
                    [ext_name]: e.value,
                    product_sequence_number: data[0].sequence_number,
                });
                setSelectedProduct(e)
            } else if (ext_name === 'supplier_id') {
                let data = suppliers_all.filter(i => i._id === e.value)
                console.log(data[0]);
                console.log(data[0].image);
                set_form_data({
                    ...form_data,
                    'supplier_id': e.value,
                    supplier_name: data[0].name,
                    supplier_icon: data[0].image
                });
                setSelectedSupplier(e)
            } else if (ext_name === 'brand_id') {
                let data = brands_all.filter(i => i._id === e.value)
                set_form_data({
                    ...form_data,
                    'brand_id': e.value,
                    additional_fields: data[0].additional_fields,
                    brand_name: data[0].name,
                    brand_icon: data[0].image
                });
                setSelectedBrand(e)
            } else if (ext_name === 'custom1' || ext_name === 'custom2') {
                const { name } = e.target;
                set_form_data({ ...form_data, [name]: ext_name });
            } else {
                if (ext_name === 'model_year') {
                    setSelectedYear(e)
                }
                set_form_data({ ...form_data, [ext_name]: e.value });
            }
        } else {
            const { name, value } = e.target;
            // console.log(name, value);
            if (name === 'is_item_in_stock' || name === 'is_visible_in_store' || name === 'is_most_popular') {
                set_form_data({ ...form_data, [name]: e.target.checked });
                // } else if ((name === 'offer_percentage') && ext_name !== undefined) {
                //   set_form_data({ ...form_data, [name]: ext_name });
            } else if (name === 'images') {

            } else if (name === 'supplier_id') {
                let data = suppliers.filter(i => i._id === value)
                set_form_data({
                    ...form_data,
                    [name]: value,
                    supplier_name: data[0].name,
                    supplier_icon: data[0].image
                });
            } else if (name === 'brand_id') {
                let data = brands.filter(i => i._id === value)
                set_form_data({
                    ...form_data,
                    [name]: value,
                    brand_name: data[0].name,
                    brand_icon: data[0].image
                });
            } else if (name === 'store_id') {
                set_form_data({
                    ...form_data,
                    [name]: value,
                });
            } else {
                set_form_data({ ...form_data, [name]: value });
            }
        }
    }

    const handleAdditionalFields = (e) => {
        const { name, value } = e.target;
        console.log(name, value);
        set_form_data({ ...form_data, additional_fields: [...form_data.additional_fields.map(m => m.name === name ? { ...m, value: value } : m)] });
    }

    useEffect(() => {
        console.log(272, form_data);
    }, [form_data])


    // useEffect(() => {
    //     let price = Number(form_data.input_price) * Number(form_data.input_price_multi) + Number(form_data.input_price_plus);
    //     set_form_data({ ...form_data, price: price });
    // }, [form_data.input_price, form_data.input_price_multi, form_data.input_price_plus])

    const update_image = async (item) => {
        try {
            const data = new FormData();
            if (uploadableImage.length > 0) {
                for (let i = 0; i < uploadableImage.length; i++) {
                    data.append("image_url", uploadableImage[i]);
                }
            }

            // data.append('image_url[0]', uploadableImage[0]);
            data.append('item_id', item._id);
            data.append('store_id', store_data.store._id);
            data.append('servertoken', store_data.store.server_token);
            data.append('timezone', store_data.timezone);

            let apires = await update_item_image(data);
            console.log(527, apires);
            if (apires.data.success) {
                toast.success('Item Added successfully', {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setSelectedImage([])
                setUploadableImage([])
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.log(error);
        }
    }

    const saveNewItem = async () => {

        if (form_data.name.trim() === '') {
            toast.error('Please enter Item Name', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return
        }
        else if (form_data.product_id === '') {
            toast.error('Please select Sub-category', {
                position: 'top-right',
                autoClose: 5000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true
            });
            return
        }

        setLoader(true)
        const data = new FormData();
        for (let i = 0; i < form_data.domain_ids.length; i++) {
            data.append('domain_ids[' + i + ']', form_data.domain_ids[i]);
        }
        // data.append('item_weight', form_data.item_weight);
        // data.append('offer_percentage', form_data.offer_percentage);
        // data.append('custom_tag1', form_data.custom_tag1);
        // data.append('custom_tag2', form_data.custom_tag2);
        data.append('name[0]', form_data.name);
        data.append('short_details[0]', form_data.short_details);
        data.append('details[0]', editorRef.current.getContent());
        // data.append('details[0]', form_data.details);
        data.append('input_price', form_data.input_price);
        data.append('input_price_multi', form_data.input_price_multi);
        data.append('input_price_plus', form_data.input_price_plus);
        data.append('product_sequence_number', form_data.product_sequence_number);
        data.append('note_for_item', form_data.note_for_item);
        data.append('is_item_in_stock', form_data.is_item_in_stock);
        data.append('is_most_popular', form_data.is_most_popular);
        data.append('is_visible_in_store', form_data.is_visible_in_store);
        data.append('delivery_time_static', form_data.delivery_time_static);
        data.append('source_link', form_data.source_link);
        data.append('supplier_name', form_data.supplier_name);
        data.append('supplier_icon', form_data.supplier_icon);
        data.append('supplier_id', form_data.supplier_id);
        data.append('brand_name', form_data.brand_name);
        data.append('brand_icon', form_data.brand_icon);
        data.append('brand_id', form_data.brand_id);
        data.append('product_id', form_data.product_id);
        data.append('store_id', store_data.store._id);
        data.append('server_token', store_data.store.server_token);
        data.append('timezone', store_data.timezone);
        data.append('additional_fields', JSON.stringify(form_data.additional_fields));
        try {
            let apires = await add_item(data);
            console.log(126, apires)
            if (apires.success) {
                update_image(apires.item)
                reset_form();
            } else {
                toast.error(apires.status_phrase, {
                    position: 'top-right',
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true
                });
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            console.log(error)
        }
    }

    const getProduct_list = async () => {
        try {
            let apires = await get_product_list({
                store_id: store_data.store._id,
                server_token: store_data.store.server_token,
            });

            console.log('get_product_list:', apires);

            if (apires.success) {
                let data = []
                let sort_v = apires.products.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
                for (const p of sort_v) {
                    data.push({ value: p._id, label: p.name[0] })
                }
                setProduct_list(data)
                setAll_Product_list(sort_v);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getSuppliers = async () => {
        try {
            let apires = await get_supplier(`?perPage=${2000}`);
            console.log('get_supplier:', apires);
            if (apires.data.suppliers.length > 0) {
                let data = []
                let sort_v = apires.data.suppliers.sort((a, b) => a.name.localeCompare(b.name));
                for (const o of sort_v) {
                    data.push({ value: o._id, label: o.name })
                }
                setSuppliers([...data])
                setSuppliers_all(sort_v)
            }
        } catch (error) {
            console.log(error)
        }
    }

    const getBrands = async () => {
        try {
            let apires = await get_brand(`?perPage=${2000}`);
            console.log('get_brand:', apires);
            if (apires.data.brands.length > 0) {
                let data = []
                let sort_v = apires.data.brands.sort((a, b) => a.name.localeCompare(b.name));
                for (const o of sort_v) {
                    data.push({ value: o._id, label: o.name, additional_fields: o.additional_fields })
                }
                console.log(455, data);
                set_form_data({
                    ...form_data,
                    brand_id: data[0].value,
                    'additional_fields': data[0].additional_fields,
                    brand_name: sort_v[0].name,
                    brand_icon: sort_v[0].image
                });
                // setSelectedBrand(data[0])
                setSelectedBrand(null)
                setBrands([...data])
                setBrands_all(sort_v);
            }
        } catch (error) {
            console.log(error)
        }
    }

    const get_domain_list = async () => {
        try {
            let apires = await get_domains();
            let data = []
            let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
            for (const p of sort_v) {
                data.push({ value: p._id, label: p.name })
            }
            console.log('get_domains:', data);
            setDomain_list(data)
        } catch (error) {
            console.log();
        }
    }

    const handleDomain = (d) => {

        // let data = []
        // for (const iterator of d) {
        //     console.log(iterator.value);
        //     data.push(iterator.value)
        // }
        // set_form_data({ ...form_data, domain_ids: data })

        setselected_domain(d)
        set_form_data({ ...form_data, domain_ids: [d.value] })
    }

    useEffect(() => {
        if (selected_domain) {
            let data = []
            for (const p of all_product_list) {
                if (p.domain_ids.indexOf(selected_domain.value) > -1) {
                    data.push({ value: p._id, label: p.name[0], domain_ids: p.domain_ids })
                }
            }
            setProduct_list(data)
            setSelectedProduct(null)

            let brand_data = []
            for (const o of brands_all) {
                if (o.domain_ids.indexOf(selected_domain.value) > -1) {
                    brand_data.push({ value: o._id, label: o.name, additional_fields: o.additional_fields })
                }
            }
            setBrands(brand_data);
            setSelectedBrand(null)
        }

    }, [selected_domain])

    useEffect(() => {
        getProduct_list()
        getSuppliers()
        getBrands()
        get_domain_list()
    }, [])

    function handleImageChange(e) {
        setUploadableImage([...uploadableImage, e.target.files[0]]);
        set_form_data({ ...form_data, images: [...form_data.images, e.target.files[0]] });
        setSelectedImage([...selectedImage, URL.createObjectURL(e.target.files[0])]);
        // props.setCurentImage(URL.createObjectURL(event.target.files[0]));
        // props.setCropPopup(true);
    }

    function handleDrop(e) {
        e.preventDefault();
        const file = e.dataTransfer.files[0];
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => {
            // setImage(reader.result);
            // console.log(121, reader.result)
            // setCurentImage(reader.result);
            setSelectedImage([...selectedImage, reader.result]);
            setUploadableImage([...uploadableImage, file]);
            set_form_data({ ...form_data, images: [...form_data.images, file] });
        };
        // props.setCropPopup(true);
    }

    function handleDragOver(event) {
        event.preventDefault();
    }

    function handleDragLeave(event) {
        event.preventDefault();
    }

    const handlePaste = (e) => {
        const items = e.clipboardData.items;
        for (let i = 0; i < items.length; i++) {
            const item = items[i];
            if (item.kind === 'file' && item.type.includes('image')) {
                const blob = item.getAsFile();
                const reader = new FileReader();
                reader.onloadend = () => {
                    setSelectedImage([...selectedImage, reader.result]);
                    setUploadableImage([...uploadableImage, blob]);
                    set_form_data({ ...form_data, images: [...form_data.images, blob] });
                };
                reader.readAsDataURL(blob);
                break;
            }
        }
    };

    // function handlePaste(event) {
    //   const clipboardData = event.clipboardData;
    //   const file = clipboardData.items[0].getAsFile();
    //   const reader = new FileReader();
    //   reader.readAsDataURL(file);
    //   reader.onload = () => {
    //     setSelectedImage([...selectedImage, reader.result]);
    //     // setCurentImage(reader.result);
    //     // setUploadableImage([...uploadableImage, file]);
    //     set_form_data({ ...form_data, images: [...form_data.images, file] });
    //   };
    //   // props.setCropPopup(true);
    // }

    const onBlurWeight = (e) => {
        const { name, value } = e.target;
        set_form_data({ ...form_data, [name]: Number(value).toFixed(3) });
    }

    const onBlurPrice = (e) => {
        const { name, value } = e.target;
        set_form_data({ ...form_data, [name]: Number(value).toFixed(2) });
    }

    return (
        <div className={styles.addItemPopup}>
            <ToastContainer />
            <div className="row mt-3">
                <div className="col-md-12 text-center">
                    <h4 className={styles.itemDes}>
                        <span className={styles.pop_logo}><img src={ukbd} alt="" /> </span> <span className={styles.pop_title}>Buyrealbrands {'>'} Partner {'>'} Add New Brand Item</span>
                        <span className={styles.itemDesIcon} onClick={() => navigate(-1)}><i className="fa-thin fa-xmark"></i></span>
                    </h4>
                </div>
                <div className="col-md-12">
                    <div className={styles.tabContainer}>
                        <div className={styles.tabHead}>
                            <div className={classNames(styles.tabs, activeTab === 1 ? styles.active : '')} onClick={() => selectTab(1)}>Details</div>
                            <div className={classNames(styles.tabs, activeTab === 2 ? styles.active : '')} onClick={() => selectTab(2)}>Description</div>
                            <div className={classNames(styles.tabs, activeTab === 3 ? styles.active : '')} onClick={() => selectTab(3)}>Images</div>
                            <div className={classNames(styles.tabs, activeTab === 4 ? styles.active : '')} onClick={() => selectTab(4)}>Others</div>
                        </div>
                        <div className={styles.tabBody}>
                            {activeTab === 1 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}>
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)} style={{ minHeight: '68px', height: 'unset' }}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Domain <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            // isMulti
                                                            name="domain"
                                                            value={selected_domain}
                                                            options={domain_list}
                                                            className="basic-multi-select"
                                                            classNamePrefix="select"
                                                            styles={selectStyles}
                                                            onChange={handleDomain}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Brand</label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            value={selectedBrand}
                                                            onChange={(e) => handleOnChange(e, 'brand_id')}
                                                            options={brands}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Sub-category <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            value={selectedProduct}
                                                            onChange={(e) => handleOnChange(e, 'product_id')}
                                                            options={product_list}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Item Name <span style={{ color: '#ff0000' }}>*</span></label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.name} name="name" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Item Name" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Supplier</label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            defaultValue={selectedSupplier}
                                                            onChange={(e) => handleOnChange(e, 'supplier_id')}
                                                            options={suppliers}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Price</label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={form_data.input_price} min={0} name="input_price" onChange={(e) => handleOnChange(e)} onBlur={onBlurPrice} className="form-control rounded-0 bs_input_f" />
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        {/* 





 */}
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                {form_data.additional_fields && form_data.additional_fields.map((v, i) =>
                                                    <div className={classNames("form-group", styles.item_group)} key={'fi_' + i}>
                                                        <label className={classNames("form-label mb-0", styles.form_label)}>{v.label}</label>
                                                        <div className="input-group mt-0">
                                                            {v.type === 'select' ?
                                                                <select className="form-control rounded-0 bs_input_f" name={v.name} value={v.value} onChange={handleAdditionalFields}>
                                                                    <option value={''} disabled>Select</option>
                                                                    {v.options.map((o, oi) =>
                                                                        <option value={o.value} key={'op_' + oi}>{o.label}</option>
                                                                    )}
                                                                </select>
                                                                :
                                                                <input type={v.type} value={v.value} name={v.name} onChange={handleAdditionalFields} className="form-control rounded-0 bs_input_f" placeholder={v.label} />
                                                            }
                                                        </div>
                                                    </div>
                                                )}
                                                {/* <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Model Year</label>
                                                    <div className="input-group mt-0">
                                                        <Select
                                                            className={classNames(styles.customSelect_item, 'form-control rounded-0 bs_input_f')}
                                                            defaultValue={selectedYear}
                                                            onChange={(e) => handleOnChange(e, 'model_year')}
                                                            options={years}
                                                        />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Case Size (mm)</label>
                                                    <div className="input-group mt-0">
                                                        <input type="number" value={form_data.case_size} min={0} name="case_size" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Case Size" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Bracelet Material</label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.bracelet_material} name="bracelet_material" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Bracelet Material" />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Dial Color</label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" value={form_data.dial_color} name="dial_color" onChange={(e) => handleOnChange(e)} className="form-control rounded-0 bs_input_f" placeholder="Dial Color" />
                                                    </div>
                                                </div> */}

                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                {/* <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Movement</label>
                                                    <div className="input-group">
                                                        <select className={classNames("form-control rounded-0", styles.input_item)} value={form_data.movement} name="movement" onChange={(e) => handleOnChange(e)}>
                                                            <option value={''} disabled>Select</option>
                                                            <option value={'Automatic'}>Automatic</option>
                                                            <option value={'Manual'}>Manual</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Have Box</label>
                                                    <div className="input-group mt-0">
                                                        <select className={classNames("form-control rounded-0", styles.input_item)} value={form_data.have_box} name="have_box" onChange={(e) => handleOnChange(e)}>
                                                            <option value={''} disabled>Select</option>
                                                            <option value={'Yes'}>Yes</option>
                                                            <option value={'No'}>No</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Have Papers</label>
                                                    <div className="input-group mt-0">
                                                        <select className={classNames("form-control rounded-0", styles.input_item)} value={form_data.have_papers} name="have_papers" onChange={(e) => handleOnChange(e)}>
                                                            <option value={''} disabled>Select</option>
                                                            <option value={'Yes'}>Yes</option>
                                                            <option value={'No'}>No</option>
                                                        </select>
                                                    </div>
                                                </div> */}
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={form_data.is_visible_in_store} name="is_visible_in_store" onChange={(e) => handleOnChange(e)} id="is_visible" />
                                                        <label className="form-check-label small" htmlFor="is_visible">Visible</label>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={form_data.is_item_in_stock} name="is_item_in_stock" onChange={(e) => handleOnChange(e)} id="in_stock" />
                                                        <label className="form-check-label small" htmlFor="in_stock"> In-Stock</label>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    {/* <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={form_data.is_most_popular} name="is_most_popular" onChange={(e) => handleOnChange(e)} id="is_popular" />
                                                        <label className="form-check-label small" htmlFor="is_popular" >Popular</label>
                                                    </div> */}
                                                </div>
                                            </div>
                                        </div>
                                        <div className={classNames("col-md-3", styles.form_column)}>
                                            <div className={styles.columnItemContainer}>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>
                                                    <div className="form-check form-switch">
                                                        <input type="checkbox" className="form-check-input" checked={form_data.is_most_popular} name="is_most_popular" onChange={(e) => handleOnChange(e)} id="is_popular" />
                                                        <label className="form-check-label small" htmlFor="is_popular" >Popular</label>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Source Link</label>
                                                    <div className="input-group mt-0">
                                                        <input type="text" className="form-control rounded-0 bs_input_f" value={form_data.source_link} name="source_link" onChange={(e) => handleOnChange(e)} />
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}>
                                                    <label className={classNames("form-label mb-0", styles.form_label)}>Delivery Time</label>
                                                    <div className="input-group mt-0">
                                                        <select className={classNames("form-control rounded-0", styles.input_item)} value={form_data.delivery_time_static} name="delivery_time_static" onChange={(e) => handleOnChange(e)}>
                                                            <option value={''} disabled>Select Delivery Time</option>
                                                            <option value={'2 to 3 days'}>2 to 3 days</option>
                                                            <option value={'7 to 10 days'}>7 to 10 days</option>
                                                            <option value={'15 days. Subject to BD Customs'}>15 days. Subject to BD Customs</option>
                                                            <option value={'Same Day'}>Same Day</option>
                                                        </select>
                                                    </div>
                                                </div>
                                                <div className={classNames("form-group", styles.item_group)} style={{ display: 'flex', alignItems: 'center' }}>

                                                </div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                                <div className={classNames("form-group", styles.item_group)}></div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 2 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}>
                                    <div className={classNames(styles.form_container)}>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Short Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className="shot_desc" value={form_data.short_details} placeholder="Short Description" onChange={(e) => setShortDetailsContent(e)} />
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)} style={{ width: '50%' }}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Full Description</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <Editor
                                                    apiKey='1qt2ojkjlqziack23hn23paxrz8jx6z04cgvx9pw7gf7t8qw'
                                                    onInit={(evt, editor) => editorRef.current = editor}
                                                    init={{
                                                        height: 500,
                                                        width: '100%',
                                                        menubar: true,
                                                        plugins: 'anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount',
                                                        toolbar: 'undo redo | blocks fontfamily fontsize | bold italic underline strikethrough | link image media table | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat',
                                                    }}
                                                    initialValue={form_data.details}
                                                />
                                                {/* <ReactQuill className="full_desc" value={form_data.details} placeholder="Details" onChange={(e) => setDetailsContent(e)} /> */}
                                            </div>
                                        </div>
                                        <div className={classNames(styles.form_column)}>
                                            <label className={classNames("form-label mb-0", styles.form_label)}>Note</label>
                                            <div className={styles.columnItemContainer} style={{ height: 'calc(100% - 24px)', minHeight: 'calc(100% - 25px)', backgroundColor: '#fff', border: 'none' }}>
                                                <ReactQuill className="full_note" value={form_data.note_for_item} placeholder="Write a note ..." onChange={(e) => setNoteContent(e)} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 3 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)', position: 'relative' }}>
                                    <div className={classNames(styles.form_container)} style={{ padding: '0px 24px', height: 'calc(100% - 30px)' }}>
                                        <div className={styles.imageDropDownContainer}
                                            onPaste={handlePaste}
                                            onDrop={handleDrop}
                                            onDragOver={handleDragOver}
                                            onDragLeave={handleDragLeave} >
                                            {selectedImage.length > 0 ?
                                                selectedImage.map((v, i) =>
                                                    <div className={styles.uploadedImage} key={i + '_img'}>
                                                        <div className={styles.uploadedInner}>
                                                            <img src={v} alt="Preview" />
                                                        </div>
                                                    </div>
                                                )
                                                : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            }
                            {activeTab === 4 &&
                                <div className="tab-pane fade show active" style={{ height: 'calc(100% - 54px)' }}>
                                    <div className="row pb-0">
                                        {/* <div className="col-md-12">
                      <textarea cols="30" rows="auto" className="form-control rounded-0" style={{ minHeight: '120px' }} value={form_data.note_for_item} name="note_for_item" onChange={(e) => handleOnChange(e)} placeholder="Write a note ..."></textarea>
                    </div> */}
                                    </div>
                                </div>
                            }

                            <div className={styles.tab_footer_section}>
                                <button className={styles.leftArrow} style={activeTab === 1 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 1 ? prev - 1 : prev)}><i className="fa-thin fa-chevron-left"></i></button>
                                <div className={styles.footerMiddleSection}>
                                    {activeTab === 3 &&
                                        <label className={styles.uploaderDiv} htmlFor={'imageUpload'}>
                                            <input type={'file'} name={'imageUpload'} id={'imageUpload'} onChange={handleImageChange} accept="image/*" hidden />
                                            <span className={styles.addIcon}>
                                                <i className="fa-thin fa-circle-plus"></i>
                                            </span>
                                            <span className={styles.addLabel}>Add Image</span>
                                        </label>
                                    }
                                    <button className={styles.save_btn} onClick={reset_form} style={{ marginRight: 15 }}>Reset</button>
                                    <button className={styles.save_btn} onClick={saveNewItem} style={loader ? { backgroundColor: '#012965', color: '#fff', pointerEvents: 'none' } : {}}>{loader ? 'Saving...' : 'Save Item'}</button>
                                </div>
                                <button className={styles.RightArrow} style={activeTab === 4 ? { opacity: '0.5' } : {}} onClick={() => setActiveTab(prev => prev !== 4 ? prev + 1 : prev)}><i className="fa-thin fa-chevron-right"></i></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default AddNewBrandItem;
