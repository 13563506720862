/* eslint-disable no-unreachable */
import { getStoreData, removeUserData } from "../../Utils/method"
const initialState = {
    user_data: getStoreData({ type: 'get' }),
    side_bar_loader: true,
    currency_sign: '',
    cart_total_weight: 0,
    cart_total_parcentage: 0,
    currency_data: {
        payment_currency: '',
        view_price_currency: '',
        exchange_rate_website: '',
    },
    currencyChangeCounter: 0,
    deliveryWarning: {
        warning: false,
        delivery_id: ''
    },
    signupWarning: {
        warning: false,
        data: {}
    },
    toast_data: {
        visibility: false,
        status: '',
        msg: '',
    },
    full_page_loader: true,
    home_loader: true,
    right_side_loader: false,
    floatOptions: false,
    timelineStage: 1,
    active_item: 'popular',
    stores: [],
    product_groups: [],
    website_setting: {
        special_offer_html: '100% authentic brands at doorsteps direct from London Special offer FREE delivery',
        footer_text: '',
        facebook_link: '',
        instagram_link: '',
        linked_in_link: '',
        twitter_link: '',
        youtube_link: '',
        android_app_link: '',
        ios_app_link: '',
        store_registration_url: '',
        about_us: '',
        faq: '',
        contact_us: '',
        jobs: '',
        cover_image_1: '',
        cover_image_2: '',
        cover_image_3: '',
        cover_text_1: '',
        cover_text_2: '',
        cover_text_3: '',

    },
    allfavourite_items: [],
    favourite_items: [],
    ordersList: [],
    lastOrder: [],
    selectedDLAddress: {},
    items: [],
    delivery: [],
    city: {},
    country: {},
    active_product: "",
    active_product_data: {},
    active_main_cate: {},
    user_privacy: '',
    user_terms: '',
    payment_mode_type: 'card',
    paginate_data: {
        hasNextPage: false,
        hasPrevPage: false,
        totalDocs: 0,
        limit: 0,
        totalPages: 0,
        page: 0,
        nextPage: null,
        prevPage: null,
        pagingCounter: 0,
    },
    login_modal: {
        signin: false,
        signup: false,
        from: ''
    },
    item_preview_view: {
        status: false,
        data: {}
    }
}

function getActiveProductData(products, active) {
    let d = [];
    for (let i of products) {
        let p = i.products;
        let q = [];
        for (let t of p) {
            q.push({ ...t, main_cate: i });
        }
        d = [...d, ...q];
    }
    for (let i of d) {
        if (i._id === active) {
            console.log(i)
            return i;
        }
    }

}

function rootReducer(state = initialState, action) {
    switch (action.type) {
        case 'set_user_data': {
            return {
                ...state,
                user_data: action.payload
            };

        }
        case 'set_item_preview_view': {
            return {
                ...state,
                item_preview_view: {
                    status: action.payload.status,
                    data: action.payload.data
                }
            };

        }
        case 'set_login_modal': {
            return {
                ...state,
                login_modal: { ...action.payload }
            };

        }
        case 'set_user_privacy': {
            return {
                ...state,
                user_privacy: action.payload
            };

        }
        case 'set_user_terms': {
            return {
                ...state,
                user_terms: action.payload
            };

        }
        case 'set_payment_mode_type': {
            return {
                ...state,
                payment_mode_type: action.payload
            };

        }
        case 'set_allfavourite_items': { return { ...state, allfavourite_items: action.payload } }
        case 'set_favourite_items': { return { ...state, favourite_items: action.payload } }
        case 'set_ordersList': { return { ...state, ordersList: action.payload } }
        case 'set_lastOrder': { return { ...state, lastOrder: action.payload } }
        case 'set_selectedDLAddress': { return { ...state, selectedDLAddress: action.payload } }
        case 'set_currency_sign': { return { ...state, currency_sign: action.payload } }
        case 'set_cart_total_weight': { return { ...state, cart_total_weight: action.payload } }
        case 'set_cart_total_parcentage': { return { ...state, cart_total_parcentage: action.payload } }
        case 'set_home_loader': { return { ...state, home_loader: action.payload } }
        case 'set_right_side_loader': { return { ...state, right_side_loader: action.payload } }
        case 'set_floatOptions': { return { ...state, floatOptions: action.payload } }
        case 'set_timelineStage': { return { ...state, timelineStage: action.payload } }
        case 'set_currencyChangeCounter': { return { ...state, currencyChangeCounter: action.payload } }
        case 'set_signupWarning': { return { ...state, signupWarning: action.payload } }
        case 'set_toast_data': { return { ...state, toast_data: action.payload } }
        case 'set_deliveryWarning': { return { ...state, deliveryWarning: action.payload } }
        case 'set_currency_data': {
            return {
                ...state,
                currency_data: action.payload
            }
        }
        case 'set_home_data': {
            return {
                ...state,
                paginate_data: {
                    ...state.paginate_data,
                    ...action.payload.paginate_data,
                },
                stores: action.payload.stores,
                product_groups: action.payload.product_groups,
                website_setting: action.payload.website_setting,
                items: action.payload.items,
                delivery: action.payload.delivery,
                city: action.payload.city,
                country: action.payload.country,
                active_product: action.payload.active_item,
                active_product_data: {},
                active_main_cate: {},
                active_item: action.payload.active_item,
                home_loader: false,
                full_page_loader: false,
            };

        }
        case 'set_product_data': {
            console.log(action.payload.active_product)
            return {
                ...state,
                paginate_data: {
                    ...state.paginate_data,
                    ...action.payload.paginate_data,
                },
                active_product: action.payload.active_product,
                active_product_data: getActiveProductData(state.product_groups, action.payload.active_product),
                active_main_cate: action.payload.active_product === 'popular' ? {} : getActiveProductData(state.product_groups, action.payload.active_product).main_cate,
                items: action.payload.items,
                active_item: action.payload.active_item,
                home_loader: false,
                full_page_loader: false
            };

        }
        case 'add_items': {
            return {
                ...state,
                paginate_data: {
                    ...state.paginate_data,
                    ...action.payload.paginate_data,
                },
                items: [...state.items, ...action.payload.items]
            };

        }
        default: { return { ...state } }
    }
}

export default rootReducer;