import React, { useState, useEffect } from "react";
import styles from "../../../../assets/css/PastOrder.module.css";
import { complete_order, getStoreData, list_orders, payment_gateway_list, set_order_status } from "../../../../Utils/method";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Select from 'react-select';
import OrderDetails from "./Order_details";
import classNames from "classnames";

function PastOrder() {
  const store_data = getStoreData({ type: 'get' });
  const [order_list, setorder_list] = useState([])
  const [loader, setLoader] = useState(true)
  const [orderDetails, setOrderDetails] = useState(false)
  let query = {}
  const searchOptions = [
    { value: 'user', label: 'Search By : User' },
    { value: 'order', label: 'Search By : Order' },
  ]

  const deliveryOptions = [
    { value: 'delivery', label: 'Delivery', name: 'delivery', checked: true },
    { value: 'pickup', label: 'Pick Up', name: 'pickup', checked: true },
    { value: 'schedule', label: 'Schedule', name: 'schedule', checked: true },
    { value: 'now', label: 'Now', name: 'now', checked: true },
  ]

  const [paymentOptions, setPaymentOptions] = useState([
    { value: 'cash', label: 'Cash', id: 'cash', name: 'cash', checked: true }
  ])

  const [filter_data, setFilter_data] = useState({
    search_by: 'user',
    search_value: '',
    deliveryTypes: [],
    paymentBy: []
  })

  const onSearchApply = () => {
    var delivery_type_query = filter_data.deliveryTypes.filter(_t => _t.checked);
    // var order_type_query = filter_data.orderTypes.filter(_t => _t.checked);

    // query['delivery_types'] = delivery_type_query.concat(order_type_query);
    query['delivery_types'] = delivery_type_query;
    if (query['delivery_types']) {
      query['delivery_types'].forEach(v => {
        v.name = (v.name).toLowerCase()
      });
    }
    query['payment_by'] = filter_data.paymentBy.filter(_t => _t.checked);

    if (filter_data.search_by && filter_data.search_value !== '') {
      query['search_by'] = filter_data.search_by;
      query['search_value'] = filter_data.search_value;
    } else {
      // query['search_by'] = undefined;
      // query['search_value'] = undefined;
    }
    console.log(89, query);
    initial(query);
  }

  const set_search_by = (e) => {
    setFilter_data({ ...filter_data, search_by: e.value })
  }

  const set_delivery_type = (e) => {
    setFilter_data({ ...filter_data, deliveryTypes: [...e] })
  }

  const set_payment_by = (e) => {
    setFilter_data({ ...filter_data, paymentBy: [...e] })
  }

  const initial = async (query = {}) => {
    setLoader(true)
    try {
      var previousDate = new Date(0).toUTCString();
      var yesterDayDate = new Date(new Date().setDate(new Date().getDate() - 1)).toUTCString()
      let d = {
        start_date: previousDate,
        end_date: yesterDayDate,
        page: 1,
        perPage: 10,
        query: query,
        store_id: store_data.store._id,
        server_token: store_data.store.server_token,
        timezone: store_data.timezone
      }

      let apires = await list_orders(d);
      if (apires.success) {
        setorder_list(apires.data.results)
      } else {
        setorder_list([])
        toast.error(apires.status_phrase, {
          position: 'top-right',
          autoClose: 4000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
      console.log(15, apires);
      setLoader(false)
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  useEffect(() => {
    let _isMounted = true;
    async function gatwayList() {
      try {
        let apires = await payment_gateway_list();
        if (_isMounted) {
          if (apires.success) {
            let data = []
            apires.payment_gateway.forEach(v => {
              data.push({ id: v._id, checked: true, name: v.name || 'Other', value: v.name, label: v.name.charAt(0).toUpperCase() + v.name.slice(1) })
            });
            setPaymentOptions([...paymentOptions, ...data])
          }
        }
      } catch (error) {
        console.log(error);
      }
    }
    gatwayList()
    if (_isMounted) {
      initial()
    }
    return () => {
      _isMounted = false
    }
  }, [])

  const selectStyles = {
    control: (base) => ({
      ...base,
      fontSize: '14px',
      boxShadow: 'none',
      '&:focus': {
        border: '0 !important',
      },
      border: 'none',
      background: 'transparent',
      minHeight: '18px'
    }),
    multiValue: (base) => ({
      ...base,
      background: '#ced4da',
      borderRadius: '0px',
      marginTop: '0px',
      fontWeight: '400',
      lineHeight: '18px',
      position: 'relative',
      top: '-2px',
      left: '-10px'
    }),
    ValueContainer: (base) => ({

    })
  }

  const onChangeStatus = async (order_id, order_status) => {
    setLoader(true)
    try {
      let data = {
        id: store_data.store._id,
        store_id: store_data.store._id,
        server_token: store_data.store.server_token,
        timezone: store_data.timezone,
        order_status: order_status,
        order_id: order_id,
      }
      let apires = await set_order_status(data)
      console.log(73, apires);
      if (apires.data.success) {
        initial()
      }
    } catch (error) {
      setLoader(false)
      console.log(error);
    }
  }

  const onCompleteOrder = async (order_id) => {
    setLoader(true)
    try {
      let data = {
        id: store_data.store._id,
        store_id: store_data.store._id,
        server_token: store_data.store.server_token,
        timezone: store_data.timezone,
        order_id: order_id,
      }
      let apires = await complete_order(data)
      console.log(73, apires);
      if (apires.data.success) {
        initial()
      }
    } catch (error) {
      console.log(error);
    }
  }

  return (
    <div className={styles.order_container}>
      <ToastContainer />
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}>
          <div className={styles.titleWithBC}>
            <h3>Past Order</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>Running Order</li>
              <li className={styles.breadcrumb_item}>Past Order</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection}>
          <span className={styles.per_page}>Per Page</span>
          <select className={styles.btn_page}>
            <option value={10}>10</option>
            <option value={20}>20</option>
            <option value={50}>50</option>
            <option value={100}>100</option>
          </select>
          {/* <button className={styles.exportButton}>Export</button> */}
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <Select className={styles.searchBy}
                defaultValue={''}
                isSearchable={false}
                placeholder='Search By : User'
                onChange={set_search_by}
                options={searchOptions}
              ></Select>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Search" className={styles.searchbox} onChange={(e) => setFilter_data({ ...filter_data, search_value: e.target.value })} />
                <button className={styles.searchIcon} type="submit">
                  <span><i className="fa-light fa-magnifying-glass"></i></span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <Select className={styles.btn_delivery}
                defaultValue={''}
                isMulti
                isSearchable={false}
                placeholder='Delivery Type'
                styles={selectStyles}
                options={deliveryOptions}
                onChange={set_delivery_type}
              >
              </Select>
            </div>
            <div className={styles.navItem}>
              {!loader &&
                <Select className={styles.btn_delivery}
                  defaultValue={''}
                  isMulti
                  isSearchable={false}
                  placeholder='Select Payment By '
                  styles={selectStyles}
                  options={paymentOptions}
                  onChange={set_payment_by}
                ></Select>
              }
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply} onClick={onSearchApply}>Apply</button>
            </div>
          </div>
          <div className={styles.nav_item_right}>
            <div className={styles.navItem}>
              <button className={styles.exportButton}>Export</button>
            </div>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className="card rounded-0">
          <div className="card-body table-responsive">
            <table className="table orderlist_table" >
              <thead className={styles.thead_light}>
                <tr className={styles.table_heads}>
                  <th>#</th>
                  <th>Name</th>
                  <th style={{ width: '25%' }}>Address</th>
                  <th>Price</th>
                  <th>Type</th>
                  <th>Status</th>
                  <th>Info</th>
                </tr>
              </thead>
              <tbody className={styles.tbody}>
                {loader && <div className="loaderContainer"></div>}
                {order_list.length > 0 ?
                  order_list.map((v, i) =>
                    <tr className="text-left" key={'past_' + i}>
                      <td >{v.unique_id}</td>
                      <td onClick={() => setOrderDetails(true)}>{v.user_name || 'N/A'}</td>
                      <td onClick={() => setOrderDetails(true)}>{v.address || 'N/A'}</td>
                      <td >{v.total} ({v.payment_method})</td>
                      <td >
                        {(!v.is_user_pick_up_order && !v.is_schedule_order) && <span className={classNames(styles.typeLabel)}>Delivery</span>}
                        {v.is_user_pick_up_order && <span className={classNames(styles.typeLabel)}>Pickup</span>}
                        {v.is_schedule_order && <span className={classNames(styles.typeLabel)}>Schedule Delivery</span>}
                      </td>
                      <td >
                        {v.order_status === 1 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> Waiting </span>
                          : v.order_status === 3 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> Accepted </span>
                            : v.order_status === 5 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> Prepared </span>
                              : v.order_status === 50 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> Way to BD </span>
                                : v.order_status === 51 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> BD Customs </span>
                                  : v.order_status === 52 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> BD Warehouse </span>
                                    : v.order_status === 7 ? <span style={{ width: 120 }} className={classNames(styles.statusLabel)}> Ready </span>
                                      : <span className={styles.statusLabel}> .... </span>
                        }
                      </td>
                      <td >
                        {v.order_status === 1 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 3)}>Accept</button>
                          : v.order_status === 3 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 5)}>Prepared</button>
                            : v.order_status === 5 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 50)}>Way to BD</button>
                              : v.order_status === 50 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 51)}>BD Customs</button>
                                : v.order_status === 51 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 52)}>BD Warehouse</button>
                                  : v.order_status === 52 ? <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onChangeStatus(v._id, 7)}>Ready</button>
                                    : v.order_status === 7 && (!v.is_user_pick_up_order && !store_data.store.is_store_can_complete_order) ?
                                      <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => ''}>Waiting For Delivery Man</button>
                                      : v.order_status === 7 && (v.is_user_pick_up_order || store_data.store.is_store_can_complete_order) ?
                                        <button style={{ width: 120 }} className={classNames(styles.infoLabel)} onClick={() => onCompleteOrder(v._id)}>Complete</button>
                                        : <button style={{ width: 120 }} className={classNames(styles.infoLabel)}>Completed</button>
                        }
                      </td>
                    </tr>
                  )
                  :
                  <tr className="text-center">
                    <td colSpan="8">Orders Not Found...</td>
                  </tr>
                }
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {orderDetails && <OrderDetails setOrderDetails={setOrderDetails} />}
    </div>
  );
}

export default PastOrder;
