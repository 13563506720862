/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import main_logo from "../../../assets/UKBD.png"
import { useCart } from "react-use-cart";
import CurrencyFormat from 'react-currency-format';
import { IMAGE_URL } from "../../../Config/Config"
import styles from '../../../assets/css/Cart.module.css'
import AddButton from './AddButton';
import { getCurrencySign, addWeights, addParcentage } from '../../../Utils/common';

function CartItems(props) {
    const select_item = localStorage.getItem('delivery_id');
    const delivery = useSelector((state) => state.rootReducer.delivery);
    const [activeDelivery, setactiveDelivery] = useState(select_item === '' || select_item === null ? delivery[0] : delivery.filter(v => v._id === select_item)[0])
    const user = useSelector((state) => state.rootReducer.user_data);
    const dispatch = useDispatch()

    console.log(delivery, user);
    const {
        items,
        // updateItemQuantity,
    } = useCart();

    useEffect(() => {
        const totalWeight = addWeights(items);
        const totalParcentage = addParcentage(items);
        dispatch({ type: "set_cart_total_weight", payload: totalWeight })
        dispatch({ type: "set_cart_total_parcentage", payload: totalParcentage })
    }, [])

    const fractionCheck = (v) => {
        let data = v.split(' ');
        if (data[1].indexOf('.') === -1) {
            return v;
        } else {
            let frc = data[1].split('.')
            return <><span>{data[0]} {frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
        }
    }

    // const viewComment = (id) => {
    //     let note = '';
    //     if (props.itemNotes) {
    //         for (let i of props.itemNotes) {
    //             if (id === i.item_id) {
    //                 note = i.note;
    //             }
    //         }
    //     }
    //     return note
    // }

    return (

        props.type && props.type === 'order_item' ?
            props.order.cart_detail.order_details.map(v =>
                <div key={v.id} className={styles.carting_item}>
                    <div className={styles.itemImg_2}>
                        <img src={IMAGE_URL + v.image_url[0]} alt="product_image" onError={(event) => { event.currentTarget.src = main_logo }} />
                    </div>
                    <div className={styles.itemDetails_2}>
                        <h2 className={styles.carting_name_2}>{v.name}</h2>
                        <p className={styles.carting_shortDesc}></p>
                        <p className={styles.carting_size}></p>
                    </div>
                    <AddButton data={v} theme="cart" />

                    <div className={styles.itemPrice_2}>
                        <CurrencyFormat value={Number(v.itemTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign(activeDelivery.delivery_currency_code) + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                    </div>
                </div>
            )
            :
            items.map(v =>
                <div key={v.id} className={styles.carting_item}>
                    <div className={styles.itemImg}>
                        <img src={IMAGE_URL + v.image_url[0]} alt="product_image" onError={(event) => { event.currentTarget.src = main_logo }} />
                    </div>
                    <div className={styles.itemDetails}>
                        <h2 className={styles.carting_name}>{v.name}</h2>
                    </div>
                    {/* {user && <div className={styles.itemComment} onClick={() => props.setCommentPopup({ status: true, item_id: v.id, note: viewComment(v.id) })}>
                        {viewComment(v.id) !== '' ?
                            <p><span style={{fontWeight: 500}}>Comment:</span>  {viewComment(v.id)} </p>
                            :
                            <div className={styles.addCommentBtn}>
                                <span className={styles.cIcon}><i className="fa-thin fa-comment"></i></span>
                                <span className={styles.clabel}>Add comment</span>
                            </div>
                        }
                    </div>} */}
                    <AddButton data={v} theme="cart" />
                    <div className={styles.itemPrice}>
                        <CurrencyFormat value={Number(v.itemTotal).toFixed(2)} displayType={'text'} thousandSeparator={true} prefix={getCurrencySign('GBP') + ' '} renderText={value => <p className={styles.uk_price}>{fractionCheck(value)}</p>} />
                    </div>
                </div>
            )
    )
}

export default CartItems;