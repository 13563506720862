import React, { useEffect, useState } from "react";
import styles from "../../../assets/css/User.module.css";
import DynamicForm from "./Form/page";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function FormPreview() {
    const [call_form, setCall_form] = useState(false);
    const [form_id, setForm_id] = useState('');

    const handle_get_form = () => {
        if (form_id.trim() !== '') {
            setCall_form(true)
        }
    }

    return (
        <div className={styles.brand_page}>
            <ToastContainer />
            {/* {loader && <div className="loaderContainer"></div>} */}
            <div className={styles.itemsPageHeader}>
                <div className={styles.pageHeaderSection}>
                    <div className={styles.titleWithBC}>
                        <h3>Form</h3>
                        <ol className={styles.breadcrumb}>
                            <li className={styles.breadcrumb_item}>Home</li>
                            <li className={styles.breadcrumb_item}>Form Preview</li>
                        </ol>
                    </div>
                </div>
                <div className={styles.pageHeaderSection} style={{ justifyContent: 'space-between' }}>
                    {/* <p style={{ marginBottom: 0 }}>Data ({forms.length})</p> */}
                    <div className={styles.nav}>
                        <div className={styles.nav_item_left}>
                            <div className={styles.navItem}>
                                <div className={styles.searchbar}>
                                    <input type="text" placeholder="Enter Form Id" className={styles.searchbox} value={form_id} onChange={(e) => setForm_id(e.target.value)} />
                                    <button className={styles.searchIcon} type="submit" onClick={handle_get_form}>
                                        <span><i className="fa-light fa-magnifying-glass"></i></span>
                                    </button>
                                </div>
                            </div>

                        </div>
                        {/* <div className={styles.bottomSeparator}></div> */}
                    </div>

                </div>
            </div>
            <div className={styles.main_body}>
                <div className="row">
                    <div className="col-md-12 col-lg-12 col-xs-12">
                        <div className="card rounded-0">
                            <div className="card-body table-responsive">
                                {(call_form && form_id !== '') ? <DynamicForm form_id={form_id} /> : 'Get form by form id'}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default FormPreview;