import React, { useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import styles from "../../../assets/css/ProductItems.module.css";
import "../../../assets/css/ProductItems.module.css";
import { IMAGE_URL } from "../../../Config/Config";
// import { getItemFromProductForStore } from "../../../Utils/method";
// import AddSubCategory from "./AddSubCategory";
import { useDispatch, useSelector } from "react-redux";

function ProductItems() {
  const params = useParams();
  const dispatch = useDispatch();
  const [items, setItems] = useState([]);
  const [loader, setLoader] = useState(true);
  const [expandedItem, setExpandedItem] = useState("");
  const addSubcategoryPopup = useSelector(
    (state) => state.rootReducer.addSubcategoryPopup
  );

  useEffect(() => {
    let _isMounted = true;
    async function initialdata() {
      try {
        setLoader(true);
        let data = {
          product_id: params._id,
          page: 1,
          // is_partner: true,
          is_editor: true,
          // is_partner: true,
          limit: 20,
        };
        // let apires = await getItemFromProductForStore(data);
        // console.log(13, "useEffect", apires);
        // if (_isMounted) {
        //   console.log(14, "useEffect", apires.data.success);
        //   if (apires.data.success) {
        //     setItems(apires.data.paginate_data.docs);
        //     console.log("call API", apires.data.paginate_data.docs);
        //   }
        // }
        setLoader(false);
      } catch (error) {
        console.log(error);
      }
    }
    initialdata();

    return () => {
      _isMounted = false;
    };
  }, [params._id]);

  useEffect(() => {
    console.log(44, items);
  }, [items]);

  return (
    <>
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}></div>
        <div className={styles.pageHeaderSection}>
          <div className={styles.searchSection}>
            <input
              className={styles.searchInput}
              type={"text"}
              placeholder="Search"
            />
          </div>
          <Link
            to={"/menu/add-new-item"}
            className={styles.addItemBtn}
            style={{ marginRight: "15px" }}
          >
            Add new item
          </Link>
          <div
            onClick={() =>
              dispatch({ type: "set_addSubcategoryPopup", payload: true })
            }
            className={styles.addItemBtn}
          >
            Add Sub-category
          </div>
        </div>
      </div>
      <div className={styles.itemsContainer}>
        {!loader &&
          items.map((v) => (
            <div className={styles.mainItems} key={v._id}>
              <div
                className={styles.itemSection}
                onClick={() =>
                  setExpandedItem((prev) => (prev === v._id ? "" : v._id))
                }
              >
                <div className={styles.itemImage}>
                  <img src={IMAGE_URL + v.image_url[0]} alt={"item"} />
                </div>
                <div className={styles.itemTitle}>
                  <p>{v.name[0]}</p>
                </div>
                <div className={styles.itemPrice}>
                  <p>
                    {v.wallet_currency_code} {v.view_price}
                  </p>
                </div>
              </div>
              {expandedItem === v._id && (
                <div className={styles.expandSection}>
                  <div className="row p-3">
                    <div className="col-md-6">
                      <textarea
                        cols="30"
                        rows="auto"
                        className="form-control rounded-0"
                      ></textarea>
                    </div>
                    <div className="col-md-6">
                      <div className="row mb-3">
                        <label className="col-sm-5 col-form-label text-end">
                          Sub-Category
                        </label>
                        <div className="col-sm-7 ">
                          <select className="form-control rounded-0">
                            <option>Cadbury</option>
                            <option>KitKat</option>
                            <option>Lindit</option>
                            <option>Maltesers</option>
                          </select>
                        </div>
                      </div>
                      <div className="row mb-3">
                        <label className="col-sm-7 col-form-label text-end">
                          Sequence No. (* only 4 digit)
                        </label>
                        <div className="col-sm-5 flex-column">
                          <div className="input-group">
                            <span className="text-center width-40 input-group-text rounded-0">
                              101
                            </span>
                            <input
                              type="number"
                              className="form-control rounded-0"
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row p-3">
                    <div className="col-md-12">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="is_visible"
                            />
                            <label
                              className="form-check-label small"
                              for="is_visible"
                            >
                              {" "}
                              Visible{" "}
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="in_stock"
                            />
                            <label
                              className="form-check-label small"
                              for="in_stock"
                            >
                              {" "}
                              In-Stock{" "}
                            </label>
                          </div>
                          <div className="form-check form-switch">
                            <input
                              type="checkbox"
                              className="form-check-input"
                              id="is_popular"
                            />
                            <label
                              className="form-check-label small"
                              for="is_popular"
                            >
                              {" "}
                              Popular{" "}
                            </label>
                          </div>
                          <div className="form-group">
                            <div className="input-group mt-1">
                              <span className="text-center d-block width-40 input-group-text p-2 rounded-0 small">
                                {" "}
                                SN{" "}
                              </span>
                              <input
                                type="text"
                                className="form-control rounded-0"
                                placeholder="Supplier Name"
                              />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="input-group mt-1">
                              <span className="text-center d-block width-40 input-group-text p-2 rounded-0 small">
                                {" "}
                                BN{" "}
                              </span>
                              <input
                                type="text"
                                className="form-control rounded-0"
                                placeholder="Brand Name"
                              />
                            </div>
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_none"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_none"
                                >
                                  {" "}
                                  NONE{" "}
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_10"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_10"
                                >
                                  {" "}
                                  10% OFF{" "}
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_20"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_20"
                                >
                                  {" "}
                                  20% OFF{" "}
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_30"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_30"
                                >
                                  {" "}
                                  30% OFF{" "}
                                </label>
                              </div>
                              <div className="input-group input-group mt-1">
                                <span className="text-center d-block width-40 input-group-text p-2 rounded-0">
                                  {" "}
                                  SI{" "}
                                </span>
                                <select className="form-control rounded-0">
                                  <option>Aldi.png</option>
                                  <option>Argos.png</option>
                                  <option>Best_pets.png</option>
                                  <option>Boots.png</option>
                                </select>
                              </div>
                              <div className="form-group">
                                <div className="input-group input-group mt-1">
                                  <span className="text-center d-block width-40 input-group-text p-2 rounded-0">
                                    {" "}
                                    BI{" "}
                                  </span>
                                  <select className="form-control rounded-0">
                                    <option>Apple.png</option>
                                    <option>Addidas.png</option>
                                    <option>Albetta.png</option>
                                    <option>Aero.png</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_buy1get1"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_buy1get1"
                                >
                                  {" "}
                                  BUY 1 GET 1 FREE{" "}
                                </label>
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_custom1"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_custom1"
                                >
                                  {" "}
                                  Custom Tag 1{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-0"
                                  placeholder="Custom Tag 1"
                                />
                              </div>
                              <div className="form-check form-switch">
                                <input
                                  type="checkbox"
                                  className="form-check-input"
                                  id="is_custom2"
                                />
                                <label
                                  className="form-check-label small"
                                  for="is_custom2"
                                >
                                  {" "}
                                  Custom Tag 2{" "}
                                </label>
                                <input
                                  type="text"
                                  className="form-control rounded-0"
                                  placeholder="Custom Tag 2"
                                />
                              </div>
                            </div>
                            <div className="form-group">
                              <div className="input-group input-group mt-1">
                                <span className="rounded-0 text-center d-block width-40 input-group-text p-2">#
                                </span>
                                <input
                                  type="text"
                                  className="form-control rounded-0"
                                />
                              </div>
                              <button href="#" className="btn btn-outline-primary position-relative mt-2 rounded-0"> Go </button>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-3">
                          <div className="row">
                            <div className="col-md-12">
                              <span className="btn btn-primary position-relative mt-2 rounded-0">
                                {" "}
                                More Edit{" "}
                              </span>
                            </div>
                            <div className="col-md-12 mt-2 flex-column">
                              <div className="form-group">
                                <div className="input-group mt-1">
                                  <span className="text-center d-block width-40 input-group-text p-2 rounded-0 small">
                                    {" "}
                                    £{" "}
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control rounded-0"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-2 flex-column">
                              <div className="form-group">
                                <div className="input-group mt-1">
                                  <span className="text-center d-block width-40 input-group-text p-2 rounded-0 small">
                                    {" "}
                                    KG{" "}
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control rounded-0"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-2 flex-column">
                              <div className="form-group">
                                <div className="input-group mt-1">
                                  <span className="text-center d-block width-40 input-group-text p-2 rounded-0 small">
                                    {" "}
                                    Web{" "}
                                  </span>
                                  <input
                                    type="number"
                                    className="form-control rounded-0"
                                  />
                                </div>
                              </div>
                            </div>
                            <div className="col-md-12 mt-2 flex-column">
                              <div className="form-group">
                                <div className="input-group input-group mt-1">
                                  <span className="text-center d-block width-40 input-group-text p-2 rounded-0">
                                    {" "}
                                    DT{" "}
                                  </span>
                                  <select className="form-control rounded-0">
                                    <option>Select Delivery Time</option>
                                    <option>2 To 10 Days</option>
                                    <option>7 To 10 Days</option>
                                    <option>15 days. Subject to BD Customs</option>
                                    <option>Same Day</option>
                                  </select>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
      </div>
      {/* {addSubcategoryPopup && <AddSubCategory />} */}
    </>
  );
}

export default ProductItems;
