/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react'
import styles from '../../../assets/css/CreateOrder.module.css'
import { IMAGE_URL } from '../../../Config/Config'
import brandPng from '../../../assets/brand.png'
// import AddNewOrder from './AddNewOrder'
// import DeliveryParcel from './DeliveryParcel'
import { getStoreData, get_product_list, get_store_product_item_list } from '../../../Utils/method'
import { useCart } from "react-use-cart";
import classNames from 'classnames'
import { IoBagSharp } from 'react-icons/io5';
import SearchSuggestion from './SearchSuggestion'
import Cart from '../Cart/Cart'
import Checkout from '../Checkout/Checkout'

function CreateOrder() {
  const { addItem, inCart, totalUniqueItems, updateItemQuantity, removeItem, items, cartTotal } = useCart();
  const store_data = getStoreData({ type: 'get' });
  const [loader, setLoader] = useState(true)
  // const [orders, setOrders] = useState([]);
  const [product_list, setProduct_list] = useState([]);
  const [all_items, setItems] = useState([]);
  const [cart_stage, setCart_stage] = useState(0);

  // const updateArray = (nv) => {
  //   setOrders([...orders, nv])
  // }

  useEffect(() => {
    let _isMounted = true;
    async function initial() {
      setLoader(true);
      try {
        let data = {
          store_id: store_data.store._id,
          server_token: store_data.store.server_token,
        }
        let apires = await get_product_list(data);
        if (_isMounted) {
          console.log('get_product_list:', apires);
          if (apires.success) {
            setProduct_list(apires.products);
            onchangeProduct(apires.products[0]._id)
          }
          setLoader(false);
        }
      } catch (error) {
        setLoader(false);
        console.log(error)
      }
    }
    initial()
    return () => { _isMounted = false }
  }, [])

  const onchangeProduct = async (v) => {
    try {
      let d = {
        store_id: store_data.store._id,
        server_token: store_data.store.server_token,
        product_id: v,
        timezone: store_data.timezone,
      }
      let apires = await get_store_product_item_list(d);
      if (apires.data.success) {
        setItems(apires.data.products[0].items)
        console.log(80, apires.data.products[0].items);
      }
      console.log(70, apires);
    } catch (error) {
      console.log(error);
    }
  }

  const addto_cart = (item) => {
    console.log(item)

    if (inCart(item._id)) {
      items.filter(k => k.id === item._id).map(v =>
        updateItemQuantity(item._id, v.quantity + 1)
      )
      // q = items.filter(k => k.id === item._id)[0].quantity + 1;
    } else {
      addItem({ ...item, id: item._id })
    }
  }

  const [searchSuggest, setSearchSuggest] = useState(false)
  const openOnFocus = () => {
    setSearchSuggest(prev => !prev)
  }

  const fractionCheck = (v) => {
    console.log(97, v);
    // let data = v.split(' ');
    let data = v.toString();
    if (data.indexOf('.') === -1) {
      return v;
    } else {
      let frc = data.split('.')
      return <><span>{frc[0]}</span>.<span className={styles.fractionValue}>{frc[1]}</span></>
    }
  }

  return (
    <div className={styles.brand_page}>
      {loader && <div className="loaderContainer"></div>}
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection}>
          <div className={styles.titleWithBC}>
            <h3>Create Order</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>Create Order</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection}>
          <div className=''>
            <select className={styles.searchbar} onClick={(e) => onchangeProduct(e.target.value)}>
              {product_list.filter(i => i.name.length !== 0).map(v => <option value={v._id} key={v._id}>{v.name[0]}</option>)}
            </select>
          </div>
          <div className={styles.nav}>
            <div className={styles.nav_item_left}>
              <div className={styles.navItem}>
                <div className={styles.searchbar}>
                  <input type="text" placeholder="Search" className={styles.searchbox} onFocus={openOnFocus} />
                  <button className={styles.searchIcon} type="submit">
                    <span><i className="fa-light fa-magnifying-glass"></i></span>
                  </button>
                </div>
              </div>
            </div>
          </div>
          <div className={styles.cart_total_section} onClick={() => items.length > 0 ? setCart_stage(1) : null}>
            <IoBagSharp color="#ff0000" size={16} style={{ position: 'relative', top: '-1px' }} />
            {totalUniqueItems > 0 && <span className={styles.pro_qty} style={{ color: "#ff0000", marginLeft: 2 }}>[{totalUniqueItems}]</span>}
            <span className={styles.cartTotal}>£ {fractionCheck(cartTotal.toFixed(2))}</span>
          </div>
        </div>
      </div>
      <div className={styles.main_body}>
        <div className={styles.page_divider}>
          <div className={styles.listContainer}>
            {all_items.map((v) =>
              <div className="col-md-12" key={v._id}>
                <div className={styles.orderItem}>
                  <div className={styles.orderItemHolder}>
                    {v.image !== '' ?
                      <img className={styles.orderItemImg} src={IMAGE_URL + v.image_url[0]}
                        onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = brandPng }} alt='item_img' />
                      :
                      <img className={styles.orderItemImg} src={brandPng} onError={({ e }) => { e.onerror = null; e.src = brandPng; }} alt='category_img' />
                    }
                  </div>
                  <p className={styles.orderItemTitle}>{v.name[0]}</p>
                  <button className={classNames(styles.EditBtn, inCart(v._id) && styles.alreadyIncart)}>
                    <span className={styles.delteAction} onClick={() => addto_cart(v)}><i className="fa-thin fa-plus"></i></span>
                  </button>
                </div>
              </div>
            )}
          </div>
        </div>
        <div className={styles.page_divider}>
          <div className={styles.cartSection}>
            <div className={styles.cart_header}>
              <span>ITEM ({totalUniqueItems})</span>
              <span>PRICE</span>
            </div>
            <div className={styles.cart_items}>
              {items.map(v =>
                <div className={styles.cart_item} key={'_' + v.id}>
                  <div className={styles.cart_itemLeft}>
                    <p className={styles.cart_item_title}>{v.name.toString()}</p>
                    <p className={styles.cart_item_qty}>{v.quantity} pcs</p>
                  </div>
                  <div className={styles.cart_itemRight}>
                    <p className={styles.cart_item_price}>£ {v.price.toFixed(2)}</p>
                    <p className={styles.cart_item_remove} onClick={() => removeItem(v.id)}>Remove</p>
                  </div>
                </div>
              )}
            </div>
          </div>
          {/* {!loader && <AddNewOrder setCreate_order_type={setCreate_order_type} create_order_type={create_order_type} product_list={product_list} />} */}
        </div>
      </div>
      {searchSuggest && <SearchSuggestion setSearchSuggest={setSearchSuggest} />}

      {cart_stage === 1 ?
        <Cart setCart_stage={setCart_stage} />
        :cart_stage === 2 ?
        <Checkout setCart_stage={setCart_stage} />
        :null
      }
    </div>
  )
}

export default CreateOrder