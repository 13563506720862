/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState, useRef } from 'react'
import styles from '../../../../assets/css/AddSubCategory.module.css'
import { IMAGE_URL } from '../../../../Config/Config';
import brandPng from '../../../../assets/brand.png'
import { add_new_brand, edit_brand, get_domains, } from '../../../../Utils/method'
import { toast } from 'react-toastify';
import Select from 'react-select'
import classNames from 'classnames';

function AddNewBrand(props) {

  const formRef = useRef(null);
  const [curentImage, setCurentImage] = useState('');
  const [domain_list, setDomain_list] = useState(props.domain_list);
  const [selected_domain, setSelected_domain] = useState([]);
  const [additionalFields, setAdditionalFields] = useState([{ id: 'ad_01', label: '', type: '', name: '', value: '', options: [{ id: 'op_01', label: '', value: '' }] }]);

  const [formData, setFormData] = useState({
    name: '',
    image: '',
    domain_ids: [],
    // additional_fields: []
  })
  useEffect(() => {
    if (props.editStatus) {
      setFormData({
        ...formData,
        name: props.editItem.name,
        image: props.editItem.image,
        domain_ids: props.editItem.domain_ids,
        // additional_fields: props.editItem.additional_fields 
      })
      setAdditionalFields(props.editItem.additional_fields.length > 0 ? props.editItem.additional_fields : [{ id: 'ad_01', label: '', type: '', name: '', value: '', options: [{ id: 'op_01', label: '', value: '' }] }])

      let _domain = []
      for (const key in domain_list) {
        const domain = domain_list[key];
        for (const i in props.editItem.domain_ids) {
          const id = props.editItem.domain_ids[i];
          if (domain.value === id) {
            _domain.push(domain)
          }
        }
      }
      setSelected_domain(_domain)
    }
  }, [props.editStatus, props.editCounter])

  const getFormData = async (e, ext) => {
    if (ext) {
      setSelected_domain(e)
      setFormData({ ...formData, [ext]: [...e.map(item => item.value)] })
    } else {
      let { name, value } = e.target;
      if (name === 'image') {
        setFormData({ ...formData, [name]: e.target.files[0] })
        setCurentImage(URL.createObjectURL(e.target.files[0]));
      } else if (name === 'name') {
        setFormData({ ...formData, [name]: value })
      }
    }
  }

  const resetForm = () => {
    setFormData({ name: '', image: '', domain_ids: [] })
    setAdditionalFields([{ id: 'ad_01', label: '', type: '', name: '', value: '', options: [{ id: 'op_01', label: '', value: '' }] }]);
    setCurentImage('');
    setSelected_domain([])
    props.setEditStatus(false);
    formRef.current.reset()
  }

  const checkInputFields = () => {
    if (formData.name === '') {
      return false;
    } else {
      return true;
    }
  }

  const saveBrand = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }

    let fields = []
    for (let i = 0; i < additionalFields.length; i++) {
      let obj = additionalFields[i]
      if (obj.label !== '' && obj.type !== '') {
        obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        if (obj.type === 'select') {
          let options = []
          for (let j = 0; j < obj.options.length; j++) {
            const option = obj.options[j];
            if (option.label !== '' && option.value !== '') {
              options.push(option)
            }
          }
          obj['options'] = options;
          fields.push(obj)
        } else {
          fields.push(obj)
        }
      }
    }
    console.log('fields:', fields);

    data.append('additional_fields', JSON.stringify(fields));
    data.append('name', formData.name);
    data.append('image_url[0]', formData.image);
    if (checkInputFields()) {
      try {
        let apires = await add_new_brand(data);
        toast.success('Brand Added successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        resetForm();
        props.updateArray(apires.brand)
      } catch (error) {
        console.log(error)
        toast.error(error.error_description, {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
      }
    }
  }

  const updateBrand = async () => {
    const data = new FormData();
    for (let i = 0; i < formData.domain_ids.length; i++) {
      data.append('domain_ids[' + i + ']', formData.domain_ids[i]);
    }
    data.append('_id', props.editItem._id);
    data.append('name', formData.name);

    let fields = []
    for (let i = 0; i < additionalFields.length; i++) {
      let obj = additionalFields[i]
      if (obj.label !== '' && obj.type !== '') {
        // if (!obj.name || obj.name === '') {
        // obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        // }
        obj['name'] = obj.label.replace(/ /g, '_').toLowerCase();
        if (obj.type === 'select') {
          let options = []
          for (let j = 0; j < obj.options.length; j++) {
            const option = obj.options[j];
            if (option.label !== '' && option.value !== '') {
              options.push(option)
            }
          }
          obj['options'] = options;
          fields.push(obj)
        } else {
          fields.push(obj)
        }
      }
    }
    console.log('Update fields:', fields);

    data.append('additional_fields', JSON.stringify(fields));

    if (typeof formData.image !== 'string') {
      data.append('image_url[0]', formData.image);
    }

    if (checkInputFields()) {
      try {
        let apires = await edit_brand(data);
        console.log(75, apires.brand);
        toast.success('Brand Updated successfully', {
          position: 'top-right',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true
        });
        props.setBrands([...props.brands.map(b => b._id === apires.brand._id ? { ...apires.brand } : b)])
        resetForm();
      } catch (error) {
        console.log(error)
      }
    }
  }

  // const get_domain_list = async () => {
  //   try {
  //     let apires = await get_domains();
  //     let data = [];
  //     let sort_v = apires.data.filter(f => f.name.length > 0).sort((a, b) => a.name.toString().localeCompare(b.name.toString()));
  //     for (const p of sort_v) {
  //       data.push({ value: p._id, label: p.name })
  //     }
  //     console.log('get_domains:', data);
  //     setDomain_list(data)
  //   } catch (error) {
  //     console.log();
  //   }
  // }

  // useEffect(() => {
  //   get_domain_list()
  // }, [])

  const isEmpty = (fields, type) => {
    let last_item = fields[fields.length - 1]
    if (type === 'option') {
      if (last_item.label !== '' && last_item.value !== '') {
        return true;
      } else {
        return false;
      }
    } else {
      if (last_item.label !== '' && last_item.type !== '') {
        // if (last_item.type === 'select') {
        //   let option = last_item.options[last_item.options.length - 1]
        //   if (option.label !== '' && option.value !== '') {
        //     return true;
        //   } else {
        //     return false;
        //   }
        // } else {
        //   return true;
        // }
        return true;
      } else {
        return false;
      }
    }
  }

  const handleAddFields = (i) => {
    isEmpty(additionalFields, 'field') &&
      setAdditionalFields([...additionalFields, { id: 'ad_' + Number(i + 2), label: '', type: '', name: '', value: '', options: [{ id: 'op_' + Number(i + 2), label: '', value: '' }] }]);
  };

  const handleFieldsData = (e, fi, oi, type) => {
    const { name, value } = e.target;
    console.log(246, name, value);
    if (type === 'field') {
      setAdditionalFields([...additionalFields.map((v, j) => fi === j ? { ...v, [name]: value.trim() } : v)])
    } else {
      setAdditionalFields([...additionalFields.map((v, j) => fi === j ? { ...v, options: [...v.options.map((ov, oj) => oi === oj ? { ...ov, [name]: value.trim() } : ov)] } : v)])
    }
  }

  const handleAddOptions = (i, oi) => {
    let item = additionalFields.filter((f, fi) => fi === i)
    isEmpty(item[0].options, 'option') &&
      setAdditionalFields([...additionalFields.map((v, j) => i === j ? { ...v, options: [...v.options, { id: 'op_' + Number(oi + 3), label: '', value: '' }] } : v)])
  }

  const handleRemove = (fid, oid, type) => {
    console.log(fid, oid, type);
    let data = [...additionalFields]
    if (type === 'field') {
      data.length > 1 && setAdditionalFields([...data.filter((f, j) => f.id !== fid)])
    }
    else {
      setAdditionalFields([...data.map((v, i) => fid === v.id ? { ...v, options: [...v.options.filter((f, j) => f.id !== oid)] } : v)])
    }
  }

  useEffect(() => {
    console.log(169, additionalFields);
  }, [additionalFields])

  return (
    <div className={styles.addSubCategoryPopup}>
      <div className={styles.addSub_header}>
        {props.editStatus ? <p>Edit Brand</p> : <p>Add New Brand</p>}
      </div>
      <form ref={formRef} className={styles.addSub_body}>
        <div className="col-md-12">
          <div className="row mt-1 mb-2">
            <div className="row">
              <label className="small mb-1">Brand Name</label>
              <div className="input-group mt-1 ">
                <input type="text" onChange={(e) => getFormData(e)} value={formData.name} name="name" className="form-control rounded-0" placeholder='Brand Name' />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small">Domain</label>
              <div className="mt-1 ">
                <Select
                  value={selected_domain}
                  isMulti
                  name="domain_ids"
                  className="form-control rounded-0 basic-multi-select"
                  classNamePrefix="select"
                  onChange={(e) => getFormData(e, 'domain_ids')}
                  options={domain_list}
                />
              </div>
            </div>
            <div className="row mt-3">
              <label className="small" style={{ position: 'relative' }}>Additional Fields </label>
              {/* <span onClick={() => handleAddFields()} className={styles.add_fields}><i className="fa-light fa-plus"></i></span> */}
            </div>
            {additionalFields.map((v, i) =>
              <div className="row mt-2" key={'field_' + i}>
                <div className='col-md-12'>
                  <div className="row">
                    <div className='col-md-5'>
                      <input type="text" name='label' value={v.label} onChange={(e) => handleFieldsData(e, i, undefined, 'field')} className="form-control rounded-0" placeholder='Field Label' />
                      {/* <input type="text" name='name' value={v.name ? v.name : 'ad_fi_0' + i + 1} onChange={(e) => handleFieldsData(e, i, undefined, 'field')} /> */}
                    </div>
                    <div className='col-md-5'>
                      <select className="form-control rounded-0" name='type' value={v.type} onChange={(e) => handleFieldsData(e, i, undefined, 'field')}>
                        <option value={''} disabled>Field Type</option>
                        <option value={'text'}>Text</option>
                        <option value={'number'}>Number</option>
                        <option value={'email'}>Email</option>
                        <option value={'date'}>Date</option>
                        <option value={'select'}>Select</option>
                      </select>
                    </div>
                    <div className='col-md-1'>
                      <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                        <span onClick={() => handleRemove(v.id, undefined, 'field')} className={styles.add_fields} style={{ backgroundColor: '#ff0000' }}><i className="fa-light fa-times"></i> </span>
                      </div>
                    </div>
                    {additionalFields.length === i + 1 &&
                      <div className='col-md-1'>
                        <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                          <span onClick={() => handleAddFields(i)} className={styles.add_fields}><i className="fa-light fa-plus"></i> </span>
                        </div>
                      </div>
                    }
                  </div>
                  {v.type === 'select' &&
                    <div className='col-md-12'>
                      {v.options.map((option, oi) =>
                        <div className="row mt-2" key={'option_' + oi}>
                          <div className='col-md-5'>
                            <input type="text" name='label' style={{ backgroundColor: '#ced4df' }} value={option.label} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder='Option label' required />
                          </div>
                          <div className='col-md-5'>
                            <input type="text" name='value' style={{ backgroundColor: '#ced4df' }} value={option.value} onChange={(e) => handleFieldsData(e, i, oi, 'option')} className={classNames(styles.input_field_item, "form-control rounded-0")} placeholder='Option value' required />
                          </div>
                          <div className='col-md-1'>
                            <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                              <span onClick={() => handleRemove(v.id, option.id, 'option')} className={styles.add_fields} style={{ backgroundColor: '#ff0000' }}><i className="fa-light fa-times"></i> </span>
                            </div>
                          </div>
                          {v.options.length === oi + 1 &&
                            <div className='col-md-1'>
                              <div style={{ minWidth: '24px', maxWidth: '100%', height: '38px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                                <span onClick={() => handleAddOptions(i, oi)} className={styles.add_fields} style={{ backgroundColor: '#ced4df', color: '#012965' }}> <i className="fa-light fa-plus"></i></span>
                              </div>
                            </div>
                          }
                        </div>
                      )}
                    </div>
                  }
                </div>
              </div>
            )}
            <div className="row mt-3">
              <label className="small">Brand Icon</label>
              <div className="input-group mt-1 ">
                <input type="file" onChange={(e) => getFormData(e)} name="image" className="form-control rounded-0" placeholder='Sub Category Name' />
              </div>
            </div>
            <div className="row">
              <div className="input-group mt-1 ">
                <div className={styles.img_view_container}>
                  {props.editStatus && curentImage === '' ?
                    <img style={{ maxWidth: 150 }} src={IMAGE_URL + props.editItem.image} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = brandPng }} alt={'Brand Icon'} />
                    : props.editStatus && curentImage !== '' ?
                      <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = brandPng }} alt={'Brand Icon'} />
                      : !props.editStatus && curentImage !== '' ?
                        <img style={{ maxWidth: 150 }} src={curentImage} onError={({ currentTarget }) => { currentTarget.onerror = null; currentTarget.src = brandPng }} alt={'Brand Icon'} />
                        : <p style={{ fontFamily: 'var(--robotoC)', color: '#ced4da' }}>Image Preview</p>
                  }
                </div>
              </div>
            </div>
          </div>
        </div>
      </form>
      {props.editStatus ?
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Cancel</button>
          <button className={styles.submitBtn} onClick={updateBrand}>Update</button>
        </div>
        :
        <div className={styles.addSub_footer}>
          <button className={styles.cancelBtn} onClick={resetForm}>Reset</button>
          <button className={styles.submitBtn} onClick={saveBrand}>Submit</button>
        </div>}
    </div>
  )
}

export default AddNewBrand