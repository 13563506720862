import React from "react";
import styles from "../../../../assets/css/PastOrder.module.css";
import { Link } from "react-router-dom";

function TableBooking() {
  return (
    <div className={styles.order_container}>
      <div className={styles.itemsPageHeader}>
        <div className={styles.pageHeaderSection} style={{width: '70%'}}>
          <div className={styles.titleWithBC}>
            <h3>Table Booking</h3>
            <ol className={styles.breadcrumb}>
              <li className={styles.breadcrumb_item}>Home</li>
              <li className={styles.breadcrumb_item}>Order</li>
              <li className={styles.breadcrumb_item}>Running Order</li>
              <li className={styles.breadcrumb_item}>Table Booking</li>
            </ol>
          </div>
        </div>
        <div className={styles.pageHeaderSection} style={{width: '30%'}}>
          <button className={styles.exportButton}>Export</button>
        </div>
      </div>
      <div className={styles.filterSection}>
        <div className={styles.nav}>
          <div className={styles.nav_item_left}>
            <div className={styles.navItem}>
              <button className={styles.searchBy}>
                Search By : User
                <span>
                  <i class="fa-duotone fa-caret-down"></i>
                </span>
              </button>
            </div>
            <div className={styles.navItem}>
              <div className={styles.searchbar}>
                <input type="text" placeholder="Search" className={styles.searchbox} />
                <button className={styles.searchIcon} type="submit">
                  <span><i class="fa-light fa-magnifying-glass"></i></span>
                </button>
              </div>
            </div>
            <div className={styles.navItem}>
              <button className={styles.btn_apply}>Apply</button>
            </div>
          </div>
          <div className={styles.nav_item_right}>
            <div className={styles.navItem}>
              <span className={styles.per_page}>Per Page</span>
              <button className={styles.btn_page}>
                10 <span> <i class="fa-duotone fa-caret-down"></i> </span>
              </button>
            </div>
          </div>
        </div>
      </div>

      <div className={styles.main_body}>
        <div className="row">
          <div className="col-md-12 col-lg-12 col-xs-12">
            <div className="card rounded-0">
              <div className="card-body table-responsive">
                <table className="table orderlist_table">
                  <thead className={styles.thead_light}>
                    <tr className={styles.table_heads}>
                      <th>#</th>
                      <th>Name</th>
                      <th>Price</th>
                      <th>Table No</th>
                      <th>Person No</th>
                      <th>Type</th>
                      <th>Status</th>
                      <th>Info</th>
                    </tr>
                  </thead>
                  <tbody className={styles.tbody}>
                    <tr className="text-left">
                      <td >126</td>
                      <td >AH Nayeem</td>
                      <td >771.00 (cash)</td>
                      <td >#8</td>
                      <td >#6</td>
                      <td ><span className={styles.typeLabel}>Delivery</span></td>
                      <td ><span className={styles.statusLabel}>Waiting</span></td>
                      <td ><button className={styles.infoLabel}>Accept</button></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="row mt-2">
        <div className="col-12">
          <pagination className="justify-content-center">
            <ul className="pagination justify-content-center">
              <li className="pagination-prev page-item disabled">
                <a className={styles.arrow_links}>
                  <i class="fa-duotone fa-circle-arrow-left" style={{ fontSize: 28, paddingTop: 10 }}></i>
                </a>
              </li>
              <li className="pagination-page page-item active">
                <a href="#" className={styles.page_link}>1</a>
              </li>
              <li className="pagination-next page-item disabled">
                <a href="#" className={styles.arrow_links}>
                  <i class="fa-duotone fa-circle-arrow-right" style={{ fontSize: 28, paddingTop: 10 }} ></i>
                </a>
              </li>
            </ul>
          </pagination>
        </div>
      </div>
    </div>
  );
}

export default TableBooking;
