import { getStoreData } from "./method";

export const ORDER_STATE = {
    WAITING_FOR_ACCEPT_STORE: 1,
    CANCELED_BY_USER: 101,
    STORE_ACCEPTED: 3,
    STORE_REJECTED: 103,
    STORE_CANCELLED: 104,
    STORE_CANCELLED_REQUEST: 105,
    STORE_PREPARING_ORDER: 5,
    OREDER_READY: 7,
    WAITING_FOR_DELIVERY_MAN: 9,
    NO_DELIVERY_MAN_FOUND: 109,
    DELIVERY_MAN_ACCEPTED: 11,
    DELIVERY_MAN_REJECTED: 111,
    DELIVERY_MAN_CANCELLED: 112,
    DELIVERY_MAN_COMING: 13,
    DELIVERY_MAN_ARRIVED: 15,
    DELIVERY_MAN_PICKED_ORDER: 17,
    DELIVERY_MAN_STARTED_DELIVERY: 19,
    DELIVERY_MAN_ARRIVED_AT_DESTINATION: 21,
    DELIVERY_MAN_COMPLETE_DELIVERY: 23,
    ORDER_COMPLETED: 25,
};

export const ORDER_STATE_Case = (value) => {
    const switchObj = {
        1: () => 'WAITING_FOR_ACCEPT_STORE',
        101: () => 'CANCELED_BY_USER',
        3: () => 'STORE_ACCEPTED',
        103: () => 'STORE_REJECTED',
        104: () => 'STORE_CANCELLED',
        105: () => 'STORE_CANCELLED_REQUEST',
        5: () => 'STORE_PREPARING_ORDER',
        7: () => 'OREDER_READY',
        9: () => 'WAITING_FOR_DELIVERY_MAN',
        109: () => 'NO_DELIVERY_MAN_FOUND',
        11: () => 'DELIVERY_MAN_ACCEPTED',
        111: () => 'DELIVERY_MAN_REJECTED',
        112: () => 'DELIVERY_MAN_CANCELLED',
        13: () => 'DELIVERY_MAN_COMING',
        15: () => 'DELIVERY_MAN_ARRIVED',
        17: () => 'DELIVERY_MAN_PICKED_ORDER',
        19: () => 'DELIVERY_MAN_STARTED_DELIVERY',
        21: () => 'DELIVERY_MAN_ARRIVED_AT_DESTINATION',
        23: () => 'DELIVERY_MAN_COMPLETE_DELIVERY',
        25: () => 'ORDER_COMPLETED',
    };
    return switchObj.hasOwnProperty(value) ? switchObj[value]() : 'Data not matched';
};

export var USER_TYPE = {
    USER: 7,
    PROVIDER: 8,
    STORE: 2
}

export var DEFAULT_IMAGE_PATH = {
    PROMO_CODE: '/assets/img/default_images/promo_code.png',
    USER: '/assets/img/default_images/user.png',
    CATEGORY: '/assets/img/default_images/category.png',
    DRIVER: '/assets/img/default_images/driver.png',
    DOCUMENT: '/assets/img/default_images/document_default.png'
}

export var DATE_FORMAT = {
    DD_MM_YYYY_HH_MM_A: 'dd MMM yyyy hh:mm a',
    DD_MM_YYYY: 'dd MMM yyyy'
}

export const getCurrencySign = (currency) => {
    if (currency === 'BDT') {
        return '৳'
    } else if (currency === 'GBP') {
        return '£'
    }
}

export const alternateCurrency = (currency) => {
    if (currency === 'BDT') {
        return '£'
    } else if (currency === 'GBP') {
        return '৳'
    }
}

// price convert according to given currency
export const priceConvertTo = (v, co) => {
    //co = {
    //     "payment_currency": "GBP",
    //     "view_price_currency": "BDT",
    //     "exchange_rate_website": 0.0078
    // }
    if (co.view_price_currency === "BDT") {
        if (co.payment_currency === 'GBP') {
            let cv = Number(v * co.exchange_rate_website);
            if (Number.isInteger(cv)) {
                // console.log(11, cv)
                return cv;
            } else {
                return parseFloat(cv).toFixed(2);
            }
        } else {
            return v;
        }
    } else {
        if (co.payment_currency === 'BDT') {
            let cv = Number(v * co.exchange_rate_website);
            return Math.round(cv)
        } else {
            return v;
        }
    }
}

export const getWeekDay = (date) => {
    date = new Date(date);
    let first = date.getDate() - date.getDay();
    let start_date = new Date(date.setDate(first))
    let last = first + 6;
    let end_date = new Date(date.setDate(last))

    if (first <= 0) {
        end_date = new Date(date.setDate(last))
        end_date = new Date(new Date(end_date).setMonth(new Date(end_date).getMonth() + 1))
    } else {
        end_date = new Date(date.setDate(last))
    }

    start_date = new Date(new Date(start_date).setHours(0, 0, 0, 0)).toUTCString()
    end_date = new Date(new Date(end_date).setHours(0, 0, 0, 0)).toUTCString()

    return [start_date, end_date];
}

export const rate_convr_GBPtoBDT = (exchangeRate) => {
    // exchangeRate = 133.17 BDT
    // 1 GBP = 133.17 BDT
    return 1 * exchangeRate;
};

export const rate_convr_BDTtoGBP = (exchangeRate) => {
    // exchangeRate = 0.0076 GBP
    // 1 BDT = 0.0076 GBP
    return 1 / exchangeRate;
};

export const calculatePercentage = (percentage, number) => {
    return (percentage / 100) * number;
};

// price convert to alternative currency from given currency
export const priceConvertToAlternate = (v, c, r) => {
    // console.log(v, c, r);
    let cv = Number(v * r);
    // console.log('Alternate Value:', cv);
    if (c === 'BDT') {
        if (Number.isInteger(cv)) {
            return cv;
        } else {
            return parseFloat(cv).toFixed(2);
        }
    } else if (c === 'GBP') {
        return Math.round(cv)
    }
}

export const previousPrice = (price, offer) => {
    if (offer === 10) {
        return Number(price / 0.9).toFixed(2);
    } else if (offer === 20) {
        return Number(price / 0.8).toFixed(2);
    } else if (offer === 30) {
        return Number(price / 0.7).toFixed(2);
    } else if (offer === 50) {
        return Number(price / 0.5).toFixed(2);
    }

}

export const addWeights = (items) => {
    let totalWeight = 0;
    for (const item of items) {
        totalWeight += (item.item_weight * item.quantity * item.shipping_duties);
    }
    return totalWeight;
};

export const addParcentage = (items) => {
    let total = 0;
    for (const item of items) {
        total += calculatePercentage(item.handling_charge_in_the_uk, item.itemTotal)
    }
    return total;
}
export const Domains_access_by_user = (data) => {
    const selected_domain = JSON.parse(localStorage.getItem('domain'));
    if (selected_domain._id !== 'all_domain_access') {
        console.log(data, selected_domain.name)
        return data.filter(v => v.name === selected_domain.name);

    } else {
        return data;
    }
}

const ifSubStore = (route, condition) => {
    const sub_store = getStoreData('get').sub_store;
    if (sub_store) {
        if (condition === 1) {
            if (sub_store.access_list.indexOf(route) === -1) {
                return true
            } else {
                return false;
            }
        } else {
            if (sub_store.access_list.indexOf(route) > -1) {
                return true
            } else {
                return false;
            }
        }

    } else {
        if(condition === 1){
            return false
        }else{
            return true
        }
        
    }

}
export const RouteAccessCheck = (route, condition) => {

    const selected_domain = JSON.parse(localStorage.getItem('domain'));
    
    if (selected_domain) {
        if (selected_domain._id !== 'all_domain_access') {
            if (typeof route === 'string') {
                if (selected_domain.access_list.indexOf(route) === -1) {
                    return false;
                } else {
                    if (ifSubStore(route, 1)) {
                        return false;
                    } else {
                        return true;
                    }
    
                }
            } else {
                if (typeof route === 'object') {
                    if (condition === "OR") {
                        let check = false;
                        for (let i of route) {
                            if (selected_domain.access_list.indexOf(i) > -1 && ifSubStore(i, 2)) {
                                check = true;
                            }
                        }
                        return check;
    
                    } else {
                        let check = true;
                        for (let i of route) {
                            if (selected_domain.access_list.indexOf(i) === -1 && ifSubStore(route, 1)) {
                                check = false;
                            }
                        }
                        return check;
                    }
                }
            }
    
        } else {
            return true;
        }
    } else {
        return false;
    }
}
export const access_list = [
    { title: 'Create order', url: "/order/create-order" },
    { title: 'Add new item', url: "/menu/add-new-item" },
    { title: 'Import new item', url: "/menu/import-new-item" },
    { title: 'Brand', url: "/menu/brand" },
    { title: 'Supplier', url: "/menu/supplier" },
    { title: 'Category', url: "/menu/category" },
    { title: 'Sub category', url: "/menu/sub-category" },
    { title: 'Past order', url: "/order/past-order" },
    { title: 'Today order', url: "/order/today-order" },
    { title: 'Tomorrow order', url: "/order/tomorrow-order" },
    { title: 'Current week order', url: "/order/current-week" },
    { title: 'Current month order', url: "/order/current-month" },
    { title: 'Order history', url: "/order/history" },
    { title: 'Reviews', url: "/order/reviews" },
    { title: 'Current week earning', url: "/earning/current-week" },
    { title: 'Current month earning', url: "/earning/current-month" },
    { title: 'Earnings ', url: "/earning/earnings" },
    { title: 'Item list', url: "/menu/item-list" },
    { title: 'Modifier category', url: "/modifier/modifier-category" },
    { title: 'Modifier import data', url: "/modifier/import-data" },
    { title: 'Users', url: "/users" },
    { title: 'Offer', url: "/offer" },
    { title: 'Domains', url: "/domains" },
    { title: 'Store settings', url: "/settings/store-settings" },
    { title: 'Delivery settings', url: "/settings/delivery-settings" },
    { title: 'Time settings', url: "/settings/time-settings" },
    { title: 'Job settings', url: "/menu/jobs" },
    { title: 'Add new job', url: "/menu/add-new-job" },
    { title: 'Add new brand item', url: "/menu/add-new-brand-item" },
]